import BaseModel from "../../Base";

export default class ItemSpecVersion extends BaseModel {
  table = "growops_inventory_item_spec_versions";
  primaryKey = "inventory_item_spec_versions_pkey";

  properties = {
    uuid: "",
    created: null,
    created_by: null,
    item_id: "",
    number: 0,
  };

  relationships = {
    rel_inventory_item_spec: [],
    rel_inventory_item_spec_attributes: [],
    rel_created_user: {},
  };

  constructor(values: any = {}, store: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
    rel_inventory_item_spec {
      uuid
    }
     rel_inventory_item_spec_attributes {
      uuid
      version_id
      value
      created
      created_by
      attribute_id
      value              
       rel_inventory_item_attribute {
         category_id
         name
         options
         rel_inventory_uom {
          name
        }
         rel_data_type {
           name
         }                
      }
    }
    rel_created_user {
      name
      email
    }
    `;
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  getDisplayVersion() {
    let values: any = super.getDisplayVersion();
    return values;
  }

  getSaveVersion(data: any = {}) {
    let values: any = super.getSaveVersion();

    return values;
  }
}
