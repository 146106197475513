
import { Vue, Component, Watch, Mixins } from "vue-property-decorator";
import { Bom, BomMaterial, Lot, PackoutGood } from "@eaua/model";
import find from "lodash/find";
import union from "lodash/union";
import flatMap from "lodash/flatMap";
import BaseLotCheck from "./BaseLotCheck.vue";
import { DateTime } from "luxon";
import { get } from "lodash";
import { findTimeDiffInHours } from "@/util/helpers";
@Component
export default class BomMaterialCheck extends Mixins(BaseLotCheck) {
  [key: string]: any;
  resetSubstitutionsOnDestroy: boolean = true;
  usedLots: Array<string> = [];
  overrideItems: Array<string> = [];
  allowEmpty: boolean = false;
  extendedExpiry = false; // Extends the wip expiration by 3 days if true
  packByWarningMessage = "";
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the current BOM from the store
   */
  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }
  /**
   * Returns true if the current lot has a pack by date
   */
  get hasPackBy(): boolean {
    return !!this.currentLot.pack_by_date;
  }

  /**
   * Returns true if the current lot has a use by date
   */
  get hasUseBy(): boolean {
    return !!this.currentLot.use_by;
  }

  /**
   * Returns true if the lot has already been used.
   * Required child class to fill usedLots
   */
  get isAlreadyUsed(): boolean {
    if (!this.hasLot) return false;
    if (this.usedLots.length > 0) {
      return this.usedLots.includes(this.currentLot.uuid);
    }
    return false;
  }
  /**
   * Checks if the current lot's use by is less than now.
   * or the pack by date if use by doesn't exist on the lot.
   */
  get isExpired(): boolean {
    let expiryDate: DateTime;

    if (this.currentLot.use_by) {
      expiryDate = DateTime.fromISO(this.currentLot.use_by);
    } else if (this.currentLot.pack_by_date) {
      expiryDate = DateTime.fromISO(this.currentLot.pack_by_date);
    } else {
      return true;
    }

    if (this.extendedExpiry) {
      expiryDate = expiryDate.plus({
        days: 3,
      });
    }

    return expiryDate < DateTime.now().toUTC();
  }
  /**
   * Returns true if the entered lot is a standard bom material
   */
  get isStandardMaterial(): boolean {
    if (!this.hasLot || !this.lotMaterial) return false;
    return !this.lotMaterial.isSubstitution;
  }
  /**
   * Returns true if the current lot is a valid substitution item
   */
  get isSubstitution(): boolean {
    if (!this.hasLot || !this.lotMaterial) return false;
    return this.lotMaterial.isSubstitution;
  }

  get isInHoldRoom(): boolean {
    return (
      this.currentLot.location_id ===
      this.$store.getters["facilities/getHoldRoom"]
    );
  }
  /**
   * Returns true if the current lot is valid for the given BOM
   */
  get isValidLot(): boolean {
    // If the current lot doesn't have an on hand > 0 or it's already
    // been used, we know it's not valid
    if ((!this.currentLot.on_hand && !this.allowEmpty) || this.isAlreadyUsed)
      return false;
    // If the lot's facility doesn't match the current facility,
    // it's not valid
    if (this.currentLotFacilityId !== this.currentFacilityId) return false;
    //If lot's location is hold room, it's not valid
    if (
      this.currentLot.location_id ===
      this.$store.getters["facilities/getHoldRoom"]
    )
      return false;

    // Check if we care about expiry and if the lot is expired
    // or missing both pack by date and use by date
    if (this.checkForExpiry() && this.isExpired) {
      return false;
    }
    //Check if the override items array contains the currently scanned lot
    //**this is typically a workaround for mix wip lots used in the Packout WIP Entry */
    if (this.overrideItems.includes(this.currentLot.item_id)) return true;
    // To be valid, the lot should either be a standard material
    // or a valid substitution
    return this.isStandardMaterial || this.isSubstitution;
  }
  /**
   * Returns the material for the current lot
   */
  get lotMaterial(): BomMaterial | null {
    if (!this.hasLot) return null;
    return this.findLotMaterial(this.currentLot);
  }

  /**
   * Returns the current batch if set
   */
  get currentBatch() {
    if (get(this.$store, "state.packout.currentGoodBatches", []).length > 0) {
      if (!this.$store.state.packout.currentGoodBatches[0].completed)
        return this.$store.state.packout.currentGoodBatches[0];
    }
    return null;
  }

  /**
   * Returns the current packout if set
   */
  get currentPackoutGood(): PackoutGood {
    return new PackoutGood(this.$store?.state?.packout?.currentGood || {});
  }

  get packoutGoods(): any {
    return this.$store.state.packout.currentGoods;
  }

  get boms(): Bom[] {
    return (this.$store.state.boms.boms || []).map((m: any) => new Bom(m));
  }

  /**
   * Returns a validation message for the current lot
   * Added a check at the beginning for pack by dates on lots
   * when adding to batches in mixing
   */
  get lotValidationMessage(): string {
    if (!this.hasLot) return "";
    if (
      (this.currentBatch || this.packoutGoods.length > 0) &&
      (this.hasPackBy || this.hasUseBy) &&
      this.isValidLot
    ) {
      if (!this.validLotPackByDate()) {
        return this.packByWarningMessage;
      }
    }
    if (this.isValidLot && this.isStandardMaterial) {
      return "Valid material";
    } else if (this.isValidLot && this.isSubstitution) {
      return "Valid substitution";
    } else if (this.isAlreadyUsed) {
      return "Lot has already been added";
    } else if (this.currentLotFacilityId !== this.currentFacilityId) {
      return "Lot at different facility";
    } else if (!this.isStandardMaterial && !this.isSubstitution) {
      return "Not a valid material for this BOM";
    } else if (!this.currentLot.on_hand) {
      return "None on hand";
    } else if (this.isInHoldRoom) {
      return "Lot is in hold room";
    } else if (this.checkForExpiry()) {
      if (!this.hasPackBy && !this.hasUseBy)
        return "No pack by offset or use by date set";
      else return "Lot is expired";
    }
    return "";
  }
  /**
   * Returns an array of materials for the given BOM.
   * Sets substitutions available to each material.
   */
  get materials(): Array<BomMaterial> {
    let bomMaterials =
      this.$store.state.boms?.boms?.length > 0
        ? flatMap(this.$store.state.boms.boms, "materials")
        : this.bom?.materials;
    return (bomMaterials || []).map((m: any) => {
      const material = new BomMaterial(m);
      material.setSubstitutions(this.substitutions);
      return material;
    });
  }
  /**
   * Returns an array of substitution items populated on BOM change
   */
  get substitutions(): Array<any> {
    return this.$store.state.inventory.substitutions;
  }
  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("bom")
  onBomChanged() {
    if (!this.bom) return;
    if (!this.bom.uuid) return;
    if (!this.bom.recipe) return;
    let tagIds: Array<string> = [];
    // Loop through bom materials, collecting tags for substitution items
    for (const material of this.recipe.materials) {
      for (const t of material.material_tags) {
        tagIds = union(tagIds, [t.tag.uuid]);
      }
    }
    // If tags are available, retrieve items matching any of the tags
    // This will populate this.substitutions
    if (tagIds.length > 0) this.retrieveSubstitutions(tagIds);
  }
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  beforeDestroy() {
    if (this.resetSubstitutionsOnDestroy) {
      this.$store.commit("inventory/reset", "substitutions");
    }
  }
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns true if we care about expiry
   */
  checkForExpiry(): boolean {
    return ["wip", "finished good"].includes(
      this.currentLot.category_name.toLowerCase()
    );
  }
  findLotMaterial(lot: Lot): BomMaterial | null {
    // Look for direct materials first
    const material: any = find(this.materials || [], {
      item_id: lot.item_id,
    });
    if (material) {
      const mat = new BomMaterial(material); // To copy
      mat.isSubstitution = false;
      return mat;
    }
    // Then look if this lot is a substitution. The reason
    // this had to be two loops is on the off-chance that a
    // substitution for one material is also a top-level material
    // itself. We want to prioritize top-level materials
    for (const m of this.materials || []) {
      const sub: any = find(m.substitutions || [], {
        uuid: lot.item_id,
      });
      if (sub) {
        const mat = new BomMaterial(material); // To copy
        mat.isSubstitution = true;
        return mat;
      }
    }
    return null;
  }
  async retrieveSubstitutions(tagIds: Array<string>) {
    if (this.substitutions.length === 0) {
      this.$store.dispatch("inventory/retrieveMaterialSubstitutions", {
        tagIds: tagIds,
      });
    }
  }

  /**
   * Checks if the current lot's  use by date (pack by if that isn't set) will
   * significantly impact the current batch's use by date
   * two main sections, one for Mixing, second fo split packout
   */

  validLotPackByDate() {
    const dateToUse = this.currentLot.use_by || this.currentLot.pack_by_date;
    this.packByWarningMessage = "";
    let currentProcessItem = this.currentBatch || this.currentPackoutGood;
    if (currentProcessItem) {
      return this.handleCurrentProcessItem(dateToUse, currentProcessItem);
    } else {
      return this.handleBoms(dateToUse);
    }
  }

  handleCurrentProcessItem(dateToUse: string, currentProcessItem: any) {
    if (!currentProcessItem.use_by) {
      return this.handleWithoutUseBy(dateToUse);
    } else {
      return this.handleWithUseBy(dateToUse, currentProcessItem.use_by);
    }
  }

  handleWithoutUseBy(dateToUse: string) {
    if (findTimeDiffInHours(dateToUse, DateTime.now().toISO()) < 24) {
      this.packByWarningMessage = `Consuming this material will update the pack by date to ${DateTime.fromISO(
        dateToUse
      ).toFormat("MM/dd/yyyy")}. This cannot be undone once added.`;
      return false;
    } else {
      return true;
    }
  }

  handleWithUseBy(dateToUse: string, passedUsedBy: string) {
    if (
      findTimeDiffInHours(dateToUse, passedUsedBy) < -12 &&
      findTimeDiffInHours(dateToUse, passedUsedBy) != 0
    ) {
      this.packByWarningMessage = `Consuming this material will update the pack by date from ${DateTime.fromISO(
        passedUsedBy
      ).toFormat("MM/dd/yyyy")} to ${DateTime.fromISO(dateToUse).toFormat(
        "MM/dd/yyyy"
      )}. This cannot be undone once added.`;
      return false;
    } else {
      return true;
    }
  }

  handleBoms(dateToUse: string) {
    for (const bom of this.boms) {
      const good =
        this.packoutGoods.find((g: PackoutGood) => g.uuid === bom.good_id) ||
        null;
      if (good) {
        const material = bom.materials.find(
          (bm: any) => bm.inventory_item.uuid === this.currentLot.item_id
        );
        if (material) {
          return this.handleMaterial(dateToUse, good, material);
        }
      }
    }
  }

  handleMaterial(dateToUse: string, good: PackoutGood, material: any) {
    if (!good.use_by) {
      this.packByWarningMessage = `Consuming this material will update ${
        material.inventory_item.number
      }'s pack by date to ${DateTime.fromISO(dateToUse).toFormat(
        "MM/dd/yyyy"
      )}. This cannot be undone once added.`;
      return false;
    } else if (
      findTimeDiffInHours(dateToUse, good.use_by) < 12 &&
      findTimeDiffInHours(dateToUse, good.use_by) != 0
    ) {
      this.packByWarningMessage = `Consuming this material will update ${
        material.inventory_item.number
      }'s pack by date from ${DateTime.fromISO(good.use_by).toFormat(
        "MM/dd/yyyy"
      )} to ${DateTime.fromISO(dateToUse).toFormat(
        "MM/dd/yyyy"
      )}. This cannot be undone once added.`;
      return false;
    } else {
      return true;
    }
  }
}
