
import { Component, Vue } from "vue-property-decorator";
import { generateUniqueKey } from "@/util/helpers";
import { sortBy } from "lodash";

@Component
export default class MainMenu extends Vue {
  // ---------------------------------------------------------------------------
  // MAPPED VARIABLES
  // ---------------------------------------------------------------------------

  //This is a list of all current kiosks.If you're adding a new kiosk all you need to do
  // is add a new route with your kiosk's path name and a title to display on the button.
  // if you have multiple you wish to add that need to be grouped together, you can move them
  // around to get them appear together, the harvest and harvest weigh below are good examples of this.
  pageList: Array<any> = [
    {
      route: "harvest",
      title: "Harvest",
      location: "Farm",
    },
    {
      route: "fulfillment",
      title: "Fulfillment",
      location: "Inventory",
    },
    {
      route: "weigh",
      title: "Harvest Weigh",
      location: "Farm",
    },
    {
      route: "storage",
      title: "Storage",
      location: "Inventory",
    },
    {
      route: "mixing/main",
      title: "Mixing",
      location: "Inventory",
    },
    {
      route: "palletizer",
      title: "Palletizer",
      location: "Inventory",
    },
    {
      route: "mixing/weigh",
      title: "Mixing Weigh",
      location: "Inventory",
    },
    {
      route: "propagation",
      title: "Propagation",
      location: "Farm",
    },
    {
      route: "packout/main",
      title: "Packout",
      location: "Inventory",
    },
    {
      route: "transplant",
      title: "Transplant",
      location: "Farm",
    },
    {
      route: "seeding",
      title: "Seeding",
      location: "Farm",
    },
    {
      route: "shipping",
      title: "Shipping",
      location: "Inventory",
    },
    {
      route: "casing",
      title: "casing",
      location: "Inventory",
    },

    {
      route: "wip-exit",
      title: "Shift Wip Exit",
      location: "Inventory",
    },
    {
      route: "packout-entry",
      title: "Packout WIP Entry",
      location: "Inventory",
    },
    {
      route: "display/main",
      title: "Packout WIP Display",
      location: "Inventory",
    },
    {
      route: "inventory",
      title: "Inventory",
      location: "Inventory",
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get locationGroups() {
    const groups: { [key: string]: { name: string; items: any[] } } = {};
    this.pageList.forEach((item) => {
      if (!groups[item.location]) {
        groups[item.location] = { name: item.location, items: [] };
      }
      groups[item.location].items.push(item);
    });

    Object.keys(groups).forEach((location) => {
      groups[location].items = sortBy(groups[location].items, [
        (item) => item.title.toLowerCase(),
      ]);
    });
    return Object.values(groups);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  navigate(destination: string = "") {
    if (destination != "") {
      this.$router.push("/" + destination);
    }
  }

  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
