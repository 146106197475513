
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { inventoryLotLabel } from "@/util/print";
import Printd from "printd";
import QrcodeVue from "qrcode.vue";
import { DateTime } from "luxon";
import { padLotNumber, padNumber } from "@/util/helpers";

@Component({
  components: {
    QrcodeVue,
  },
})
export default class LotLabel extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: () => {} })
  product!: any;

  @Ref("printBox")
  readonly printBox!: HTMLElement;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns formatted product harvest date for display
   * @returns {string}
   */
  get createdDate() {
    if (this.product.harvestDate) {
      // We need to tell moment how the 'created' time string is formatted.
      // This value is formatted in the Lot model's "getDisplayVersion()" method.
      return DateTime.fromISO(this.product.harvestDate).toFormat(
        "MM/dd/yy - hh:mm a"
      );
    }
    return "";
  }

  /**
   * Returns abbreviated crop name combined with crop number
   * @returns {string}
   */
  get cropNameNumber() {
    return this.product.abbreviation
      ? this.product.abbreviation + "_" + padNumber(this.product.cropNumber, 6)
      : "_" + padNumber(this.product.cropNumber, 6);
  }

  get useByDate() {
    return DateTime.fromISO(this.product.use_by).toFormat("MM/dd/yy - hh:mm a");
  }

  /**
   * Returns padded lot number for display
   * @returns {number}
   */
  get paddedLotNumber() {
    return padLotNumber(this.product.lot.number);
  }

  get productWithItemNumber() {
    return this.product.number + " - " + this.product.name;
  }

  /**
   * Returns object for navigate-lot
   * @returns {Object}
   */
  get convertToQrCode() {
    return JSON.stringify({
      type: "navigate-lot",
      payload: this.product.lot["uuid"],
    });
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Triggers the brower's print dialog
   */
  print() {
    let printd: Printd = new Printd();
    printd.print(this.printBox, [inventoryLotLabel]);
  }
}
