import { Store } from "vuex";
import Vue from "vue";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { store } from "..";

export function initThirdParty(store: Store<any>, logRocket: typeof LogRocket) {
  const sentryEnvs = ["dev", "prod", "stage"];
  const environment: string = process.env.VUE_APP_ENV || "";
  const useSentry: boolean =
    sentryEnvs.includes(environment) && process.env.NODE_ENV === "production";
  const sentryDsn: string =
    "https://59744435b15d431cbbf8e0c832be884d@o249976.ingest.sentry.io/6057069";

  // User ID config
  const email = store.getters["user/getEmail"];
  const userOID = store.getters["user/getOID"];

  //@ts-ignore
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    environment: environment,
    integrations: [
      // @ts-ignore
      new Integrations.BrowserTracing(),
    ],
    release: "growops@" + process.env.PACKAGE_VERSION,
    tracesSampleRate: 1.0,
    logErrors: true,
  });
  Sentry.configureScope(
    (scope: { setUser: (arg0: { email: any; id: any }) => void }) => {
      scope.setUser({ email, id: userOID });
    }
  );

  //  Integrate LogRocket and SentryIO
  logRocket.getSessionURL((sessionURL: any) => {
    Sentry.configureScope(
      (scope: { setExtra: (arg0: string, arg1: any) => void }) => {
        scope.setExtra("sessionURL", sessionURL);
      }
    );
  });
}

export function logRocketInit(app?: string) {
  if (process.env.VUE_APP_ENV === "prod") {
    LogRocket.init("nehdcs/" + process.env.PACKAGE_NAME + "-" + app);
  }
  logRocketIdentify();
}

export function logRocketIdentify() {
  const oid = store.getters["user/getOID"];
  LogRocket.identify(oid, {
    name: store.getters["user/getFullName"],
    email: store.getters["user/getEmail"],
  });
}
