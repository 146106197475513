import BaseModel from "../../Base";
import Contact from "../../shared/Contact";
import DeliveryBox from "./Box";
import SalesOrderLine from "../sales/OrderLine";
import { Lot } from "@eaua/model";
import { required } from "vuelidate/lib/validators";
import { getIcon } from "@/util/icons";
import { getUTCFromDate } from "@/util/helpers";
import {
  formatTimestamp,
  formatDeliveryNumber,
  formatSalesOrderNumber,
  getUTCNow,
} from "@/util/helpers";
import find from "lodash/find";
import get from "lodash/get";
import { DateTime } from "luxon";
import isEmpty from "lodash/isEmpty";

export default class Delivery extends BaseModel {
  table = "growops_new_deliveries";
  view = "growops_deliveries_vw";
  primaryKey = "new_deliveries_pkey";
  queryReturnPagination = "";

  properties = {
    uuid: "",
    number: "",
    type: "delivery",
    label_type: "standard",
    is_case: null,
    date: "",
    created: "",
    created_by: "",
    details: {},
    sales_order_id: "",
    facility_id: "",
    completed: null,
    completed_by: null,
    archived: null,
    archived_by: null,
  };

  // View properties should only contain derived values in the database view
  viewProperties = {
    dl_number: "",
    so_number: "",
    customer_id: "",
    customer_name: "",
    customer_po: "",
    delivery_zone: "",
    packed: "",
    loaded: "",
    requested: "",
    box_count: 0,
    cold_box_count: 0,
    warm_box_count: 0,
    status: "template",
    is_case: null,
    shipping_contact: "",
  };

  relationships = {
    sales_order: {},
    delivery_boxes: [],
  };

  // Config: will eventually move to database
  config: any = {
    delivery_types: {
      delivery: {
        text: "Delivery",
        value: "delivery",
        icon: getIcon("delivery", "delivery"),
      },
      pickup: {
        text: "Pickup",
        value: "pickup",
        icon: getIcon("delivery", "pickup"),
      },
      tpl: {
        text: "3PL",
        value: "tpl",
        icon: getIcon("delivery", "tpl"),
      },
    },

    label_types: {
      standard: {
        text: "Standard",
        value: "standard",
        icon: getIcon("label", "default"),
      },
      usfoods: {
        text: "US Foods",
        value: "usfoods",
        icon: getIcon("label", "default"),
      },
      pti: {
        text: "PTI",
        value: "pti",
        icon: getIcon("label", "default"),
      },
      wf: {
        text: "Whole Foods",
        value: "wf",
        icon: getIcon("label", "default"),
      },
      master: {
        text: "Master",
        value: "master",
        icon: getIcon("label", "default"),
      },
      gianteagle: {
        text: "Giant Eagle",
        value: "gianteagle",
        icon: getIcon("label", "default"),
      },
    },

    status_options: {
      template: {
        color: "grey",
        value: getIcon("purchasing", "waiting"),
        label: "Not Packed",
      },
      partial: {
        color: "purple",
        value: getIcon("base", "inProgress"),
        label: "Partially Packed",
      },
      packed: {
        color: "orange",
        value: getIcon("delivery", "packed"),
        label: "Packed",
      },
      loaded: {
        color: "blue",
        value: getIcon("delivery", "loaded"),
        label: "Loaded",
      },
      delivered: {
        color: "primary",
        value: getIcon("base", "complete"),
        label: "Delivered",
      },
      archived: {
        color: "black",
        value: getIcon("base", "archive"),
        label: "Archived",
      },
    },

    case_options: {
      true: { text: "Cases", value: true },
      false: { text: "Manual Boxes", value: false },
    },
  };

  dateFormat = "simple-yearless";

  // Derived properties
  boxCount: number = 0;
  boxesToSave: any = [];
  coldBoxCount: number = 0;
  facility: any = {};
  formattedNumber: string = "";
  orderNumber: string = "";
  originAddress: any = {};
  salesOrderLines: any = [];
  status: string = "template";
  warmBoxCount: number = 0;
  packedDate: string = "-";
  loadedDate: string = "-";
  requestedDate: string = "-";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturnPagination = this.getQueryReturnTemplate(true);

    // Add delivery boxes to the query return object
    this.queryReturn += `
      delivery_boxes(
        where: { archived: { _is_null: true } },
        order_by: { sequence: asc }
      ) {
        ${new DeliveryBox().queryReturn}
      }
      sales_order {
        uuid
        number
        customer_id
        customer_name
        customer_po
        customer {
          uuid
          name
          label_type
          delivery_zone
          shipping_contact {
            ${new Contact().queryReturn}
          }
        }
        billing_contact
        requested
        stage
        order_lines {
          ${new SalesOrderLine().queryReturn}
        }
        delivery_type
        deliveries: new_deliveries {
          uuid
        }
      }
    `;

    // Add validation rules
    this.validations.type = { required };
    this.validations.label_type = { required };
    this.validations.date = { required };
    this.validations.sales_order_id = { required };
    this.validations.facility_id = { required };
    this.validations.is_case = { required };

    // Set derived values
    this.setBoxCount();
    this.setDeliveryNumber();
    this.setFacility();
    this.setOrderNumber();
    this.setStatus();
    this.setBoxDates();
    this.setRequestedDate();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets box count to number of boxes in this delivery order
   */
  setBoxCount() {
    // Use view box count if loaded
    if (this.box_count) {
      this.boxCount = this.box_count;
      return;
    }
    if (!this.delivery_boxes) {
      this.boxCount = 0;
      return;
    }
    this.boxCount = this.delivery_boxes.length;
    for (let box of this.delivery_boxes) {
      if (box.delivery_lines) {
        if (box.delivery_lines.length > 0) {
          if (box.refrigerate) {
            if (this.is_case) {
              for (let deliveryCase of box.delivery_lines) {
                this.coldBoxCount += deliveryCase.quantity;
              }
            } else {
              this.coldBoxCount++;
            }
          } else {
            if (this.is_case) {
              for (let deliveryCase of box.delivery_lines) {
                this.coldBoxCount += deliveryCase.quantity;
              }
            } else {
              this.warmBoxCount++;
            }
          }
        }
      }
    }
  }

  /**
   * Sets a formatted delivery number
   */
  setDeliveryNumber() {
    this.formattedNumber = this.dl_number || formatDeliveryNumber(this.number);
  }

  /**
   * Sets this delivery's origin facility object and address object
   */
  setFacility() {
    if (!this.facility_id || !this.store.state) {
      this.facility = {};
      return;
    }
    this.facility = this.store.getters["facilities/getFacilityByUuid"](
      this.facility_id
    );
    this.originAddress = get(this.facility, "details.address", {});
  }

  /**
   * Sets a formatted sales order number
   */
  setOrderNumber() {
    this.orderNumber =
      this.so_number ||
      formatSalesOrderNumber(get(this.sales_order, "number", ""));
  }

  /**
   * Sets the current status of this delivery
   */
  setStatus() {
    //If delivery is archived set status to archived
    if (this.archived) {
      this.status = "archived";
      return;
    }

    // Use view status if loaded
    if (this.dl_number) return;

    // If this delivery is completed, it's been delivered
    if (this.completed) {
      this.status = "delivered";
      return;
    }

    //  Check the first box for packed or loaded. Since all boxes
    //   are packed and loaded at once, you only need to check one
    let box: any = get(this.delivery_boxes, "[0]", null);
    if (box) {
      if (box.packed) this.status = "packed";
      if (box.loaded) this.status = "loaded";
    }
  }

  /**
   * Sets this delivery's packed and loaded dates from the first nested box
   */
  setBoxDates() {
    // If the view is loaded, use packed and loaded dates
    if (this.dl_number) {
      this.packedDate = this.packed;
      this.loadedDate = this.loaded;
      return;
    }
    // Check the first box for packed or loaded. Since all boxes
    // are packed and loaded at once, you only need to check one
    let box: any = get(this.delivery_boxes, "[0]", null);
    if (box) {
      if (box.packed)
        this.packedDate = formatTimestamp(box.packed, this.dateFormat)!;
      if (box.loaded)
        this.loadedDate = formatTimestamp(box.loaded, this.dateFormat)!;
    }
  }

  /**
   * Sets this delivery's requested date from the linked sales order
   */
  setRequestedDate() {
    this.requestedDate =
      this.requested || get(this.sales_order, "requested", "-");
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns customer details from the linked sales order
   * @returns {Object}
   */
  getCustomerDetails() {
    if (this.sales_order && this.sales_order.customer_id) {
      return {
        id: this.sales_order.customer_id,
        name: this.sales_order.customer_name || "",
        po: this.sales_order.customer_po || "",
        shipping_contact: get(
          this.sales_order,
          "customer.shipping_contact",
          {}
        ),
        billing_contact: get(this.sales_order, "customer.shipping_contact", {}),
      };
    }
    return {};
  }

  /**
   * Returns a display Delivery
   * @returns {Object}
   */
  getPaginationDisplayVersion(): object {
    let values: any = super.getDisplayVersion([
      "archived",
      "created",
      "completed",
      "date",
      "packed",
      "loaded",
      "requested",
    ]);
    values.boxCount = this.boxCount;
    values.name = this.formattedNumber;
    values.number = this.orderNumber;
    values.display_type = get(
      this.config,
      `delivery_types.${values.type}.text`,
      "-"
    );
    values.display_label_type = get(
      this.config,
      `label_types.${values.label_type}.text`,
      "-"
    );

    if (this.sales_order_id) {
      values.salesOrderLink = {
        name: "salesorder",
        params: { uuid: this.sales_order_id },
      };
    }
    if (this.customer_id) {
      values.customerLink = {
        name: "sales-customer",
        params: { uuid: this.customer_id },
      };
    }
    values.display_time = DateTime.fromISO(this.date).toFormat("hh:mm a");
    return values;
  }

  /**
   * Returns a display Delivery
   * @returns {Object}
   */
  getDisplayVersion(): object {
    let values: any = super.getDisplayVersion(["created", "completed", "date"]);
    values.boxCount = this.boxCount;
    values.coldBoxCount = this.coldBoxCount;
    values.warmBoxCount = this.warmBoxCount;
    values.name = this.formattedNumber;
    values.orderNumber = this.orderNumber;
    values.facility_name = this.facility.name;
    values.status = this.status;
    values.customerName = get(this.sales_order, "customer_name", "-");
    values.type = get(this.config, `delivery_types.${values.type}.text`, "-");
    values.zone = get(this.sales_order, "customer.delivery_zone", "-");
    values.label_type = get(
      this.config,
      `label_types.${values.label_type}.text`,
      "-"
    );
    values.packedDate = this.packedDate;
    values.loadedDate = this.loadedDate;
    values.display_time = DateTime.fromISO(this.date).toFormat("hh:mm a");
    values.requestedDate = formatTimestamp(this.requestedDate, this.dateFormat);

    if (this.sales_order) {
      values.salesOrderLink = {
        name: "salesorder",
        params: { uuid: this.sales_order.uuid, stage: this.sales_order.stage },
      };
      values.customerLink = {
        name: "sales-customer",
        params: { uuid: this.sales_order.customer.uuid },
      };
    }
    return values;
  }

  /**
   * Returns the origin facility's city and state as a string
   * @returns {String}
   */
  getOriginCity() {
    if (this.originAddress.city && this.originAddress.state) {
      return `${this.originAddress.city}, ${this.originAddress.state}`;
    }
    return "";
  }

  /**
   * Returns modeled sales order lines that either have a remaining
   * quantity to pack or match the given delivery line id
   * @param {string} deliveryLineId A delivery line uuid
   * @returns {Array}
   */
  getSalesOrderLines(
    deliveryLineId: string = "",
    refrigerate: boolean | null = null
  ) {
    let orderLines: any = get(this.sales_order, "order_lines", []);
    let lines: any = [];
    for (let ol of orderLines) {
      let line = new SalesOrderLine(ol);
      let refrigerateMatches: boolean = true;
      if (refrigerate !== null)
        refrigerateMatches = refrigerate === line.item.refrigerate;
      if (line.qtyToPack > 0 && refrigerateMatches) {
        lines.push(line);
        continue;
      }
      if (!deliveryLineId) continue;
      let found: any = find(get(line, "delivery_lines", []), {
        uuid: deliveryLineId,
      });
      if (found && refrigerateMatches) {
        lines.push(line);
        continue;
      }
    }
    return lines;
  }

  /**
   * Returns a summary of sales order lines with quantities in
   * this delivery and in other deliveries
   * @returns {Array}
   */
  getSalesOrderLineSummary() {
    let summary: any = [];
    let lines: any = get(this.sales_order, "order_lines", []);
    for (let ol of lines) {
      let line = new SalesOrderLine(ol);
      let qtyInThisDelivery: number = 0;
      let qtyInAnotherDelivery: number = 0;
      let lineLots: any[] = [];
      if (line.qtyInDelivery > 0) {
        for (let dl of line.delivery_lines) {
          let box: any = find(this.delivery_boxes, { uuid: dl.box_id });
          if (box && !box.archived) {
            qtyInThisDelivery += dl.quantity;
            for (let boxDeliveryLine of box.delivery_lines) {
              if (boxDeliveryLine.lot) {
                lineLots.push(boxDeliveryLine.lot);
              }
            }
          } else {
            qtyInAnotherDelivery += dl.quantity;
          }
        }
      }
      line.qtyInThisDelivery = qtyInThisDelivery;
      line.qtyInAnotherDelivery = qtyInAnotherDelivery;
      if (isEmpty(lineLots)) {
        line.lotNumber = "";
        line.caseLotLink = {
          name: "inventory-lot",
          params: { uuid: "none" },
        };
      } else if (lineLots.length > 0) {
        line.lotNumber = [];
        line.caseLotLink = [];
        for (let l of lineLots) {
          let lot = new Lot(l);
          if (!line.lotNumber.includes(lot.getLabel())) {
            line.lotNumber.push(lot.getLabel());
            line.caseLotLink.push(lot.getLink());
          }
        }
      }
      summary.push(line);
    }
    return summary;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    let boxes: any = [];

    // Build delivery lines to save
    for (let db of this.boxesToSave) {
      let box = new DeliveryBox(db, this.store);
      // If this is a new delivery or a copy
      if (!this.uuid) {
        box.resetProperties([
          "uuid",
          "delivery_id",
          "created",
          "created_by",
          "packed",
          "packed_by",
          "loaded",
          "loaded_by",
        ]);
      } else {
        box.resetProperties(["delivery_id"]);
      }
      if (!box.archived) boxes.push(box.getSaveVersion());
    }
    // Add nested lines for upsert
    if (boxes.length > 0) {
      values.delivery_boxes = {
        data: boxes,
        on_conflict: new DeliveryBox().getOnConflictObject(),
      };
    }
    // Remove the delivery number if it's empty or null
    if (values.hasOwnProperty("number") && !values.number) {
      delete values.number;
    }
    return values;
  }

  /**
   * Returns a completed save version of this class.
   * @returns {Object} Mapped properties
   */
  getCompleteVersion() {
    let values: any = super.getSaveVersion();
    values.completed = getUTCNow();
    values.completed_by = this.currentUserId;
    return values;
  }

  /**
   * Returns this delivery type's icon
   * @returns {string}
   */
  getTypeIcon() {
    if (this.type)
      return get(this.config, `delivery_types.${this.type}.icon`, "");
    return "";
  }

  /**
   * Returns this delivery type's label
   * @returns {string}
   */
  getTypeLabel() {
    if (this.type)
      return get(this.config, `delivery_types.${this.type}.text`, "");
    return "";
  }

  /**
   * Builds a return query for packout orders. Searches for sales order
   * lines that:
   *  - fall within the requested date range
   *  - have a customer with the given facility as a provider
   *  - have no current related packout lines
   * @param {Object} dateRange Object with `start` and `end`
   * @param {string} facilityIds Array of facility ids
   * @returns {string}
   */
  getReturnQueryForManifest(dateRange: any = {}, facilityIds: any = []) {
    if (!dateRange.start || !dateRange.end) return "";
    if (!(facilityIds.length > 0)) return "";
    let start: string = getUTCFromDate(
      formatTimestamp(dateRange.start, "datepicker")!
    );
    let end: string = DateTime.fromISO(dateRange.end)
      .plus({ days: 1 })
      .toUTC()
      .toJSON();
    let where: string = `
      {
        _and: {
          date: {
            _gte: "${start}",
            _lt: "${end}"
          }
          , 
          facility_id: {
            _in: ["${facilityIds.join(", ")}"]
          }
        }
      }
    `;
    return this.getReturnQuery(where, "", this.queryReturnWithSalesOrder);
  }
}
