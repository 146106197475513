import {
  set,
  setDeep,
  retrieveObject,
  retrieveSet,
  sendQuery,
} from "@/util/vuex";
import { filterItems, formatTimestamp, getUTCNow } from "@/util/helpers";
import find from "lodash/fp/find";
import get from "lodash/get";
import axios from "axios";
import {
  CropOrder,
  CropOrderDetail,
  CropOrderLineItem,
  HarvestDetail,
} from "@eaua/model";
import { filter } from "lodash";
import { DateTime } from "luxon";

const CROP_ORDER_STORE_DEFAULTS: { [key: string]: any } = {
  cropOrders: [],
  cropOrdersAggregateTotal: 0,
  currentCropOrder: null,
  currentCropOrderUuid: "",
  currentCropOrderPlotUuid: "",
  currentCropDetail: {},
  currentKioskUUID: {},
  refreshInterval: 60000,
  latestHarvestUuid: "",
  latestHarvest: {},
  processedHarvests: {},
  currentHarvest: {},
  currentCropOrderDetailId: "",
  currentSequentialHarvests: [],
  readFromMainKioskInterval: 5000,
  orderHarvestDetails: [],
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = () => {
  let state: any = {};
  for (let property in CROP_ORDER_STORE_DEFAULTS) {
    state[property] = CROP_ORDER_STORE_DEFAULTS[property];
  }
  return state;
};

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setCropOrders: setDeep("cropOrders"),
  setCropOrdersAggregateTotal: set("cropOrdersAggregateTotal"),
  setCurrentCropOrder: set("currentCropOrder"),
  setCurrentCropDetail: set("currentCropDetail"),
  setCurrentCropOrderUuid: set("currentCropOrderUuid"),
  setCurrentCropOrderPlotUuid: set("currentCropOrderPlotUuid"),
  setLatestHarvest: set("latestHarvest"),
  setCurrentSequentialHarvests: setDeep("currentSequentialHarvests"),
  setCurrentCropOrderDetailId: set("currentCropOrderDetailId"),
  setCurrentKioskUUID: set("currentKioskUUID"),
  setOrderHarvestDetails: setDeep("orderHarvestDetails"),
  reset(state: any, property: string) {
    state[property] = CROP_ORDER_STORE_DEFAULTS[property];
  },
  resetStore(state: any) {
    Object.assign(state, CROP_ORDER_STORE_DEFAULTS);
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  /**
   * Returns a order given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getCropOrderByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.cropOrders) || {};
    },

  /**
   * returns a crop order uuid map
   */
  getCropOrderUuidMap: (state: any) => {
    return state.cropOrders.reduce(function (map: any, order: any) {
      map[order.uuid] = order;
      return map;
    }, {});
  },

  /**
   * Returns an array of filtered orders for the route `crop-orders`
   * @returns {Array}
   */
  getFilteredCropOrders: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["crops"] || [];
    let search = rootState.filter.search["crops"] || "";
    return filterItems(state.orders, filters, search);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // CROP ORDERS ---------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Saves a order.
   * Commits saved order using `setCurrentCropOrder`.
   * @param context Store module context
   * @param {Object} order CropOrder save-version object
   */
  saveCropOrder: function (context: any, { order, detailsPage = false }: any) {
    let orderTemplate = new CropOrder();
    let upsertQuery: string = orderTemplate.getUpsertQuery(
      detailsPage ? orderTemplate.getDetailedReturnQuery() : ""
    );
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: order,
          },
        })
        .then(
          (success: any) => {
            // Store the saved order as current order
            let queryName: string = `insert_${orderTemplate.table}`;
            let savedOrder = success.data.data[queryName].returning[0];
            context.commit("setCurrentCropOrder", savedOrder);
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves a order by uuid.
   * Commits order using `setCurrentCropOrder`.
   * @param context Store module context
   * @param {string} uuid CropOrder UUID
   */
  retrieveCropOrderKiosk: function (context: any, { uuid }: any) {
    let returnQuery: string = `
  query getCropUUID {
    growops_plot_details(where: {uuid: {_eq: "${uuid}"}}) {
      active_crop
    }
  }
`;
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentKioskUUID",
      "growops_plot_details"
    );
  },
  /**
   * Retrieves crop order with associated plot UUID
   * @param context Store module context
   * @param {string} uuid CropOrder UUID
   */
  retrieveCropOrder: function (
    context: any,
    { uuid, number = "", includePlots = false, detailed = false }: any
  ) {
    let orderTemplate = new CropOrder();
    let queryReturnString = orderTemplate.queryReturn;
    if (includePlots) queryReturnString = orderTemplate.queryReturnWithPlots;
    if (detailed) queryReturnString = orderTemplate.getDetailedReturnQuery();

    let returnQuery: string = orderTemplate.getReturnQuery(
      number ? `{mfg_id: {_eq: "${number}"}}` : `{uuid: {_eq: "${uuid}"}}`,
      "",
      queryReturnString
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentCropOrder",
      orderTemplate.table
    );
  },

  /**
   * Retrieves crop order with associated plot UUID
   * @param context Store module context
   * @param {string} uuid CropOrder UUID
   */
  retrieveCropOrderByNumber: function (context: any, { number }: any) {
    const typeIds: Array<string> = (
      context.rootState.stages.detailTypes || []
    ).reduce((acc: Array<string>, t: any) => {
      if (["production", "growout", "harvest", "regrowth"].includes(t.value)) {
        acc.push(t.uuid);
      }
      return acc;
    }, []);
    const orderTemplate = new CropOrder();
    const returnQuery: string = orderTemplate.getReturnQuery(
      `{
          mfg_id: {_eq: "${number}"},
          status: {_eq: "active"},
          rel_stage: { 
            order_detail_type_id: { _in: ["${typeIds.join('", "')}"] }
          }
        }
        `,
      ""
    );

    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentCropOrder",
      orderTemplate.table
    );
  },

  /**
   * Retrieves crops orders, filtering out orders that were completed
   * more than a month ago.
   * Commits orders using `setCropOrders`.
   * @param context Store module context
   */
  retrieveCropOrders: function (
    context: any,
    {
      where = "",
      pagination = {},
      sortBy = "",
      useView = false,
      useDetailed = false,
    }: any = {}
  ) {
    let orderTemplate = new CropOrder();
    let defaultWhere: string = `
      {
        _and:
          {
            facility_id: {_eq: "${
              context.rootState.facilities.currentFacilityUuid
            }"},
            status: {_eq: "active"},
            _or: [
              {completed: {_gte: "${DateTime.now()
                .minus({ days: 30 })
                .toISO()}"}},
              { completed: { _is_null: true }}
            ]
          }
      }
    `;

    let returnQuery: string = orderTemplate.getReturnQuery(
      where || defaultWhere,
      sortBy || `{ created: desc }`,
      useView
        ? orderTemplate.viewQueryReturn
        : useDetailed
        ? orderTemplate.getDetailedReturnQuery()
        : orderTemplate.queryReturn,
      pagination,
      useView
    );

    if (!returnQuery) return;
    return retrieveSet(
      context,
      returnQuery,
      "setCropOrders",
      useView ? orderTemplate.view : orderTemplate.table
    );
  },

  retrieveLatestHarvest: function (context: any, { produceId, zoneId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `{
            growops_harvest_details(
              where: {
                lotByLotId: {
                  created: {_gte: "${formatTimestamp(
                    getUTCNow(),
                    "datepicker"
                  )}"}
                }, 
                produce_id: {
                  _eq: "${produceId}"
                }, 
                orderDetailsBydetailId: {
                  orderLocationsBydetailId: {zone_id: {_eq: "${zoneId}"}}
                }
              }, 
              limit: 1, 
              order_by: {
                lotByLotId: {created: desc}
              }
            ) {
              ${new HarvestDetail().queryReturn}
            }
          }`,
        })
        .then(
          (success: any) => {
            context.commit(
              "setLatestHarvest",
              get(success, "data.data.growops_harvest_details[0]", {})
            );
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  retrieveSequentialHarvests: function (
    context: any,
    { plotUuid, orderUuid }: any
  ) {
    const harvestTypeId: Array<string> =
      context.rootGetters["stages/getOrderDetailTypeByValue"]("harvest").uuid;
    if (!harvestTypeId) return;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query retrieveSequentialHarvests($plot_uuid:uuid, $order_uuid:uuid, $sequential_type_filter: jsonb) {
            shared_facility_plots(where: {uuid: {_eq: $plot_uuid}}) {
              rel_crop_order_plots(where: {removed: {_is_null: true}, order_id: {_eq: $order_uuid}}) {
                rel_order {
                  orderDetailsByHeaderId(where: {type_id: {_eq: "${harvestTypeId}"}, status: {_contains: $sequential_type_filter}}) {
                    uuid
                    status
                    harvestDetailsBydetailId(where: {amount: {_gt:0}}) {
                      uuid
                      header_id
                      detail_id
                      modified,
                      modified_by,
                      units
                      amount
                      lot_uuid
                      lot_id
                      produce_id
                      weight
                      uom
                      grade
                      created
                      lotByLotId{
                        number
                      }
                    }
                    orderLocationsBydetailId {
                      plot_ids
                    }
                  }
                }
              }
            }
          }`,
          variables: {
            sequential_type_filter: {
              value: "sequential",
            },
            plot_uuid: plotUuid,
            order_uuid: orderUuid,
          },
        })
        .then(
          (success: any) => {
            // We can assume that the first order is the only order for this plot
            // because production plots should not allow multiple orders
            let orderDetails: any = get(
              success,
              `data.data.shared_facility_plots[0].rel_crop_order_plots[0].rel_order.orderDetailsByHeaderId`,
              []
            );
            context.commit("setCurrentSequentialHarvests", {
              list: filter(
                orderDetails,
                (orderDetail: any, index: any, list: any) => {
                  if (
                    (orderDetail.status.invalid == undefined ||
                      orderDetail.status.invalid == false) &&
                    orderDetail.harvestDetailsBydetailId.length != 0
                  ) {
                    return get(
                      orderDetail,
                      "orderLocationsBydetailId[0].plot_ids",
                      []
                    ).includes(plotUuid);
                  }
                }
              ),
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  undoLastSequentialHarvest: function (context: any, { detail }: any) {
    let detailTemplate = new CropOrderDetail();
    let updateQuery: string = detailTemplate.getUpdateQuery("$where", "uuid");
    if (!updateQuery) return;
    return sendQuery(context, updateQuery, {
      where: {
        uuid: { _eq: detail.uuid },
      },
      set: {
        status: detail.status,
        modified: getUTCNow(),
        modified_by: detailTemplate.currentUserId,
      },
    });
  },

  /**
   * Retrieves completed crops orders for use in the
   * completed crop report.
   * @param context Store module context
   */
  retrieveCropOrderReport: function (
    context: any,
    { startDate, endDate }: any
  ) {
    let orderTemplate = new CropOrder();
    let where: string = `
      {
        _and:
          {
            facility_id: {_eq: "${context.rootState.facilities.currentFacilityUuid}"},
            status: {_eq: "active"},
            _and: [
              {completed: {_gte: "${startDate}"}},
              {completed: {_lte: "${endDate}"}}
            ]
          }
      }
    `;
    let returnQuery: string = orderTemplate.getReturnQuery(
      where,
      `{ completed: asc }`,
      orderTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setCropOrders",
      orderTemplate.table
    );
  },

  /**
   * Completes a crop order
   * @param context Store module context
   * @param {string} uuid CropOrder uuid
   */
  completeCropOrder: function (context: any, { uuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + `/completecrop`, {
          header_id: uuid,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  reopenCropOrder: function (context: any, { order }: any) {
    let orderTemplate = new CropOrder();
    let upsertQuery: string = orderTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: order,
          },
        })
        .then(
          (success: any) => {
            // Store the saved order as current order
            let queryName: string = `insert_${orderTemplate.table}`;
            let savedOrder = success.data.data[queryName].returning[0];
            context.commit("setCurrentCropOrder", savedOrder);
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // CROP ORDER DETAILS ------------------------------------------------------
  // -------------------------------------------------------------------------

  /**
   * Saves a crop order detail
   * @param context Store module context
   * @param {Object} detail Crop order detail save-version object
   */

  saveOrderDetail: function (context: any, { detail, isEdit = false }: any) {
    let detailTemplate = new CropOrderDetail();
    let upsertQuery: string = detailTemplate.getUpsertQuery();
    if (!upsertQuery) return;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/v2/save-crop-order-detail", {
          detail: detail,
          isEdit: isEdit,
        })
        .then(
          (success: any) => {
            const detailId: string = get(success, "data[0].uuid", "") || "";
            context.commit("setCurrentCropOrderDetailId", detailId);
            resolve(detailId);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves HarvestDetail
   *
   */
  saveHarvestDetail(context: any, { harvestDetail }: any) {
    let harvestDetailsTemplate = new HarvestDetail();
    let upsertQuery: string = harvestDetailsTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return sendQuery(context, upsertQuery, {
      input: harvestDetail,
    });
  },

  /**
   * Retrieves specific order detail
   * @param context Store module context
   * @param {string} uuid CropOrder UUID
   */
  retrieveCropOrderDetail: function (context: any, { uuid }: any) {
    const orderTemplate = new CropOrderDetail();
    const returnQuery: string = orderTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`
    );

    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentCropDetail",
      orderTemplate.table
    );
  },

  retrieveOrderHarvestDetails: function (context: any, { detailId }: any) {
    const template = new HarvestDetail();
    const returnQuery: string = template.getReturnQuery("$where");
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setOrderHarvestDetails",
      template.table,
      {
        where: { detail_id: { _eq: detailId } },
      }
    );
  },

  deleteMeasurements: async function (context: any, { uuid }: any) {
    // GraphQL will fail if the uuid exists and is empty
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `
          mutation {
            delete_research_order_measurements(where: {order_detail_id: {_eq: "${uuid}"}}) {
              affected_rows
            }
          }`,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a crop order detail's images.
   * @param context Store module context
   * @param {string} uuid Crop Detail ID
   * @param {Array} images Array of image sources
   */
  saveCropDetailImages: function (
    context: any,
    { facilityUuid, uuid, type, images, index = 0 }: any
  ) {
    if (images.length && get(images, "[0].src", null)) {
      const enviroment: any = process.env.VUE_APP_ENV;
      for (let image of images) {
        const fileType = image.src.substring(
          image.src.lastIndexOf("data:image/") + 11,
          image.src.lastIndexOf(";base64")
        );
        const resourceName = `${enviroment}/${facilityUuid}/${type}/${uuid}_${index}_${Date.now()}.${fileType}`;
        const currentUser = context.rootState.user.oid;

        axios
          .post(`${process.env.VUE_APP_API_URL}/blob/document`, {
            resourceName,
            facilityUuid,
            detailId: uuid,
            serviceName: type,
            base64: image.src,
            createdBy: currentUser,
          })
          .then(
            (_success) => {},
            (fail) => {
              console.log(fail);
            }
          );
        index++;
      }
    }
  },

  /**
   * Retrieves a crop order's signed URLs
   */
  retrieveCropDetailImageUrls: function (
    context: any,
    { facilityId, detailId, detailType, imageCount }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `/blob/document/getcollectionbyblobname`,
          {
            blobName: `${process.env.VUE_APP_ENV}/${facilityId}/${detailType}/${detailId}`,
            count: imageCount,
          }
        )
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Makes a call to clear a plot.
   */
  vacatePlot(context: any, { plot_id, order_id }: any) {
    return axios.post(
      process.env.VUE_APP_API_URL + `/harvest-kiosk/vacate-plot`,
      {
        plot_id: plot_id,
        order_id: order_id,
      }
    );
  },

  saveCropOrderLineItem: function (context: any, { line }: any) {
    const template = new CropOrderLineItem();
    const upsertQuery: string = template.getUpsertQuery();
    if (!upsertQuery) return;

    return sendQuery(context, upsertQuery, { input: line });
  },

  deleteCropOrderLineItems: function (context: any, { uuids }: any) {
    const deleteQuery: string = new CropOrderLineItem().getDeleteQuery(
      "$where"
    );
    if (!deleteQuery || !uuids || uuids.length === 0) return;

    return sendQuery(context, deleteQuery, {
      where: { uuid: { _in: uuids } },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
