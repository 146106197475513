import BaseModel from "../../Base";

export default class FulfillmentDetails extends BaseModel {
  table = "growops_quicker_fulfillment_qty_vw";
  view = "growops_quicker_fulfillment_qty_vw";

  properties = {
    customer_id: "",
    facility_id: "",
    customer_name: "",
    delivery_id: "",
    delivery_number: "",
    item_id: "",
    item_name: "",
    item_number: "",
    qty_allocated: 0,
    qty_in_deliveries: 0,
    qty_ordered: 0,
    qty_to_pack: 0,
    sales_order_id: "",
    sales_order_line_id: "",
    sales_order_number: "",
    uom_id: "",
    uom_name: "",
    completed: "",
    packed: "",
    loaded: "",
    type: "",
    date: "",
    created: "",
    reference_id: "",
    qty_in_this_delivery: 0,
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    super.initialize(values);
  }
}
