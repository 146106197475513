import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { Store } from "vuex";
import { harvestRoutes } from "./router/harvest-routes";

export class HarvestModule implements VueModule {
  readonly name = "harvest";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(harvestRoutes);
  }
}
