import { required } from "vuelidate/lib/validators";
import clone from "lodash/clone";
import BaseModel from "../../Base";

export default class AttributeCategory extends BaseModel {
  table = "growops_inventory_item_attribute_categories";
  primaryKey = "inventory_item_attribute_categories_pkey";

  properties = {
    uuid: "",
    name: "",
    modified: "",
    modified_by: "",
  };

  relationships = {};

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.returnQuery += ``;

    // Add validation rules
    this.validations.name = { required };
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------
  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    values.modified_by = this.currentUserId;
    return values;
  }

  /**
   * Returns a display Tag
   * @returns {Object}
   */
  getDisplayVersion(): Record<string, unknown> {
    let values: any = super.getDisplayVersion();
    return values;
  }
}
