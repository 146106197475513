export enum SocketEventType {
  // Core Events
  Connect = "connect",

  // Message events
  Delete = "delete",
  Subscribe = "subscribe",
  Unsubscribe = "unsubscribe",
  Upsert = "upsert",
  SetSource = "set-source",
}
