
import { Vue, Component, Prop } from "vue-property-decorator";
import { generateUniqueKey } from "@/util/helpers";
import isObject from "lodash/isObject";
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
})
export default class IteratorItem extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => {} })
  header!: any;

  @Prop({ default: () => {} })
  item!: any;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get renderIcon() {
    return (
      (this.header.type && this.header.type === "icon") ||
      this.header.showAsIcon
    );
  }

  get renderDisplayValue() {
    return !!this.header.displayValue;
  }

  get renderButton() {
    return (
      this.header.type && this.header.type === "button" && isObject(this.value)
    );
  }

  get renderProgressBar() {
    return this.header.type && this.header.type === "progress";
  }

  get renderTextField() {
    return this.header.type && this.header.type === "text-field";
  }

  get renderNumberField() {
    return this.header.type && this.header.type === "number-field";
  }

  get renderChip() {
    return this.header.type && this.header.type === "chip";
  }

  get renderBarcode() {
    return this.header.type && this.header.type === "barcode";
  }

  get renderQRCode() {
    return this.header.type && this.header.type === "qrcode";
  }

  get renderLink() {
    return this.header.type && this.header.type === "link";
  }

  get value() {
    if (this.header.displayValue && this.item[this.header.displayValue]) {
      return this.item[this.header.displayValue];
    }
    if (!this.item[this.header.value] && this.header.value.includes(".")) {
      let value: any = this.item;
      const temp: any = this.header.value.split(".");
      for (const nested of temp) {
        value = value[nested];
      }
      return value || "";
    }
    return this.item[this.header.value];
  }

  get link() {
    return this.item[this.header.link];
  }

  get config() {
    return this.header.config || {};
  }

  get isArray() {
    return Array.isArray(this.value);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
