
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BasePreloader extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: 60 })
  size!: number;

  @Prop({ default: "Loading" })
  label!: string;

  @Prop({ default: 0 })
  seconds!: number;

  @Prop({ default: 300 })
  minHeight!: number;

  // LOCAL VARIABLES -----------------------------------------------------------
  interval: any = {};
  value: number = 0;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get style() {
    return {
      "min-height": this.minHeight + "px",
    };
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  beforeMount() {
    clearInterval(this.interval);
  }

  mounted() {
    if (this.seconds > 0) {
      this.interval = setInterval(() => {
        if (this.value !== 100) this.value += 10;
      }, this.seconds * 100);
    }
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}
