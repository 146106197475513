import BaseModel from "../../Base";
import { clone, cloneDeep, get } from "lodash";
import findIndex from "lodash/findIndex";
import isNil from "lodash/isNil";
import pullAt from "lodash/pullAt";
import groupBy from "lodash/groupBy";
import SpecItemAttribute from "./SpecItemAttribute";
import { DateTime } from "luxon";

export default class ItemSpec extends BaseModel {
  table = "growops_inventory_item_specs";
  primaryKey = "inventory_item_specs_pkey";

  properties = {
    uuid: "",
    created: null,
    created_by: null,
    removed: null,
    removed_by: null,
    version_id: "",
    item_id: "",
  };

  relationships = {
    rel_inventory_item: {},
    rel_inventory_item_spec: {},
    rel_created_user: {},
    rel_removed_user: {},
    rel_inventory_item_spec_attributes: [],
  };

  derivedProperties = {
    groupedAttributes: {},
  };

  attributesToSave: any = [];

  constructor(values: any = {}, store: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
    rel_created_user {
      name
      email
    } 
    `;

    // Set derived properties
    this.setGroupedAttributes();
    this.attributesToSave = (this.rel_inventory_item_spec_attributes || []).map(
      (sa: any) => new SpecItemAttribute(sa).getSaveVersion()
    );
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setGroupedAttributes() {
    this.groupedAttributes = groupBy(
      this.rel_inventory_item_spec_attributes || [],
      "rel_inventory_item_attribute.category_id"
    );
  }

  updateAttribute(attribute: SpecItemAttribute): void {
    // Make sure attributesToSave exists
    if (!this.attributesToSave) this.attributesToSave = [];

    // Look for an existing matching attribute
    const index: number = findIndex(this.attributesToSave, {
      attribute_id: attribute.attribute_id,
    });
    // If the attribute already exists, update the value
    if (index > -1) {
      // If the attribute is null, undefined, or an empty string,
      // unset the attribute from this.attributesToSave
      if (isNil(attribute.value) || attribute.value === "") {
        pullAt(this.attributesToSave, index);
      }
      // Otherwise, update the value
      else {
        this.attributesToSave[index].value = attribute.value;
      }
    }
    // Otherwise, create it
    else {
      this.attributesToSave.push(attribute.getSaveVersion());
    }
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  getDisplayVersion() {
    let values: any = super.getDisplayVersion();
    values.number = this.number;
    values.createdBy = this.rel_created_user.email;
    values.formattedCreated = DateTime.fromISO(values.created).toFormat(
      "MM/dd/yyyy - HH:mm:ss"
    );
    values.formattedName = values.formattedCreated;
    return values;
  }

  getSaveVersion(data: any = {}) {
    let values: any = super.getSaveVersion();

    values.created_by = this.currentUserId;
    values.item_id = get(data, "itemId", null);
    delete values.version_id;
    if (get(data, "specId", null)) {
      values.uuid = get(data, "specId", null);
    } else {
      delete values.uuid;
    }

    values.rel_inventory_item_spec_version = {};
    values.rel_inventory_item_spec_version.data = {};
    values.rel_inventory_item_spec_version.data.created_by = this.currentUserId;
    values.rel_inventory_item_spec_version.data.modified_by =
      this.currentUserId;
    values.rel_inventory_item_spec_version.data.item_id = values.item_id;
    values.rel_inventory_item_spec_version.data.rel_inventory_item_spec_attributes =
      {};
    values.rel_inventory_item_spec_version.data.rel_inventory_item_spec_attributes.data =
      clone(this.attributesToSave);

    return values;
  }

  /**
   * Builds a basic upsert query if a table name and a query return
   * string are set. Expects a variable called `input` to be passed
   * in the GraphQL call.
   * @param {string} uuid Box uuid
   * @returns {string}
   */
  getUpsertQuery() {
    let updateOrderQuery: string = "";
    // // Will update sales order stage
    // updateOrderQuery = `

    // `;
    return super.getUpsertQuery("", updateOrderQuery);
  }
}
