
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Copyright extends Vue {
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the current year.
   * @returns {string}
   */
  get currentYear() {
    return new Date().getFullYear();
  }

  /**
   * Returns version from package.json
   */
  get version() {
    return process.env.PACKAGE_VERSION || "";
  }
}
