import Vue from "vue";
import axios from "axios";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

// Sets simple properties
export const set =
  (property: string) => (store: { [x: string]: any }, payload: any) =>
    (store[property] = payload);

// Sets arrays that need child properties to be reactive
// Requires input to be an array called "list"
// Requires output to be an array
export const setDeep =
  (property: string) => (store: { [x: string]: any }, payload: any) => {
    store[property] = [];
    if (payload && payload.list) {
      payload.list.forEach((e: any, i: any) => {
        Vue.set(store[property], i, e);
      });
    }
  };

// Toggles boolean properties
export const toggle = (property: string) => (store: { [x: string]: any }) =>
  (store[property] = !store[property]);

// Get the current user profile for store actions
export const currentUser = (context: any) => {
  if (context.rootState) {
    const user: any = context.rootState.user.profile;
    if (user.uuid) return user;
  }
  return {};
};

export function retrieveSet(
  context: any,
  query = "",
  mutation = "",
  table = "",
  variables: any = undefined
) {
  if (!query || !mutation || !table) return;
  return sendQuery(context, query, variables, mutation, table);
}

export function retrieveObject(
  context: any,
  query = "",
  mutation = "",
  table = "",
  variables: any = undefined
) {
  if (!query || !mutation || !table) return;
  return sendQuery(context, query, variables, mutation, table, true);
}

export function retrieveDetailsObject(
  context: any,
  query = "",
  mutation = "",
  table = ""
) {
  if (!query || !mutation || !table) return;
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
        query: query,
      })
      .then(
        (success: any) => {
          context.commit(mutation, success.data.data["growops_" + table]);
          resolve(success);
        },
        (fail: any) => {
          reject(new Error(fail.status));
        }
      );
  });
}

export function sendQuery(
  context: any,
  query = "",
  variables: any = {},
  mutation = "",
  table = "",
  singleResult = false
) {
  if (!query) return;
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
        query: query,
        variables: variables,
      })
      .then(
        (success: any) => {
          if (mutation && table) {
            const data: any = get(success, `data.data.${table}`, []);
            const agg: any = get(success, `data.data.${table}_aggregate`, null);
            // If we force a single result, only commit the first result
            if (singleResult) {
              // If this is an update or upsert, check for the returning value
              // Otherwise, just commit the first result data
              if (data.returning) context.commit(mutation, data.returning[0]);
              else context.commit(mutation, data[0]);
            }
            // Otherwise, commit the result list
            else {
              // If this is an update or upsert, check for the returning value
              // Otherwise, just commit the data
              if (data.returning)
                context.commit(mutation, { list: data.returning });
              else context.commit(mutation, { list: data });

              // Check for aggregate results. These will come back
              // with pagination queries
              if (agg) {
                context.commit(
                  mutation + "AggregateTotal",
                  agg.aggregate.count
                );
              }
            }
          }
          resolve(success.data.data);
        },
        (fail: any) => {
          reject(fail);
        }
      );
  });
}

/**
 * This function is for tracking a lot uuid or box uuid in the transaction store
 * to prevent a transaction from being consumed by a duplicate scan.
 * This object is removed when the the application resets or the page reloads.
 * @param context
 * @param id
 * @param removeMode
 * @returns
 */
export function trackScannedUuid(
  context: any,
  lotIds: string | string[],
  remove: boolean = false
) {
  // If uuid is an array, call trackScannedUuid for each uuid in the array
  if (Array.isArray(lotIds)) {
    lotIds.forEach((lot: any) => {
      trackScannedUuid(context, lot, remove);
    });
    return;
  }

  //This index is used to check if the uuid has already been scanned
  //and is in the map
  const foundId = context.rootState.transaction.uuidMap[lotIds];
  //Several Kiosk applications have remove modes and so we need
  //to handle the logic for both adding and removing uuids from the map
  if (remove) {
    if (!foundId) {
      return;
    }
    context.dispatch("transaction/removeUuidFromMap", lotIds, {
      root: true,
    });
    return;
  }

  if (foundId) {
    throw new Error("Duplicate lot addition attempt detected.");
  }

  const transactionKey = uuidv4();
  // Add the lotId and idempotency key to the map
  context.dispatch(
    "transaction/setUuidToMap",
    { uuid: lotIds, transactionKey },
    {
      root: true,
    }
  );
}
