import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const wipExitRoutes: RouteConfig = {
  path: "/wip-exit",
  component: () => import("@/modules/wipExit/views/Index.vue"),
  children: [
    {
      path: "",
      name: "WipExitLanding",
      component: () => import("@/modules/wipExit/views/Landing.vue"),
    },
    {
      path: "details/:batchId/:goodId",
      name: "WipExitDetails",
      props: true,
      component: () => import("@/modules/wipExit/views/Details.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("wip-exit");
    next();
  },
};
