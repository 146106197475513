
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import flow from "lodash/fp/flow";
import orderBy from "lodash/fp/orderBy";
import filter from "lodash/fp/filter";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class IteratorGantt extends Vue {
  @Prop({ default: () => [] })
  items!: any[];

  @Prop({ default: () => [] })
  headers!: any;

  @Prop({ default: () => [] })
  actions!: any;

  sortByName: boolean = false;
  sortByNameAsc: boolean = false;
  sortByProgress: boolean = false;
  sortByProgressAsc: boolean = false;
  sortByCompletion: boolean = false;
  sortByCompletionAsc: boolean = false;

  get eligibleItems() {
    let items = cloneDeep(this.items);
    return flow(
      // @ts-ignore
      filter((i: any) => {
        return (
          !["creation", "regrowth", "complete"].includes(i.status) &&
          i.progress !== null &&
          this.checkGanttEligibility(i.productId)
        );
      }),
      orderBy([this.sortType], [this.orderType])
    )(items);
  }
  get predictedYields() {
    return this.$store.state.order.predictedYields;
  }
  get sortType() {
    return this.sortByName
      ? "name"
      : this.sortByProgress
      ? "progress"
      : "harvestDate";
  }
  get orderType() {
    return this.sortByName
      ? this.sortByNameAsc
        ? "asc"
        : "desc"
      : this.sortByProgress
      ? this.sortByProgressAsc
        ? "asc"
        : "desc"
      : this.sortByCompletionAsc
      ? "asc"
      : "desc";
  }

  checkGanttEligibility(productId: number) {
    return (
      !!this.predictedYields &&
      !!this.predictedYields[productId] &&
      !isNaN(this.predictedYields[productId].avgTimeToHarvest)
    );
  }

  updateSortOrder(type: string) {
    switch (type) {
      case "name":
        this.sortByName = true;
        this.sortByNameAsc = !this.sortByNameAsc;
        this.sortByProgress = false;
        this.sortByCompletion = false;
        break;
      case "progress":
        this.sortByName = false;
        this.sortByProgress = true;
        this.sortByProgressAsc = !this.sortByProgressAsc;
        this.sortByCompletion = false;
        break;
      case "completion":
        this.sortByName = false;
        this.sortByProgress = false;
        this.sortByCompletion = true;
        this.sortByCompletionAsc = !this.sortByCompletionAsc;
        break;
      default:
        break;
    }
  }

  /**
   * Adds item as callback param to actions
   */
  getActions(item: any) {
    let actions = [];
    for (let a of this.actions) {
      let action = cloneDeep(a);
      action.callbackParams = item;
      actions.push(action);
    }
    return actions;
  }

  @Watch("predictedYields")
  onPredictedYieldsChanged(newPredictedYields: any, oldPredictedYields: any) {
    this.eligibleItems;
  }
}
