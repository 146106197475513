import BaseModel from "@/modules/core/models/Base";
import Zone from "@/modules/core/models/shared/Zone";
import { formatPhoneNumber } from "@/util/helpers";
import get from "lodash/get";

export default class Facility extends BaseModel {
  table = "shared_facilities";
  primaryKey = "facilities_pkey";

  properties = {
    uuid: "",
    code: "",
    name: "",
    details: null,
  };

  relationships = {
    rel_facility_contacts: [],
    rel_zones: [],
  };

  queryReturnWithPlots = "";

  // Derived properties
  display_address = "";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add zones and plots to query return
    this.queryReturnWithPlots = `
      ${this.queryReturn}
      rel_zones {
        ${new Zone().queryReturn}
      }
    `;

    // Set derived values
    this.setDisplayAddress();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets the display address for this facility
   */
  setDisplayAddress() {
    // this.display_address = get(this.address, "formatted", "");
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Facility
   * @returns {Object}
   */
  getDisplayVersion(): Record<string, unknown> {
    let values: any = super.getDisplayVersion();
    values.address = this.display_address;
    values.phone = formatPhoneNumber(this.phone || "");
    if (values.type) {
      values.type = get(this.config, `types.${values.type}.text`, "");
    }
    return values;
  }
}
