import BaseModel from "@/modules/core/models/Base";
import { formatPhoneNumber } from "@/util/helpers";
import get from "lodash/get";

export interface IProfile {
  uuid: string;
  email: string;
  name: string;
  created: string | null;
  details: string | null;
}

export default class Profile extends BaseModel {
  table = "shared_users";
  primaryKey = "users_pkey";

  properties: IProfile = {
    uuid: "",
    email: "",
    name: "",
    created: null,
    details: null,
  };

  // Derived properties
  apps: any[] = [];
  facility_code = "";
  facility_id = "";
  isAdmin = false;
  phone = "";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Set derived values
    this.setDetails();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets derived values from this profile's details
   */
  setDetails() {
    this.apps = get(this.details, "apps", ["growops"]);
    this.facility_code = get(this.details, "facility", "");
    this.facility_id = get(this.details, "facility_id", "");
    this.isAdmin = get(this.details, "admin", false);

    const phone: string = get(this.details, "phone", "");
    this.phone = formatPhoneNumber(phone);
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Profile
   * @returns {Object}
   */
  getDisplayVersion(): Record<string, unknown> {
    const values: any = super.getDisplayVersion();
    values.phone = this.phone || "";
    return values;
  }

  /**
   * Returns a save version of this Profile
   * @returns {Object}
   */
  getSaveVersion(): Record<string, unknown> {
    const values: any = super.getSaveVersion();
    values.email = values.email.toLowerCase();
    return values;
  }

  /**
   * Returns an update query for the oid
   * @param uuid Currently saved uuid
   * @param oid New OID
   */
  getUpdateUuidQuery(uuid = "", oid = "") {
    if (!uuid || !oid) return "";
    if (uuid === oid) return "";
    return `
      mutation update${this.getClassName()} {
        update_${this.table} (
          where: {uuid: {_eq: "${uuid}"}},
          _set: {uuid: "${oid}"}) {
          returning {
            ${this.queryReturn}
          }
        }
      }
    `;
  }
}
