import BaseModel from "@/modules/core/models/Base";
import { Lot } from "@eaua/model";
import {
  required,
  minValue,
  maxLength,
  decimal,
  numeric,
} from "vuelidate/lib/validators";
import { formatCurrency } from "@/util/helpers";
import get from "lodash/get";

export const category_ids: any = {
  dev: {
    produce: "bcb2422d-8b5f-4bfd-96b4-17b1f1dae9b8",
  },
  stage: {
    produce: "bcb2422d-8b5f-4bfd-96b4-17b1f1dae9b8",
  },
  prod: {
    produce: "",
  },
};

export default class InventoryItem extends BaseModel {
  table = "growops_inventory_items";
  primaryKey = "inventory_items_pkey";

  properties = {
    uuid: "",
    name: "",
    gtin: null,
    uom_id: "",
    category_id: null,
    type: "inventory",
    number: null,
    active: true,
    allow_sale: false,
    allow_purchase: false,
    reference_id: null,
    details: null,
    cost: null,
    price: null,
    best_by_offset: null,
    ship_by_offset: null,
    refrigerate: false,
    archived: null,
    archived_by: null,
    created: null,
    document_id: null,
    created_by: null,
  };

  relationships = {
    uom: {},
    lots: [],
    lots_aggregate: {},
    tagByItemId: [],
    item_tags: [],
    categoryByTagId: {},
  };

  // Config: will eventually move to database
  config: any = {
    type_options: [
      {
        value: "inventory",
        text: "Inventory",
      },
      {
        value: "consumable",
        text: "Consumable",
      },
      {
        value: "service",
        text: "Service",
      },
    ],
  };

  // Derived properties
  category: any = {};
  detailedName: string = "";
  display_price: string = "0.00";
  display_cost: string = "0.00";
  display_unit: string = "";
  on_hand: number = 0;
  tags: any[] = [];

  queryReturnWithLots: string = "";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add item info to the query return object
    this.queryReturn += `
      uom {
        uuid
        name
        type
      }
      tagByItemId {
        tagByTagId {
          uuid
          name
          permanent
        }
      }
      lots_aggregate {
        aggregate {
          sum {
            on_hand
          }
        }
      }
      categoryByTagId {
        name
      }
      rel_recipe_materials {
        uuid
      }
    `;

    // If the current facility is set, use facility to limit
    // lots aggregate
    let lotsWhere: string = "";
    if (this.currentFacilityId) {
      lotsWhere = `
      where: {
        location: {
          facility_id: {_eq: "${this.currentFacilityId}"}
        },
        on_hand: {_gt: "0"}
      }, `;
    }
    // Add lots to query return object
    this.queryReturnWithLots = `
      ${this.queryReturn}
      lots(${lotsWhere}order_by: {created: desc}) {
        ${new Lot().queryReturn}
      }
    `;

    // Add validation rules
    let validations: any = {
      name: { required },
      category: { required },
      gtin: { maxLength: maxLength(13), numeric },
      uom_id: { required },
      type: { required },
      cost: { decimal, minValue: minValue(0) },
      price: { decimal, minValue: minValue(0) },
      best_by_offset: { decimal, minValue: minValue(0) },
      ship_by_offset: { decimal, minValue: minValue(0) },
      refrigerate: { required },
    };
    for (let rule in validations) {
      this.validations[rule] = validations[rule];
    }

    // Set derived values
    this.setCategory();
    this.setDetailedName();
    this.setDisplayCost();
    this.setDisplayPrice();
    this.setDisplayUnit();
    this.setOnHand();
    this.setTags();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets this item's category object
   */
  setCategory() {
    if (this.categoryByTagId) {
      this.category = {
        name: this.categoryByTagId.name,
        uuid: this.category_id,
      };
    }
  }

  /**
   * Sets a detailed name with item number and upc
   */
  setDetailedName() {
    this.detailedName = this.name || "";
    if (this.number) this.detailedName += ` - ${this.number}`;
    if (this.gtin) this.detailedName += ` - ${this.gtin}`;
  }

  /**
   * Formats this item's cost for display.
   * ie, `4.50`
   */
  setDisplayCost() {
    this.display_cost = formatCurrency(this.cost || "");
  }

  /**
   * Formats this item's price for display.
   * ie, `4.50`
   */
  setDisplayPrice() {
    this.display_price = formatCurrency(this.price || "");
  }

  /**
   * Sets the display unit.
   * ie, `oz(s)`
   */
  setDisplayUnit() {
    if (this.uom && this.uom.name) {
      this.display_unit = this.uom.name;
    }
  }

  /**
   * Sets this item's on hand value from aggregate lot data
   */
  setOnHand() {
    this.on_hand = get(this.lots_aggregate, "aggregate.sum.on_hand", 0);
  }

  /**
   * Sets an initial value of tags from tagByItemId.
   */
  setTags() {
    if (this.tagByItemId) {
      for (let t of this.tagByItemId) {
        if (!t.tagByTagId.permanent) {
          this.tags.push(t.tagByTagId);
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Packout Good
   * @returns {Object}
   */
  getDisplayVersion(): Record<string, unknown> {
    let values: any = super.getDisplayVersion();
    values.category = this.categoryByTagId
      ? this.categoryByTagId.name[0].toUpperCase() +
        this.categoryByTagId.name.slice(1)
      : "";
    if (this.display_cost === "0.00") {
      this.display_cost = "N/A";
    }
    if (this.display_price === "0.00") {
      this.display_price = "N/A";
    }
    values.cost = this.display_cost;
    values.price = this.display_price;
    values.unit = this.display_unit;
    values.tags = this.getTagNames();
    return values;
  }

  /**
   * Builds a basic upsert query if a table name and a query return
   * string are set. Expects a variable called `input` to be passed
   * in the GraphQL call.
   * @param {string} uuid UUID of item being saved
   * @returns {string}
   */
  getUpsertQuery(uuid: string = "", returnString: string = "") {
    let removeTagsQuery: string = "";
    if (uuid) {
      // Delete existing tags
      removeTagsQuery = `
        delete_growops_inventory_item_tags(
          where: {
            item_uuid: {_eq: "${uuid}"}
          }
        ) {
          affected_rows
        }
      `;
    }
    return super.getUpsertQuery(returnString, removeTagsQuery);
  }

  /**
   * Returns a query string with lots in stock at a given facility
   * @param facilityId
   */
  getQueryReturnWithFacilityLots(facilityId: string = "") {
    if (!facilityId) facilityId = this.currentFacilityId;
    if (!facilityId) return "";
    let queryReturn: string = this.queryReturn;
    let lotWhere: string = `
      {
        _and: {
          on_hand: {_gt: 0},
          location: {facility_id: {_eq: "${facilityId}"}}
        }
      }
    `;
    queryReturn += `lots(where: ${lotWhere}) { ${new Lot().queryReturn} }`;
    return queryReturn;
  }
  /**
   * Returns a query string with lots in stock at a given facility
   * @param facilityId
   */
  getQueryReturnWithFacilityLotsForPackoutEdit(facilityId: string = "") {
    if (!facilityId) facilityId = this.currentFacilityId;
    if (!facilityId) return "";
    let queryReturn: string = this.queryReturn;
    let lotWhere: string = `
      {
        _and: {
          location: {facility_id: {_eq: "${facilityId}"}}
        }
      }
    `;
    queryReturn += `lots(where: ${lotWhere}) { ${new Lot().queryReturn} }`;
    return queryReturn;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    let tagsToSave: any = [];
    if (this.category) {
      values.category_id = this.category.uuid;
      tagsToSave.push({
        tag_uuid: this.category.uuid,
      });
    }
    for (let t of this.tags) {
      let tag: any = {
        tag_uuid: t.uuid,
      };
      tagsToSave.push(tag);
    }
    // Add nested tags for upsert
    if (tagsToSave.length > 0) {
      values.tagByItemId = {
        data: tagsToSave,
        on_conflict: {
          constraint: "inventory_item_tags_pkey",
          update_columns: [],
        },
      };
    }
    if (values.best_by_offset == "") values.best_by_offset = null;
    if (values.ship_by_offset == "") values.ship_by_offset = null;
    return values;
  }

  /**
   * Returns an array of tag ids, excluding category
   * @returns {Array}
   */
  getTagIds() {
    return this.tags.map((t: any) => t.uuid);
  }

  /**
   * Returns an array of tag names, excluding category
   * @returns {Array}
   */
  getTagNames() {
    return this.tags.map((t: any) => t.name);
  }

  /**
   * Returns true if this item has the given tag id
   * @param tagId Tag UUID
   * @returns {boolean}
   */
  hasTag(tagId: string = "") {
    if (!tagId) return false;
    if (this.category && this.category.uuid === tagId) return true;
    let tags: any = this.getTagIds();
    if (tags.includes(tagId)) return true;
    return false;
  }
}
