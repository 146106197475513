
import { Component, Mixins } from "vue-property-decorator";
import Notification from "../../mixins/Notification.vue";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class AppNotificationSnackbar extends Mixins(Notification) {
  created() {
    this.priority = "low";
  }
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns actions with a more-readable font color
   * @returns {Array}
   */
  get actions() {
    let actions = cloneDeep(this.notification.actions);
    for (let action of actions) {
      action.color = "secondary";
    }
    return actions;
  }
}
