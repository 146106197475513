import { set } from "@/util/vuex";
import axios from "axios";
import { groupBy, mapValues, orderBy, filter, flatMap } from "lodash";

const TRANSACTION_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    uuidMap: {},
    inventoryTransactions: [],
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = TRANSACTION_STORE_DEFAULTS();

const mutations = {
  setTransactionKey: set("transactionKey"),
  setInventoryTransactions: set("inventoryTransactions"),
  setUuidMap: (state: any, uuidMap: any) => {
    state.uuidMap = uuidMap;
  },
  reset(state: any, property: string) {
    state[property] = TRANSACTION_STORE_DEFAULTS()[property];
  },
};

const getters = {
  /**
   * Returns a lot count based on a journal transaction list
   * and then balances the consumed lots against the restored lots
   * in instances where a lot has been scanned but then removed from a packout.
   * This function also filters on item_id so we can track lots scanned for shift packouts
   * @param state
   * @returns {Array<any>}
   */
  getLotTransactions:
    (state: any) =>
    (item_id: any = null) => {
      let filteredGroups: any = [];
      let stateTransactions = state.inventoryTransactions;
      let groups: any = groupBy(stateTransactions, "lot_id");

      if (item_id) {
        filteredGroups = mapValues(groups, (transactions) => {
          return filter(transactions, (transaction) => {
            return transaction.details.item_id === item_id;
          });
        });
      } else {
        filteredGroups = groups;
      }

      let orderedGroups = mapValues(filteredGroups, (group) =>
        orderBy(group, "created", "desc")
      );

      let balancedTransactions = mapValues(
        orderedGroups,
        (groupTransactions: any) => {
          let positive = groupTransactions.filter((t: any) => t.amount > 0);
          let negative = groupTransactions.filter((t: any) => t.amount < 0);

          return negative.filter((neg: any) => {
            let index = positive.findIndex(
              (pos: any) => pos.amount === Math.abs(neg.amount)
            );
            if (index > -1) {
              // remove the balanced transaction
              positive.splice(index, 1);
              return false;
            }
            return true;
          });
        }
      );

      let transactionsArray = flatMap(balancedTransactions, (group) => group);

      return transactionsArray;
    },
};

const actions = {
  /**
   * Adds a lotId and transactionKey to the lotIdMap in the store
   * @param context
   * @param uuid
   * @param transactionKey
   */
  setUuidToMap: function (context: any, { uuid, transactionKey }: any) {
    // Get the current idMap from the state
    const currentUuidMap = context.state.uuidMap;

    // Push the new id and transactionKey into the idMap array
    currentUuidMap[uuid] = transactionKey;

    // Commit the setIdMap mutation with the updated idMap
    context.commit("setUuidMap", currentUuidMap);
  },

  /**
   * Removes an id and transactionKey from the IdMap in the store
   * given an index matching the id
   * @param context
   * @param index
   */
  removeUuidFromMap: function (context: any, uuid: any) {
    // Get the current idMap from the state
    const currentUuidMap = context.state.uuidMap;

    // Remove the id and transactionKey from the idMap array
    delete currentUuidMap[uuid];

    // Commit the setIdMap mutation with the updated idMap
    context.commit("setUuidMap", currentUuidMap);
  },

  /**
   * Retrieves the most recent transactions (by limit)
   * Commits results to inventory transactions.
   * @param context Store module context
   */
  retrieveJournalTransactions: async function (
    context: any,
    { originator_id, limit }: any
  ) {
    if (!originator_id) return;
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `/inventory/retrieveJournalTransactions`,
          {
            originator_id: originator_id,
            limit: limit || "",
          }
        )
        .then(
          (success: any) => {
            context.commit("setInventoryTransactions", success.data);
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
