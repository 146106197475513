import Session from "@/types/Session";
import LogRocket from "logrocket";
import Vue from "vue";
import { auth } from "..";
import { store } from "..";
import { initThirdParty, logRocketInit } from "./initThirdParty";

export async function handleLoginResponse(resp: any) {
  if (resp !== null) {
    auth.authClient.setActiveAccount(resp.account);
    const session = {
      email: resp.account.username,
      name: resp.account.name,
      token: resp.idToken,
      oid: resp.uniqueId,
    } as Session;
    await auth.setCurrentUser(store, session);

    // get the user token
    const token: string = store.getters["user/getToken"];
    // Initialize: set headers token,
    // retrieve user profile and facilities from database
    if (token) {
      Vue.prototype.$auth.initializeAfterSignIn(store, token);
      await store.dispatch("facilities/retrieveFacilities");
    }
    initThirdParty(store, LogRocket);
  } else {
    auth.signIn();
  }
}
