
import { Component, Mixins, Watch } from "vue-property-decorator";
import { setListeners, unsetListeners } from "@/util/eventbus";
import { mapState } from "vuex";
import { Profile } from "@eaua/model";
import DataRefresh from "./mixins/DataRefresh.vue";

@Component({
  computed: {
    ...mapState("user", ["profile"]),
    ...mapState("facilities", ["currentFacilityUuid"]),
  },
})
export default class App extends Mixins(DataRefresh) {
  // ---------------------------------------------------------------------------
  // MAPPED VARIABLES
  // ---------------------------------------------------------------------------
  profile!: any;
  currentFacilityUuid!: string;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the current user's email address
   * @returns {string}
   */
  get userEmail() {
    return this.$store.getters["user/getEmail"];
  }

  /**
   * Returns true if the current session is authenticated
   * @returns {boolean}
   */
  get isAuthenticated() {
    return this.$store.state.user.authenticated;
  }

  /**
   * Returns true if a profile is stored
   * @returns {boolean}
   */
  get profileLoaded() {
    return !!this.$store.state.user.profile.uuid;
  }

  /**
   * Returns the app settings for the app
   * @returns {Record<string, string>} app settings
   */
  get appSettings() {
    return this.$store.state.app.appSettings;
  }

  /**
   * Returns version from package.json
   */
  get packageVersion() {
    return process.env.PACKAGE_VERSION || "";
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  /**
   * Set listeners for this component's events if they aren't already on
   */
  mounted() {
    setListeners(this.events);
    if (!this.verifyUserFacility()) this.openFacilitySelector();
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  updated() {
    this.$nextTick(() => {
      if (this.profileLoaded && !this.currentFacilityUuid) {
        this.openFacilitySelector();
      }
    });
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("profileLoaded", { immediate: true })
  async onProfileLoaded() {
    if (this.profileLoaded) {
      await this.retrieveDetailTypes();
      this.setRefreshInterval(900000); // refresh inverval by 15 minutes = 900000 ms
      this.scheduleAsyncRefresh(this.retrieveAppSettings);
    }
  }

  @Watch("appSettings")
  onSettingsChanged() {
    if (this.appSettings) {
      const { maintenance_mode, current_version } = this.appSettings;
      let dialogOpened = false;

      // On maintenance mode true show maintenance dialog
      if (maintenance_mode) {
        // @ts-ignore: Can't read function from ref
        this.$refs["maintenance-mode-dialog"].open({
          settings: this.appSettings,
        });
        dialogOpened = true;
      } else {
        // @ts-ignore: Can't read function from ref
        this.$refs["maintenance-mode-dialog"].close();
      }
      // On current version mismatch show non dismissable dialog
      if (current_version !== this.packageVersion && !dialogOpened) {
        // @ts-ignore: Can't read function from ref
        this.$refs["version-mismatch-dialog"].open({
          settings: this.appSettings,
          packageVersion: this.packageVersion,
        });
      } else {
        // @ts-ignore: Can't read function from ref
        this.$refs["version-mismatch-dialog"].close();
      }
    }
  }

  @Watch("profile")
  triggerFacilitySelector() {
    if (!this.verifyUserFacility()) this.openFacilitySelector();
  }

  @Watch("userEmail")
  triggerRetrieveProfile() {
    // If the userEmail isn't set, exit
    if (!this.userEmail) return;
    // If the user email matches the profile email, exit
    if (
      this.profile &&
      this.profile.email &&
      this.profile.email.toLowerCase() === this.userEmail.toLowerCase()
    ) {
      return;
    }
    // Otherwise, update the user profile from the database
    this.$store.dispatch("user/retrieveProfile");
  }

  events: any = [
    {
      name: "open-facility-selector",
      function: this.openFacilitySelector,
    },
  ];

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Retrieves all detail types for use in various components
   */
  async retrieveDetailTypes(): Promise<void> {
    this.$store.dispatch("stages/retrieveDetailTypes");
  }

  /**
   * Retrieves the app settings by the app name
   */
  async retrieveAppSettings() {
    await this.$store.dispatch("app/retrieveAppSettings");
  }

  /**
   * Opens the facility selector dialog
   */
  openFacilitySelector() {
    if (this.$refs["facility-selector"] && this.profileLoaded) {
      // @ts-ignore: Can't read function from ref
      this.$refs["facility-selector"].open();
    }
  }

  /**
   * Returns true if the current user has a facility selected
   * @returns {boolean}
   */
  verifyUserFacility() {
    if (!this.profile.email) return false;
    let profile = new Profile(this.profile);
    if (!profile.facility_code || !profile.facility_id) return false;
    return true;
  }
}
