
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { requiredIf } from "vuelidate/lib/validators";
import { setListeners, unsetListeners } from "@/util/eventbus";
import { getIcon } from "@/util/icons";
import find from "lodash/find";
import get from "lodash/get";
import Form from "@/modules/core/mixins/Form.vue";

@Component({
  validations: {
    selected: {
      required: requiredIf("required"),
    },
  },
})
export default class BaseSelection extends Mixins(Form) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: false, type: Boolean })
  allowAdd!: Boolean;

  @Prop({ default: false, type: Boolean })
  allowAllOption!: Boolean;

  @Prop({ default: false, type: Boolean })
  allowEdit!: Boolean;

  @Prop({ default: false, type: Boolean })
  allowAddAndEdit!: Boolean;

  @Prop({ default: false, type: Boolean })
  allowMultiple!: Boolean;

  @Prop({ default: false, type: Boolean })
  allowChips!: boolean;

  @Prop({ default: false, type: Boolean })
  disabled!: boolean;

  @Prop({ default: "" })
  hint!: string;

  @Prop({ default: null })
  options!: any;

  @Prop({ default: "" })
  overrideLabel!: string;

  @Prop({ default: "" })
  overrideWhere!: string;

  @Prop({ default: "" })
  prependIcon!: string;

  @Prop({ default: false, type: Boolean })
  preventDataRetrieval!: Boolean;

  @Prop({ default: false, type: Boolean })
  required!: Boolean;

  @Prop({ default: true, type: Boolean })
  requireAdminToEdit!: Boolean;

  @Prop({ default: true })
  resetOnDestroy!: Boolean; // Resets store data on destroy

  @Prop({ default: false, type: Boolean })
  showSelectAll!: Boolean;

  @Prop({ default: null })
  used!: any;

  @Prop({ default: "" })
  value!: string | Array<string>; // Passed via v-model

  // LOCAL VARIABLES -----------------------------------------------------------
  [key: string]: any;
  defaultLabel: string = "Selection";
  events: any = null;
  forceRefresh: Boolean = false;
  initialValue: string | Array<string> = "";
  optionsName: string = "";
  retries: number = 3;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get selected(): string | Array<string> {
    return this.value;
  }

  set selected(value: string | Array<string>) {
    this.$emit("input", value);
  }

  get label(): string {
    let label = this.overrideLabel || this.defaultLabel;
    if (this.required) return label;
    return label + " (optional)";
  }

  get currentFacilityId(): string {
    return this.$store.state.facilities.currentFacilityUuid;
  }

  get addEnabled(): boolean {
    if (!this.allowAdd && !this.allowAddAndEdit) return false;
    if (this.requireAdminToEdit && !this.isAdmin) return false;
    return true;
  }

  get addOrEditEnabled(): boolean {
    return this.addEnabled || this.editEnabled;
  }

  get editEnabled(): boolean {
    if (!this.allowEdit && !this.allowAddAndEdit) return false;
    if (this.requireAdminToEdit && !this.isAdmin) return false;
    return true;
  }

  get isAdmin(): boolean {
    return this.$store.getters["user/getIsAdmin"];
  }

  get isDev(): boolean {
    return this.$store.getters["user/getIsDev"];
  }

  /**
   * Returns the state of the select all icon in plots menu.
   * @returns {string} Icon value
   */
  get selectAllIcon() {
    if (!this.showSelectAll || !this.optionsName || !this.allowMultiple)
      return "";
    let options: any = this[this.optionsName];
    if (this.selected && options.length === this.selected.length) {
      return getIcon("base", "deselect");
    }
    if (this.selected && this.selected.length) {
      return getIcon("base", "selectPartial");
    }
    return "mdi-checkbox-blank-outline";
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("isLoading", { immediate: true })
  onLoadingChanged() {
    this.$emit("loading-data", this.isLoading);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  created() {
    // Store the initialValue
    this.initialValue = this.value;
    // Retrieve data
    if (!this.options && this.retries > 0 && !this.preventDataRetrieval) {
      this.retrieveData();
      this.retries--; // Prevents data retrieval loop
    }
  }

  mounted() {
    if (this.events) setListeners(this.events);
  }

  beforeUpdate() {
    if (this.events) setListeners(this.events);
  }

  updated() {
    if (!this.options && this.retries > 0) {
      this.retrieveData();
      this.retries--; // Prevents data retrieval loop
    }
  }

  beforeDestroy() {
    if (this.resetOnDestroy) this.resetData();
    if (this.events) unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Retrieves data. Should be overloaded by child component.
   */
  async retrieveData() {}

  /**
   * Resets data. Should be overloaded by child component.
   */
  resetData() {}

  /**
   * Filters used options out of the available options.
   * @returns {Array}
   */
  filterUsedOptions(options: any = []): Array<any> {
    // If the used array isn't set, there's nothing to filter
    if (!this.used || this.used.length === 0) return options;

    return options.filter((o: any) => {
      // If the value is null (None) or it matches the current selection,
      // we don't want to filter it out
      if (!o.value) return true;
      if (Array.isArray(this.selected) && this.selected.includes(o.value)) {
        return true;
      } else if (o.value === this.selected) return true;
      // If the value is in the used array, filter it out
      if (get(this.used, "[0].value", null)) {
        if (find(this.used, { value: o.value })) return false;
      } else if (this.used.includes(o.value)) return false;

      // By default, don't filter
      return true;
    });
  }

  /**
   * Toggler to select or deselect all options
   */
  toggleSelectAll() {
    if (!this.optionsName || !this.allowMultiple) return;
    this.$nextTick(() => {
      let options: any = this[this.optionsName];
      if (!this.selected) this.selected = [];
      if (options.length === this.selected.length) this.selected = [];
      else this.selected = options.slice().map((p: any) => p.value);
    });
  }
}
