import BaseModel from "../../Base";
import Dinero from "dinero.js";
import {
  required,
  minValue,
  maxValue,
  decimal,
} from "vuelidate/lib/validators";
import {
  formatSalesOrderNumber,
  formatCurrency,
  formatTimestamp,
} from "@/util/helpers";
import get from "lodash/get";
import isNil from "lodash/isNil";
import sumBy from "lodash/sumBy";
import { DateTime } from "luxon";

export default class SalesOrderLine extends BaseModel {
  table = "growops_sales_order_lines";
  primaryKey = "sales_order_line_pkey";

  properties = {
    uuid: "",
    order_id: "",
    sequence: 0,
    item_id: "",
    quantity: null,
    uom_id: "",
    unit_price: null,
    net_unit_price: null,
    allocated: null,
    discount_amount: 0,
    discount_percent: 0,
    expected_ship_date: null,
    shipped_quantity: 0,
    invoiced_quantity: 0,
    created: null,
    created_by: null,
    completed: null,
    completed_by: null,
    notes: {},
    reference_id: null,
  };

  relationships = {
    delivery_lines: [],
    item: {},
    packout_lines: [],
    sales_order: {},
  };

  config: any = {
    discount_types: {
      none: {
        value: "none",
        text: "None",
      },
      percent: {
        value: "percent",
        text: "Percent Off",
      },
      amount: {
        value: "amount",
        text: "Amount Off",
      },
    },
  };

  // Derived properties
  customer: any = {};
  salesOrderNumber: string = "";
  requestedDate: string = "";
  formattedRequestedDate: string = "";
  itemNumber: string = "";
  itemName: string = "";
  itemUpc: string = "";
  refrigerate: boolean = false;

  qtyAllocated: number = 0;
  qtyDelivered: number = 0;
  qtyInDelivery: number = 0;
  qtyToPack: number = 0;
  qtyRejected: number = 0;
  qtyInvoiced: number = 0;

  queryReturnWithSalesOrder: string = "";
  queryForAllocations: string = "";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add item and uom info to the query return object
    this.queryReturn += `
      uom {
        uuid
        name
        type
      }
      item {
        uuid
        name
        gtin
        number
        details
        best_by_offset
        ship_by_offset
        refrigerate
        item_label_name
        internal_unit_count
        internal_unit_weight
        internal_unit_weight_uom
        internal_unit_packaging
      }
      delivery_lines (where: {delivery_box: {archived: {_is_null: true}}}) {
        uuid
        box_id
        accepted
        rejected
        quantity
        lot_id
      }
      rel_sales_order_line_credits {
        UUID
        created
        created_by
        new_amount
        difference
        new_quantity
        new_unit_price
        new_discount
        rel_created_by {
          name
        }
      }
      packout_lines {
        uuid
        actual_quantity
        allocated
      }
    `;

    // Add sales order to `this.queryReturnWithSalesOrder`
    this.queryReturnWithSalesOrder = `
      ${this.queryReturn}
      sales_order {
        uuid
        number
        requested
        customer {
          uuid
          name
        }
      }
    `;

    // Build query for allocations
    this.queryForAllocations = `
      ${this.getQueryReturnTemplate()}
      item {
        uuid
        name
        number
        internal_unit_count
        internal_unit_weight
        internal_unit_weight_uom
        internal_unit_packaging
      }
      sales_order {
        uuid
        number
        requested
        customer {
          uuid
          name
        }
      }
    `;

    // Exclude order_id from update columns
    this.queryUpdateColumns = this.getQueryUpdateColumns([
      "uuid",
      "order_id",
      "created",
      "created_by",
    ]);

    // Add validation rules
    this.validations.order_id = { required };
    this.validations.item_id = { required };
    this.validations.uom_id = { required };
    this.validations.allocated = { minValue: minValue(0) };
    // this.validations.net_unit_price = {
    //   required,
    //   decimal,
    //   minValue: minValue(0)
    // };
    // this.validations.discount_amount = { decimal, minValue: minValue(0) };
    this.validations.discount_percent = { decimal };
    this.validations.quantity = { required, decimal, minValue: minValue(0) };

    // Set derived values
    this.setCustomer();
    this.setItemValues();
    this.setRequestedDate();
    this.setSalesOrderNumber();

    // Set quantities
    this.setQuantityAllocated();
    this.setQuantityInvoiced();
    this.setQuantityDelivered();
    this.setQuantityInDelivery();
    this.setQuantityToPack();
    this.setQuantityRejected();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets the customer from the linked sales order
   */
  setCustomer() {
    if (
      this.sales_order &&
      this.sales_order.customer &&
      this.sales_order.customer.name
    ) {
      this.customer = this.sales_order.customer;
    } else this.customer = {};
  }

  /**
   * Sets the requested date
   */
  setRequestedDate() {
    if (this.sales_order && this.sales_order.requested) {
      this.requestedDate = this.sales_order.requested;
    } else this.requestedDate = "";

    this.formattedRequestedDate = this.requestedDate
      ? DateTime.fromISO(this.requestedDate).toFormat("LLL d")
      : "";
  }

  /**
   * Sets the formatted sales order number
   */
  setSalesOrderNumber() {
    if (this.sales_order && this.sales_order.number) {
      this.salesOrderNumber = formatSalesOrderNumber(this.sales_order.number);
    } else this.salesOrderNumber = "";
  }

  /**
   * Sets item values for sorting in display tables
   */
  setItemValues() {
    if (this.item) {
      this.itemName = this.item.name || "";
      this.itemNumber = this.item.number || "";
      this.itemUpc = this.item.gtin || "";
      this.refrigerate = this.item.refrigerate || false;
    }
  }

  /**
   * Sets the allocated quantity to actual produced, packout line allocated
   * amount, original order quantity, or zero
   */
  setQuantityAllocated() {
    let allocated: number | null = get(
      this.packout_lines,
      "[0].allocated",
      null
    );
    let actual: number | null = get(
      this.packout_lines,
      "[0].actual_quantity",
      null
    );
    // If allocated has been specifically set to zero in the packout,
    // and there's no actual qty set, set qty allocated to zero
    if (allocated === 0 && !actual) this.qtyAllocated = 0;
    // If there are no allocations through packout, and the sales
    // order line is specifically set to zero, set qty allocated to zero
    else if (!allocated && !actual && this.allocated === 0) {
      this.qtyAllocated = 0;
    }
    // Set the allocated value by order of priority
    else {
      this.qtyAllocated =
        actual || allocated || this.allocated || this.quantity || 0;
    }
  }

  /**
   * Sets the invoiced quantity to actual produced, packout line allocated
   * amount, or zero
   */
  setQuantityInvoiced() {
    let allocated: number | null = get(
      this.packout_lines,
      "[0].allocated",
      null
    );
    let actual: number | null = get(
      this.packout_lines,
      "[0].actual_quantity",
      null
    );
    this.qtyInvoiced = actual || allocated || 0;
  }

  /**
   * Sets the quantity delivered to the number accepted in a delivery
   */
  setQuantityDelivered() {
    this.qtyDelivered = sumBy(this.delivery_lines, "accepted") || 0;
  }

  /**
   * Sets the quantity currently in a delivery, whether it's packed,
   * loaded, or just templated
   */
  setQuantityInDelivery() {
    this.qtyInDelivery = sumBy(this.delivery_lines, "quantity") || 0;
  }

  /**
   * Sets the quantity left to pack for a delivery
   */
  setQuantityToPack() {
    this.qtyToPack = this.qtyAllocated - this.qtyInDelivery;
  }

  /**
   * Sets the quantity rejected
   */
  setQuantityRejected() {
    this.qtyRejected = sumBy(this.delivery_lines, "rejected") || 0;
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display name for use when selecting delivery lines
   */
  getDeliveryDisplayName() {
    return `${this.itemName} (${this.qtyInDelivery}/${this.qtyAllocated} boxed)`;
  }

  /**
   * Returns a display Sales Order Line
   * @returns {Object}
   */
  getDisplayVersion(): object {
    let values: any = super.getDisplayVersion();
    values.unit_price = formatCurrency(values.unit_price);
    values.discount_amount = formatCurrency(values.discount_amount);
    values.net_unit_price = formatCurrency(values.net_unit_price);
    values.qtyInDelivery = this.qtyInDelivery;
    values.subtotal = Dinero({
      amount: Math.round(values.net_unit_price * 100),
    })
      .multiply(Number(this.qtyAllocated))
      .toFormat("0.00");
    values.salesOrderNumber = this.salesOrderNumber;
    values.requestedDate = DateTime.fromISO(this.requestedDate).toFormat(
      "yyyy-MM-dd"
    );
    values.itemName = this.itemName;
    values.itemNumber = this.itemNumber;
    values.itemUpc = this.itemUpc;
    values.accepted_quantity = this.qtyDelivered;
    values.rejected_quantity = this.qtyRejected;

    let allocated: number | null = get(
      this.packout_lines,
      "[0].allocated",
      null
    );
    let actual: number | null = get(
      this.packout_lines,
      "[0].actual_quantity",
      null
    );

    if (actual) values.allocated_quantity = actual;
    else if (!isNil(allocated)) values.allocated_quantity = allocated;
    else if (!isNil(values.allocated))
      values.allocated_quantity = values.allocated;
    else values.allocated_quantity = "N/A";

    return values;
  }

  getInvoiceDisplayVersion(type: string = "") {
    let values: any = this.getDisplayVersion();
    values.name = this.itemName;
    values.gtin = this.itemUpc;
    values.unit_price = `$${values.net_unit_price}`;
    switch (type) {
      case "credit":
        values.quantity = this.qtyAllocated;
        values.amount = Dinero({
          amount: Math.round(values.net_unit_price * 100),
        })
          .multiply(Number(this.qtyAllocated))
          .toFormat("0.00");
        break;
      case "newcredit":
        values.quantity = this.qtyAllocated;
        values.discount = `$${Dinero({
          amount: Math.round(Number(this.discount_amount) * 100),
        }).toFormat("0.00")}`;
        values.amount = Dinero({
          amount: Math.round(Number(values.initialValues.amount) * 100),
        }).toFormat("0.00");
        //credit amount always needs to be positive for accounting
        values.amount = -values.amount > 0 ? -values.amount : values.amount;
        values.new_amount = Dinero({
          amount: Math.round(Number(values.initialValues.new_amount) * 100),
        }).toFormat("0.00");
        break;
      case "modified":
        values.quantity = this.qtyDelivered;
        values.amount = Dinero({
          amount: values.net_unit_price * 100,
        })
          .multiply(Number(this.qtyDelivered))
          .toFormat("0.00");
        break;
      case "invoiceFromDelivery":
        values.quantity = this.qtyInDelivery;
        values.amountNum = Dinero({
          amount: Math.round(values.net_unit_price * 100),
        })
          .multiply(Number(this.qtyInDelivery))
          .toFormat("0.00");
        values.amount = `$${Dinero({
          amount: Math.round(values.net_unit_price * 100),
        })
          .multiply(Number(this.qtyInDelivery))
          .toFormat("0.00")}`;
        break;
      case "invoiceFromDeliveryOld":
        values.quantity = this.qtyInDelivery;
        values.amountNum = values.subtotal;
        values.amount = `$${values.subtotal}`;
        break;
      default:
        values.quantity = this.qtyAllocated;
        values.amountNum = values.subtotal;
        values.amount = `$${values.subtotal}`;
        break;
    }
    return values;
  }

  /**
   * Builds a return query for packout orders. Searches for sales order
   * lines that:
   *  - fall within the requested date range
   *  - have a customer with the given facility as a provider
   *  - have no current related packout lines
   * @param {Object} dateRange Object with `start` and `end`
   * @param {string} facilityIds Array of facility ids
   * @returns {string}
   */
  getReturnQueryForPackoutGroceryList(
    dateRange: any = {},
    facilityIds: any = []
  ) {
    if (!dateRange.start || !dateRange.end) return "";
    if (!(facilityIds.length > 0)) return "";
    let start: string = formatTimestamp(dateRange.start, "datepicker");
    let end: string = DateTime.fromISO(dateRange.end)
      .plus({ days: 1 })
      .toFormat("yyyy-MM-dd");
    let where: string = `
      {
        _and: {
          sales_order: {
            _and: {
              confirmed: { _is_null: false },
              completed: { _is_null: true },
              canceled: { _is_null: true },
              requested: {
                _gte: "${start}",
                _lt:"${end}"
              },
              customer: {
                provider_facilities: {
                  facility_id: {
                    _in: ["${facilityIds.join(", ")}"]
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.getReturnQuery(where, "", this.queryReturnWithSalesOrder);
  }

  /**
   * Builds a return query for packout orders. Searches for sales order
   * lines that:
   *  - fall within the requested date range
   *  - have a customer with the given facility as a provider
   *  - have no current related packout lines
   * @param {Object} dateRange Object with `start` and `end`
   * @param {string} facilityIds Array of facility ids
   * @returns {string}
   */
  getReturnQueryForPackout(dateRange: any = {}, facilityIds: any = []) {
    if (!dateRange.start || !dateRange.end) return "";
    if (!(facilityIds.length > 0)) return "";
    let start: string = formatTimestamp(dateRange.start, "datepicker")!;
    let end: string = DateTime.fromISO(dateRange.end)
      .plus({ days: 1 })
      .toFormat("yyyy-MM-dd");
    let where: string = `
      {
        _and: {
          sales_order: {
            _and: {
              confirmed: { _is_null: false },
              completed: { _is_null: true },
              canceled: { _is_null: true },
              requested: {
                _gte: "${start}",
                _lt:"${end}"
              },
              customer: {
                provider_facilities: {
                  facility_id: {
                    _in: ["${facilityIds.join(", ")}"]
                  }
                }
              }
            }
          },
          _not: {
            packout_lines: {
              uuid: {}
            }
          }
        }
      }
    `;
    return this.getReturnQuery(where, "", this.queryReturnWithSalesOrder);
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    // Remove the order id if it's empty or null
    if (values.hasOwnProperty("order_id") && !values.order_id) {
      delete values.order_id;
    }
    return values;
  }
}
