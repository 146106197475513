import BaseModel from "@/modules/core/models/Base";

export default class Zone extends BaseModel {
  table = "shared_facility_zones";
  primaryKey = "facility_zones_pkey";

  properties = {
    uuid: "",
    name: "",
    description: null,
    details: null,
    active: true,
    facility_id: "",
    type: "",
  };

  relationships = {
    rel_facility: {},
  };

  // Derived properties
  display_address = "";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);
  }

  /**
   * Returns a display Zone
   * @returns {Object}
   */
}
