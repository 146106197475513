
import { Component, Mixins, Prop } from "vue-property-decorator";
import Form from "@/modules/core/mixins/Form.vue";
import LotList from "../LotList.vue";

@Component({
  components: {
    LotList,
  },
})
export default class ConsumedWipLotForm extends Mixins(Form) {
  @Prop({ default: "consumed-wip-lot-actions" })
  destination!: string;

  isLoading: boolean = false;
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-consumed-lots-dialog`,
        label: "Cancel",
      },
    ];
  }
}
