
import { Mixins, Component, Ref, Watch } from "vue-property-decorator";
import { Bom, Lot, PackoutMaterial, PackoutGood } from "@eaua/model";
import Form from "@/modules/core/mixins/Form.vue";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";
import { getIcon } from "@/util/icons";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";

@Component
export default class MaterialsList extends Mixins(Form) {
  //LOCAL VARIABLES --------------------------------------------------------------
  headers: Array<any> = [
    {
      text: "Item",
      value: "item_name",
    },
    {
      text: "Lot",
      value: "lot_number",
    },
    {
      text: "Harvested",
      value: "harvest_date",
    },
    {
      text: "Consumed",
      value: "quantity",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
    },
  ];

  get actions(): Array<any> {
    return [
      {
        callback: "open-edit-consumed-dialog",
        event: "open-edit-dialog",
        icon: getIcon("base", "edit"),
        isIcon: true,
        loading: this.isLoading,
        disabled: this.isLoading,
        label: "Edit",
      },
      {
        callback: "open-remove-confirmation-dialog",
        icon: getIcon("base", "delete"),
        isIcon: true,
        label: "Delete",
        disabled: (item: any) => {
          if (item.completed) {
            return !(item.completed === null);
          } else {
            return false;
          }
        },
      },
    ];
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: any = [
    {
      name: "confirm-remove-packout-material",
      function: this.removeLot,
    },
    {
      name: "open-remove-confirmation-dialog",
      function: this.showRemoveDialog,
    },
    {
      name: "open-edit-consumed-dialog",
      function: this.showEditConsumedDialog,
    },
  ];

  // ---------------------------------------------------------------------------
  // REFS
  // ---------------------------------------------------------------------------

  @Ref("remove-confirmation-dialog")
  readonly removeConfirmationDialog!: any;

  @Ref("edit-lot-consumed")
  readonly editLotConsumed!: any;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }

  get materials(): Array<PackoutMaterial> {
    return (this.$store.state.packout.currentGood?.materials || []).map(
      (m: any) => new PackoutMaterial(m)
    );
  }

  get currentPackoutGood(): PackoutGood {
    return new PackoutGood(this.$store?.state?.packout?.currentGood || {});
  }

  get currentPackoutGoodUuid(): string {
    return this.currentPackoutGood.uuid;
  }

  get currentLot(): Lot {
    return new Lot(this.$store.state.lots.currentLot || {});
  }
  get materialList(): Array<any> {
    const materialList: Array<any> = (this.materials || []).map(
      (mat: PackoutMaterial) => {
        const lot = new Lot(mat?.lot || {});
        return {
          uuid: mat.uuid, // Needed for the removeLot method
          created: mat.created,
          harvest_date: DateTime.fromISO(lot.created).toFormat("MM/dd"),
          item_name: lot.itemName,
          lot_number: lot.name,
          quantity: mat.getQuantityWithUnit(),
        };
      }
    );
    return orderBy(materialList, ["created"], ["desc"]);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async removeLot(item: any) {
    this.calculateUseByDate(item);
    await this.$store
      .dispatch("packout/deleteGoodMaterial", {
        goodMaterial: item,
      })
      .then((success) => {
        EventBus.$emit("retrieve-packout-good");
        emitNotification({
          priority: "low",
          message: "Deleted lot from materials list",
          title: "Success",
          type: "success",
        });
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to delete lot. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });
  }

  /**
   * This method has been updated and broken into these 3 parts.
   * This checks each material's use by if it exists and compares it to the current
   * batch use by. If the material doesn't have a use by its pack by is used instead.
   */
  async calculateUseByDate(item: any = null) {
    // Find the material that has the same uuid as the passed item
    const material = this.currentPackoutGood.materials.find(
      (m: any) => m.uuid === item.uuid
    );
    // Check if the currentPackoutGood has the same use_by date as the material
    if (this.currentPackoutGood.use_by === material.lot.use_by) {
      // Iterate through all the materials, ignoring the one that matches item
      const otherMaterials = this.currentPackoutGood.materials.filter(
        (m: any) => m.uuid !== item.uuid
      );

      // Find the material with the soonest use_by date
      const soonestUseByMaterial = otherMaterials.reduce(
        (soonest: any, current: any) => {
          let currentUseBy;
          if (current.lot.use_by) {
            currentUseBy = DateTime.fromISO(current.lot.use_by);
          } else {
            currentUseBy = DateTime.fromISO(current.lot.created).plus({
              days: current.inventory_item.best_by_offset,
            });
            current.lot.use_by = currentUseBy.toISO(); // Save the calculated date
          }

          let soonestUseBy;
          if (soonest.lot.use_by) {
            soonestUseBy = DateTime.fromISO(soonest.lot.use_by);
          } else {
            soonestUseBy = DateTime.fromISO(soonest.lot.created).plus({
              days: soonest.inventory_item.best_by_offset,
            });
            soonest.lot.use_by = soonestUseBy.toISO(); // Save the calculated date
          }

          return currentUseBy < soonestUseBy ? current : soonest;
        },
        otherMaterials[0]
      );

      // Set the currentpackoutgood's use_by date to be the soonest use_by from the materials left
      this.currentPackoutGood.use_by = soonestUseByMaterial.lot.use_by;
    }
  }

  // Universal confirmation dialog.
  showRemoveDialog(item: any = {}): void {
    this.removeConfirmationDialog.open({
      passedObject: item,
      passedTitle: `Remove ${item.lot_number}`,
      passedMessage: `Are you sure you want to remove ${item.quantity} of ${item.lot_number} ${item.item_name}?`,
    });
  }

  async showEditConsumedDialog(item: any = {}): Promise<void> {
    const packoutMaterial = this.materials.find(
      (ml) => ml.lotNumber == item.lot_number
    );

    if (!packoutMaterial || !this.currentPackoutGoodUuid) return;

    this.isLoading = true;

    await this.$store.dispatch("lots/retrieveCurrentLot", {
      uuid: packoutMaterial.lot_id,
      useView: true,
    });

    this.isLoading = false;

    this.editLotConsumed.open({
      passedTitle: `Edit ${item.lot_number} Consumed`,
      passedObject: {
        inventoryItem: packoutMaterial.lot.inventory_item,
        packoutMaterialQuantity: packoutMaterial.quantity,
        packoutMaterialAfterQuantity: packoutMaterial.after_quantity,
        packoutMaterial,
        packoutGoodUuid: this.currentPackoutGoodUuid,
        lotOnHand: this.currentLot.on_hand,
      },
    });
  }
}
