// @ts-ignore
import { ref, withParams } from "vuelidate/lib/validators/common";
export default (minValueName: any) =>
  withParams(
    { type: "minValue", minValueName },
    function (value: any, parentVm: any) {
      // @ts-ignore
      let min: any = ref(minValueName, this, parentVm);
      if (min === null || min === undefined) return true;
      return Number(value) > min;
    }
  );
