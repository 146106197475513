import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { PalletRoutes } from "./router/pallet-routes";

export class PalletizerModule implements VueModule {
  readonly name = "palletizer";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(PalletRoutes);
  }
}
