
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { get } from "lodash";

@Component
export default class BatchListDialog extends Mixins(Dialog) {
  [key: string]: any;

  @Prop({ default: "" })
  passedGoodId!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "batch-list-actions";
  scoped = true;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-batch-list-dialog");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize() {}
}
