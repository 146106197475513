import Vue from "vue";
import Router from "vue-router";
import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

Vue.use(Router);

export const transplantRoutes: RouteConfig = {
  path: "/transplant",
  component: () => import("../views/Index.vue"),
  children: [
    {
      path: "",
      name: "TransplantMain",
      component: () => import("../views/Main.vue"),
    },
    {
      path: "details/:date/:cropOrRecipeId/:moduleId/:zoneId?",
      props: true,
      name: "TransplantDetails",
      component: () => import("../views/Details.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("transplant");
    next();
  },
};
