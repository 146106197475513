
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import union from "lodash/union";
import BaseSelection from "@/modules/core/mixins/BaseSelection.vue";

@Component
export default class ModuleSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  facilityId!: string;

  @Prop({ default: "" })
  stages!: string | string[];

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Module";

  addModuleButton: any = {
    callback: "show-add-module-dialog",
    callbackType: "self",
    icon: getIcon("base", "add"),
    isIcon: true,
    label: "Add Module",
    class: "form-append-button mx-0",
  };

  editModuleButton: any = {
    callback: "show-edit-module-dialog",
    callbackType: "self",
    icon: getIcon("base", "edit"),
    isIcon: true,
    label: "Edit Module",
    class: "form-append-button mx-0",
  };

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of module options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets modules from the store.
   * @returns {Array}
   */
  get moduleOptions(): Array<any> {
    let options: any[] = [];
    if (this.isLoading) return options;
    if (!this.required && !this.allowMultiple) {
      options.push({ value: null, text: "None" });
    }
    if (!this.allowMultiple && this.allowAllOption) {
      options.push({ value: "all", text: "All" });
    }
    if (this.options)
      return this.filterUsedOptions(union(options, this.options));

    let stages: string[] = [];
    if (Array.isArray(this.stages)) stages = this.stages;
    else if (this.stages) stages = [this.stages];
    else stages = [];

    return this.filterUsedOptions(
      union(options, this.$store.getters["facilities/getModuleOptions"](stages))
    );
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("moduleOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (
      this.moduleOptions.length === 1 &&
      !this.allowMultiple &&
      this.moduleOptions[0].value &&
      this.moduleOptions[0].value !== "all"
    )
      this.selected = this.moduleOptions[0].value;
  }

  @Watch("facilityId")
  onFacilityChanged() {
    this.forceRefresh = true;
    this.retrieveData();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update modules from the database
   */
  async retrieveData(): Promise<any> {
    if (
      this.forceRefresh ||
      this.$store.state.facilities.modules.length === 0
    ) {
      this.isLoading = true;
      await this.$store.dispatch("facilities/retrieveModules", {
        facilityId: this.facilityId || this.currentFacilityId,
      });
      this.isLoading = false;
      this.forceRefresh = false;
    }
  }

  /**
   * Resets modules
   */
  resetData() {
    this.$store.commit("facilities/reset", "modules");
  }

  showCreateDialog() {
    // @ts-ignore: Can't read function from ref
    this.$refs["edit-dialog"].open({});
  }

  showEditDialog() {
    let module: any = this.$store.getters["facilities/getModuleByUuid"](
      this.selected
    );
    if (module.uuid) {
      // @ts-ignore: Can't read function from ref
      this.$refs["edit-dialog"].open(module);
    }
  }
}
