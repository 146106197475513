
import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonOptions from "@/types/app/shared/ButtonOptions";

@Component
export default class MainLayout extends Vue {
  // ---------------------------------------------------------------------------
  // PROPS
  // ---------------------------------------------------------------------------

  @Prop({ type: String, default: "title" }) title!: string;
  @Prop({ default: () => [] }) actions!: ButtonOptions[];
  @Prop({ type: Object, default: null }) back!: ButtonOptions;
  @Prop({ type: Object, default: null }) next!: ButtonOptions;
  @Prop({ type: Boolean, default: false }) fluid!: Boolean;
  @Prop({ type: Boolean, default: false }) warnBorder!: Boolean;
}
