import BaseModel from "../../Base";
import { get, upperFirst } from "lodash";
import { required } from "vuelidate/lib/validators";

export default class ItemAttribute extends BaseModel {
  table = "growops_inventory_item_attributes";
  primaryKey = "inventory_item_attributes_pkey";

  properties = {
    uuid: null,
    name: "",
    description: "",
    data_type_id: null,
    options: "",
    category_id: "",
    created: null,
    created_by: null,
    modified: null,
    modified_by: null,
    archived: null,
    archived_by: null,
    product_type: "",
    uom_category_id: null,
    uom_id: null,
  };

  relationships = {
    rel_data_type: {},
    rel_inventory_item_spec_attributes: [],
    rel_inventory_uom_category: {},
    rel_inventory_item_attribute_categories: {},
    rel_inventory_uom: {},
  };

  categoryName: string = "";
  dataType: string = "";

  constructor(values: any = {}, store: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  //  INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
    rel_data_type {
      name
    }
    rel_inventory_uom_category {
      name
    }
    rel_inventory_item_attribute_categories {
      uuid
      name
    }
    rel_inventory_uom {
      name
      type
    }
  `;

    this.validations.name = { required };
    this.validations.data_type_id = { required };
    this.validations.product_type = { required };
    // Set derived properties
    this.setUOMCategoryName();
    this.setCategoryName();
    this.setDataType();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setUOMCategoryName() {
    this.uomCategoryName = upperFirst(
      get(this.rel_inventory_uom_category, "name", "None")
    );
  }

  setCategoryName() {
    let categoryName: string = "None";
    if (this.rel_inventory_item_attribute_categories) {
      categoryName = upperFirst(
        get(this.rel_inventory_item_attribute_categories, "name", "None")
      );
    }
    this.categoryName = categoryName;
  }

  setDataType() {
    this.dataType = get(this.rel_data_type, "name", "") || "";
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  getDisplayVersion() {
    let values = super.getDisplayVersion();
    values.uomCategoryName = this.uomCategoryName;
    values.dataType = this.dataType;
    values.categoryName = this.categoryName;
    values.productType = [];
    if (this.product_type) {
      for (let item of this.product_type) {
        values.productType.push(item.name);
      }
    }
    return values;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values = super.getSaveVersion();
    if (this.checkForEmptyAttributeOptions()) {
      values.options = null;
    }
    return values;
  }

  /**
   * Returns true if options are empty
   * @returns {boolean}
   */
  checkForEmptyAttributeOptions() {
    if (!this.options) return true;
    if (this.options.length === 0) return true;
    if (this.options.length === 1 && !this.options[0].value) return true;
    return false;
  }

  isList() {
    return this.dataType === "List";
  }

  isNumber() {
    return this.dataType === "Number";
  }

  isNumberRange() {
    return this.dataType === "Number Range";
  }

  isText() {
    return this.dataType === "Text";
  }
}
