import BaseModel from "@/modules/core/models/Base";
export interface IHarvestTask {
  [key: string]: any;
  uuid: string;
  order_id: string;
  line_id: string | null;
  created_by: string;
  plot_id: string;
  completed: string | null;
  completed_by: string | null;
  modified: string | null;
  modified_by: string | null;
  totes_completed: string | null;
  canceled: string | null;
  canceled_by: string | null;
  blade_height: number | null;
}

export default class HarvestTask extends BaseModel {
  table = "growops_tasks_harvest";
  primaryKey = "harvest_pkey";

  properties: IHarvestTask = {
    uuid: "",
    order_id: "",
    line_id: null,
    plot_id: "",
    created: null,
    created_by: "",
    completed: null,
    completed_by: null,
    modified: null,
    modified_by: null,
    totes_completed: null,
    canceled: null,
    canceled_by: null,
    blade_height: null,
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  initialize(values: any = {}) {
    super.initialize(values);
  }
}
