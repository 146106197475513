
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";

@Component
export default class ConfirmationDialog extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "confirmation-actions";
  scoped = true;

  passedObject: any = {};
  passedTitle: string = "Confirm";
  passedMessage: string = "Are you sure?";

  // PROPS ------------------------------------------------------------------------
  @Prop({ default: "" })
  passedEvent!: string;

  @Prop({ default: "Confirm" })
  passedButtonLabel!: string;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-confirmation-dialog");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize(values: any = {}) {
    this.passedObject = values.passedObject;
    this.passedTitle = values.passedTitle;
    this.passedMessage = values.passedMessage;
  }
}
