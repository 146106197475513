import { set, setDeep, retrieveSet } from "@/util/vuex";
import { Profile, Session } from "@eaua/model";
import axios from "axios";
import get from "lodash/get";
import { SocketEventType, socketClient } from "../../sockets";
import { APP_OPTIONS } from "./app";

const USER_STORE_DEFAULTS: { [key: string]: any } = {
  authenticated: false,
  users: [],
  name: "",
  email: "",
  token: "",
  oid: "",
  session: {},
  profile: {},
  refreshInterval: 30000,
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = () => {
  let state: any = {};
  for (let property in USER_STORE_DEFAULTS) {
    state[property] = USER_STORE_DEFAULTS[property];
  }
  return state;
};

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setAuthenticated: set("authenticated"),
  setEmail: set("email"),
  setName: set("name"),
  setOID: set("oid"),
  setProfile: set("profile"),
  setToken: set("token"),
  setUsers: setDeep("users"),
  resetUser(state: any) {
    state.session = {};
    state.profile = {};
    state.name = "";
    state.email = "";
    state.token = "";
    state.oid = "";
    state.authenticated = false;
  },
  reset(state: any, property: string) {
    state[property] = USER_STORE_DEFAULTS[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  /**
   * Returns an array of authorized apps for the current user.
   * Allows growops by default.
   * @returns {Array}
   */
  getAuthorizedApps: (state: any) => {
    let authApps: any = {
      growops: {
        text: "GrowOps",
        value: "growops",
      },
    };
    APP_OPTIONS.forEach((a: any) => {
      if (
        state.profile.details &&
        state.profile.details.apps &&
        state.profile.details.apps.includes(a.value)
      ) {
        authApps[a.value] = a;
      }
    });
    return authApps;
  },

  /**
   * Returns the current user's email address
   * @returns {string}
   */
  getEmail: (state: any) => {
    return state.email;
  },

  /**
   * Returns the current user's first name
   * @returns {string}
   */
  getFirstName: (state: any) => {
    return state.name.split(" ")[0];
  },

  /**
   * Returns the current user's full name
   * @returns {string}
   */
  getFullName: (state: any) => {
    return state.name;
  },

  /**
   * Returns true if the user has admin permissions
   * @returns {boolean}
   */
  getIsAdmin: (state: any) => {
    return get(state.profile, "details.admin", false);
  },

  /**
   * Returns true if the user has admin permissions
   * @returns {boolean}
   */
  getIsDev: (state: any) => {
    return get(state.profile, "details.dev", false);
  },

  /**
   * Returns true if the user has purchasing permissions
   * @returns {boolean}
   */
  getIsPurchasing: (state: any) => {
    return get(state.profile, "details.purchasing", false);
  },

  /**
   * Returns true if the user can edit recipes
   * @returns {boolean}
   */
  getIsRecipeEditor: (state: any) => {
    return get(state, "profile.details.recipe_editor", false);
  },

  /**
   * Returns true if the user has researcher permissions
   * @returns {boolean}
   */
  getIsResearcher: (state: any) => {
    return get(state, "profile.details.researcher", false);
  },

  /**
   * Returns true if the user has sales permissions
   * @returns {boolean}
   */
  getIsSales: (state: any) => {
    return get(state.profile, "details.salesperson", false);
  },

  /**
   * Returns true if the user has permission to modify a packout BOM
   * @returns {boolean}
   */
  getIsPackoutManager: (state: any) => {
    return get(state.profile, "details.packoutManager", false);
  },

  /**
   * Returns the current user's Azure OID
   * @returns {string}
   */
  getOID: (state: any) => {
    return state.oid;
  },

  /**
   * Returns the current user's full profile
   * @returns {Object}
   */
  getProfile: (state: any) => {
    return state.profile;
  },

  /**
   * Returns the current user's session token
   * @returns {string}
   */
  getToken: (state: any) => {
    return state.token;
  },

  /**
   * Returns an array of user's for a dropdown selection
   * TODO: Convert over to name instead of email once
   * all users have been converted
   * @returns {string}
   */
  getUserOptions: (state: any) => {
    let users: any = [];
    for (let user of state.users) {
      users.push({
        text: user.name || user.email,
        value: user.uuid,
      });
    }
    return users;
  },

  /**
   * Returns a function that returns true if the profile has a uuid
   * @returns {Function}
   */
  getProfileLoaded: (state: any) => {
    return () => !!get(state, "profile.uuid", false);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  /**
   * Updates user details from the currently authenticated session
   * @param session Cognito session
   */
  updateSessionDetails(context: any, session: Session) {
    let hasUser = false;
    if (session.token) {
      hasUser = true;

      let name = session.name;
      let email = session.email;
      let token = session.token;
      let oid = session.oid;

      context.commit("setName", name);
      context.commit("setEmail", email);
      context.commit("setToken", token);
      context.commit("setOID", oid);
    }
    if (hasUser === false) {
      context.commit("resetUser");
    }
  },

  /**
   * Retrieves all researcher users, sorted by email.
   * Commits users using `setUsers`.
   * @param context Store module context
   */
  retrieveResearchUsers: function (context: any) {
    let userTemplate = new Profile();
    let returnQuery: string = userTemplate.getReturnQuery(
      `{details: {_has_key: "researcher"}}`,
      `{ email: asc }`
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setUsers", userTemplate.table);
  },

  /**
   * Retrieves all users, sorted by email.
   * Commits users using `setUsers`.
   * @param context Store module context
   */
  retrieveUsers: function (context: any) {
    let userTemplate = new Profile();
    let returnQuery: string = userTemplate.getReturnQuery("", `{ email: asc }`);
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setUsers", userTemplate.table);
  },

  /**
   * Retrieves a profile, given an email address.
   * Will create a new user, if no profile exists. Will also update the user's
   * uuid and name if necessary.
   * Otherwise, commits profile using `setProfile`.
   * @param context Store module context
   */
  retrieveProfile: function (
    context: any,
    { email = "", retries = 0 }: any = {}
  ) {
    let where: string = "";
    let userTemplate = new Profile();

    if (email) {
      where = `{email: {_eq: "${email.toLowerCase()}"}}`;
    } else {
      where = `{uuid: {_eq: "${context.state.oid}"}}`;
    }

    let returnQuery: string = userTemplate.getReturnQuery(where);
    if (!returnQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: returnQuery,
        })
        .then(
          (success: any) => {
            // Set the profile from success return data
            let profile: any = get(
              success,
              `data.data.${userTemplate.table}.[0]`,
              null
            );

            // We want to capture any 200 errors in the response
            const hasErrors: boolean = get(success, "data.errors", null)
              ? true
              : false;

            // If there are errors, try retreiving the profile again
            if (hasErrors) {
              // Limit our number of retries
              if (retries > 2) throw new Error(success);
              context.dispatch("retrieveProfile", {
                email: email,
                retries: retries++,
              });
            } else {
              // Create a new profile if there's not one for this user
              if (!profile) {
                profile = {
                  email: context.state.email,
                  name: context.state.name,
                  uuid: context.state.oid,
                };
                context.dispatch("createProfile", {
                  profile: new Profile(profile).getSaveVersion(),
                });
              }

              // Otherwise, check if the profile name and email match azure.
              // Update name and email from the state if doesn't match
              else if (
                profile.name !== context.state.name ||
                (profile.email || "").toLowerCase() !==
                  (context.state.email || "").toLowerCase()
              ) {
                let profileToSave: any = profile;
                profileToSave.name = context.state.name;
                profileToSave.email = context.state.email;
                context.dispatch("saveProfile", {
                  profile: new Profile(profileToSave).getSaveVersion(),
                });
              }

              // Otherwise, set the current profile
              else {
                context.commit("setProfile", profile);
                context.dispatch("setCurrentFacility", { profile: profile });
              }
            }
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Creates a user profile. Uses an insert query instead of upsert,
   * so it will fail if a user already exists.
   * Commits saved profile using `setProfile`.
   * @param context Store module context
   * @param {Object} profile Profile save-version object
   */
  createProfile: function (context: any, { profile }: any) {
    let userTemplate = new Profile();
    let insertQuery: string = `
      mutation createNewUser($input: [shared_users_insert_input!]!) {
        insert_shared_users(objects: $input) {
          returning {
            ${userTemplate.queryReturn}
          }
        }
      }
    `;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: insertQuery,
          variables: {
            input: profile,
          },
        })
        .then(
          (success: any) => {
            // Store the saved profile as current profile
            let queryName: string = `insert_${userTemplate.table}`;
            let profile: any = success.data.data[queryName].returning[0];
            context.commit("setProfile", profile);
            // Set the current facility
            context.dispatch("setCurrentFacility", { profile: profile });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a user profile.
   * Commits saved profile using `setProfile`.
   * @param context Store module context
   * @param {Object} profile Profile save-version object
   */
  saveProfile: function (context: any, { profile }: any) {
    let userTemplate = new Profile();
    let upsertQuery: string = userTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: profile,
          },
        })
        .then(
          (success: any) => {
            // Store the saved profile as current profile
            let queryName: string = `insert_${userTemplate.table}`;
            let profile: any = success.data.data[queryName].returning[0];
            context.commit("setProfile", profile);
            // Set the current facility
            context.dispatch("setCurrentFacility", { profile: profile });
            socketClient.emitWhenAvailable(SocketEventType.Upsert, {
              table: "shared_users",
              uuid: profile.uuid,
            });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Updates the current user's uuid to match Azure's oid.
   * Commits saved profile using `setProfile`.
   * @param context Store module context
   * @param {Object} profile User profile
   */
  updateProfileOid: function (context: any, { profile }: any) {
    let userTemplate = new Profile();
    let updateQuery: string = userTemplate.getUpdateUuidQuery(
      profile.uuid,
      context.state.oid
    );
    if (!updateQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: updateQuery,
        })
        .then(
          (success: any) => {
            let queryName: string = `update_${userTemplate.table}`;
            let profile: any = success.data.data[queryName].returning[0];
            // Store the saved profile as current profile
            context.commit("setProfile", profile);
            // Set the current facility
            context.dispatch("setCurrentFacility", { profile: profile });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Sets the current facility from stored profile details
   * @param context Store module context
   * @param {any=} profile User profile
   */
  setCurrentFacility: function (context: any, { profile = null }: any = {}) {
    profile = profile || context.profile || {};
    if (!profile.uuid) return;

    let facilityCode: string = get(profile, "details.facility", "");
    let facilityUuid: string = get(profile, "details.facility_id", "");

    if (facilityCode) {
      context.commit("facilities/setCurrentFacility", facilityCode, {
        root: true,
      });
    }
    if (facilityUuid) {
      context.commit("facilities/setCurrentFacilityUuid", facilityUuid, {
        root: true,
      });
      socketClient.emitWhenAvailable(SocketEventType.Subscribe, facilityUuid);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
