
import QrcodeVue from "qrcode.vue";
import { Vue, Component } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";

@Component({
  components: {
    QrcodeVue,
  },
})
export default class QRNav extends Vue {
  // ---------------------------------------------------------------------------
  // LOCAL VARIABLES
  // ---------------------------------------------------------------------------
  scannedCode: string = "";
  canScan: boolean = true;

  // ---------------------------------------------------------------------------
  // LIFE CYCLE EVENTS
  // ---------------------------------------------------------------------------
  mounted() {
    // Listen for QR entry
    window.addEventListener("keypress", this.scanForQR);
  }

  beforeDestroy() {
    window.removeEventListener("keypress", this.scanForQR);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  scanForQR(e: any) {
    const textInput = e.key || String.fromCharCode(e.keyCode);
    const targetName = e.target.localName;
    if (this.canScan) {
      if (textInput === "Enter") {
        this.canScan = false;
        setTimeout(() => (this.canScan = true), 250);
        let codeString: any = this.scannedCode;
        this.scannedCode = "";
        try {
          let codeObj: any = JSON.parse(codeString);
          this.routeByQr(codeObj);
        } catch (err) {
          console.log("Error: Not a JSON Object - " + codeString);
        }
      } else if (
        textInput &&
        textInput.length === 1 &&
        targetName !== "input"
      ) {
        this.scannedCode = this.scannedCode + textInput;
      }
    }
  }

  routeByQr(qrObj: any) {
    if (this.isLabelV2(qrObj)) {
      this.manageLabelV2(qrObj);
    } else if (this.isLabelV1(qrObj)) {
      this.manageLabelV1(qrObj);
    } else {
      emitNotification({
        message: `Failed to read QR Code.`,
        title: "QR Error 3",
        type: "error",
      });
      console.log("Error: Failed to read QR Code " + JSON.stringify(qrObj));
    }
  }

  private isLabelV1(qrObj: any) {
    return qrObj.type && qrObj.payload;
  }

  private isLabelV2(qrObj: any) {
    return qrObj.event && qrObj.event_payload;
  }

  manageLabelV2(qrObj: any) {
    try {
      EventBus.$emit(qrObj.event, qrObj.event_payload);
    } catch (err) {
      emitNotification({
        message: `Failed to read QR Code. `,
        title: "QR Error 1",
        type: "error",
      });
      console.log("Error: Failed to read QR Code " + JSON.stringify(qrObj));
    }
  }

  manageLabelV1(qrObj: any) {
    try {
      EventBus.$emit(qrObj.type + "_deprecated", qrObj.payload);
    } catch (err) {
      emitNotification({
        message: `Failed to read QR Code. `,
        title: "QR Error 1",
        type: "error",
      });
      console.log("Error: Failed to read QR Code " + JSON.stringify(qrObj));
    }
  }
}
