
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Form from "../../mixins/Form.vue";

@Component({
  computed: {
    ...mapGetters({
      currentFacility: "facilities/getCurrentFacility",
    }),
  },
  validations: {
    supervisorCode: {
      required,
    },
  },
})
export default class SupervisorAuthForm extends Mixins(Form) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "auth-success-event" })
  onAuthSucessEvent!: string;

  @Prop({ default: "destination-tag" })
  destination!: string;
  // MAPPED VARIABLES ----------------------------------------------------------
  currentFacility!: any;
  // LOCAL VARIABLES -----------------------------------------------------------
  supervisorCode: string = "";

  events: IEventBusEvent[] = [
    {
      name: "do-auth-check",
      function: this.auth,
    },
  ];
  // ---------------------------------------------------------------------------
  // LIFECICLE METHODS
  // ---------------------------------------------------------------------------
  mounted() {
    setListeners(this.events);
  }
  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Disables save if the form hasn't been touched, if there are any errors, or
   * if the cropOrder doesn't have an id
   * @returns {boolean}
   */
  get saveDisabled() {
    // Disable save if nothing's been touched
    if (!this.$v.$anyDirty) return true;
    if (this.isLoading) return true;
    if (this.$v.$anyError) return true;
    return false;
  }
  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions(): Array<any> {
    let actions: any = [
      {
        callback: "do-auth-check",
        label: "Authorize",
        disabled: this.saveDisabled,
      },
    ];
    return actions;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Method to allow users to send an auth request with supervisors code
   * to dispatch an event.
   */
  auth() {
    this.$v.$touch();
    this.validationHasRun = true;
    if (!this.$v.$anyError) {
      if (this.currentFacility.details) {
        if (
          this.supervisorCode == this.currentFacility.details.supervisorCode
        ) {
          EventBus.$emit(this.onAuthSucessEvent);
        } else {
          emitNotification({
            inDialog: true,
            message: `Please check the supervisor's code.`,
            title: "Error",
            type: "error",
          });
        }
      } else {
        emitNotification({
          inDialog: true,
          message: `This facility doesn't have a Supervisor code assigned`,
          title: "Error",
          type: "error",
        });
      }
    }
  }
}
