
import { Vue, Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { getIcon } from "@/util/icons";

@Component({
  computed: {
    ...mapState("user", ["name"]),
  },
})
export default class AppToolbarProfile extends Vue {
  // MAPPED VARIABLES ----------------------------------------------------------
  name!: string;
  profileIcon: string = getIcon("profile", "user");

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns current user's name
   * @returns {string}
   */
  get accountName() {
    return this.name.length > 0 ? this.name : "User";
  }
}
