
import { Vue, Component, Watch } from "vue-property-decorator";
import { setListeners, unsetListeners } from "@/util/eventbus";
import { generateUniqueKey } from "@/util/helpers";
import { NOTIFICATION_EVENTS } from "@/modules/core/models/app/Notification";
import isEmpty from "lodash/isEmpty";

@Component
export default class Notification extends Vue {
  // LOCAL VARIABLES -----------------------------------------------------------
  showNotification = false;
  priority = "low";
  inDialog = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the value of showNotification
   * @returns {boolean}
   */
  get visible() {
    return this.showNotification;
  }

  /**
   * Mutates the value of showNotification
   */
  set visible(val: boolean) {
    this.showNotification = val;
  }

  /**
   * Returns the notification in the app store
   * @returns {Object}
   */
  get notification() {
    return this.$store.state.app.notification;
  }

  /**
   * Returns true if this notification has actions
   * @returns {boolean}
   */
  get hasActions() {
    return !isEmpty(this.notification.actions);
  }

  /**
   * Returns events to listen on
   * @returns {Array}
   */
  get events() {
    let event: any = {
      function: this.close,
    };
    if (this.inDialog) {
      event.name = NOTIFICATION_EVENTS.inDialog;
    } else {
      event.name = NOTIFICATION_EVENTS[this.priority];
    }
    if (event.name) return [event];
    else return [];
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("notification", { deep: true })
  onNotificationChanged() {
    if (this.inDialog && this.notification.inDialog) {
      this.visible = true;
    } else if (
      !this.inDialog &&
      !this.notification.inDialog &&
      this.priority === this.notification.priority
    ) {
      this.visible = true;
    } else this.visible = false;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  mounted() {
    if (!isEmpty(this.events)) setListeners(this.events);
  }

  beforeUpdate() {
    if (!isEmpty(this.events)) setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Hides the notification
   */
  close() {
    this.visible = false;
  }

  /**
   * Encapsulates generateUniqueKey for use in template
   * @returns {string}
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
