import Vue from "vue";
import {
  set,
  setDeep,
  retrieveObject,
  retrieveSet,
  trackScannedUuid,
} from "@/util/vuex";
import { padLotNumber } from "@/util/helpers";
import axios from "axios";
import filter from "lodash/filter";
import find from "lodash/find";
import { Lot } from "@eaua/model";
import { groupBy } from "lodash";

const LOT_STORE_DEFAULTS: { [key: string]: any } = {
  lots: [],
  lotsAggregateTotal: 0,
  currentLot: {},
  itemLots: {},
  currentTrack: {},
  lotToAdd: {},
  inventoryReasonCodes: [],
  currentBatchLot: [],
  currentLotCount: 0,
};

export const state = () => {
  let state: any = {};
  for (let property in LOT_STORE_DEFAULTS) {
    state[property] = LOT_STORE_DEFAULTS[property];
  }
  return state;
};

export const getters = {
  /**
   * Returns a lot given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getLotByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.lots);
    },

  /**
   * Returns an array of lot options for a dropdown.
   * @returns {Array}
   */
  getLotOptions: (state: any) => {
    let lots: any = [];
    for (let lot of state.lots) {
      lots.push({
        value: lot.uuid,
        text: padLotNumber(lot.number),
      });
    }
    return lots;
  },

  /**
   * Returns lots given an inventory location.
   * @returns {Array}
   */
  getLotsAtLocation: (state: any) => (location: any) => {
    return filter(state.lots, { location_id: location });
  },

  /**
   * Returns lots given an item id.
   * @returns {Array}
   */
  getLotsByItemId: (state: any) => (itemId: any) => {
    return filter(state.lots, ["item_id", itemId]);
  },
};

export const mutations = {
  setLots: setDeep("lots"),
  setCurrentLot: set("currentLot"),
  setCurrentTrack: set("currentTrack"),
  setlotToAdd: set("lotToAdd"),
  setCurrentBatchLot: set("currentBatchLot"),
  setItemLots(state: any, payload: any) {
    state.itemLots = groupBy(payload?.list || [], "item_id");
  },
  setInventoryReasonCodes: set("inventoryReasonCodes"),
  setLotsAggregateTotal: set("lotsAggregateTotal"),
  updateCurrentLotLocation(state: any, payload: any) {
    Vue.set(state.currentLot, "location_id", payload.uuid);
    Vue.set(state.currentLot.location, "name", payload.name);
  },
  updateCurrentLotAmount(state: any, payload: any) {
    Vue.set(state.currentLot, "on_hand", payload.amount);
  },
  updateCurrentLotCount: set("currentLotCount"),
  reset(state: any, property: string) {
    state[property] = LOT_STORE_DEFAULTS[property];
  },
};

export const actions = {
  /**
   * Retrieves all lots. Doesn't include transactions.
   * Commits lots using `setLots`.
   * @param context Store module context
   */
  retrieveRelativeLots: function (
    context: any,
    { uuids, limit }: { uuids?: any[]; limit?: number } = {}
  ) {
    let returnQuery: string = `query relativeLots($lotHeaderIds: [uuid!], $limit: Int) {
      growops_inventory_lots(where: {lot_header_id: {_in: $lotHeaderIds}}, limit: $limit) {
          uuid
          number
          on_hand
          created
          inventory_item {
            number
            name
          }
          rel_lot_header {
            number
          }
      }
    }`;

    let variables = {
      lotHeaderIds: uuids,
      limit: limit,
    };

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setLots",
      "growops_inventory_lots",
      variables
    );
  },

  retrieveReasonCodes: function (context: any) {
    let returnQuery: string = `
      query getReasonCodes {
        growops_inventory_adjustment_reason_codes(where: {archived: {_is_null: true}}, order_by: {reason: asc}) {
          number
          reason
          uuid
        }
      }
    `;

    return retrieveSet(
      context,
      returnQuery,
      "setInventoryReasonCodes",
      "growops_inventory_adjustment_reason_codes"
    );
  },

  /**
   * Retrieves an lot by uuid. Includes transactions.
   * Commits lot using `setCurrentLot`.
   * @param context Store module context
   * @param {string} uuid Lot UUID
   */
  retrieveCurrentLot: function (
    context: any,
    { uuid, number, includeTransactions = false, useView = false }: any
  ) {
    let lotTemplate = new Lot();
    // Set the return string if it's a view or if transactions
    // should be included
    //
    let returnString = "";
    if (useView) returnString = lotTemplate.viewQueryReturn;
    else if (includeTransactions)
      returnString = lotTemplate.queryReturnWithTransactions;
    // Change the where clause depending on the type of value passed
    const where = number
      ? `{number: {_eq: "${number}"}}`
      : `{uuid: {_eq: "${uuid}"}}`;
    const returnQuery = lotTemplate.getReturnQuery(
      where,
      "",
      returnString,
      {},
      useView
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentLot",
      useView ? lotTemplate.view : lotTemplate.table
    );
  },

  /**
   * Saves a lot.
   * @param context Store module context
   * @param {Object} lot Lot save-version object
   */
  saveLot: function (context: any, { lot }: any) {
    let lotTemplate = new Lot();
    let upsertQuery: string = lotTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: lot,
          },
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a lot for casing labels.
   * @param context Store module context
   * @param {Object} lot Lot save-version object
   */
  saveBatchLot: function (context: any, { lot }: any) {
    let lotTemplate = new Lot();
    let upsertQuery: string = lotTemplate.getUpsertQuery(
      lotTemplate.queryReturnWithTransactions
    );
    if (!upsertQuery) return;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: lot,
          },
        })
        .then(
          (success: any) => {
            // Setting currentLot to be used in Case print label
            context.commit(
              "setCurrentBatchLot",
              success.data.data.insert_growops_inventory_lots.returning
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Adjusts inventory for a lot.
   * @param {string} uuid The lot's uuid
   * @param {number} amount The difference in on hand quantity
   * @param {details} details A details object for the transaction
   */
  adjustLotInventory: function (
    context: any,
    { uuid, amount, details, reasonCode }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + `/inventory/inventoryadjustment`, {
          amount: amount,
          details: details,
          lot_id: uuid,
          reason_code_id: reasonCode,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Adjusts inventory on hand for a case lot.
   * @param {string} uuid The lot's uuid
   */
  addCase: function (context: any, { uuid, notes }: any) {
    return new Promise((resolve, reject) => {
      try {
        trackScannedUuid(context, uuid);
      } catch (error: any) {
        reject(error);
        return;
      }
      axios
        .post(process.env.VUE_APP_API_URL + `/v2/add-case`, {
          lot_id: uuid,
          notes: notes,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            trackScannedUuid(context, uuid, true);
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * @param {string} uuid The lot's uuid
   */
  removeCase: function (context: any, { uuid, notes }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + `/v2/remove-case`, {
          lot_id: uuid,
          notes: notes,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Relocates a lot.
   * @param {string} uuid The lot's uuid
   * @param {string} location_id The target location's uuid
   */
  relocateLot: function (context: any, { uuid, locationId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + `/inventory/relocatelot`, {
          loc_id: locationId,
          lot_id: uuid,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves lots by item id.
   * Commits lots using `setItemLots`.
   * ignores hold room items.
   * @param context Store module context
   * @param itemId Item uuid
   */
  retrieveLotsByItemIds: function (context: any, { itemIds, facilityId }: any) {
    if (!itemIds || !facilityId) return;
    let lotTemplate = new Lot();
    const holdRoomQuerySegment = context.rootGetters["facilities/getHoldRoom"]
      ? `uuid: {_neq: "${context.rootGetters["facilities/getHoldRoom"]}"},`
      : ``;
    let returnQuery: string = lotTemplate.getReturnQuery(
      `{
        _and:
          {
            item_id: {_in: ["${itemIds.join('", "')}"]},
            location: {
              ${holdRoomQuerySegment}
              facility_id: {_eq: "${facilityId}"}
            },
          },
          on_hand: {_gt: "0"}
      }`,
      `{created: asc}`
    );
    if (!returnQuery) return;
    return retrieveSet(context, returnQuery, "setItemLots", lotTemplate.table);
  },

  /**
   * Retrieves lots by tags.
   * ignores hold room items.
   * @param context Store module context
   * @param tags array of tags
   */
  retrieveLotsByTag: function (context: any, { tags }: any) {
    let lotTemplate = new Lot();
    const holdRoomQuerySegment = context.rootGetters["facilities/getHoldRoom"]
      ? `uuid: {_neq: "${context.rootGetters["facilities/getHoldRoom"]}"},`
      : ``;
    let returnQuery: string = lotTemplate.getReturnQuery(
      `{inventory_item: {tagByItemId: {tag_uuid: {_in: ["${tags.join(
        '", "'
      )}"]}}},
      location: {
        ${holdRoomQuerySegment}
         facility_id: {_eq: "${
           context.rootState.facilities.currentFacilityUuid
         }"}},
      },
        on_hand: {_neq: "0"}}`,
      `{number: asc}`
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setLots", lotTemplate.table);
  },

  /**
   * Retrieves  lot tracking by uuid.
   * @param context Store module context
   * @param {string} uuid
   */
  retrieveCurrentLotTrack: function (context: any, { uuid }: any) {
    let lotTemplate = new Lot();
    let returnQuery: string = lotTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`,
      "",
      lotTemplate.getDetailedReturnQuery(uuid)
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentTrack",
      lotTemplate.table
    );
  },

  splitLotIntoMultiple: function (context: any, { orderedLotSplit }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_URL + `/inventory/inventoryLotMultiSplit`,
          {
            ordered_lot_split: orderedLotSplit,
          }
        )
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
