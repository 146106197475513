import {
  set,
  setDeep,
  retrieveSet,
  retrieveObject,
  trackScannedUuid,
} from "@/util/vuex";
import find from "lodash/fp/find";
import get from "lodash/get";
import { filterItems } from "@/util/helpers";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import InventoryItem from "../../models/growops/inventory/Item";
import Pallet from "../../models/growops/inventory/Pallet";
import ItemAttribute from "../../models/growops/inventory/ItemAttibutes";
import AttributeCategory from "../../models/growops/inventory/AttributeCategory";
import ItemSpecVersion from "../../models/growops/inventory/ItemSpecVersion";
import { isObjectLike } from "lodash";
import ItemSpec from "../../models/growops/inventory/ItemSpec";
import { DateTime } from "luxon";

// TODO: Use a model
const INVENTORY_TRANSFER_RETURN_OBJECT: string = `
  uuid
  origin_id
  destination_id
  created
  created_by
  origin_accepted
  origin_accepted_by
  destination_accepted
  destination_accepted_by
  shipped_quantity
  received_quantity
  lot_id
  lot {
    number
  }
  created_by_user {
    name
    email
  }
`;

// TODO: Use a model
const INVENTORY_AUDIT_RETURN_OBJECT: string = `
  id
  created
  created_by
  location_id
  submitted
  submitted_by
  reason_code_id
  userByCreatedBy {
    name
  },  
  locationByLocationId{
    name
  }
  audit_details
  tag_data
`;

// TODO: Use a model
const INVENTORY_AUDIT_LOTS_RETURN_OBJECT: string = `
  uuid
  audit_id
  lot_id
  reason_code_id
  starting_on_hand
  updated_on_hand
  lotByLotId {
    number
    inventory_item {
      name
      uom {
        name
      }
    },
    location {
      uuid
      name
    }
  }
`;

// TODO: Use a model
const TAG_RETURN_OBJECT: string = `
  uuid
  name
  archived
  archived_by
  permanent
`;

const INVENTORY_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    items: [],
    itemOptions: [],
    products: [],
    pallets: [],
    supplies: [],
    transactions: [],
    attributeCategories: [],
    tags: [],
    archivedTags: [],
    itemsByTag: [],
    inventoryTransfers: [],
    audits: [],
    auditLots: [],
    attributes: [],
    rejections: [],
    currentAudit: {},
    currentItem: {},
    currentItemUUID: "",
    currentLot: {},
    currentPallet: {},
    currentItemSpec: {},
    specVersions: [],
    currentItemVersions: [],
    defaultSpecVersion: {},
    refreshInterval: 60000,
    ItemsAggregateTotal: 0,
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = INVENTORY_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setInventoryTransfers: setDeep("inventoryTransfers"),
  setTags: setDeep("tags"),
  setArchivedTags: setDeep("archivedTags"),
  setItems: setDeep("items"),
  setItemOptions: setDeep("itemOptions"),
  setCurrentAudit: set("currentAudit"),
  setCurrentItem: set("currentItem"),
  setCurrentItemUUID: set("currentItemUUID"),
  setCurrentPallet: set("currentPallet"),
  setCurrentItemSpec: set("currentItemSpec"),
  setProducts: setDeep("products"),
  setPallets: setDeep("pallets"),
  setSupplies: setDeep("supplies"),
  setItemByTag: set("itemsByTag"),
  setAudits: setDeep("audits"),
  setAuditLots: setDeep("auditLots"),
  setRejections: setDeep("rejections"),
  setItemsAggregateTotal: set("ItemsAggregateTotal"),
  setCurrentRejection: set("currentRejection"),
  setAttributes: setDeep("attributes"),
  setAttributeCategories: setDeep("attributeCategories"),
  setSpecVersions: set("specVersions"),
  setCurrentItemVersions: set("currentItemVersions"),
  setDefaultSpecVersion: set("defaultSpecVersion"),
  reset(state: any, property: string) {
    state[property] = INVENTORY_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  // Audits / Cycle Counts ///////////////////////////////
  ////////////////////////////////////////////////////////
  /**
   * Returns a selected cycle count audit by uuid
   */
  getAuditByUuid: (state: any) => (uuid: any) => {
    return find(state.audits, ["id", uuid]);
  },

  // INVENTORY TRANSFER ///////////////////////////////////
  /////////////////////////////////////////////////////////
  /**
   * Returns a sales order given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getInventoryTransferByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.inventoryTransfers) || {};
    },

  getFilteredInventoryTransfers: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventorytransfers"] || [];
    let search = rootState.filter.search["inventorytransfers"] || "";
    return filterItems(state.inventoryTransfers, filters, search);
  },
  getFilteredLots: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventory-item"] || [];
    let search = rootState.filter.search["inventory-item"] || "";
    return filterItems(state.currentItem.lots, filters, search);
  },

  // TAGS /////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  /**
   * Returns archived tags.
   * @returns {Array}
   */
  getArchivedTags: (state: any) => {
    let archTags: any = [];
    for (let tag of state.archivedTags) {
      if (tag.archived != null) {
        archTags.push(tag);
      }
    }
    return archTags;
  },

  /**
   * Returns a category given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getTagByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.tags);
    },

  getTagOptions: (state: any) => {
    return state.tags;
  },

  getTagByName:
    (state: any) =>
    (name: string = "") => {
      if (!name) return {};
      return find({ name: name }, state.tags) || {};
    },

  getFilteredTags: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventory-tags"] || [];
    let search = rootState.filter.search["inventory-tags"] || "";
    return filterItems(state.tags, filters, search);
  },

  // ITEM /////////////////////////////////////////////////
  /////////////////////////////////////////////////////////

  getItemOptions: (state: any) => {
    let items: any = [];
    let lots: any = [];
    for (let item of state.items) {
      // This could probably be added to the query that retrieves the items
      // Just make sure that doesn't break some other part of the app that
      // might be expecting archived items. Nothing that uses this getter
      // (getItemOptions) seems to need archived items, so I've added the check here.
      if (item.archived) {
        continue;
      }
      lots = [];
      if (item.lots) {
        for (let lot of item.lots) {
          lots.push({
            value: lot.uuid,
            uuid: lot.uuid,
            number: lot.number,
            display: lot.number + " - " + lot.inventory_item.name,
            location: lot.location.facility_id,
            on_hand: lot.on_hand,
            item_id: lot.item_id,
            location_id: lot.location_id,
            created: lot.created,
          });
        }
      }
      let name = item.number ? item.number + " - " : "";
      name += item.name;
      items.push({
        value: item.uuid,
        text: name,
        price: item.price,
        lots: cloneDeep(lots),
      });
    }
    return items;
  },

  getProduceOptions: (state: any) => {
    let items: any = [];
    for (let item of state.items) {
      if (item.allow_purchase == true) {
        items.push({
          value: item.uuid,
          text: item.name,
          price: item.price,
        });
      }
    }
    return items;
  },

  getDetailedItemOptions: (state: any) => {
    let items: any = [];
    for (let i of state.items) {
      let item: any = cloneDeep(i);
      item.text = item.name;
      if (item.number) item.text += ` - ${item.number}`;
      if (item.gtin) item.text += ` - ${item.gtin}`;
      items.push(item);
    }
    return items;
  },

  /**
   * Returns an array of item options to be used as a standard
   * app-wide. Do not change unless something is broken.
   * @returns {Array}
   */
  getItemOptionsForStandardSelection:
    (state: any) =>
    (
      showUpc: boolean = false,
      facilityId: string = "",
      categoryId: string = "",
      categoryName: string = ""
    ) => {
      if (!state.itemOptions) return [];
      let options: Array<any> = [];
      // If facilityId is passed, filter out options without stock
      // at the given facility
      if (!facilityId) options = state.itemOptions;
      else {
        options = state.itemOptions.filter((i: any) => {
          if (!i.on_hand_by_facility) return false;
          for (let oh of i.on_hand_by_facility) {
            if (oh.has_positive_inventory && oh.facility_id === facilityId)
              return true;
          }
          return false;
        });
      }
      // Filter options by category, and map to dropdown options
      // return options
      return options
        .filter((i: any) => {
          if (categoryId && i.category_id) return i.category_id === categoryId;
          else if (categoryName && i.category_name) {
            return i.category_name.toLowerCase() === categoryName.toLowerCase();
          } else if (!categoryName && !categoryId) return true;
          return false;
        })
        .map((i: any) => {
          let text: string = "";
          if (i.number) text = `${i.number} - `;
          if (showUpc && i.gtin) text += `${i.gtin} - `;
          text += i.name;

          if (facilityId) {
            let facilityOnHand: any =
              find(
                { facility_id: facilityId },
                get(i, "on_hand_by_facility", [])
              ) || {};
            text += facilityOnHand.on_hand
              ? ` (${facilityOnHand.on_hand} on hand)`
              : "";
          }
          return {
            text: text,
            value: i.uuid,
          };
        });
    },

  /**
   * Returns an item from the itemOptions array given a uuid
   * @returns {Object}
   */
  getItemOptionByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.itemOptions) || {};
    },

  getProductOptions: (state: any) => {
    if (!state.products) return [];
    return state.products.map((p: any) => {
      let item = new InventoryItem(p);
      return {
        value: item.uuid,
        text: item.detailedName,
      };
    });
  },

  getSupplyOptions: (state: any) => {
    if (!state.supplies) return [];
    return state.supplies.map((p: any) => {
      let item = new InventoryItem(p);
      return {
        value: item.uuid,
        text: item.detailedName,
      };
    });
  },

  /**
   * Returns an item given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getItemByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.items) || {};
    },

  /**
   * Returns a product given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getProductByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.products) || {};
    },

  /**
   * Returns a supply item given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getSupplyByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.supplies) || {};
    },

  getFilteredItems: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventory-items"] || [];
    let search = rootState.filter.search["inventory-items"] || "";
    return filterItems(state.items, filters, search);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // INVENTORY TRANSFERS ///////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // TODO: Use a model
  retrieveInventoryTransfers: function (
    context: any,
    { where = null }: any = {}
  ) {
    let whereClause: string = "";
    if (where) whereClause = `where: ${where}, `;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query GrowopsInventoryTransfers {
            growops_inventory_transfer_lines(
              ${whereClause}
              order_by: { created: asc }
            ) {
              ${INVENTORY_TRANSFER_RETURN_OBJECT}
            }
          }`,
        })
        .then(
          (success: any) => {
            context.commit("setInventoryTransfers", {
              list: success.data.data.growops_inventory_transfer_lines,
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // TODO: Use a model
  saveInventoryTransfer: function (context: any, { inventoryTransfer }: any) {
    if (inventoryTransfer.hasOwnProperty("uuid") && !inventoryTransfer.uuid) {
      delete inventoryTransfer.uuid;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation insertInventoryTransfer($inventoryTransfer: [growops_inventory_transfer_insert_input!]!) {
            insert_growops_inventory_transfer_lines(
              objects: $inventoryTransfer,
              on_conflict: {
                constraint: inventory_transfer_pkey,
                update_columns: [received_quantity]
              }
            ) {
              returning {
                uuid
              }
            }
          }`,
          variables: {
            inventoryTransfer: inventoryTransfer,
          },
          username: "created_by",
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveInventoryTransfers");
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a attribute.
   * Retrieves all attributes using `retrieveAttributes`.
   * @param context Store module context
   * @param {Object} attribute attribute save-version object
   */
  saveAttribute: function (context: any, { attribute }: any) {
    let attributeTemplate = new ItemAttribute();
    let upsertQuery: string = attributeTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: attribute,
          },
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveAttributes");
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // TAGS //////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  // TODO: Use a model
  retrieveArchivedTags: function (context: any, { where = null }: any = {}) {
    let whereClause: string = "where: {archived: {_is_null: false}}";
    if (where) whereClause = `where: ${where}`;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query InventoryTags {
            growops_inventory_tags(
              ${whereClause}
              order_by: { name: asc }
            ) {
              ${TAG_RETURN_OBJECT}
            }
          }`,
        })
        .then(
          (success: any) => {
            context.commit("setArchivedTags", {
              list: success.data.data.growops_inventory_tags,
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // TODO: Use a model
  retrieveTags: function (context: any, { where = null }: any = {}) {
    let whereClause: string = "where: {archived: {_is_null: true}}";
    if (where) whereClause = `where: ${where}`;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query InventoryTags {
            growops_inventory_tags(
              ${whereClause}
              order_by: { name: asc }
            ) {
              ${TAG_RETURN_OBJECT}
            }
          }`,
        })
        .then(
          (success: any) => {
            context.commit("setTags", {
              list: success.data.data.growops_inventory_tags,
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // TODO: Use a model
  saveTag: function (context: any, { tag }: any) {
    // If the category has a null or empty uuid, remove it from the object
    // GraphQL will fail if the uuid exists and is empty
    if (tag.hasOwnProperty("uuid") && !tag.uuid) {
      delete tag.uuid;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation upsertTag(
            $tag: [growops_inventory_tags_insert_input!]!
          ) {
            insert_growops_inventory_tags(
              objects: $tag,
              on_conflict: {
                constraint: inventory_tags_pkey,
                update_columns: [name, archived, archived_by]
              }
            ) {
              returning {
                ${TAG_RETURN_OBJECT}
              }
            }
          }`,
          variables: {
            tag: tag,
          },
        })
        .then(
          (success: any) => {
            // Refresh tags
            context.dispatch("retrieveTags");
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  saveVersion: function (context: any, { item }: any) {
    // return new Promise((resolve, reject) => {
    //   axios
    //     .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
    //       query: `mutation upsertVersion(
    //           $item: [growops_inventory_item_specs_insert_input!]!
    //         ) {
    //           insert_growops_inventory_item_specs(
    //             objects: $item,
    //             on_conflict: {
    //               constraint: inventory_item_specs_pkey,
    //               update_columns: [version_id]
    //             }
    //           ) {
    //             returning {
    //               uuid
    //               version_id
    //             }
    //           }
    //         }`,
    //       variables: {
    //         item: item
    //       }
    //     })
    //     .then(
    //       (success: any) => {
    //         // Refresh tags
    //         context.dispatch("retrieveSpecVersions", {
    //           uuid: item.item_id
    //         });
    //         context.dispatch("retrieveDefaultSpecVersion", {
    //           uuid: item.item_id
    //         });
    //         resolve(true);
    //       },
    //       fail => {
    //         reject(new Error(fail.status));
    //       }
    //     );
    // });

    let itemTemplate = new ItemSpec();
    let upsertQuery: string = itemTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: item,
          },
        })
        .then(
          (success: any) => {
            // Store the newly created item as current item
            let queryName: string = `insert_${itemTemplate.table}`;
            // Refresh tags
            context.dispatch("retrieveSpecVersions", {
              uuid: item.item_id,
            });
            context.dispatch("retrieveDefaultSpecVersion", {
              uuid: item.item_id,
            });
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // TODO: Use a model
  deleteTag: function (context: any, { tag }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation archiveTag($set: growops_inventory_tags_set_input) {
            delete_growops_inventory_item_tags(where: {tag_uuid: {_eq: "${tag.uuid}"}}) {
              returning {
                tag_uuid
              }
            }
            update_growops_inventory_tags(where: {uuid: {_eq: "${tag.uuid}"}}, _set: $set) {
              returning {
                uuid
              }
            }
          }`,
          variables: {
            set: {
              archived: tag.archived,
              archived_by: tag.archived_by,
            },
          },
        })
        .then(
          (success: any) => {
            // Refresh deliveries
            context.dispatch("retrieveTags");
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // ITEMS /////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  /**
   * Retrieves inventory item attributes.
   * Commits items using `setAttributes`.
   * TODO: remove vestigial parameter
   * @param context Store module context
   * @param {any} vestigial Needs to be removed
   */
  retrieveAttributes: function (context: any) {
    let attributeTemplate = new ItemAttribute();
    let returnQuery: string = attributeTemplate.getReturnQuery(
      `{archived: {_is_null: true}}`
    );
    if (!returnQuery) return;
    return retrieveSet(
      context,
      returnQuery,
      "setAttributes",
      attributeTemplate.table
    );
  },

  /**
   * Retrieves a list of versions for an item.
   * Commits collections using `setCurrentItemVersions`.
   * @param context Store module context
   */
  retrieveProductSpecVersions: function (
    context: any,
    { where = "", order_by = "" }: any = {}
  ) {
    const versionTemplate = new ItemSpec();
    const returnQuery: string = versionTemplate.getReturnQuery(
      isObjectLike(where) ? "$where" : where,
      isObjectLike(order_by) ? "$order_by" : order_by
    );
    if (!returnQuery) return;

    let variables: any = {};
    if (isObjectLike(where)) variables.where = where;
    if (isObjectLike(order_by)) variables.order_by = order_by;

    return retrieveSet(
      context,
      returnQuery,
      "setCurrentItemVersions",
      versionTemplate.table,
      variables
    );
  },

  /**
   * Retrieves inventory item categories.
   * Commits items using `setCategories`.
   * TODO: remove vestigial parameter
   * @param context Store module context
   * @param {any} vestigial Needs to be removed
   */
  retrieveCategories: function (context: any) {
    let categoryTemplate = new AttributeCategory();
    let returnQuery: string = categoryTemplate.getReturnQuery();
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setAttributeCategories",
      categoryTemplate.table
    );
  },
  /**
   * Retrieves inventory item spec version list.
   * Commits items using `setSpecVersions`.
   * TODO: remove vestigial parameter
   * @param context Store module context
   * @param {any} vestigial Needs to be removed
   */
  retrieveDefaultSpecVersion: function (context: any, { uuid }: any) {
    let itemTemplate = new ItemSpec();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{item_id: {_eq: "${uuid}"}}`
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setDefaultSpecVersion",
      itemTemplate.table
    );
  },

  /**
   * Retrieves inventory item spec version list.
   * Commits items using `setSpecVersions`.
   * TODO: remove vestigial parameter
   * @param context Store module context
   * @param {any} vestigial Needs to be removed
   */
  retrieveSpecVersions: function (context: any, { uuid }: any) {
    let itemTemplate = new ItemSpecVersion();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{item_id: {_eq: "${uuid}"}}`
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setSpecVersions",
      itemTemplate.table
    );
  },

  /**
   * Retrieves inventory items that aren't archived.
   * Commits items using `setItems`.
   * TODO: remove vestigial parameter
   * @param context Store module context
   * @param {any} vestigial Needs to be removed
   */
  retrieveItems: function (
    context: any,
    {
      where = "",
      pagination = {},
      sortBy = "",
      removeQueryString = false,
    }: any = {}
  ) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      where,
      sortBy,
      removeQueryString
        ? itemTemplate.viewQueryReturn
        : itemTemplate.queryReturn,
      pagination,
      removeQueryString
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setItems",
      removeQueryString ? itemTemplate.view : itemTemplate.table
    );
  },

  /**
   * Retrieves inventory items from view
   * Commits items using `setItemOptions`.
   * @param context Store module context
   */
  retrieveItemOptions: function (
    context: any,
    { where = "", orderBy = "", minified = true }: any = {}
  ) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      where,
      orderBy,
      minified
        ? itemTemplate.getMinifiedQueryReturnTemplate(true)
        : itemTemplate.viewQueryReturn,
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setItemOptions",
      itemTemplate.view
    );
  },

  /**
   * Retrieves inventory pallets that are active.
   * Active pallets are pallets that arecreated in the last 3 days
   * Commits items using `setPallets`.
   * @param context Store module context
   */
  retrieveActivePallets: function (context: any) {
    let threeDaysAgo: any = DateTime.now().minus({ days: 3 }).toISO();
    let itemTemplate = new Pallet();
    const facilityUuid = context.rootState.facilities.currentFacilityUuid;

    let returnQuery: string = itemTemplate.getReturnQuery(
      `{
        rel_location: {rel_room:{facility_id: {_eq: "${facilityUuid}"}}},
        _or: [
          {created: {_gt: "${threeDaysAgo}"}}, 
        {rel_lots: {on_hand: {_gt: "0"}}
        }]
      }`,
      "",
      itemTemplate.queryReturnWithOnHand
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setPallets", itemTemplate.table);
  },

  /**
   * Retrieves inventory pallet by uuid.
   * Commits item using `setCurrentPallet`.
   * @param uuid passed uuid of selected pallet
   * @param context Store module context
   */
  retrieveCurrentPallet: function (context: any, { uuid }: any) {
    let itemTemplate = new Pallet();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`,
      "",
      itemTemplate.queryReturnWithLots
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentPallet",
      itemTemplate.table
    );
  },

  /**
   * Retrieves inventory items that are saleable and aren't archived.
   * Commits items using `setProducts`.
   * @param context Store module context
   */
  retrieveProducts: function (context: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{archived: {_is_null: true}, allow_sale: {_eq: true}}`,
      `{name: asc}`
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setProducts", itemTemplate.table);
  },

  /**
   * Retrieves inventory items that are purchaseable and aren't archived.
   * Commits items using `setSupplies`.
   * @param context Store module context
   */
  retrieveSupplies: function (context: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{archived: {_is_null: true}, allow_purchase: {_eq: true}}`,
      `{name: asc}`
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setSupplies", itemTemplate.table);
  },

  /**
   * Saves an inventory item.
   * Commits saved item using `setCurrentItem`.
   * @param context Store module context
   * @param {Object} item Item save-version object
   */
  saveItem: function (context: any, { item }: any) {
    let itemTemplate = new InventoryItem();
    let upsertQuery: string = itemTemplate.getUpsertQuery(
      item.uuid || "",
      itemTemplate.queryReturn
    );
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: item,
          },
        })
        .then(
          (success: any) => {
            // Store the newly created item as current item
            let queryName: string = `insert_${itemTemplate.table}`;
            context.commit(
              "setCurrentItem",
              success.data.data[queryName].returning[0]
            );
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves an item spec.
   * @param context Store module context
   * @param {Object} item Item save-version object
   */
  saveSpec: function (context: any, { item }: any) {
    let itemTemplate = new ItemSpec();
    let upsertQuery: string = itemTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: item,
          },
        })
        .then(
          (success: any) => {
            // Store the newly created item as current item
            let queryName: string = `insert_${itemTemplate.table}`;
            context.commit(
              "setCurrentItemSpec",
              success.data.data[queryName].returning[0]
            );
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves an item category.
   * @param context Store module context
   * @param {Object} item Item save-version object
   */
  saveCategory: function (context: any, { category }: any) {
    let categoryTemplate = new AttributeCategory();
    let upsertQuery: string = categoryTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: category,
          },
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveCategories");
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a pallet.
   * Commits saved item using `setCurrentPallet`.
   * @param context Store module context
   * @param {Object} item Item save-version object
   */
  savePallet: function (context: any, { item }: any) {
    let itemTemplate = new Pallet();
    let upsertQuery: string = itemTemplate.getUpsertQuery(
      itemTemplate.queryReturnWithLots
    );
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: item,
          },
        })
        .then(
          (success: any) => {
            // Store the newly created item as current item
            let queryName: string = `insert_${itemTemplate.table}`;
            context.commit(
              "setCurrentPallet",
              success.data.data[queryName].returning[0]
            );
            // Refresh items if editing
            // if (item.uuid) {
            //   context.dispatch("retrieveItems");
            // }
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Relocates all lots within a pallet
   * @param context Store module context
   * @param {Object} pallet pallet to save
   */
  relocatePallet: function (context: any, { pallet_id, loc_id }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + `/inventory/relocatePallet`, {
          loc_id: loc_id,
          pallet_id: pallet_id,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Passes an array of lot transaction updates to the inventory service
   * @param context Store module context
   * @param {Object} pallet pallet to save
   */
  submitTransactions: function (
    context: any,
    { lotTransactions, removal }: { lotTransactions: any; removal: boolean }
  ) {
    return new Promise((resolve, reject) => {
      try {
        trackScannedUuid(context, lotTransactions.lot_id, removal);
      } catch (error: any) {
        reject(error);
        return;
      }

      axios
        .post(process.env.VUE_APP_API_URL + `/inventory/transaction-journal`, {
          transactions: lotTransactions,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves an item's document (i.e., product spec sheet)
   * @param context Store module context
   * @param {string} uuid document ID
   * @param {Array} document document src
   */
  uploadItemDocument: function (context: any, { document }: any) {
    const facilityUuid = context.rootState.facilities.currentFacilityUuid;
    const type = "product-spec";

    let reader: any = new FileReader();
    let base64 = "";
    reader.onload = (event: any) => {
      base64 = event.target.result;
      axios
        .post(`${process.env.VUE_APP_API_URL}/blob/document`, {
          facilityUuid,
          base64,
          detailId: document.uuid,
          resourceName: document.path,
          serviceName: type,
        })
        .then(
          (_success) => {},
          (fail) => {
            console.log(fail);
          }
        );
    };
    reader.readAsDataURL(document.file);
  },

  /**
   * Retrieves an item by uuid.
   * Commits item using `setCurrentItem`.
   * @param context Store module context
   * @param {string} uuid Item UUID
   */
  retrieveCurrentItem: function (context: any, { uuid }: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentItem",
      itemTemplate.table
    );
  },

  retrieveCurrentAudit: function (context: any, { uuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query GrowopsItem {
            growops_inventory_audits(
              where: {id: {_eq: "${uuid}"}}
            ) {
              ${INVENTORY_AUDIT_RETURN_OBJECT}
            }
          }`,
        })
        .then(
          (success: any) => {
            context.commit(
              "setCurrentAudit",
              success.data.data.growops_inventory_audits[0]
            );
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  retrieveItemsByCategory: function (context: any, { categId }: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{
        _and: {
          archived: {_is_null: true},
          categoryByTagId: {uuid: {_eq: "${categId}"}}
        }
      }`,
      `{name: asc}`,
      itemTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setItems", itemTemplate.table);
  },

  /**
   * Retrieves inventory items by tag id.
   * Commits items using `setItems`.
   * @param context Store module context
   * @param {Array} uuids Array of tag uuids
   */
  retrieveItemsByTagIds: function (context: any, { uuids }: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{
        _and: {
          archived: {_is_null: true},
          tagByItemId: {tag_uuid: {_in: ["${uuids.join('", "')}"]}}
        }
      }`,
      `{name: asc}`,
      itemTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setItems", itemTemplate.table);
  },

  /**
   * Retrieves inventory items by tag id.
   * Commits items using `setSubstitutions`.
   * @param context Store module context
   * @param {Array} uuids Array of tag uuids
   */
  retrieveMaterialSubstitutions: function (context: any, { tagIds }: any) {
    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{
        _and: {
          archived: {_is_null: true},
          tagByItemId: {tag_uuid: {_in: ["${tagIds.join('", "')}"]}}
        }
      }`,
      `{name: asc}`,
      itemTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setSubstitutions",
      itemTemplate.table
    );
  },

  /**
   * Retrieves inventory items by tag id with stock at current facility.
   * Commits items using `setItems`.
   * @param context Store module context
   * @param {Array} uuids Array of tag uuids
   */
  retrieveItemsWithOnHandByTagIds: function (context: any, { uuids }: any) {
    let facilityId: string = context.rootState.facilities.currentFacilityUuid;
    if (!facilityId) return;

    let itemTemplate = new InventoryItem();
    let returnQuery: string = itemTemplate.getReturnQuery(
      `{
        _and: {
          tagByItemId: {tag_uuid: {_in: ["${uuids.join('", "')}"]}},
          lots: {
            _and: {
              on_hand: {_gt: 0},
              location: {facility_id: {_eq: "${facilityId}"}}
            }
          }
        }
      }`,
      `{name: asc}`,
      itemTemplate.getQueryReturnWithFacilityLots(facilityId)
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setItems", itemTemplate.table);
  },

  // CYCLE COUNTS //////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  retrieveCycleCounts: function (context: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query {
                    growops_inventory_audits(
                      order_by: { created: desc },
                      where: {
                        facility_id: {_eq: "${context.rootState.facilities.currentFacilityUuid}"}
                      }
                    ){
                      ${INVENTORY_AUDIT_RETURN_OBJECT}
                    }
                  }`,
        })
        .then(
          (success: any) => {
            context.commit("setAudits", {
              list: success.data.data.growops_inventory_audits,
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  retrieveCycleCountLots: function (context: any, { uuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query {
                    growops_inventory_audit_lots(
                      where: {audit_id: {_eq: "${uuid}"}}
                    ){
                      ${INVENTORY_AUDIT_LOTS_RETURN_OBJECT}
                    }
                  }`,
        })
        .then(
          (success: any) => {
            context.commit("setAuditLots", {
              list: success.data.data.growops_inventory_audit_lots,
            });
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  saveCycleCounts: function (context: any, { audit }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation insertInventoryAudit($audit: [growops_inventory_audits_insert_input!]!) {
                    insert_growops_inventory_audits(
                      objects: $audit,
                      on_conflict: {
                        constraint: inventory_audits_pkey,
                        update_columns: [submitted, submitted_by]
                      }
                    ) {
                      returning {
                         ${INVENTORY_AUDIT_RETURN_OBJECT}
                      }
                    }
                  }`,
          variables: {
            audit: audit,
          },
        })
        .then(
          (success: any) => {
            context.commit(
              "setCurrentAudit",
              success.data.data.insert_growops_inventory_audits.returning[0]
            );
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  updateCycleCountLots: function (context: any, { lots }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation insertInventoryAudit($lots: [growops_inventory_audit_lots_insert_input!]!) {
            insert_growops_inventory_audit_lots(
                      objects: $lots,
                      on_conflict: {
                        constraint: inventory_audit_lots_pkey,
                        update_columns: updated_on_hand
                      }
                    ) {
                      affected_rows
                    }
                  }`,
          variables: {
            lots: lots,
          },
        })
        .then(
          (success: any) => {
            resolve(true);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  //  DOCUMENT
  getDocumentUrl: function (context: any, { document }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_JS_URL + "/document", {
          imagePath: document.path,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
