// @ts-ignore
import { ref, withParams } from "vuelidate/lib/validators/common";
export default (maxValueName: any) =>
  withParams(
    { type: "maxValue", maxValueName },
    function (value: any, parentVm: any) {
      // @ts-ignore
      let max: any = ref(maxValueName, this, parentVm);
      if (max === null || max === undefined) return true;
      return Number(value) <= max;
    }
  );
