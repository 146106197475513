import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { shippingRoutes } from "./router/shipping-routes";
export class ShippingModule implements VueModule {
  readonly name = "shipping";
  constructor(private router: VueRouter) {}
  install(Vue: typeof _Vue) {
    this.router.addRoute(shippingRoutes);
  }
}
