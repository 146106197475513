
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Bom, BomMaterial, PackoutGood, PackoutMaterial } from "@eaua/model";
import Profile from "@/modules/core/models/shared/Profile";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import round from "lodash/round";
import set from "lodash/set";
import sumBy from "lodash/sumBy";
import filter from "lodash/filter";

@Component
export default class PackoutBomSummary extends Vue {
  // PROPS ---------------------------------------------------------------------

  @Prop({ default: "" })
  batchId!: string;

  @Prop({ default: "Bill of Materials" })
  title!: string;

  // LOCAL VARIABLES -----------------------------------------------------------

  headers: Array<any> = [
    {
      text: "Item",
      value: "item_name",
    },
    {
      text: "Lots",
      value: "count",
    },
    {
      text: "Consumed",
      value: "consumed",
    },
    {
      text: "Target",
      value: "target",
    },
    {
      text: "Progress",
      value: "progress",
      type: "progress",
    },
  ];

  isSaving = false;
  underscanned = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get allMaterialsScanned(): boolean {
    return this.materialsList.every((m: any) => m.count > 0);
  }

  get batchSize(): number {
    return +this.packoutGood.quantity || 0;
  }

  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }

  get consumedAggregate(): number {
    return round(sumBy(this.materialsWipList, "consumed"), 2);
  }

  get lotCount(): number {
    return sumBy(this.materialsWipList, "count") || 0;
  }

  get materialsWipList(): Array<any> {
    return filter(this.materialsList, (material: any) => {
      return material.type === "wip";
    });
  }

  get materialsList(): Array<any> {
    let list: any = {};
    let packoutGoodMaterials = cloneDeep(this.packoutGood?.materials || []);

    // Add all bom materials to list to show the full recipe
    for (let bomMat of this.bom?.materials || []) {
      const material = new BomMaterial(bomMat);
      if (
        ["wip", "produce", "material-component"].includes(
          material.category?.name
        )
      ) {
        list[bomMat.item_id] = {
          item_name: `${bomMat.inventory_item.number} - ${bomMat.inventory_item.name}`,
          type: material.category?.name,
          consumed: 0,
          target: this.getMaterialQty(bomMat.item_id),
          count: 0,
        };
      }
    }
    if (packoutGoodMaterials.length > 0) {
      for (const m of packoutGoodMaterials) {
        const material = new PackoutMaterial(m);
        if (!["wip", "material-component"].includes(material.type)) continue;
        const itemId: string = m.lot?.item_id || "";
        const consumed: number = m.before_quantity - m.after_quantity;
        const target: number = this.getMaterialQty(itemId);
        // If this item hasn't been added, build it
        if (!list[itemId]) {
          list[itemId] = {
            item_name: material.item.name,
            type: material.type,
            consumed: consumed,
            target: target,
            progress: (consumed / (target || this.batchSize)) * 100,
            count: 1,
          };
        }
        // If the item's already been added, just increment values
        else {
          list[itemId].count++;
          list[itemId].consumed += consumed;
          list[itemId].progress = (list[itemId].consumed / target) * 100;
        }
      }
    }
    return Object.values(list);
  }

  get packoutGood(): PackoutGood {
    return this.$store.state.packout.currentGood;
  }

  get percentComplete(): number {
    return round((this.consumedAggregate * 100) / this.targetAggregate) || 0;
  }

  get progressColor(): string {
    const value = this.percentComplete;
    if (value < 50) return "red";
    if (value >= 50 && value < 75) return "yellow";
    if (value >= 75 && value < 100) return "blue";
    if (value > 110) return "red";
    return "primary";
  }

  /**
   * Divides the batch size by the bom quantity to get the overall multiplier.
   * Recipe qty will almost always be 1, but could be different.
   */
  get recipeMultiplier(): number {
    if (!this.bom.quantity) return 0;
    return this.batchSize / this.bom.quantity;
  }

  get remainingAggregate(): number {
    return round(this.targetAggregate - this.consumedAggregate, 1);
  }

  get targetAggregate(): number {
    return sumBy(this.materialsWipList, "target");
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("lotCount", { immediate: true })
  async onLotCountChanged() {
    // We're watching on lot count changed bc we actually need to check
    // updates on remainingAggregate and allMaterialsScanned, but watching
    // either of those could cause race conditions for saving
    let needToSave = false;
    const currentWorkItem: any =
      this.$store.state.user.profile?.details?.current_work_item || {};
    // Since remainingAggregate and allMaterialsScanned are computed,
    // we're waiting for the next tick to give them time to update
    this.$nextTick(() => {
      // Setting here for initial load
      if (this.underscanned !== currentWorkItem) {
        this.underscanned = currentWorkItem.underScanned;
        this.$emit("underscanned-changed", this.underscanned);
      }
      // If the remaining aggregate is greater than zero,
      // the packout good is underscanned.
      if (currentWorkItem.underScanned !== this.remainingAggregate > 0) {
        this.underscanned = this.remainingAggregate > 0;
        this.$emit("underscanned-changed", this.underscanned);
        needToSave = true;
      }
      // If allItemsScanned has changed, we'll need to save the update
      if (currentWorkItem.allItemsScanned !== this.allMaterialsScanned) {
        needToSave = true;
      }
      // Underscanned and allItemsScanned need to be saved to the
      // user's profile for the casing kiosk
      if (needToSave) this.saveProfile();
    });
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Returns the proportional quantity for a given material
   */
  getMaterialQty(itemId: string): number {
    const bomMaterial: any = find(this.bom.materials || [], {
      item_id: itemId,
    });
    if (!bomMaterial) return 0;
    return round(this.recipeMultiplier * bomMaterial.quantity, 4);
  }

  /**
   * Updates underscanned and all items scanned for the current user's work item
   */
  async saveProfile(): Promise<void> {
    let profile = new Profile(this.$store.state.user.profile || {});
    set(profile, "details.current_work_item.underScanned", this.underscanned);
    set(
      profile,
      "details.current_work_item.allItemsScanned",
      this.allMaterialsScanned
    );
    await this.$store.dispatch("user/saveProfile", {
      profile: profile.getSaveVersion(),
    });
  }
}
