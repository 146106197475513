import app from "./app";
import user from "./user";
import facilities from "./facilities";
import harvestTask from "./harvest";
import filter from "./filter";
import inventoryModules from "./inventory/index";
import cropOrder from "./cropOrder";
import delivery from "./delivery";
import packout from "./packout";
import tasks from "./tasks";
import stages from "./stages";

// TODO: Organize store files into folder structure
// before list gets out of hand
export default {
  app,
  cropOrder,
  delivery,
  facilities,
  filter,
  harvestTask,
  ...inventoryModules,
  user,
  tasks,
  packout,
  ...inventoryModules,
  stages,
};
