import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { homeRoutes } from "./router/home-routes";
import { Store } from "vuex";

export class HomeModule implements VueModule {
  readonly name = "home";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(homeRoutes);
  }
}
