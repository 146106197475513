
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import { getIcon } from "@/util/icons";
import Form from "@/modules/core/mixins/Form.vue";
import { DateTime } from "luxon";
import { get } from "lodash";
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { emitNotification } from "@/util/notifications";
import { PackoutBatch, PackoutGood } from "@eaua/model";

@Component
export default class UndoForm extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------

  //Props
  @Prop({ default: "destination" })
  destination!: string;
  @Prop({ default: "" })
  goodId!: string;
  @Prop({ default: "" })
  batchId!: string;
  @Prop({ default: "" })
  batchNumber!: string;

  @Ref("printLot")
  readonly printLot!: any;

  @Ref("undo-confirmation-dialog")
  readonly undoBatchDialog!: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  currentItemId: string = "";

  reprintItem: any = {};

  events: IEventBusEvent[] = [
    {
      name: "reprint-mix",
      function: this.print,
    },
    {
      name: "open-undo-confirmation-dialog",
      function: this.showRemoveDialog,
    },
    {
      name: "confirm-undo-batch-item",
      function: this.removeBatchItem,
    },
  ];

  headers: any = [
    {
      text: "Date/Time",
      value: "created",
    },
    {
      text: "Amount",
      value: "amount",
    },
    {
      text: "",
      value: "actions",
    },
  ];

  // Actions available to each row/card
  tableActions: any = [
    {
      callback: "open-undo-confirmation-dialog",
      icon: getIcon("base", "delete"),
      isIcon: true,
      label: "Undo",
      disabled: this.isGoodCompleted,
    },
    {
      callback: "reprint-mix",
      icon: getIcon("base", "print"),
      isIcon: true,
      label: "Reprint",
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  // List of the batch materials. mostly calculating packout offset
  // the batch's offset needs to be the lowest wip's offset from its
  // materials used.
  get mappedBatchList() {
    if (this.currentBatchList) {
      return this.currentBatchList
        .filter((x: any) => +x.weight > 0)
        .map((batch: any) => {
          let packByOffsetDate: any;
          packByOffsetDate = this.currentBatch.use_by
            ? this.currentBatch.use_by
            : DateTime.fromISO(this.currentBatchLot.created).plus({
                hours:
                  this.currentPackoutGood.inventory_item.pack_by_offset * 24,
              });

          return {
            item_number: get(
              this.currentPackoutGood,
              "inventory_item.number",
              ""
            ),
            item_name: get(this.currentPackoutGood, "inventory_item.name", ""),
            lot_number: batch.rel_inventory_lot.number,
            amount:
              batch.weight +
              " " +
              get(this.currentPackoutGood, "inventory_item.uom.name", ""),
            uom: get(this.currentPackoutGood, "inventory_item.uom.name", ""),
            created:
              DateTime.fromISO(
                get(this.currentPackoutGood, "rel_lot_header.created", "")
              ).toFormat("MM/dd/yy") || "",
            packByDate:
              DateTime.fromISO(packByOffsetDate).toFormat("MM/dd/yy") || "N/A",
            batch_number: this.batchNumber,
            ...batch,
          };
        });
    }
    return [];
  }

  /** Returns current manufactoring order plot uuid
   * @returns {string}
   */
  get currentBatchList() {
    return this.$store.state.packout.batchLotList || [];
  }

  get currentBatch(): PackoutBatch {
    return this.$store.state.packout.currentWeighGoodBatch[0] || {};
  }

  get currentBatchLot(): PackoutBatch {
    return this.$store.state.packout.currentBatchLot || {};
  }

  get currentPackoutGood(): PackoutGood {
    return this.$store.state.packout.currentGood;
  }

  get isGoodCompleted(): boolean {
    return !!this.currentPackoutGood.completed;
  }

  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions(): Array<any> {
    let actions: any = [];
    return actions;
  }

  mounted() {
    setListeners(this.events);
    this.retrieveCurrentBatchLotList();
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async retrieveCurrentBatchLotList() {
    await this.$store
      .dispatch("packout/retrieveBatchLotList", {
        batchId: this.batchId,
      })
      .then((success) => {})
      .catch((error) => {
        emitNotification({
          message: `Failed to retrieve batch list.`,
          title: "Error",
          type: "error",
        });
      });
    this.showPreloader = false;
  }

  showRemoveDialog(item: any = {}): void {
    this.undoBatchDialog.open({
      passedObject: item,
      passedTitle: "Undo Tote",
      passedMessage: "Are you sure you wish to remove this tote? ",
    });
  }

  async removeBatchItem(item: any = {}) {
    let saveItem: any = {};
    saveItem.weight = 0;
    saveItem.batch_id = item.batch_id;
    saveItem.lot_id = item.lot_id;
    saveItem.modified = DateTime.now().toISO();
    saveItem.modified_by = this.$store.state.user.profile.uuid;
    await this.$store
      .dispatch("packout/saveBatchLot", {
        batchLot: saveItem,
      })
      .then((success) => {
        this.retrieveCurrentBatchLotList();
        emitNotification({
          priority: "low",
          message: `Removed tote`,
          title: "Success",
          type: "success",
        });
      })
      .catch((error) => {
        emitNotification({
          priority: "low",
          message: `failed to remove tote`,
          title: "Error",
          type: "error",
        });
      });
  }

  /**
   * Triggers the brower's print dialog
   */
  async print(item: any = {}) {
    this.reprintItem = item;
    this.$nextTick(() => {
      this.printLot.print();
    });
  }
}
