
import { Vue, Component, Prop } from "vue-property-decorator";
import { BomMaterial } from "@eaua/model";

@Component
export default class MaterialsList extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  materials!: Array<BomMaterial>;

  @Prop({ default: false })
  showAmount!: boolean;
}
