
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { Component, Mixins } from "vue-property-decorator";
import ConsumedWipLotForm from "../forms/ConsumedWipLot.vue";

@Component({
  components: {
    ConsumedWipLotForm,
  },
})
export default class ConsumedWipLot extends Mixins(Dialog) {
  [key: string]: any;
  // scoped = true;
  portalDestination = "consumed-wip-lot-actions";

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-consumed-wip-lot-dialog");
  }

  get lotCountTitle(): string {
    return `Consumed WIP Lots  (Total Lot Count: ${this.$store.state.lots.currentLotCount})`;
  }
}
