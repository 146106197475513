import BaseModel from "../../Base";
import { required } from "vuelidate/lib/validators";
import { store } from "@/modules/core";
import get from "lodash/get";

export default class Location extends BaseModel {
  table = "growops_inventory_locations";
  primaryKey = "inventory_locations_pkey";

  properties = {
    uuid: "",
    facility_code: "", // Deprecated. DO NOT USE
    name: "",
    description: "",
    facility_id: "", // Deprecated. DO NOT USE
    location_row: "",
    location_column: "",
    room_id: "",
  };

  relationships = {
    facility: {},
    rel_room: {},
    inventory_lots: [],
  };

  derivedProperties = {
    displayName: "",
    roomName: "",
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
      rel_room {
        name
        facility_id
      }
    `;

    this.validations.name = { required };
    this.validations.room_id = { required };

    this.setRoomName();
    this.setDisplayName(); // Should run after setRoomName
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setDisplayName() {
    this.displayName = this.getDisplayName();
  }

  setRoomName() {
    this.roomName = get(this.rel_room, "name", "");
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Location
   * @returns {Object}
   */
  getDisplayVersion(): object {
    let values: any = super.getDisplayVersion();
    values.facilityName = this.getFacilityName();
    return values;
  }

  /**
   * Returns this location's name plus grid position
   * @returns {string}
   */
  getDisplayName(): string {
    let grid = "";
    if (this.location_row || this.location_column) {
      grid = `(${this.location_column}-${this.location_row})`;
    }
    if (this.name && this.name.includes(grid)) return this.name;
    else if (!this.name) {
      if (this.roomName) return `${this.roomName} ${grid}`;
      else return grid;
    }
    return `${this.name} ${grid}`;
  }

  /**
   * Returns this location's facility name from the store
   * @returns {string}
   */
  getFacilityName(): string {
    const facilityId = get(this.rel_room, "facility_id", "");
    if (!facilityId) return "";
    const facility: any =
      store.getters["facilities/getFacilityByUuid"](facilityId);
    return facility.name || "";
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion(): Record<string, unknown> {
    let values: any = super.getSaveVersion();
    if (!values.facility_id) values.facility_id = this.currentFacilityId;
    return values;
  }
}
