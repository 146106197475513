import BaseModel from "../../Base";
import ItemAttribute from "./ItemAttibutes";
import Parameter from "../../research/Parameter";
import { required } from "vuelidate/lib/validators";
import get from "lodash/get";

export default class SpecItemAttribute extends BaseModel {
  table = "growops_inventory_item_spec_attributes";
  primaryKey = "inventory_item_spec_attributes_pkey";

  properties = {
    uuid: "",
    version_id: null,
    value: "",
    created: null,
    created_by: null,
    modified: null,
    modified_by: null,
    attribute_id: null,
  };

  relationships = {
    rel_inventory_item_attribute: {},
    rel_inventory_item_spec_version: {},
    rel_inventory_uom: {},
  };

  createdByUser: string = "";
  dataType: string = "";
  attribute = new ItemAttribute();
  category: string = "";

  // Valid is used for the define parameters form
  valid: boolean = false;

  constructor(values: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add uom category to query return
    this.queryReturn += `
    rel_inventory_item_attribute{
      name
    }
    rel_inventory_item_spec_version{
      number
    }
    `;

    this.setCreatedByUser();
    this.setDataType();

    if (this.uuid) this.valid = true;
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setCreatedByUser() {
    this.createdByUser = get(this.rel_created_by, "name", "");
  }

  setDataType() {
    this.dataType = get(this.rel_data_type, "name", "");
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns true if options are empty
   * @returns {boolean}
   */
  checkForEmptyOptions() {
    if (!this.options) return true;
    if (this.options.length === 0) return true;
    if (this.options.length === 1 && !this.options[0].value) return true;
    return false;
  }

  /**
   * Returns a display version for this model
   * @returns {Object}
   */
  getDisplayVersion() {
    let values = super.getDisplayVersion();
    let uomName = this.getUomName();
    values.dataType = this.dataType;
    values.name =
      this.getType() === "measurement"
        ? this.getMeasurementName()
        : this.getParameterName();
    values.uom = uomName || "-";
    values.value =
      this.value_lower || this.value_upper
        ? `${this.value_lower} - ${this.value_upper}`
        : this.value;
    values.display_value = uomName
      ? `${values.value} ${uomName}`
      : values.value;
    if (this.value && (this.value_lower || this.value_upper)) {
      values.display_value += ` (Target: ${this.value})`;
    }
    if (this.options) values.options = this.options.map((o: any) => o.value);
    else values.options = "-";
    values.notes = this.notes || "-";
    values.createdByUser = this.createdByUser;
    return values;
  }

  /**
   * Returns name value from related measurement model
   * @returns {string}
   */
  getMeasurementName() {
    return this.measurement.name || "";
  }

  /**
   * Returns name value from related parameter model
   * @returns {string}
   */
  getParameterName() {
    return this.parameter.name || "";
  }

  getSaveVersion() {
    let values = super.getSaveVersion();

    delete values.uuid;
    delete values.version_id;

    return values;
  }

  /**
   * Returns the type of this parameter. Can be `measurement`
   * or `parameter`
   * @returns {string}
   */
  getType() {
    if (this.measurement_id) return "measurement";
    return "parameter";
  }

  /**
   * Returns the related uom's name
   * @returns {string}
   */
  getUomName() {
    return get(this.rel_uom, "name", "");
  }

  isList() {
    return this.dataType === "List";
  }

  isNumber() {
    return this.dataType === "Number";
  }

  isNumberRange() {
    return this.dataType === "Number Range";
  }

  isText() {
    return this.dataType === "Text";
  }
}
