
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import { BomMaterial } from "@eaua/model";
import { get } from "lodash";

@Component
export default class ConsumedLotsForm extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "consumed-lots-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  isLoading: boolean = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-consumed-lots-dialog-${this.dialogId}`,
        label: "Cancel",
      },
    ];
  }

  get materials(): Array<any> {
    return get(this.$store, "state.packout.currentGood.materials", []);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }
}
