import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";

const DEFAULT_NOTIFICATION_VALUES: { [key: string]: any } = {
  priority: "low",
  type: "info",
  actions: [],
  title: "",
  message: "",
  inDialog: false,
};

export const NOTIFICATION_EVENTS: { [key: string]: any } = {
  low: "close-low-priority-notification",
  medium: "close-medium-priority-notification",
  high: "close-high-priority-notification",
  inDialog: "close-dialog-notification",
};

export default class Notification {
  [key: string]: any; // Index signature

  // Reference to vuex store
  store: any = {};

  priority = ""; // low, medium, high
  type = ""; // info, warning, error, success
  actions: any = []; // array of action objects
  title = "";
  message = "";
  inDialog = false; // was notification triggered from a dialog

  constructor(values: any = {}, store: any = {}) {
    this.setStore(store);
    this.initialize(values);
  }

  /**
   * Initializes notification properties. Grabs from passed notification first,
   * and fills in gaps from DEFAULT_NOTIFICATION_VALUES constant
   * @param {Object} notification - Values to add to properties
   */
  initialize(notification: any = {}) {
    // Check this class for each property in DEFAULT_NOTIFICATION_VALUES
    // Set to the incoming notification value if it exists
    for (let property in DEFAULT_NOTIFICATION_VALUES) {
      if (Object.prototype.hasOwnProperty.call(this, property)) {
        this[property] = Object.prototype.hasOwnProperty.call(
          notification,
          property
        )
          ? notification[property]
          : clone(DEFAULT_NOTIFICATION_VALUES[property]);
      }
    }

    if (this.priority && isEmpty(this.actions)) {
      let action: any = {
        label: "Dismiss",
      };
      switch (this.priority) {
        case "medium":
          action.callback = NOTIFICATION_EVENTS.medium;
          break;
        case "high":
          action.callback = NOTIFICATION_EVENTS.high;
          break;
        default:
          action.callback = NOTIFICATION_EVENTS.low;
          break;
      }
      if (this.inDialog) {
        action.callback = NOTIFICATION_EVENTS.inDialog;
      }
      this.actions.push(action);
    }
  }

  /**
   * Mutates the vuex store reference
   * @param store
   */
  setStore(store: any = {}) {
    this.store = store;
  }

  storeNotification() {
    this.store.commit("app/setNotification", this.getObjectValues());
  }

  /**
   * Returns the display version of this notification
   * @returns {Object} Mapped notification
   */
  getDisplayVersion() {
    return this.getObjectValues();
  }

  getObjectValues() {
    let notification: any = {};
    for (let property in DEFAULT_NOTIFICATION_VALUES) {
      notification[property] = this[property]
        ? this[property]
        : clone(DEFAULT_NOTIFICATION_VALUES[property]);
    }
    return notification;
  }
}
