
import { Vue, Component, Prop } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";

@Component
export default class Dialog extends Vue {
  //PROPS
  @Prop({ type: String, default: "close" })
  closeEvent!: string;
  @Prop({ default: "" })
  title!: string;
  @Prop({ type: String, default: "cancel" })
  closeLabel!: string;
  @Prop({ default: true })
  hasCancelBtn!: boolean;

  // LOCAL VARIABLES -----------------------------------------------------------
  actions: any = [];
  events: any = [];
  visible = false;
  confirmMessage = "";
  summary = "";
  showPermanentMessage = false;
  callback = Function();
  closeEventName = "close-dialog";
  toggleCancelEventName = "toggle-cancel";
  scoped = false;
  cancelDisabled = false;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  created() {
    this.setCloseEventName(this.closeEvent);
  }

  beforeMount() {
    if (this.closeEventName) {
      this.events.push({
        name: this.closeEventName,
        function: this.close,
      });
    }

    this.events.push({
      name: this.toggleCancelEventName,
      function: this.toggleCancel,
    });

    if (this.scoped) {
      // @ts-ignore
      let idTag = `-${this._uid}`;
      for (let event of this.events) {
        event.name += idTag;
      }
    }
  }

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Placeholder for a dialog initialization
   * @param {Object=} values
   */
  initialize(values: any = {}): void {}

  /**
   * Closes the dialog and re-initializes
   */
  close() {
    this.visible = false;
    this.initialize({});
  }

  toggleCancel() {
    this.cancelDisabled = !this.cancelDisabled;
  }

  /**
   * Opens the dialog, clears banner notifications, and initializes
   * @param {Object=} values
   */
  open(values: any = {}) {
    EventBus.$emit("close-app-notification");
    this.initialize(values);
    this.visible = true;
  }

  /**
   * Updates the dialog's action buttons on an event trigger
   * @param {Array=} actions
   */
  updateDialogActions(actions: any = []) {
    this.actions = actions;
  }

  /**
   * Sets the dialog's close event name
   * @param {string=} name
   */
  setCloseEventName(name: string | undefined = "") {
    this.closeEventName = name;
  }

  /**
   * Sets the title property given a value and name
   * @param {Object} value The passed value object
   * @param {string} name The given name, ie `Item`, `Category`, etc
   */
  setTitle(value: any = {}, name = "") {
    let title = "";
    if (!value.uuid) {
      title = "New";
    } else if (value.duplicate) {
      title = "Copy";
    } else title = "Edit";
    if (name) {
      title += ` ${name}`;
    }
    this.title = title;
  }

  /**
   * Sets the title property given a action
   * @param {string} action The action to confirm
   */
  setConfirmTitle(action = "") {
    let title = "Confirm ";
    title += action;
    this.title = title;
  }

  /**
   * Sets the confirmMessage property given a message
   * @param {string} message The display confirm message
   */
  setConfirmMessage(message = "") {
    let confirmMessage = "Are you sure you want to ";
    confirmMessage += message;
    this.confirmMessage = `${confirmMessage}?`;
  }

  /**
   * Sets the summary property given a message
   * @param {string} message The display summary message
   */
  setSummary(message = "") {
    this.summary = message;
  }
}
