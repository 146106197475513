
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";

@Component
export default class ConfirmationForm extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "add-confirmation-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  @Prop({ default: "Are you sure?" })
  passedMessage!: string;

  @Prop({ default: "" })
  passedEvent!: string;

  @Prop({ default: () => {} })
  passedObject!: any;

  @Prop({ default: "Confirm" })
  passedButtonLabel!: string;

  // LOCAL VARIABLES -----------------------------------------------------------

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-confirmation-dialog-${this.dialogId}`,
        label: "Cancel",
      },
      {
        callback: "confirm-event-action",
        text: this.isLoading,
        label: this.passedButtonLabel,
      },
    ];
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  events: Array<IEventBusEvent> = [
    {
      name: "confirm-event-action",
      function: this.confirmEvent,
    },
  ];

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {}

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  // Trigger the passed event and pass the passed object. Object can be left blank
  // But this allows us to do things like trigger a save on a line in an iterator table.
  confirmEvent() {
    EventBus.$emit(this.passedEvent, this.passedObject);
    EventBus.$emit(`close-confirmation-dialog-${this.dialogId}`);
  }
}
