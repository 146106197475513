import "vuetify/dist/vuetify.min.css";

export const VUETIFY_OPTIONS: any = {
  options: {
    customProperties: true,
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#00897B",
        secondary: "#97d700",
        accent: "#62a500",
        error: "#f44336",
        warning: "#ffc107",
        info: "#03a9f4",
        success: "#62a500",
      },
    },
  },
};
