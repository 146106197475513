
import { Vue, Component, Prop } from "vue-property-decorator";
import isEmpty from "lodash/isEmpty";

const ICON_DEFAULTS = {
  color: "primary",
  class: "",
  dark: false,
  direction: "top",
  label: "",
  size: null,
  value: "",
};

export interface IBaseIconTooltip_Icon {
  color: string;
  class: string;
  dark: boolean;
  direction: string; // direction of the tooltip
  label: string;
  size: number | string | null;
  value: string; // the icon to show
  [key: string]: any; // index signature
}

@Component
export default class BaseIconTooltip extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: () => {} })
  value!: any;

  // Local Variables - Replaces data()
  icon: IBaseIconTooltip_Icon = { ...ICON_DEFAULTS };

  // COMPUTED -----------------------------------------------------
  get isTopTooltip() {
    return this.icon.direction === "top";
  }

  get isLeftTooltip() {
    return this.icon.direction === "left";
  }

  get isRightTooltip() {
    return this.icon.direction === "right";
  }

  get isBottomTooltip() {
    return this.icon.direction === "bottom";
  }

  // LIFECYCLE EVENTS ---------------------------------------------
  created() {
    if (!isEmpty(this.value)) {
      this.initialize(this.value);
    }
  }

  // METHODS ------------------------------------------------------
  initialize(icon: IBaseIconTooltip_Icon) {
    if (!isEmpty(icon)) {
      // reset notification to allow multiple with same information
      this.reset();
      for (let property in icon) {
        if (this.icon.hasOwnProperty(property)) {
          this.icon[property] = icon[property];
        }
      }
    }
  }

  reset() {
    this.icon = { ...ICON_DEFAULTS };
  }
}
