import BaseModel from "../../Base";
import { store } from "@/modules/core";
import { required } from "vuelidate/lib/validators";
export default class Pallet extends BaseModel {
  table = "growops_inventory_pallets";
  primaryKey = "inventory_pallets_pkey";

  properties = {
    uuid: "",
    name: "",
    number: "",
    created: null,
    created_by: null,
    completed: null,
    location_id: "",
  };

  relationships = {
    rel_lots: [],
    rel_location: {},
    rel_user: {},
    rel_lots_aggregate: {},
  };

  queryReturnWithLots = "";
  queryReturnWithOnHand = "";

  derivedProperties = {
    onHand: 0,
  };

  constructor(values: any = {}, store: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
      rel_location{
        name
        rel_room {
          uuid
          name
        }
      }
    `;

    this.queryReturnWithOnHand = `
      ${this.queryReturn}
      rel_lots_aggregate {
        aggregate {
          sum {
            on_hand
          }
        }
      }
    `;

    this.queryReturnWithLots = `
      ${this.queryReturn}
      rel_lots{
        uuid
        number
        location_id
        on_hand
        created
        pallet_id 
        rel_lot_header{
          number
        }
        rel_delivery_lines {
          delivery_box {
            archived
            delivery_id
            delivery {
              number
            }
          }
        }
        inventory_item {
          name
          uuid
          refrigerate
          ship_by_offset
        }
      }
    `;

    // Explicitly set columns that can be updated
    this.queryUpdateColumns = ["name", "completed", "location_id"];

    this.validations.name = { required };
    this.validations.location_id = { required };

    this.setOnHand();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setOnHand(): void {
    if (this.rel_lots_aggregate) {
      this.onHand = this.rel_lots_aggregate?.aggregate?.sum?.on_hand || 0;
    } else if (this.rel_lots?.length > 0) {
      this.onHand = this.rel_lots.reduce((oh: number, lot: any) => {
        oh += lot.on_hand;
      }, 0);
    }
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  getDisplayVersion() {
    let values: any = super.getDisplayVersion();
    values.rel_location = this.rel_location;
    return values;
  }

  getSaveVersion() {
    let values: any = super.getSaveVersion();
    return values;
  }
}
