
import { Vue, Component } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import { EventBus } from "@/util/eventbus";

@Component
export default class AppToolbarFacility extends Vue {
  // LOCAL VARIABLES -----------------------------------------------------------
  facilityIcon: string = getIcon("facility", "campus");

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns true if the current user is flagged as admin
   * @returns {boolean}
   */
  get currentUserIsAdmin() {
    return this.$store.getters["user/getIsAdmin"];
  }

  /**
   * Returns the current facility's name from the vuex store
   * @returns {string}
   */
  get facilityName() {
    return this.$store.getters["facilities/getFacilityName"]();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Emits an event to open the facility selector
   */
  openSelector() {
    if (this.currentUserIsAdmin) {
      EventBus.$emit("open-facility-selector");
    }
  }
}
