
import Form from "@/modules/core/mixins/Form.vue";
import AgGridCellEditor from "@/modules/seeding/mixins/AgGridCellEditor.vue";
import maxValue from "@/util/validators/maxValue";
import minValue from "@/util/validators/minValue";
import { Component, Mixins } from "vue-property-decorator";
import { requiredIf } from "vuelidate/lib/validators";

@Component({
  validations: {
    value: {
      maxValue: maxValue("max"),
      minValue: minValue("min"),
      required: requiredIf("required"),
    },
  },
})
export default class NumericEditor extends Mixins(AgGridCellEditor, Form) {
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns the max value set in config.
   * @returns {number|null}
   */
  get max(): number | null {
    if (this.hasOwnProperty("maxValue") && typeof this.maxValue === "function")
      return this.maxValue(this.max);
    if (this.params.hasOwnProperty("maxValue")) return this.max;
    return null;
  }

  /**
   * Returns the min value set in config.
   * @returns {number|null}
   */
  get min(): number | null {
    if (this.hasOwnProperty("minValue") && typeof this.minValue === "function")
      return this.minValue(this.min);
    if (this.params.hasOwnProperty("minValue")) return this.min;
    return null;
  }

  /**
   * Returns params for error messages
   * @returns {Object}
   */
  get validationParams() {
    let params: any = {};
    if (this.max) params.max = this.max;
    if (this.min || this.min === 0) params.min = this.min;
    return params;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Makes sure the user enters more than just `.` or `-`
   */
  onKeyDown(event: KeyboardEvent) {
    // If the input doesn't contain any numbers, clear the value
    // if the user hits enter or tab
    if (this.isKeyStopEvent(event) && !/\d/.test(this.valueString)) {
      this.value = null;
    }
  }
}
