
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import union from "lodash/union";
import get from "lodash/get";
import BaseSelection from "@/modules/core/mixins/BaseSelection.vue";

@Component
export default class ZoneSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  facilityId!: string;

  @Prop({ default: "" })
  type!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Zone";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of zone options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets zones from the store.
   * @returns {Array}
   */
  get zoneOptions() {
    let options: any[] = this.required ? [] : [{ value: null, name: "None" }];
    if (this.allowAllOption) {
      options.push({ value: "all", name: "all" });
    }
    if (this.options) {
      return this.filterUsedOptions(union(options, this.options));
    } else {
      return this.filterUsedOptions(
        union(
          options,
          (
            this.$store.state.facilities.zoneOptions[
              this.facilityId || this.currentFacilityId
            ] || []
          ).filter((z: any) => !this.type || z.type === this.type)
        ).sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("zoneOptions.length", { immediate: true })
  onZoneOptionsChanged() {
    // If there is only one option, select it
    if (this.zoneOptions.length === 1) this.selected = this.zoneOptions[0].uuid;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update lots from the database
   */
  async retrieveData(): Promise<any> {
    this.isLoading = true;
    if (
      get(
        this.$store,
        `state.facilities.zoneOptions[${
          this.facilityId || this.currentFacilityId
        }].length`,
        0
      ) === 0
    ) {
      // Add filters to where clause
      let where: string = `
        {
          facility_id: {_eq: "${this.facilityId || this.currentFacilityId}"},
          active: {_eq: true}
        }
      `;

      // Override where if an override statement is passed via prop
      if (this.overrideWhere) where = this.overrideWhere;

      // Dispatch query
      await this.$store.dispatch("facilities/retrieveZoneOptions", {
        where: where,
      });
    }
    this.isLoading = false;
  }

  /**
   * Resets items
   */
  resetData() {
    this.$store.commit("facilities/reset", "zoneOptions");
  }
}
