import MsalAuthenticator from "@eaua/authentication-ui";
import axios from "axios";
const clientId = process.env.VUE_APP_MSAL_CLIENTID || "";
const tenantId = process.env.VUE_APP_MSAL_TENANTID || "";
const redirectUri = process.env.VUE_APP_CALLBACK_URL || "";
export const auth = new MsalAuthenticator(
  clientId,
  tenantId,
  redirectUri,
  axios
);
