
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import { getIcon } from "@/util/icons";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import Form from "@/modules/core/mixins/Form.vue";
import { DateTime } from "luxon";
import { mask } from "vue-the-mask";

@Component({
  directives: { mask },
})
export default class SelectLot extends Mixins(Form) {
  @Prop({ default: "destination" })
  destination!: string;
  @Prop({ default: "submit-event" })
  submitEvent!: string;
  @Prop({ default: "Select lot" })
  submitLabel!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  lotNumber: any = null;
  currentLot: any = {};

  // Global events this component watches for and the function triggered
  events: any = [
    {
      name: "emit-lot-override",
      function: this.emitLotOverride,
    },
  ];

  headers: any[] = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Created Date",
      value: "created",
    },
    {
      text: "On Hand",
      value: "on_hand",
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get mappedLotInfo() {
    let currentLotInArray: any[] = [];
    if (!isEmpty(this.currentLot)) {
      let currentLot: any = clone(this.currentLot);
      currentLot.created = DateTime.fromISO(currentLot.created).toFormat(
        "yyyy-MM-dd"
      );
      currentLot.name = currentLot.inventory_item.name;

      currentLotInArray.push(currentLot);
    }
    return currentLotInArray;
  }

  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: "emit-lot-override",
        label: this.submitLabel,
        disabled: this.saveDisabled,
      },
    ];
    return actions;
  }

  /**
   * Returns true if the save button should be disabled
   * @returns {boolean}
   */
  get saveDisabled() {
    if (this.lotNumber === null || isEmpty(this.currentLot)) {
      return true;
    } else {
      return false;
    }
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("actions", { immediate: true })
  onActionsChanged() {
    this.$emit("update-lot-override-actions", this.actions);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  beforeMount() {
    this.initialize();
  }

  created() {}

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Initializes the form. Resets values and offers end point to inject delivery
   */
  initialize(delivery: any = {}) {
    this.isLoading = false;
  }

  async retrieveLotInfo() {
    if (this.lotNumber && this.lotNumber !== null)
      await this.$store.dispatch("lots/retrieveAddLotByNumber", {
        number: this.lotNumber,
      });
    if (!this.$store.state.lots.lotToAdd) {
      emitNotification({
        inDialog: true,
        message: `Lot not found, please verify that the lot # is NOT the parent lot #.`,
        title: "Error",
        type: "error",
      });
    } else {
      this.currentLot = this.$store.state.lots.lotToAdd;
    }
  }

  emitLotOverride() {
    if (this.lotNumber !== null && !isEmpty(this.currentLot)) {
      EventBus.$emit(this.submitEvent, this.currentLot);
    }
  }

  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
