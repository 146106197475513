
import Form from "@/modules/core/mixins/Form.vue";
import { Component, Watch, Mixins, Prop } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import { getIcon } from "@/util/icons";
//lodash imports
import get from "lodash/get";
@Component({
  validations: { moNumber: { required } },
  computed: {
    ...mapState("facilities", ["facilities"]),
  },
})
export default class LocationSelectForm extends Mixins(Form) {
  //Props
  @Prop({ default: "destination" })
  destination!: string;

  @Prop({ default: {} })
  passedLocation!: any;

  @Prop({ default: "" })
  currentFacility!: string;

  selectedLocationRoom: string = "";
  selectedLocation: string = "";
  events: IEventBusEvent[] = [
    {
      name: "save-location",
      function: this.emitLocation,
    },
  ];

  @Watch("locationRoomOptions")
  onLocationRoomOptionsChanged() {
    if (this.locationRoomOptions.length === 1) {
      this.selectedLocationRoom = get(
        this.locationRoomOptions,
        "[0].value",
        ""
      );
    }
  }

  @Watch("locationOptionsByRoom")
  onLocationOptionsChanged() {
    if (this.locationOptionsByRoom.length === 1) {
      this.selectedLocation = get(this.locationOptionsByRoom, "[0].value", "");
    }
  }

  @Watch("actions", { immediate: true })
  onActionsChanged() {
    this.$emit("update-enrollment-actions", this.actions);
  }
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Return facility location room options.
   * @returns {Array}
   */
  get locationRoomOptions() {
    return this.$store.getters["facilities/locationRoomOptions"];
  }

  /**
   * Returns an array of locations from associated location room
   * @returns {Array}
   */
  get locationOptionsByRoom() {
    if (this.selectedLocationRoom) {
      return this.$store.getters["facilities/getLocationsByLocationRoom"](
        this.selectedLocationRoom
      ).map((p: any) => {
        return {
          text: p.name,
          value: p.uuid,
        };
      });
    }
    return [];
  }

  /**
   * Return storage location validation rules.
   * @returns {Object}
   */
  get storageLocationValidationRules() {
    return {
      required: (value: any) => !!value || "Required.",
    };
  }

  /**
   * Returns true if the save button should be disabled
   * @returns {boolean}
   */
  get submitDisabled(): boolean {
    // Disable save if nothing's been touched
    if (!this.$v.$anyDirty) return true;
    // Disable save if validation's pending
    if (this.$v.$pending) return true;
    if (this.isLoading) return true;
    if (this.$v.$anyError) return true;
    return false;
  }
  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions(): Array<any> {
    return [
      {
        callback: "save-location",
        text: this.isLoading,
        loading: this.isLoading,
        label: "Save Location",
      },
    ];
  }
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  mounted() {
    setListeners(this.events);
    this.selectedLocation = this.passedLocation.selectedLocation;
    this.selectedLocationRoom = this.passedLocation.selectedLocationRoom;
    this.retrieveInventoryLocationRooms();
  }
  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Tells the store to update inventory locations from the database
   */
  async retrieveInventoryLocationRooms() {
    await this.$store.dispatch(
      "facilities/retrieveLocationRooms",
      this.currentFacility
    );
  }

  emitLocation() {
    EventBus.$emit("update-mixing-location", {
      room: this.selectedLocationRoom,
      location: this.selectedLocation,
    });
  }

  /**
   * Blurs storage location dropdown on item select
   * Blur for validation check
   */
  blurStorageLocationField() {
    // @ts-ignore: Can't read function from ref
    this.$refs["storageLocationField"].blur();
  }

  /**
   * Blurs storage location dropdown on item select
   * Blur for validation check
   */
  blurStorageLocationRoomField() {
    // @ts-ignore: Can't read function from ref
    this.$refs["storageLocationRoomField"].blur();
  }

  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
