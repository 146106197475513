import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { packoutEntryRoutes } from "./router/packout-entry-routes";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";

export class PackoutEntryModule implements VueModule {
  readonly name = "packout-entry";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(packoutEntryRoutes);

    try {
      const requireComponent = require.context("./components/", true, /\.vue$/);

      requireComponent.keys().forEach((fileName) => {
        const componentName = `${upperFirst(this.name)}${upperFirst(
          camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
        )}`;

        Vue.component(componentName, (resolve) => {
          const componentConfig = requireComponent(fileName);

          resolve(componentConfig.default || componentConfig);
        });
      });
    } catch (e) {
      console.log(`Could not load packout WIP Entry module components.`);
    }
  }
}
