import Vue from "vue";
import { CoreModule, auth, handleLoginResponse, store } from "@/modules/core";
import { HomeModule } from "@/modules/home";
import "./registerServiceWorker";
import { socketClient } from "./modules/core/sockets";
import { sync } from "vuex-router-sync";
import { HarvestModule } from "./modules/harvest";
import { WeighModule } from "./modules/weigh";
import { FulfillmentModule } from "./modules/fulfillment";
import { StorageModule } from "./modules/storage";
import { PackoutModule } from "./modules/packout/packout-module";
import { PalletizerModule } from "./modules/palletizer";
import { TransplantModule } from "./modules/transplant";
import { PropagationModule } from "./modules/propagation";
import { SeedingModule } from "./modules/seeding";
import { InventoryModule } from "./modules/inventory";
import { MixingModule } from "./modules/mixing/mixing-module";
import { CasingModule } from "./modules/casing/casing-module";
import { ShippingModule } from "./modules/shipping";
import { WipExitModule } from "./modules/wipExit/wipExit-module";
import { PackoutEntryModule } from "./modules/packout-entry/packout-entry-module";
import { DisplayModule } from "./modules/display/display-module";

const coreModule = new CoreModule();

/**
 * Only install modules
 */
function bootstrap() {
  Vue.config.productionTip = false;

  const homeModule = new HomeModule(coreModule.router!);
  homeModule.install(Vue);

  const harvestModule = new HarvestModule(coreModule.router!);
  harvestModule.install(Vue);

  const weighModule = new WeighModule(coreModule.router!);
  weighModule.install(Vue);

  const fulfillmentModule = new FulfillmentModule(coreModule.router!);
  fulfillmentModule.install(Vue);

  const storageModule = new StorageModule(coreModule.router!);
  storageModule.install(Vue);

  const palletizerModule = new PalletizerModule(coreModule.router!);
  palletizerModule.install(Vue);

  const transplantModule = new TransplantModule(coreModule.router!);
  transplantModule.install(Vue);

  const propagationModule = new PropagationModule(coreModule.router!);
  propagationModule.install(Vue);

  const seedingModule = new SeedingModule(coreModule.router!);
  seedingModule.install(Vue);

  const inventoryModule = new InventoryModule(coreModule.router!);
  inventoryModule.install(Vue);

  const mixingModule = new MixingModule(coreModule.router!);
  mixingModule.install(Vue);

  const casingModule = new CasingModule(coreModule.router!);
  casingModule.install(Vue);

  const packoutModule = new PackoutModule(coreModule.router!);
  packoutModule.install(Vue);

  const shippingModule = new ShippingModule(coreModule.router!);
  shippingModule.install(Vue);

  const wipExitModule = new WipExitModule(coreModule.router!);
  wipExitModule.install(Vue);

  const packoutEntryModule = new PackoutEntryModule(coreModule.router!);
  packoutEntryModule.install(Vue);

  const displayModule = new DisplayModule(coreModule.router!);
  displayModule.install(Vue);
}

/**
 * Some componets are being mounted before having a session initialized so this
 * will mount the whole app just after having a session initialized
 */
export function mountApp() {
  // Instead boostrap we could install ONLY the modules allowed for the user here
  sync(coreModule.store!, coreModule.router!);
  coreModule.install(Vue);
  const oid = store.getters["user/getOID"];
  socketClient.connect(oid, "Kiosk");
}

Vue.config.productionTip = false;
Vue.prototype.$auth = auth;
Vue.prototype.$socketClient = socketClient;

bootstrap();

(async () => {
  const existingSession = await auth.checkForExistingSession();
  if (!existingSession) {
    await auth.authClient
      .handleRedirectPromise()
      .then(handleLoginResponse)
      .catch((error: any) => {
        console.log(error);
      });
  } else {
    await handleLoginResponse({
      account: existingSession.account,
      idToken: existingSession.idToken,
      uniqueId: existingSession.uniqueId,
    });
  }
})().then(mountApp);
