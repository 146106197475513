
import { Component, Prop } from "vue-property-decorator";
import clone from "lodash/clone";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import FilterBase from "./Base.vue";
import { generateUniqueKey } from "@/util/helpers";

@Component
export default class FilterChips extends FilterBase {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: "" })
  field!: string;

  @Prop({ default: undefined })
  justify!: string | undefined;

  @Prop({ default: "" })
  searchComponent!: string;

  // Local Variables - Replaces data()
  search!: string;

  // COMPUTED -----------------------------------------------------
  get hasField() {
    if (!isEmpty(this.field)) return true;
    return false;
  }

  get routeName() {
    let route: string = this.$route.name || "";
    if (this.searchComponent) route += `-${this.searchComponent}`;
    return route;
  }

  get filteredChips() {
    let filters = clone(this.filters);
    if (this.hasField) {
      filters = filter(filters, ["field", this.field]);
    }
    return filter(filters, ["route", this.routeName]);
  }

  // METHODS ------------------------------------------------------
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
