import BaseModel from "../../Base";
import { required, minValue, decimal } from "vuelidate/lib/validators";
import { padLotNumber } from "@/util/helpers";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import { DateTime } from "luxon";
import get from "lodash/get";

export default class DeliveryLine extends BaseModel {
  table = "growops_delivery_lines";
  primaryKey = "delivery_lines_pkey";

  properties = {
    uuid: "",
    box_id: "",
    lot_id: "",
    sales_order_line_id: "",
    quantity: 0,
    accepted: null,
    rejected: 0,
    harvest_date: null,
    packout_date: null,
  };

  relationships = {
    lot: {},
  };

  // Derived properties
  delivery: any = {};
  lotNumber: string = "";
  itemName: string = "";
  sales_order_line: any = {};

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add lot/item info to the query return object
    this.queryReturn += `
      lot {
        uuid
        number
        on_hand
        created
        pallet_id
        rel_pallet{
          number
          name
        }
        rel_lot_header {
          number
        }
        location{
          name
          rel_room {
            name
          }
        }
        inventory_item {
          uuid
          name
          item_label_name
          internal_unit_weight
          internal_unit_count
          internal_unit_weight_uom
          internal_unit_packaging
          pack_by_offset
        }
      }
    `;

    // Add validation rules
    // this.validations.lot_id = { required };
    this.validations.sales_order_line_id = { required };
    this.validations.quantity = { decimal, minValue: minValue(0) };

    // Set derived values
    this.setDelivery(values.delivery || {});
    this.setItemName();
    this.setLotNumber();
    this.setSalesOrderLine();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets the parent delivery object
   * @param delivery Delivery object
   */
  setDelivery(delivery: any = {}) {
    let box: any = null;
    let isCorrectDelivery: boolean = false;
    if (!this.box_id) return;
    // If no delivery is passed, and the store is set,
    // pull the currentDelivery from the store
    if (!delivery.uuid && this.store.state) {
      delivery = this.store.state.delivery.currentDelivery;
    }
    // If there are delivery boxes, search for the box
    if (delivery.delivery_boxes) {
      box = find(delivery.boxes, { uuid: this.box_id });
    }
    // If the box is found, this is the correct delivery, and it can be set
    if (box) isCorrectDelivery = true;
    if (isCorrectDelivery) this.delivery = cloneDeep(delivery);
  }

  /**
   * Sets item name from linked lot
   */
  setItemName() {
    this.itemName = get(this.lot, "inventory_item.name", "");
  }

  /**
   * Sets item name from linked lot
   */
  setLotNumber() {
    this.lotNumber = padLotNumber(get(this.lot, "number", ""));
  }

  /**
   * Sets the sales order line from linked delivery
   */
  setSalesOrderLine() {
    if (!this.sales_order_line_id) return;
    let lines: any = get(this.delivery, "sales_order.order_lines", []);
    let line: any = find(lines, { uuid: this.sales_order_line_id });
    this.sales_order_line = line ? cloneDeep(line) : {};
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Delivery Line
   * @returns {Object}
   */
  getDisplayVersion(): object {
    let values: any = super.getDisplayVersion();
    values.itemName = this.itemName;
    values.lotNumber = this.lotNumber;
    return values;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    // Remove the box uuid if it's empty or null
    if (values.hasOwnProperty("box_id") && !values.box_id) {
      delete values.box_id;
    }
    // Remove the sales order line uuid if it's empty or null
    if (
      values.hasOwnProperty("sales_order_line_id") &&
      !values.sales_order_line_id
    ) {
      delete values.sales_order_line_id;
    }
    return values;
  }

  getItemName(isCase: boolean = false) {
    if (isCase) {
      return (
        get(this.lot, "inventory_item.item_label_name", "") || this.itemName
      );
    }
    return this.itemName;
  }

  getItemQuantity(isCase: boolean = false) {
    if (isCase) {
      return get(this.lot, "inventory_item.internal_unit_count", "");
    }
    return this.quantity;
  }

  getItemPackaging(isCase: boolean = false) {
    if (isCase) {
      return get(this.lot, "inventory_item.internal_unit_packaging", "");
    }
    return "Clamshell";
  }

  getItemHarvestDate(isCase: boolean = false) {
    if (isCase) {
      return DateTime.fromISO(this.lot.created).toFormat("MM/DD/YYYY");
    }
    return this.harvest_date;
  }

  getItemPackoutDate(isCase: boolean = false) {
    if (isCase) {
      return DateTime.fromISO(this.lot.created).toFormat("MM/DD/YYYY");
    }
    return this.packout_date;
  }
}
