import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const shippingRoutes: RouteConfig = {
  path: "/shipping",
  component: () => import("@/modules/shipping/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/shipping/views/Main.vue"),
      name: "ShippingMain",
    },
    {
      path: "details/:uuid",
      component: () => import("@/modules/shipping/views/Details.vue"),
      name: "ShippingDetails",
      props: true,
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("shipping");
    next();
  },
};
