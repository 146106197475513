
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";

@Component
export default class RemoveAll extends Mixins(Dialog) {
  [key: string]: any;
  // LOCAL VARIABLES -----------------------------------------------------------
  packagingRecipeMaterials: any[] = [];
  packagingMaterialLots: any[] = [];
  filterRelativeLots: any[] = [];

  @Prop({ default: true })
  noGoodSelected!: any;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-destroy-all-dialog");
  }

  @Watch("noGoodSelected", { immediate: true })
  onWatchNoGoodSelected() {
    if (this.noGoodSelected === true) {
      EventBus.$emit("close-destroy-all-dialog");
    }
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  markAsCompleted() {
    this.$emit("markAsCompleted", true);
  }

  initialize(currentPackagingInfo: any = {}) {
    this.packagingMaterialLots = currentPackagingInfo.packagingMaterialLots;
    this.packagingRecipeMaterials =
      currentPackagingInfo.packagingRecipeMaterials;
    this.filterRelativeLots = currentPackagingInfo.relativeLots;
  }
}
