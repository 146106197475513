
import { Lot } from "@eaua/model";
import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseLotCheck extends Vue {
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns true if the current lot is set
   */
  get hasLot(): boolean {
    return !!this.currentLot.uuid;
  }
  /**
   * Returns the current lot from the store
   */
  get currentLot(): Lot {
    return new Lot(this.$store.state.lots.currentLot || {});
  }
  /**
   * Returns the current lot's facility id
   */
  get currentLotFacilityId(): string {
    if (this.currentLot.location) return this.currentLot.location.facility_id;
    if (this.currentLot.facility_id) return this.currentLot.facility_id;
    return this.currentLot?.facility?.uuid || "";
  }
  /**
   * Returns the current facility id from the store
   */
  get currentFacilityId(): string {
    return this.$store.state.facilities.currentFacilityUuid;
  }
  /**
   * Returns true if the current lot is valid for the given BOM
   */
  get isValidLot(): boolean {
    // If the lot's facility doesn't match the current facility,
    // it's not valid
    if (this.currentLotFacilityId !== this.currentFacilityId) return false;
    if (
      this.currentLot.location_id ===
      this.$store.getters["facilities/getHoldRoom"]
    ) {
      return false;
    }
    return true;
  }
  /**
   * Returns a validation message for the current lot
   */
  get lotValidationMessage(): string {
    if (!this.hasLot) return "";
    if (this.currentLotFacilityId !== this.currentFacilityId) {
      return "Lot at different facility";
    }
    if (
      this.currentLot.location_id ===
      this.$store.getters["facilities/getHoldRoom"]
    ) {
      return "Lot in hold room";
    }
    return "";
  }
}
