import { RouteConfig } from "vue-router/types/router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const SeedingRoutes: RouteConfig = {
  path: "/seeding",
  component: () => import("@/modules/seeding/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/seeding/views/Main.vue"),
      name: "SeedingMain",
    },
    {
      path: "materials/:date/:cropOrRecipeId/:moduleId?",
      props: true,
      component: () => import("@/modules/seeding/views/Materials.vue"),
      name: "SeedingMaterials",
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("seeding");
    next();
  },
};
