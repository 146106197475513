
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import union from "lodash/union";
import BaseSelection from "../../mixins/BaseSelection.vue";

@Component
export default class InventoryRoomSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  facilityId!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Storage Room";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of room options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets rooms from the store.
   * @returns {Array}
   */
  get roomOptions(): Array<any> {
    let options: any[] = [];
    if (!this.required && !this.allowMultiple) {
      options.push({ value: null, text: "None" });
    }
    if (this.options)
      return this.filterUsedOptions(union(options, this.options));
    return this.filterUsedOptions(
      union(options, this.$store.getters["locations/getRoomOptions"])
    );
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("roomOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (this.roomOptions.length === 1)
      this.selected = this.roomOptions[0].value;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update rooms from the database
   */
  async retrieveData(): Promise<any> {
    if (this.facilityId && this.$store.state.locations.rooms.length === 0) {
      this.isLoading = true;
      await this.$store.dispatch("locations/retrieveRooms", {
        facilityId: this.facilityId,
      });
      this.isLoading = false;
    }
  }

  /**
   * Resets rooms
   */
  resetData() {
    this.$store.commit("locations/reset", "rooms");
  }
}
