import { io, Socket } from "socket.io-client";
import {
  InvalidSocketServerUrl,
  SocketNotConnectedError,
  SocketUninitializedError,
} from "./errors";
import { SocketEventType } from "./enums";
import { DefaultEventsMap } from "@socket.io/component-emitter";

class SocketClient {
  private _socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;

  /**
   * Connects the client with the SocketServer located in the url defined in
   * VUE_APP_SOCKET_SERVER_URL and the namespace set in VUE_APP_SOCKET_SERVER_NAMESPACE
   * @param  {string} user The uuid for the current user
   * @param  {string} source The name/type of app/kiosk
   * @param  {Function} onConnectListener Callback executed when the socket gets connected
   * @throws InvalidSocketServerUrl
   */
  public connect(
    user: string,
    source: string,
    onConnectListener?: () => void
  ): void {
    this._socket = this.createSocket({ user, source });
    this._socket.on(SocketEventType.Connect, () => {
      if (onConnectListener) {
        onConnectListener();
      }
      console.log("🔗: connected as " + source);
    });

    if (process.env.NODE_ENV !== "production") {
      this.registerDebugEvents();
    }

    this._socket.onAny((event, ...args) => {
      switch (event) {
        case SocketEventType.Subscribe:
          console.log("🔗: Subscribed to room", args[0]);
          break;
      }
    });
  }

  /*checkConnection() {
    if (!this._socket) {
      this._doConnect({ user, source });
    }
    if (!this._socket.connected) {
      // throw new SocketNotConnectedError();
      console.warn("🔗: not connected to the svc-socket service");
      return false;
    }
  }*/

  /**
   * Close The connection
   */
  public close() {
    this._socket?.close();
    this._socket = undefined;
  }

  /**
   * Call the emit  method of the socket connected to the url defined in
   * VUE_APP_SOCKET_SERVER_URL and the namespace set in SOCKET_SERVER_PATH
   * @param  {SocketEventType} eventType
   * @param  {any} payload
   * @throws SocketUninitializedError
   * @throws SocketNotConnectedError
   */
  public emit(
    eventType: SocketEventType,
    payload: SubscriptionPayload | ItemPayload
  ) {
    if (this.checkSocket()) {
      this._socket?.emit(eventType, payload);
    }
  }

  /**
   * Put the event on hold until the socket is available after connect
   *
   * @param eventType
   * @param payload
   */
  public emitWhenAvailable(
    eventType: SocketEventType,
    payload: SubscriptionPayload | ItemPayload
  ) {
    this.waitUntilAvailable().then(() => this.emit(eventType, payload));
  }

  /**
   * A wrap to set the listener to "upsert" event
   *
   * @param {Function} listener
   * @returns {void}
   */
  public onUpsert(listener: (payload: ItemPayload) => void): void {
    if (this.checkSocket()) {
      this._socket?.on(SocketEventType.Upsert, listener);
    }
  }

  /**
   * Remove the listener from an "upsert" event
   *
   * @param {Function} listener
   * @returns {void}
   */
  public removeUpsertListener(listener: (payload: ItemPayload) => void): void {
    if (this.checkSocket()) {
      this._socket?.off(SocketEventType.Upsert, listener);
    }
  }

  /**
   * Create socket connection and send the initial data to register  client
   * @param {SocketClientInitData} data;
   * @returns {Socket} socket connection
   */
  private createSocket(data: SocketClientInitData): Socket {
    const url = process.env.VUE_APP_SOCKET_SERVER_URL;
    if (!url) {
      throw new InvalidSocketServerUrl();
    }
    const socketNamespace =
      process.env.VUE_APP_SOCKET_SERVER_NAMESPACE || "/socket/";
    console.log(`🔗: Connecting to ${url}${socketNamespace}`);
    return io(url, {
      path: socketNamespace,
      query: data,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 999999,
    });
  }

  /**
   * Loops until the socket is initialized, even after getting connected event
   * because sometimes the socket takes a while to initialize and is still not available.
   * If the connection is closed or not initialized the promises on hold will be discarded
   * throwing an SocketUninitializedError exception
   *
   * @returns {Promise<void>}
   */
  public waitUntilAvailable(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let count = 0;
      const interval = setInterval(() => {
        count++;
        if (!this._socket) {
          if (count > 20) {
            clearInterval(interval);
            throw new SocketUninitializedError();
          }
          return;
        }
        if (this._socket?.connected) {
          clearInterval(interval);
          resolve();
        } else if (count > 120) {
          count = 0;
          throw new SocketNotConnectedError();
        }
      }, 500);
    });
  }

  /**
   * Validates if the socket is initialized or connected
   *
   * @returns {boolean}
   */
  private checkSocket(): boolean {
    if (!this._socket) {
      throw new SocketUninitializedError();
    }
    if (!this._socket.connected) {
      // throw new SocketNotConnectedError();
      console.warn("🔗: not connected to the svc-socket service");
      return false;
    }
    return true;
  }

  /**
   * Register all the possible event handlers for debugging purposes
   */
  private registerDebugEvents() {
    if (!this._socket) {
      return;
    }
    this._socket.on("disconnect", (data: any) => {
      console.log("🔗 disconnected");
    });
    this._socket.on("error", (data: any) => {
      console.error("🔗 error:", data);
    });
    this._socket.io.on("reconnect_error", (error: any) => {
      console.error("🔗 reconnect_error:", error);
    });
    this._socket.on("xhr poll error", (error: any) => {
      console.error("🔗 xhr poll error:", error);
    });
    this._socket.on("connect_failed", (error: any) => {
      console.error("🔗 connect_failed:", error);
    });
    this._socket.on("connect_error", (error) => {
      console.error("🔗 connect_error:", error);
    });
  }
}

export const socketClient = new SocketClient();
