
import { Vue, Component, Prop } from "vue-property-decorator";
import { getIcon } from "@/util/icons";

@Component
export default class BaseMultiUseDialog extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: "destination" })
  destination!: string;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: true })
  persistent!: boolean;

  @Prop({ default: true })
  dismissable!: boolean;

  @Prop({ default: true })
  scrollable!: boolean;

  @Prop({ default: false })
  fullscreen!: boolean;

  @Prop({ default: "800" })
  maxWidth!: string | number;

  @Prop({ default: () => [] })
  actions!: any;

  @Prop({ default: "both" })
  borders!: any;

  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: true })
  resetOnClose!: boolean;

  @Prop({ default: true })
  showActions!: boolean;

  closeIcon: string = getIcon("base", "close");

  // COMPUTED -----------------------------------------------------

  get hasActionsSlot() {
    return !!this.$slots.actions;
  }

  get hasTitle() {
    return !!this.title;
  }

  get visible() {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }
}
