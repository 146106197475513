import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const mixingRoutes: RouteConfig = {
  path: "/mixing",
  component: () => import("@/modules/storage/views/Index.vue"),
  children: [
    {
      path: "main",
      component: () => import("@/modules/mixing/views/Landing.vue"),
      name: "MixingMain",
    },
    {
      path: "details/:uuid",
      name: "MixingDetails",
      props: true,
      component: () => import("@/modules/mixing/views/Details.vue"),
    },
    {
      path: "waiting-weigh",
      name: "MixingWaitingWeight",
      component: () => import("@/modules/mixing/views/Wait.vue"),
    },
    {
      path: "weigh",
      name: "MixingWeighKiosk",
      component: () => import("@/modules/mixing/views/weigh/Index.vue"),
      children: [
        {
          path: "",
          name: "MixingWeightLanding",
          component: () => import("@/modules/mixing/views/weigh/Landing.vue"),
        },
        {
          path: "totes/:batchId/:goodId",
          name: "MixingWeightTotes",
          props: true,
          component: () => import("@/modules/mixing/views/weigh/Totes.vue"),
        },
        {
          path: "summary/:batchId/:goodId",
          name: "MixingWeightSummary",
          props: true,
          component: () => import("@/modules/mixing/views/weigh/Summary.vue"),
        },
      ],
    },
  ],
  beforeEnter: (to, from, next) => {
    if (to.path === "/mixing/main") {
      logRocketInit("mixing");
    } else {
      logRocketInit("mixing-weigh");
    }
    next();
  },
};
