import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import { Vue as _Vue } from "vue/types/vue";

export function registerComponents(Vue: typeof _Vue) {
  const requireComponent = require.context("../components/", true, /\.vue$/);

  requireComponent.keys().forEach((fileName) => {
    const componentName = upperFirst(
      camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
    );

    Vue.component(componentName, (resolve) => {
      const componentConfig = requireComponent(fileName);

      resolve(componentConfig.default || componentConfig);
    });
  });
}
