
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { inventoryLotLabel } from "@/util/print";
import Printd from "printd";
import QrcodeVue from "qrcode.vue";
@Component({
  components: {
    QrcodeVue,
  },
})
export default class LotLabel extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: () => {} })
  product!: any;

  @Ref("printBox")
  readonly printBox!: HTMLElement;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns object for navigate-lot
   * @returns {Object}
   */
  get convertToQrCode() {
    return JSON.stringify({
      type: "navigate-lot",
      payload: this.product.lot_id,
    });
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Triggers the brower's print dialog
   */
  print() {
    let printd: Printd = new Printd();
    printd.print(this.printBox, [inventoryLotLabel]);
  }
}
