
import { mapState } from "vuex";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getIcon } from "@/util/icons";

@Component({
  computed: {
    ...mapState("filter", ["filterDrawer"]),
  },
})
export default class BaseDataIteratorTools extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "card" })
  displayType!: string;

  @Prop({ default: true })
  showDisplayOptions!: boolean; // Allows changing the display type

  @Prop({ default: "" })
  searchComponent!: string;

  @Prop({ default: true })
  showGantt!: boolean;

  @Prop({ default: false })
  searchOnly!: boolean; // Hides the filter drawer toggle button

  // MAPPED VARIABLES ----------------------------------------------------------
  filterDrawer!: boolean;

  // LOCAL VARIABLES -----------------------------------------------------------
  displayState: number = this.displayType === "card" ? 0 : 1;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns display value based on toggle number.
   * @returns {string} display value
   */
  get display() {
    switch (this.displayState) {
      case 0:
        return "card";
      case 2:
        return "gantt";
      default:
        return "table";
    }
  }

  /**
   * Mutates `display` value to a number for toggle buttons.
   * @param {string} value - Display value, ie `card`, `table`, etc
   */
  set display(value: string) {
    switch (value) {
      case "card":
        this.displayState = 0;
        break;
      case "gantt":
        this.displayState = 2;
        break;
      default:
        this.displayState = 1;
    }
  }

  /**
   * Returns 0 (for toggle button) if `filterDrawer` is true
   * @returns {number|null}
   */
  get showFilters() {
    if (this.filterDrawer === true) return 0;
    return null;
  }

  /**
   * Mutates `setFilterDrawer` in the vuex store
   * @param {number|any} val
   */
  set showFilters(val: any) {
    let show: boolean = val === 0 ? true : false;
    this.$store.commit("filter/setFilterDrawer", show);
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("displayState")
  onDisplayStateChanged() {
    this.$emit("change-iterator-display", this.display);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Toggles the filter drawer state in the vuex store
   */
  toggleFilterDrawer() {
    this.$store.commit("filter/toggleFilterDrawer");
  }

  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
