
import { Prop, Component, Mixins, Watch } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import { mask } from "vue-the-mask";
@Component({
  components: {},
  computed: {},
  directives: { mask },
})
export default class RemoveLotForm extends Mixins(Form) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => {} })
  passedCaseLot!: any;
  @Prop({ default: "destination" })
  destination!: string;
  @Prop({ default: 0 })
  dialogId!: number | null;
  // LOCAL VARIABLES -----------------------------------------------------------
  caseLot!: any;
  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: `close-remove-lot-dialog-${this.dialogId}`,
        label: "Cancel",
        disabled: this.isLoading,
      },
      {
        callback: "remove-case",
        label: "Remove Case",
        loading: this.isLoading,
        disabled: this.isLoading,
      },
    ];
    return actions;
  }
  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  // Global events this component watches for and the function triggered
  events: any = [
    {
      name: "remove-case",
      function: this.setCaseToRemove,
    },
  ];
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  beforeMount() {
    this.initialize();
  }
  mounted() {
    setListeners(this.events);
  }
  beforeUpdate() {
    setListeners(this.events);
  }
  beforeDestroy() {
    unsetListeners(this.events);
  }
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Initializes the form. Resets values and offers end point to inject delivery
   */
  initialize(delivery: any = {}) {
    this.isLoading = false;
    this.caseLot = this.passedCaseLot;
  }
  /**
   * Sets the store with the current lot so the watcher Details.vue picks it up for removal
   */
  async setCaseToRemove() {
    this.isLoading = true;
    await this.$store
      .dispatch("lots/retrieveCurrentLot", {
        uuid: this.caseLot?.lot_id || this.caseLot?.uuid,
      })
      .then(() => {
        EventBus.$emit("add-lot", this.$store.state.lots.currentLot);
      });
    EventBus.$emit(`close-remove-lot-dialog-${this.dialogId}`);
  }
}
