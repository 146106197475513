import Vue from "vue";
export const EventBus = new Vue();

export function setListeners(events: Array<IEventBusEvent> = []) {
  for (const event of events) {
    // @ts-ignore
    if (!EventBus._events[event.name]) {
      EventBus.$on(event.name, event.function);
    }
  }
}

export function unsetListeners(events: Array<IEventBusEvent> = []) {
  for (const event of events) {
    // @ts-ignore
    if (EventBus._events[event.name]) {
      EventBus.$off(event.name);
    }
  }
}

export interface IEventBusEvent {
  name: string;
  function: (payload?: any) => void;
}
