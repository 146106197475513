
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import union from "lodash/union";
import BaseSelection from "../../mixins/BaseSelection.vue";

@Component
export default class InventoryLocationSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  facilityId!: string;

  @Prop({ default: false })
  inline!: boolean;

  @Prop({ default: "" })
  roomLabel!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Storage Location";
  roomId: string = "";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of location options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets locations from the store.
   * @returns {Array}
   */
  get locationOptions(): Array<any> {
    let options: any[] = [];
    if (!this.required && !this.allowMultiple) {
      options.push({ value: null, text: "None" });
    }
    if (this.options)
      return this.filterUsedOptions(union(options, this.options));

    return this.filterUsedOptions(
      union(options, this.roomRegistry[this.roomId]?.locationOptions)
    );
  }

  /**
   * Returns rooms from the store. Used to trigger setting the room id
   * if only a location id is set
   */
  get rooms(): Array<object> {
    return this.$store.state.locations.rooms;
  }

  get roomRegistry(): any {
    return this.$store.getters["locations/getRoomRegistry"];
  }

  /**
   * Returns true if the fields should be displayed inline
   * @returns {boolean}
   */
  get displayInline(): boolean {
    return this.inline && this.$vuetify.breakpoint.mdAndUp;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("locationOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (this.locationOptions.length === 1)
      this.selected = this.locationOptions[0].value;
  }

  @Watch("roomId")
  onRoomChanged(newVal: any, oldVal: any) {
    if (newVal && oldVal) {
      if (this.locationOptions.length === 1)
        this.selected = this.locationOptions[0].value;
      else this.selected = "";
    }
  }

  @Watch("rooms.length", { immediate: true })
  onRoomLengthChanged() {
    this.setRoomId();
  }

  @Watch("selected")
  onValueChanged() {
    this.setRoomId();
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  beforeMount() {
    this.setRoomId();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Sets the room id if a location is passed in, but no room id is set
   */
  setRoomId() {
    if (this.selected && !this.roomId) {
      const room = this.$store.getters["locations/getRoomByLocationId"](
        this.selected
      );
      if (room.uuid) this.roomId = room.uuid;
    }
  }
}
