import BaseModel from "../../Base";
import { required } from "vuelidate/lib/validators";
import BoxType from "@/modules/core/models/growops/delivery/BoxType";
import DeliveryLine from "@/modules/core/models/growops/delivery/Line";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";

export default class DeliveryBox extends BaseModel {
  table = "growops_delivery_boxes";
  primaryKey = "delivery_boxes_pkey";

  properties = {
    uuid: "",
    delivery_id: "",
    type_id: null,
    created: null,
    created_by: null,
    packed: null,
    packed_by: null,
    loaded: null,
    loaded_by: null,
    archived: null,
    archived_by: null,
    refrigerate: null,
    sequence: 0,
  };

  relationships = {
    delivery_box_type: {},
    delivery_lines: [],
  };

  // Config: will eventually move to database
  config: any = {
    temperature_options: {
      none: {
        value: "none",
        text: "Not Set",
      },
      cold: {
        value: "cold",
        text: "Cold",
      },
      warm: {
        value: "warm",
        text: "Warm",
      },
    },
  };

  // Derived properties
  delivery: any = {};
  index: number = 0;
  linesToSave: any = [];
  products: any = [];
  status: string = "template";

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add box type and lines to the query return object
    this.queryReturn += `
      delivery_box_type {
        ${new BoxType().queryReturn}
      }
      delivery_lines {
        ${new DeliveryLine().queryReturn}
      }
    `;

    // Add validation rules
    this.validations.delivery_id = { required };
    this.validations.type_id = { required };

    // Set derived values
    this.setDelivery(values.delivery || {});
    this.setIndex();
    this.setProducts();
    this.setStatus();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets the parent delivery object
   * @param delivery Delivery object
   */
  setDelivery(delivery: any = {}) {
    if (!this.delivery_id) return;
    // If no delivery is passed, and the store is set,
    // pull the currentDelivery from the store
    if (!delivery.uuid && this.store.state) {
      delivery = this.store.state.delivery.currentDelivery;
    }
    // Compare delivery ids
    if (delivery.uuid === this.delivery_id) {
      this.delivery = cloneDeep(delivery);
    }
  }

  /**
   * Sets the index for this box given the parent delivery
   */
  setIndex() {
    if (!this.delivery.uuid) this.index = 0;
    this.index = findIndex(this.delivery.delivery_boxes, { uuid: this.uuid });
  }

  /**
   * Sets an array of products contained in the box
   */
  setProducts() {
    if (!this.delivery || !this.delivery.uuid || !this.delivery_lines) {
      this.products = [];
      return;
    }
    let orderLines: any = get(this.delivery, "sales_order.order_lines", []);
    for (let dl of this.delivery_lines) {
      let line = new DeliveryLine(dl);
      let orderLine: any = find(orderLines, { uuid: line.sales_order_line_id });
      if (orderLine) {
        this.products.push({
          uuid: line.uuid,
          quantity: line.getItemQuantity(this.delivery.is_case),
          name: line.getItemName(this.delivery.is_case),
          packoutDate: line.getItemPackoutDate(this.delivery.is_case),
          harvestDate: line.getItemHarvestDate(this.delivery.is_case),
          lot: line.lotNumber,
          gtin: get(orderLine, "item.gtin", ""),
          details: this.getDetails(orderLine, this.delivery.is_case),
          lotUuid: line.lot.uuid,
          packaging: line.getItemPackaging(this.delivery.is_case),
        });
      }
    }
  }

  /**
   * Sets the current status of this box
   */
  setStatus() {
    if (this.archived) {
      this.status = "archived";
      return;
    }
    if (this.loaded) {
      this.status = "loaded";
      return;
    }
    if (this.packed) {
      this.status = "packed";
      return;
    }
    this.status = "template";
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Delivery Box
   * @returns {Object}
   */
  getDisplayVersion(): object {
    return super.getDisplayVersion(["archived", "created", "loaded", "packed"]);
  }

  /**
   * Builds a basic upsert query if a table name and a query return
   * string are set. Expects a variable called `input` to be passed
   * in the GraphQL call.
   * @param {string} uuid Box uuid
   * @returns {string}
   */
  getUpsertQuery(uuid: string = "", removeLines: boolean | string = true) {
    let removeLinesQuery: string = "";
    if (uuid && removeLines) {
      // Will delete existing lines
      removeLinesQuery = `
        delete_growops_delivery_lines(
          where: {box_id: {_eq: "${uuid}"}}
        ) {
          affected_rows
        }
      `;
    }
    return super.getUpsertQuery("", removeLinesQuery);
  }

  getArrayUpsertQuery(uuids: any = [], removeLines: boolean | string = true) {
    let removeLinesQuery: string = "";
    if (uuids.length > 0 && removeLines) {
      // Will delete existing lines
      removeLinesQuery = `
        delete_growops_delivery_lines(
          where: {box_id: {_in: ["${uuids.join('", "')}"]}}
        ) {
          affected_rows
        }
      `;
    }
    return super.getUpsertQuery("", removeLinesQuery);
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    let lines: any = [];
    // Build delivery lines to save
    for (let dl of this.linesToSave) {
      let line = new DeliveryLine(dl);
      line.resetProperties(["uuid", "box_id"]);
      lines.push(line.getSaveVersion());
    }
    // Add nested lines for upsert
    if (lines.length > 0) {
      values.delivery_lines = {
        data: lines,
        on_conflict: new DeliveryLine().getOnConflictObject(),
      };
    }
    // Remove the delivery uuid if it's empty or null
    if (values.hasOwnProperty("delivery_id") && !values.delivery_id) {
      delete values.delivery_id;
    }
    return values;
  }

  getDetails(orderLine: any, isCase: boolean = false) {
    if (isCase) {
      let details: any = get(orderLine, "item.details", {}) || {};
      details.dimensions = {};
      details.dimensions.weight = {};
      details.dimensions.weight.value = orderLine.item.internal_unit_weight;
      details.dimensions.weight.uom_id =
        orderLine.item.internal_unit_weight_uom;
      return details;
    } else {
      return get(orderLine, "item.details", {});
    }
  }

  getCaseLot() {
    return get(this.delivery_lines, "[0].lot_id", "");
  }
}
