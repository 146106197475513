
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";
@Component
export default class AddLot extends Mixins(Dialog) {
  [key: string]: any;
  @Prop({ default: "Add lot" })
  buttonLabel!: String;
  @Prop({ default: true })
  verifyExpired!: Boolean;
  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "add-lot-actions";
  scoped = true;
  usedLots: Array<string> = [];
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-add-lot-dialog");
    this.$store.commit("lots/reset", "currentLot");
  }
}
