
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";
@Component
export default class RemoveLot extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "remove-lot-actions";
  scoped = true;
  usedLots: Array<string> = [];
  caseLot: any = {};
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-remove-lot-dialog");
  }
  initialize(values: any = {}): void {
    this.caseLot = values.caseLot;
  }
  mounted() {
    this.$store.commit("lots/reset", "currentLot");
  }
}
