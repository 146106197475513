
import { Component, Prop, Mixins } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import LotMaterialCheck from "@/modules/core/mixins/LotMaterialCheck.vue";
import round from "lodash/round";
import { BomMaterial, Lot, PackoutMaterial } from "@eaua/model";
import { get } from "lodash";

@Component
export default class TransferControl extends Mixins(Form, LotMaterialCheck) {
  // PROPS ---------------------------------------------------------------------

  @Prop({ default: "" })
  batchId!: string;

  @Prop({ default: "" })
  goodId!: string;

  @Prop({ default: "transfer-control-actions" })
  destination!: string;

  // LOCAL VARIABLES -----------------------------------------------------------

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get batchMaterials(): Array<PackoutMaterial> {
    return this.$store.state.packout.currentGood.materials;
  }

  /**
   * Returns an array of materials from the BOM that haven't been
   * added for the current seeding tasks
   */
  get missingMaterials(): Array<BomMaterial> {
    return this.materials.reduce(
      (acc: Array<BomMaterial>, mat: BomMaterial) => {
        if (!this.selectedMaterials[mat.item_id]) acc.push(mat);
        return acc;
      },
      []
    );
  }

  /**
   * Returns a dictionary of materials that have been
   * added for the current batch
   */
  get selectedMaterials(): { [key: string]: BomMaterial } {
    let batchId = get(
      this.$store,
      "state.packout.currentGoodBatches[0].uuid",
      0
    );
    let materials: any = {};
    for (const bm of this.batchMaterials) {
      const itemId = bm?.lot?.item_id;
      if (batchId == bm.batch_id) {
        if (!itemId) continue;
        if (!materials[itemId]) {
          // Uses findLotMaterial from the LotMaterialCheck mixin
          // to find if this item is a material or material substitution
          let material: BomMaterial | null = this.findLotMaterial(
            new Lot({ item_id: itemId })
          );
          if (!material) continue;
          material.amount = bm.before_quantity - bm.after_quantity;
          materials[itemId] = material;
        }
        // If the item's already been added to our materials,
        // just increment the amount
        else materials[itemId].amount += bm.before_quantity - bm.after_quantity;
      }
      for (const itemId in materials) {
        materials[itemId].amount = round(materials[itemId].amount, 4);
      }
    }
    return materials;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }
}
