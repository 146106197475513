
import { Component, Mixins, Prop } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import { getIcon } from "@/util/icons";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import Form from "@/modules/core/mixins/Form.vue";
import { DateTime } from "luxon";
import { mask } from "vue-the-mask";
import { mapState } from "vuex";
import BaseLotCheck from "../../mixins/BaseLotCheck.vue";
@Component({
  directives: { mask },
  computed: {
    ...mapState("delivery", ["fulfillmentDetails"]),
  },
})
export default class AddManualLot extends Mixins(Form, BaseLotCheck) {
  @Prop({ default: "destination" })
  destination!: string;
  @Prop({ default: "Add lot" })
  buttonLabel!: String;
  @Prop({ default: true })
  verifyExpired!: Boolean;
  @Prop({ default: 0 })
  dialogId!: number | null;
  // MAPPED VARIABLES ----------------------------------------------------------
  fulfillmentDetails!: [any];
  // LOCAL VARIABLES -----------------------------------------------------------
  lotNumber: any = null;
  headers: any[] = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Created Date",
      value: "created",
    },
    {
      text: "On Hand",
      value: "on_hand",
    },
  ];
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get mappedLotInfo() {
    let currentLotInArray: any[] = [];
    if (!isEmpty(this.currentLot)) {
      let currentLot: any = clone(this.currentLot);
      currentLot.created = DateTime.fromISO(currentLot.created).toFormat(
        "yyyy-MM-dd"
      );
      currentLot.name = currentLot.inventory_item.name;
      currentLotInArray.push(currentLot);
    }
    return currentLotInArray;
  }
  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: `close-add-lot-dialog-${this.dialogId}`,
        label: "Cancel",
        disabled: this.isLoading,
      },
      {
        callback: `emit-add-lot-${this.dialogId}`,
        label: this.buttonLabel,
        disabled: this.saveDisabled,
        loading: this.isLoading,
      },
    ];
    return actions;
  }
  /**
   * Returns true if the save button should be disabled
   * @returns {boolean}
   */
  get saveDisabled() {
    let disabled = !this.hasLot || isEmpty(this.currentLot) || !this.isValidLot;
    if (this.verifyExpired) {
      return (
        disabled || this.currentLot.getFinishedGoodExpiration() < this.useDate
      );
    }
    return disabled;
  }
  /**
   * Returns a date
   */
  get useDate() {
    let useDate: any = DateTime.utc();
    if (this.verifyExpired && this.fulfillmentDetails.length > 0) {
      useDate = DateTime.fromISO(this.fulfillmentDetails[0].date);
    }
    return useDate;
  }
  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  // Global events this component watches for and the function triggered
  events: any = [];
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  beforeMount() {
    this.initialize();
  }
  created() {
    this.events = [
      {
        name: `emit-add-lot-${this.dialogId}`,
        function: this.emitAddLot,
      },
    ];
  }
  mounted() {
    setListeners(this.events);
  }
  beforeUpdate() {
    setListeners(this.events);
  }
  beforeDestroy() {
    this.$store.commit("lots/reset", "currentLot");
    unsetListeners(this.events);
  }
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Initializes the form. Resets values and offers end point to inject delivery
   */
  initialize(delivery: any = {}) {
    this.isLoading = false;
  }
  async retrieveLotInfo() {
    if (this.lotNumber && this.lotNumber !== null)
      await this.$store.dispatch("lots/retrieveCurrentLot", {
        number: this.lotNumber,
      });
    let useDate: any = DateTime.utc();
    if (this.verifyExpired && this.fulfillmentDetails.length > 0) {
      useDate = DateTime.fromISO(this.fulfillmentDetails[0].date);
    }
    if (!this.hasLot) {
      emitNotification({
        inDialog: true,
        message: `Lot not found, please verify that the lot # is NOT the parent lot #.`,
        title: "Error",
        type: "error",
      });
    } else {
      const currentFacility: any =
        this.$store.getters["facilities/getCurrentFacility"];
      if (this.currentLot.location.facility_id != currentFacility.uuid) {
        emitNotification({
          inDialog: true,
          message: `This Lot# ${this.lotNumber} is not in this facility.`,
          title: "Error",
          type: "error",
        });
        return;
      }
      if (
        this.currentLot.location_id ===
        this.$store.getters["facilities/getHoldRoom"]
      ) {
        emitNotification({
          inDialog: true,
          message: `This Lot# ${this.lotNumber} is located in the hold room.`,
          title: "Error",
          type: "error",
        });
        return;
      }

      const isExpired = this.currentLot.getFinishedGoodExpiration() < useDate;
      if (isExpired && this.verifyExpired) {
        emitNotification({
          inDialog: true,
          message: `This Lot# ${this.lotNumber} is too old to use.`,
          title: "Error",
          type: "error",
        });
      }
    }
  }
  emitAddLot() {
    if (this.lotNumber !== null && !isEmpty(this.currentLot)) {
      EventBus.$emit("add-lot", this.currentLot);
    }
  }
  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
