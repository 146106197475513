
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";

@Component
export default class TransferControlDialog extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "transfer-control-actions";
  scoped = true;

  // PROPS ----------------------------------------------------------------------
  @Prop({ default: "" })
  goodId!: string;

  @Prop({ default: "" })
  batchId!: string;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-transfer-control-dialog");
  }
}
