
import { Vue, Component } from "vue-property-decorator";

@Component
export default class HaltScanning extends Vue {
  // LOCAL VARIABLES -----------------------------------------------------------

  // Prevent duplicate scans and scans while saving
  haltScanning = false;

  //Methods
  setHaltScanning(value: boolean): void {
    this.haltScanning = value;
  }

  disableScanning() {
    this.haltScanning = true;
  }

  enableScanning() {
    this.haltScanning = false;
  }
  scanningDisabled() {
    return this.haltScanning;
  }
}
