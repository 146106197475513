import BaseModel from "@/modules/core/models/Base";

export interface ICropOrderLine {
  [key: string]: any;
  order_id: string;
  destination_line_id: string;
  seed_date: string;
  floats: number | null;
  floats_harvested: number | null;
  modified: string | null;
  modified_by: string | null;
}

export default class CropOrderLine extends BaseModel {
  table = "growops_crop_order_lines";

  primaryKey = "crop_order_lines_pkey";

  properties: ICropOrderLine = {
    order_id: "",
    destination_line_id: "",
    seed_date: "",
    floats: null,
    floats_harvested: null,
    modified: null,
    modified_by: null,
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  initialize(values: any = {}) {
    super.initialize(values);
  }
}
