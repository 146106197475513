import { RouteConfig } from "vue-router/types/router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const InventoryRoutes: RouteConfig = {
  path: "/inventory",
  component: () => import("@/modules/inventory/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/inventory/views/Landing.vue"),
      name: "InventoryLanding",
    },
    {
      path: "details/:uuid",
      component: () => import("@/modules/inventory/views/Details.vue"),
      name: "InventoryDetails",
      props: true,
    },

    {
      path: "transfers",
      component: () => import("@/modules/inventory/views/Transfers.vue"),
      name: "InventoryTransfers",
      props: true,
    },

    {
      path: "lotSplitSelection",
      component: () =>
        import("@/modules/inventory/views/LotSplitSelection.vue"),
      name: "LotSplitSelection",
      props: true,
    },

    {
      path: "splitLot",
      component: () => import("@/modules/inventory/views/SplitLot.vue"),
      name: "SplitLot",
      props: true,
    },

    {
      path: "adjustments",
      component: () => import("@/modules/inventory/views/Adjustments.vue"),
      name: "InventoryAdjustments",
      props: true,
    },
    {
      path: "waterSystemConsumption",
      component: () =>
        import("@/modules/inventory/views/WaterSystemConsumption.vue"),
      name: "WaterSystemConsumption",
      props: true,
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("inventory");
    next();
  },
};
