
import { Vue, Component } from "vue-property-decorator";

@Component
export default class DataRefresh extends Vue {
  /// LOCAL VARIABLES -----------------------------------------------------------
  dataRefresh: any = {
    halt: false,
    timeout: {},
    interval: 30000,
  };
  showPreloader: boolean = true;
  countdown: any = {
    timeout: {},
    number: 0,
  };
  enableCountdown: boolean = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get countdownString() {
    return `Refreshing in ${this.countdown.number}`;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  beforeDestroy() {
    clearTimeout(this.dataRefresh.timeout);
    clearTimeout(this.countdown.timeout);
    this.dataRefresh.halt = true;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Sets a simple refresh, given a method and any params
   */
  scheduleRefresh(method: any, params: any = null) {
    if (method) {
      method(params);
      this.setRefreshTimeout(this.scheduleRefresh, method, params);
    }
  }

  /**
   * Sets an async refresh, given a method and any params
   * Will set showPreloader to false on success
   */
  scheduleAsyncRefresh(method: any, params: any = null) {
    if (method) {
      method(params).then(
        (success: any) => {
          if (typeof this.showPreloader !== "undefined") {
            this.showPreloader = false;
          }
          if (!this.dataRefresh.halt)
            this.setRefreshTimeout(this.scheduleAsyncRefresh, method, params);
        },
        (fail: any) => {
          if (!this.dataRefresh.halt)
            this.setRefreshTimeout(this.scheduleAsyncRefresh, method, params);
        }
      );
    }
  }

  /**
   * Mutates dataRefresh.interval
   */
  setRefreshInterval(interval: number = 30000) {
    this.dataRefresh.interval = interval;
  }

  /**
   * Sets a countdown time for display
   * @param reset Flag to reset the countdown
   */
  setCountdown(reset: boolean = false) {
    this.countdown.number--;
    if (reset && this.dataRefresh.interval) {
      this.countdown.number = this.dataRefresh.interval / 1000;
      clearTimeout(this.countdown.timeout);
    }
    if (this.countdown.number > 0) {
      this.countdown.timeout = setTimeout(() => this.setCountdown(), 1000);
    }
  }

  /**
   * Sets the timeout object that triggers the refresh
   * @param schedule_method Set simple schedule or async
   * @param original_method Method that's called in the schedule refresh
   * @param params Params passed to original_method
   */
  setRefreshTimeout(
    schedule_method: any,
    original_method: any,
    params: any = null
  ) {
    if (schedule_method && original_method) {
      if (this.enableCountdown) this.setCountdown(true);
      this.dataRefresh.timeout = setTimeout(function () {
        schedule_method(original_method, params);
      }, this.dataRefresh.interval);
    }
  }
}
