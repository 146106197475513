
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import Printd from "printd";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    QrcodeVue,
  },
})
export default class LotLabel extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: () => {} })
  lots!: any;

  @Ref("printBox")
  readonly printBox!: HTMLElement;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns object for navigate-lot
   * @returns {Object}
   */
  convertToQrCode(uuid: any) {
    return JSON.stringify({
      type: "navigate-lot",
      payload: uuid,
    });
  }

  print(style: any) {
    let printd: Printd = new Printd();
    printd.print(this.printBox, [style]);
  }
}
