
import { Vue, Component, Prop } from "vue-property-decorator";
import { isObject } from "@/util/helpers";

@Component
export default class BaseDataIteratorCardList extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  headers!: any;

  @Prop({ default: () => {} })
  item!: any;

  @Prop({ default: false })
  twoLine!: boolean; // Displays header above value

  @Prop({ default: false })
  dense!: boolean; // Condenses the padding around list items

  @Prop({ default: false })
  hideHeaders!: boolean; // Only displays item values and icons

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns true if the header has a title or subtitle property set to true
   * @param {Object} header
   * @returns {boolean}
   */
  isTitle(header: any) {
    if (header.title || header.subtitle) return true;
    return false;
  }

  /**
   * Returns true if the header has an icon property set
   * @param {Object} header
   * @returns {boolean}
   */
  hasIcon(header: any) {
    if (header.icon) return true;
    return false;
  }

  /**
   * Returns true if this item has a custom icon for a given header
   * @param {Object} header
   * @returns {boolean}
   */
  hasCustomIcon(header: any) {
    if (this.item.icons && this.item.icons[header.value]) return true;
    return false;
  }

  /**
   * Returns true if a list item should be rendered.
   * @param {Object} header
   * @returns {boolean}
   */
  renderListItem(header: any) {
    if (header.value) {
      return (
        !this.isTitle(header) &&
        this.item.hasOwnProperty(header.value) &&
        this.item[header.value] !== null &&
        header.value !== "status" &&
        header.value !== "progress"
      );
    }
    return false;
  }

  /**
   * Encapsulate isObject helper function for use in template
   */
  isObject(item: any) {
    return isObject(item);
  }
}
