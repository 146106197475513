import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { wipExitRoutes } from "./router/wipExit-routes";

export class WipExitModule implements VueModule {
  readonly name = "wipExit";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(wipExitRoutes);
  }
}
