
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import { PackoutOrderLine } from "@eaua/model";
import Form from "@/modules/core/mixins/Form.vue";
import DataRefresh from "@/modules/core/mixins/DataRefresh.vue";
import SocketClientMixin from "@/modules/core/mixins/SocketClientMixin.vue";
import PackoutGoodMaterial from "@eaua/model/build/src/manufacturing/PackoutGood";
import { PackoutGood } from "@eaua/model";
import { get } from "lodash";

@Component
export default class DestroyAllForm extends Mixins(
  Form,
  DataRefresh,
  SocketClientMixin
) {
  [key: string]: any;

  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  packagingRecipeMaterials!: any;

  @Prop({ default: () => [] })
  packagingMaterialLots!: any;

  @Prop({ default: () => [] })
  filterRelLots!: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  qtysValid: boolean = true;
  totalPacked: number = 0;
  qtyToFulfill: number = 0;
  goodMaterialsToSave: any[] = [];

  headers: any[] = [
    { text: "Customer", value: "customer" },
    { text: "Order", value: "salesOrderNumber" },
    { text: "Ordered", value: "requestedQuantity" },
    { text: "Allocated", value: "allocated" },
    {
      text: "From Inv.",
      value: "from_inventory",
    },
    {
      text: "Actual",
      value: "actual_quantity",
      type: "number-field",
      config: {
        label: "Qty",
        min: 0,
        required: true,
      },
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns current Packout Good from store
   * @returns {Object}
   */
  get currentGood() {
    return new PackoutGood(this.$store.state.packout.currentGood);
  }

  /**
   * Reduce and cast to PackoutGoodMaterial all packaging recipe materials
   * updating afterQuantity
   * @returns {Array}
   */
  get packagingMaterials() {
    return this.packagingRecipeMaterials.reduce(
      (materials: any[], material: any) => {
        for (let lot of this.packagingMaterialLots[material.item_id] || []) {
          let afterQuantity: number = lot.on_hand - material.amount;
          materials.push(
            new PackoutGoodMaterial(
              {
                lot_id: lot.uuid,
                lot: lot,
                before_quantity: lot.on_hand,
                packout_good_id: this.currentGood.uuid,
                after_quantity: afterQuantity > 0 ? afterQuantity : 0,
                type: material.category.name,
                status: "complete",
              },
              this.$store
            )
          );
          if (afterQuantity < 0) {
            material.amount = Math.abs(afterQuantity);
          } else {
            break;
          }
        }
        return materials;
      },
      []
    );
  }

  /**
   * Returns text color based upon matching rel_lots to scannedCaseLots
   * @returns {string}
   */
  get scannedCaseAmountMsgColor() {
    return this.inventoryTransactions !== this.filterRelLots.length
      ? "color: #FF0000"
      : "color: #000000";
  }

  /**
   * Returns number of current packout good's inventory transactions
   * Filter out lot created on lot header creation
   * @returns {number}
   */
  get inventoryTransactions() {
    return (
      get(this.$store, "state.packout.confirmedCaseCount.sum.amount", 0) || 0
    );
  }

  /**
   * Returns msg of how many label created vs how many labels scanned
   * @returns {string}
   */
  get scannedCaseAmountMsg() {
    return `${this.inventoryTransactions} OF ${this.filterRelLots.length} CASES SCANNED`;
  }

  /**
   * Returns a scannedCaseLots amount and packout item name
   * @returns {string}
   */
  get itemName() {
    return `${this.inventoryTransactions}x ${this.currentGood.inventory_item.name}`;
  }

  /**
   * Returns a display version array of packout lines
   * @returns {Array}
   */
  get mappedLines() {
    let lines: any = [];
    if (this.currentGood.packout_lines) {
      for (let pl of this.currentGood.packout_lines) {
        // Default actual to allocated, if it's not set
        if (!pl.actual_quantity) {
          pl.actual_quantity = pl.allocated;
        }
        lines.push(new PackoutOrderLine(pl).getDisplayVersion());
      }
    }
    return lines;
  }

  /**
   * Returns array of actions depending on current state
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: "close-destroy-all-dialog",
        label: "Dismiss",
      },
    ];
    // Hide close button if loading
    if (this.isLoading) actions = actions.slice(1);
    return actions;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("actions", { immediate: true })
  emitActions() {
    this.$emit("update-packed-all-actions", this.actions);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  events: any = [
    {
      name: "save-good",
      function: this.saveGoodMaterials,
    },
  ];

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  markAsCompleted() {
    this.$emit("markAsCompleted", true);
  }

  async saveGoodMaterials() {
    this.isLoading = true;
    for (let mat of this.packagingMaterials) {
      await this.$store.dispatch("packout/saveGoodMaterial", {
        material: mat.getSaveVersion(),
      });
    }
    await this.$store.dispatch("packout/retrieveCurrentPackoutGood", {
      uuid: this.currentGood.uuid,
    });
    this.save();
  }

  /**
   * Dispatches `savePackoutGood` in the store. Emits message upon
   * success or failure.
   */
  async save() {
    this.isLoading = true;
    let name: string = this.currentGood.name;
    this.currentGood.quantity = this.inventoryTransactions;
    await this.$store
      .dispatch("packout/savePackoutGood", {
        good: this.currentGood.getCompleteVersion(this.mappedLines),
      })
      .then((success) => {
        this.markAsCompleted();
        EventBus.$emit("close-packed-all-dialog");
        emitNotification({
          priority: "low",
          message: `Saved ${name}`,
          title: "Success",
          type: "success",
        });
        this.upsertPackoutGoods(this.currentGood.uuid);
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to save ${name}. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });
    this.isLoading = false;
  }
}
