
import { Vue, Component } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import { CropOrderDetailType } from "@eaua/model";

@Component
export default class GlobalMixin extends Vue {
  renderToken = "";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get currentFacilityId(): string {
    return this.$store.state.facilities.currentFacilityUuid || "";
  }

  get currentUserId(): string {
    return this.$store.state.user.profile.uuid || "";
  }

  get isAdmin(): boolean {
    return this.$store.getters["user/getIsAdmin"];
  }

  get isDev(): boolean {
    return this.$store.getters["user/getIsDev"];
  }

  get isResearcher(): boolean {
    return this.$store.getters["user/getIsResearcher"];
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  generateRenderToken(): void {
    let token: number = Math.floor(Math.random() * 1000000000) + 1;
    this.renderToken = token.toString();
  }

  getDetailType(value = ""): CropOrderDetailType {
    return new CropOrderDetailType(
      this.$store.getters["stages/getOrderDetailTypeByValue"](value)
    );
  }

  getIcon(category: string, name: string): string {
    return getIcon(category, name);
  }
}
