
import ButtonOptions from "@/types/app/shared/ButtonOptions";
import { ICON_DEFINITIONS } from "@/util/icons";
import { Component, Vue } from "vue-property-decorator";
import ViewTitle from "../components/title.vue";
import MainMenu from "../components/menu.vue";

@Component({
  components: {
    ViewTitle,
    MainMenu,
  },
})
export default class Home extends Vue {}
