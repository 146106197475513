
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import { PackoutGood } from "@eaua/model";
import { required } from "vuelidate/lib/validators";
import { emitNotification } from "@/util/notifications";

@Component({
  validations: {
    batchSize: { required },
  },
})
export default class SetBatchSizeForm extends Mixins(Form) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  batchSize: number = 0;
  overrideBatchSize: boolean = false;
  isLoading: boolean = false;

  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "set-batch-size-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  @Prop({ default: 0 })
  totalConsumed!: number;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-set-batch-size-dialog-${this.dialogId}`,
        label: "Cancel",
      },
      {
        callback: `save-batch-size`,
        label: "Set",
        disabled: this.saveDisabled,
        loading: this.isLoading,
      },
    ];
  }

  // Must be less than packout order, and not 0.
  get isValidBatchSize(): boolean {
    return (
      this.batchSize <= this.packoutGood.quantity - this.totalConsumed &&
      this.batchSize > 0
    );
  }

  get packoutGood(): PackoutGood {
    return this.$store.state.packout.currentGood;
  }

  /**
   * Returns a validation message for the current lot
   */
  get batchSizeValidationMessage(): string {
    if (
      this.batchSize > 0 &&
      this.batchSize <= this.packoutGood.quantity - this.totalConsumed
    ) {
      return "Valid batch size";
    } else if (
      this.batchSize < 0 ||
      this.batchSize > this.packoutGood.quantity - this.totalConsumed
    ) {
      return (
        "WARNING: Batch size cannot exceed the remaining " +
        (this.packoutGood.quantity - this.totalConsumed) +
        " oz(s) needed to complete this, or set to be less than 1."
      );
    }
    return "";
  }

  get saveDisabled() {
    if (this.batchSize <= 0) return true;
    if (!this.isValidBatchSize && this.overrideBatchSize == false) return true;
    return false;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: Array<IEventBusEvent> = [
    {
      name: "save-batch-size",
      function: this.saveBatchSize,
    },
  ];

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async saveBatchSize() {
    this.isLoading = true;
    let saveGood: any = new PackoutGood(this.packoutGood);
    saveGood.batch_size = this.batchSize;
    await this.$store
      .dispatch("packout/savePackoutGood", {
        good: saveGood.getSaveVersion(),
      })
      .then((success) => {
        this.retrievePackoutGood();
        EventBus.$emit(`close-set-batch-size-dialog-${this.dialogId}`);
        emitNotification({
          priority: "low",
          message: `Updated Batch Size`,
          title: "Success",
          type: "success",
        });
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to updated batch size`,
          title: "Error",
          type: "error",
        });
      });
    this.isLoading = false;
  }

  async retrievePackoutGood(): Promise<void> {
    await this.$store.dispatch("packout/retrieveCurrentPackoutGood", {
      uuid: this.packoutGood.uuid,
    });
  }
}
