import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const packoutEntryRoutes: RouteConfig = {
  path: "/packout-entry",
  component: () => import("@/modules/packout-entry/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/packout-entry/views/Landing.vue"),
      name: "PackoutEntry",
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("packoutentry");
    next();
  },
};
