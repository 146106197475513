import BaseModel from "@/modules/core/models/Base";
import { CropOrder } from "@eaua/model";
import get from "lodash/get";
import { decimal, required, integer, minValue } from "vuelidate/lib/validators";
import { store } from "../../store";

export default class Plot extends BaseModel {
  table = "shared_facility_plots";
  view = "shared_facility_plots_vw";
  primaryKey = "facility_plots_pkey";

  properties = {
    uuid: "",
    name: "",
    area: null,
    area_unit: "sqft",
    zone_id: "",
    active_crop_id: null,
    active: true,
    created: null,
    created_by: null,
    line_id: null,
    line_position: null,
    max_trays: null,
    allow_multiple_orders: false,
  };

  relationships = {
    rel_line: {},
    rel_zone: {},
    rel_active_crop_order: {},
    rel_crop_order_plots: [],
  };

  viewProperties = {
    zone_name: "",
    facility_name: "",
    line_name: null,
    module_name: null,
    active_crop_orders: [],
    tray_count: 0,
    tray_space_available: -1,
  };

  queryReturnWithOrder = "";

  // Derived properties
  zone: any = {};
  orders: any = [];
  orderCount = 0;
  status = "open";

  constructor(values: any = {}, store: any = {}) {
    super();
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    this.queryReturn += `
      rel_crop_order_plots(where: {removed: {_is_null: true}}) {
        rel_order {
          uuid
        }
      }
    `;

    this.queryReturnWithOrder = `
      ${this.getQueryReturnTemplate}
      rel_active_crop_order {
        ${new CropOrder().queryReturn}
      }
      rel_crop_order_plots(where: {removed: {_is_null: true}}) {
        rel_order {
          ${new CropOrder().queryReturn}
        }
      }
    `;

    this.validations.zone_id = { required };
    this.validations.name = { required };
    this.validations.area = { decimal };
    this.validations.max_trays = { integer, minValue: minValue(1) };

    // Set derived properties
    this.setZone();
    this.setOrders();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------
  /**
   * Sets this plot's parent zone object
   */
  setZone() {
    if (!this.zone_id) return;
    this.zone = store.getters["facilities/getZoneByUuid"](this.zone_id);
  }

  setOrders() {
    if (this.rel_crop_order_plots) {
      this.orders = this.rel_crop_order_plots.map((op: any) => op.rel_order);
    } else if (this.active_crop_orders) {
      this.orders = this.active_crop_orders;
    }
    this.orderCount = this.orders.length;
    if (this.orderCount > 0) this.status = "occupied";
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  getDisplayVersion() {
    let values: any = super.getDisplayVersion();
    values.zoneName = this.zone_name || get(this.zone, "name", "-");
    values.display_area = `${this.area} ${this.area_unit}`;
    for (let property in this.viewProperties) {
      if (Object.prototype.hasOwnProperty.call(this, property)) {
        values[property] = this[property];
      }
    }
    values.orderCount = this.orderCount;
    values.orders = this.orders;
    values.allowMultiple = this.allow_multiple_orders ? "Yes" : "No";
    values.display_max_trays = values.max_trays ? values.max_trays : "Any";
    return values;
  }

  getSaveVersion() {
    let values: any = super.getSaveVersion();
    values.name = values.name.trim();
    if (!values.line_id) delete values.line_id;
    if (!values.max_trays) delete values.max_trays;
    return values;
  }
}
