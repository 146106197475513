
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";
@Component
export default class PackedAllDialog extends Mixins(Dialog) {
  [key: string]: any;
  // LOCAL VARIABLES -----------------------------------------------------------
  createdCaseCount = 0;

  @Prop({ default: true })
  noGoodSelected!: any;
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-packed-all-dialog");
  }
  @Watch("noGoodSelected", { immediate: true })
  onWatchNoGoodSelected() {
    if (this.noGoodSelected === true) {
      EventBus.$emit("close-packed-all-dialog");
    }
  }
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  markAsCompleted() {
    this.$emit("markAsCompleted", true);
  }
  initialize(values: any = {}) {
    this.createdCaseCount = values.createdCaseCount;
  }
}
