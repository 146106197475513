
import { Vue, Component, Prop } from "vue-property-decorator";
import { generateUniqueKey } from "@/util/helpers";
import isEmpty from "lodash/isEmpty";
import { cloneDeep } from "lodash";

@Component
export default class BaseCard extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  subtitle!: string;

  @Prop({ default: () => [] })
  actions!: any;

  @Prop({ default: false })
  hover!: boolean;

  @Prop({ default: false })
  selectable!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: "" })
  img!: string;

  @Prop({ default: "" })
  titleTextColor!: string;

  @Prop({ default: false })
  removeContentPadding!: boolean;

  @Prop({ default: () => {} })
  cardProps!: any;

  @Prop({ type: Boolean, default: false })
  centeredTitle!: boolean;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns classes for card content
   * @returns {string}
   */
  get contentClass() {
    let out = "flex align-start justify-end";
    if (this.removeContentPadding) out += " pa-0";
    return out;
  }

  /**
   * Returns true if actions array is not empty
   * @returns {boolean}
   */
  get hasActions() {
    return !isEmpty(this.actions);
  }

  /**
   * Returns true if actions slot is passed
   * @returns {boolean}
   */
  get hasActionsSlot() {
    return !!this.$slots.actions;
  }

  /**
   * Returns true if actions slot is passed
   * @returns {boolean}
   */
  get hasTitleActionsSlot() {
    return !!this.$slots["card-title-action"];
  }

  /**
   * Returns true if content slot is passed
   * @returns {boolean}
   */
  get hasContentSlot() {
    return !!this.$slots["card-content"];
  }

  /**
   * Returns true if title is not empty
   * @returns {boolean}
   */
  get hasTitle() {
    return !isEmpty(this.title);
  }

  /**
   * Returns true if title slot is passed
   * @returns {boolean}
   */
  get hasTitleSlot() {
    return !!this.$slots["title"];
  }

  /**
   * Returns true if subtitle is not empty
   * @returns {boolean}
   */
  get hasSubtitle() {
    return !isEmpty(this.subtitle);
  }

  /**
   * Returns true if subtitle slot is passed
   * @returns {boolean}
   */
  get hasSubtitleSlot() {
    return !!this.$slots["subtitle"];
  }

  /**
   * Returns true if image is not empty
   * @returns {boolean}
   */
  get hasImage() {
    return !isEmpty(this.img);
  }

  /**
   * Returns true if image slot is passed
   * @returns {boolean}
   */
  get hasImageSlot() {
    return !!this.$slots["image"];
  }

  /**
   * Returns true if the title color is a hex value
   * @returns {boolean}
   */
  get isTitleColorHex() {
    return !!this.titleTextColor.match(/^#[0-9A-F]{6}$/i);
  }

  /**
   * Returns a text styling class if the title text color is set
   * @returns {string}
   */
  get titleColorClass() {
    if (!this.titleTextColor || this.isTitleColorHex) return "";
    else return ` ${this.titleTextColor}--text text--darken-1`;
  }

  /**
   * Returns a text styling class if the title text color is set
   * @returns {string}
   */
  get titleColorStyle() {
    return this.isTitleColorHex ? `color: ${this.titleTextColor};` : "";
  }

  get headerClass(): string {
    let headerClass = "flex align-start pt-0 pl-3 pb-0 pr-4";
    if (this.hasContentSlot) headerClass += " shrink";
    else headerClass += " no-border";
    if (this.centeredTitle) headerClass += " text-center";
    return headerClass;
  }

  get renderProps() {
    let props: any = cloneDeep(this.cardProps);
    if (this.hover) this.cardProps.hover = this.hover;
    if (this.outlined) this.cardProps.outlined = this.outlined;
    return props;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
