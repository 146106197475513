import BaseModel from "../../Base";
import Location from "./Location";
import { required } from "vuelidate/lib/validators";
import get from "lodash/get";
export default class LocationRoom extends BaseModel {
  table = "growops_inventory_location_rooms";
  primaryKey = "inventory_location_rooms_pkey";

  properties = {
    uuid: "",
    name: "",
    facility_id: "",
  };

  relationships = {
    rel_facility: {},
    rel_locations: [],
  };

  derivedProperties = {
    facilityName: "",
    locationCount: 0,
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add locations to the query return
    this.queryReturn += `
      rel_locations {
        ${new Location().queryReturn}
      },
      rel_facility {
        name
      }
    `;

    this.validations.name = { required };
    this.validations.facility_id = { required };

    this.setFacilityName();
    this.setLocationCount();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  setFacilityName() {
    this.facilityName = get(this.rel_facility, "name", "");
  }

  setLocationCount() {
    this.locationCount = (this.rel_locations || []).length;
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Location Room
   * @returns {Object}
   */
  getDisplayVersion(): object {
    let values: any = super.getDisplayVersion();
    return values;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion() {
    let values: any = super.getSaveVersion();
    if (!values.facility_id) values.facility_id = this.currentFacilityId;
    return values;
  }
}
