
import { Component, Mixins } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";

@Component
export default class ConsumedLotsDialog extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "consumed-lots-actions";

  actions: Array<any> = [
    {
      callback: "close-consumed-lots-dialog",
      label: "Dismiss",
    },
  ];

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-consumed-lots-dialog");
  }
}
