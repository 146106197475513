
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { BomMaterial } from "@eaua/model";

@Component
export default class ConsumedLotsDialog extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "consumed-lots-actions";
  scoped = true;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-consumed-lots-dialog");
  }
}
