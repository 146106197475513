
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { Bom, PackoutGood, PackoutMaterial } from "@eaua/model";
import find from "lodash/find";
import round from "lodash/round";
import sumBy from "lodash/sumBy";
import { clone, cloneDeep, get } from "lodash";
import { IEventBusEvent, setListeners, unsetListeners } from "@/util/eventbus";

@Component
export default class MixingBatchSummary extends Vue {
  // PROPS ---------------------------------------------------------------------

  @Prop({ default: "" })
  batchId!: string;

  @Prop({ default: "Current Batch" })
  title!: string;

  // LOCAL VARIABLES -----------------------------------------------------------

  setSizeButton: any = {
    callback: "show-set-batch-size-dialog",
    label: "Change Size",
    outlined: true,
    small: true,
  };

  materiaLotCount: number = 0;
  batchLotCount: number = 0;
  availableBatchSizeCount: number = 0;

  // ---------------------------------------------------------------------------
  // REFS
  // ---------------------------------------------------------------------------

  @Ref("set-batch-size-dialog")
  readonly batchSizeDialog!: any;

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: Array<IEventBusEvent> = [
    {
      name: "show-set-batch-size-dialog",
      function: this.showBatchSizeDialog,
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Given a batch id, assume this should display the current batch size.
   * Otherwise, use the packout good quantity
   */
  get batchSize(): number {
    if (this.batchId != "") {
      if (this.packoutGood.batch_size) return this.packoutGood.batch_size || 0;
      else return this.packoutGood.quantity - this.actualAggregate || 0;
    }
    return this.packoutGood.quantity || 0;
  }

  get packoutGoodUom(): string {
    return get(this.packoutGood, "inventory_item.uom.name", "");
  }

  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }

  get consumedAggregate(): number {
    return sumBy(this.materialsList, "consumed");
  }

  get headers(): Array<any> {
    let headers: Array<any> = [
      {
        text: "Item",
        value: "item_name",
      },
      {
        text: "Consumed",
        value: "consumed",
      },
      {
        text: "Target",
        value: "target",
      },
    ];
    // If this is a batch, add a progress bar
    if (this.batchId) {
      headers.push({
        text: "Progress",
        value: "progress",
        type: "progress",
      });
    }
    return headers;
  }

  get totalConsumedCount() {
    let count: number = 0;
    let materials = cloneDeep(this.packoutGood.materials || []);

    for (const m of materials) {
      count += m.before_quantity - m.after_quantity;
    }
    return count;
  }

  get materialsList(): Array<any> {
    let list: any = {};
    let materials = cloneDeep(get(this.packoutGood, "materials", []) || []);
    if (this.batchId) {
      materials = materials.filter((m: any) => m.batch_id === this.batchId);
    }
    for (let bomMat of get(this.bom, "materials", [])) {
      list[bomMat.item_id] = {
        item_name: ` ${bomMat.inventory_item.number} - ${bomMat.inventory_item.name}`,
        consumed: 0,
        target: this.getMaterialQty(bomMat.item_id),
        count: 0,
      };
    }
    if (materials.length > 0) {
      for (const m of materials) {
        const material = new PackoutMaterial(m);
        const itemId: string = m.lot?.item_id || "";
        const consumed: number = m.before_quantity - m.after_quantity;
        const target: number = this.getMaterialQty(itemId);
        // If this item hasn't been added, build it
        if (!list[itemId]) {
          list[itemId] = {
            item_name: material.item.name,
            consumed: consumed,
            target: target,
            progress: (consumed / (target || this.batchSize)) * 100,
            count: 1,
          };
        }
        // If the item's already been added, just increment values
        else {
          list[itemId].count++;
          list[itemId].consumed += consumed;
          list[itemId].progress = (list[itemId].consumed / target) * 100;
        }
      }
    }
    return Object.values(list);
  }
  get lotCount(): number {
    return sumBy(this.materialsList, "count") || 0;
  }

  get packoutGood(): PackoutGood {
    return this.$store.state.packout.currentGood;
  }

  get currentBatch() {
    return this.$store.state.packout.currentGoodBatches[0];
  }

  /**
   * Divides the batch size by the bom quantity to get the overall multiplier.
   * Recipe qty will almost always be 1, but could be different.
   */
  get recipeMultiplier(): number {
    if (!this.bom.quantity) return 0;
    return this.batchSize / this.bom.quantity;
  }

  get remainingAggregate(): number {
    if (this.batchId)
      return +(+this.batchSize - +this.consumedAggregate).toFixed(1);

    return +(+this.batchSize - +this.actualAggregate).toFixed(1);
  }

  get actualAggregate(): number {
    return sumBy(this.$store.state.packout.currentGoodBatches, "actual") || 0;
  }

  // ---------------------------------------------------------------------------
  //  LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Returns the proportional quantity for a given material
   */
  getMaterialQty(itemId: string): number {
    const bomMaterial: any = find(this.bom.materials || [], {
      item_id: itemId,
    });
    if (!bomMaterial) return 0;
    return round(this.recipeMultiplier * bomMaterial.quantity, 4);
  }

  showBatchSizeDialog(): void {
    this.batchSizeDialog.open();
  }
}
