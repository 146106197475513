
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: null })
  value!: any;

  @Prop({ default: () => {} })
  config!: any;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns the color for the progress bar. Can be passed
   * as a string or function for customizable color changes
   */
  get color(): string {
    if (!this.config || !this.config.color) return "primary";
    if (typeof this.config.color === "function") {
      return this.config.color(this.value);
    }
    return this.config.color;
  }

  /**
   * Returns true if the value should be shown after the progress bar.
   * Defaults to true.
   */
  get showValue(): boolean {
    if (this.config.hasOwnProperty("showValue")) {
      return this.config.showValue;
    }
    return true;
  }
}
