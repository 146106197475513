
import { mapState } from "vuex";
import { Vue, Component } from "vue-property-decorator";
import clone from "lodash/clone";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import pullAt from "lodash/pullAt";

const DEFAULT_FILTER = {
  field: "",
  fieldText: "",
  route: "",
  text: "",
  value: "",
  update: false,
  exclude: false,
};

export interface IFilterBase_Filter {
  field: string; // search, user, facility, etc
  fieldText: string; // label to diplay for field
  route: string | null | undefined; // the route name to apply filter, ie "orders"
  text: string; // display text of filter
  value: string; // search value of filter,
  update: boolean; // sets add type
  exclude: boolean; // excludes the value instead of including
  [key: string]: any; // index signature
}

@Component({
  computed: {
    ...mapState("filter", ["filters"]),
  },
})
export default class FilterBase extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Local Variables - Replaces data()
  filters!: any;

  // METHODS ------------------------------------------------------
  addFilter(filter: IFilterBase_Filter) {
    if (!isEmpty(filter)) {
      filter = this.addRoute(filter);
    }

    let filterIndex = this.getFilterIndex(filter);

    if (!isEmpty(filter) && filterIndex < 0) {
      // copy stored filters and add new
      let filters = clone(this.filters);
      filters.push(filter);
      this.$store.commit("filter/setFilters", filters);
    } else if (!isEmpty(filter) && filter.update === true) {
      let filters = clone(this.filters);
      filters[filterIndex] = filter;
      this.$store.commit("filter/setFilters", filters);
    }
  }

  removeFilter(filter: IFilterBase_Filter) {
    if (!isEmpty(filter)) {
      filter = this.addRoute(filter);
    }

    let filterIndex = this.getFilterIndex(filter);

    if (!isEmpty(filter) && filterIndex > -1) {
      // pull stored filter and re-commit to store
      let filters = clone(this.filters);
      pullAt(filters, [filterIndex]);
      this.$store.commit("filter/setFilters", filters);
    }
  }

  clearFilters(field: string, route: string | undefined | null) {
    if (isEmpty(route)) {
      route = this.$route.name;
    }
    // clears filters with matching route name and field
    let filters = filter(this.filters, (f) => {
      if (!isEmpty(field)) {
        return f.route !== route || f.field !== field;
      }
      return f.route !== route;
    });
    this.$store.commit("filter/setFilters", filters);
  }

  getFilterIndex(filter: IFilterBase_Filter) {
    if (filter.update) {
      return findIndex(this.filters, {
        route: filter.route,
        field: filter.field,
      });
    } else {
      return findIndex(this.filters, {
        route: filter.route,
        field: filter.field,
        value: filter.value,
      });
    }
  }

  addRoute(filter: IFilterBase_Filter) {
    if (!Object.prototype.hasOwnProperty.call(filter, "route")) {
      filter.route = this.$route.name;
    }
    return filter;
  }
}
