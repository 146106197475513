
import { mapState } from "vuex";
import { Component, Prop } from "vue-property-decorator";
import isEmpty from "lodash/isEmpty";
import clone from "lodash/clone";
import FilterBase from "./Base.vue";

@Component({
  computed: {
    ...mapState("filter", ["search"]),
  },
})
export default class FilterSearch extends FilterBase {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  searchComponent!: string;

  // MAPPED VARIABLES ----------------------------------------------------------
  search!: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  timeout?: NodeJS.Timeout;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get appendIcon() {
    if (!isEmpty(this.input)) return "mdi-plus-circle";
    return "mdi-magnify";
  }

  get message() {
    if (!isEmpty(this.input)) return "Click (+) or hit Enter to add filter";
    return [];
  }

  get routeName() {
    let route: string = this.$route.name || "";
    if (this.searchComponent) route += `-${this.searchComponent}`;
    return route;
  }

  get input() {
    return this.search[this.routeName];
  }

  set input(value) {
    if (value === null) value = "";
    let input = value.toLowerCase();
    if (!this.search[this.routeName]) this.search[this.routeName] = "";
    this.scheduleSearchUpdate(input);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  scheduleSearchUpdate(input = "") {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (!input) {
      this.setSearch(input);
    } else {
      this.timeout = setTimeout(this.setSearch, 500, input);
    }
  }

  setSearch(input = "") {
    if (input !== this.search[this.routeName]) {
      let search = clone(this.search);
      search[this.routeName] = input;
      this.$store.commit("filter/setSearch", search);
    }
  }

  addToFilters() {
    if (!isEmpty(this.input)) {
      let filter: any = {
        field: "search",
        route: this.routeName,
        value: this.input,
        text: this.input,
      };
      this.addFilter(filter);
      this.input = "";
    }
    let searchField = this.$refs["filter-search"] as HTMLElement;
    searchField.focus();
  }
}
