
import VueComponent from "vue";
import { Vue, Component, Mixins, Ref } from "vue-property-decorator";
import { ICellEditor, ICellEditorParams } from "ag-grid-community";
import isNil from "lodash/isNil";
import Form from "@/modules/core/mixins/Form.vue";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

@Component
export default class AgGridCellEditor
  extends Mixins(Form)
  implements ICellEditor
{
  params!: ICellEditorParams;
  maxValue!: number | Function;
  minValue!: number | Function;
  value: number | null = null;
  cancelBeforeStart = false;
  highlightAllOnFocus = true;
  cursorStart: number | null = 0;
  label = "Input";

  @Ref("input")
  readonly input!: VueComponent;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get valueString() {
    return !isNil(this.value) ? this.value.toString() : "";
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setInitialState(this.params);
  }

  mounted() {
    Vue.nextTick(() => {
      const input: HTMLInputElement | null = this.getInputElement();
      if (input) {
        input.focus();
        if (this.highlightAllOnFocus) {
          input.select();
          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the carot at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the carot
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = input.value ? input.value.length : 0;
          if (length > 0) input.setSelectionRange(length, length);
        }
        input.focus();
      }
    });
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Returns the name of the key pressed
   */
  getKeyFromEvent(event: KeyboardEvent) {
    event = event || window.event;
    return event.key;
  }

  /**
   * Returns the nested input element from v-text-field
   */
  getInputElement() {
    if (this.input) return this.input.$el.querySelector("input");
    return null;
  }

  /**
   * Gets the input label from the column definition
   */
  getLabel() {
    // @ts-ignore Not part of the params spec
    const label: any = this.params.label || "";
    if (typeof label === "function") {
      return label(this.params);
    }
    return label;
  }

  /**
   * Accessor for `this.value`
   */
  getValue() {
    return this.value;
  }

  /**
   * Accessor for `this.cancelBeforeStart`
   */
  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  /**
   * Returns true if the key typed should stop editing.
   * Set to `Enter` or `Tab` key
   */
  isKeyStopEvent(event: KeyboardEvent): boolean {
    return ["Enter", "Tab"].includes(this.getKeyFromEvent(event));
  }

  /**
   * Sets the initial value for the input
   */
  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;
    this.label = this.getLabel();

    if ([KEY_BACKSPACE, KEY_DELETE].includes(params.keyPress || 0)) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (params.charPress) {
      // if a character was pressed, we start with the character
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (params.keyPress === KEY_F2) highlightAllOnFocus = false;
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }
}
