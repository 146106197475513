
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";
import { cloneDeep, isEmpty, isObject } from "lodash";

const BUTTON_DEFAULTS = {
  badge: "",
  block: false,
  callback: "",
  callbackParams: {},
  callbackType: "global",
  color: "primary",
  class: "",
  dark: false,
  disabled: false,
  text: true,
  icon: "",
  isIcon: false,
  label: "",
  loading: false,
  outlined: false,
  rounded: false,
  small: false,
  large: false,
  to: undefined,
  "min-width": "90px",
};

@Component
export default class BaseActionButton extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: {} })
  value!: any; // Button object passed by parent

  // LOCAL VARIABLES -----------------------------------------------------------
  button: any = { ...BUTTON_DEFAULTS };

  // Button properties that can't be passed via v-bind
  excludeFromBinding: any = [
    "callback",
    "callbackParams",
    "callbackType",
    "isIcon",
    "icon",
    "label",
    "to",
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns true if this button has an icon set
   * @returns {boolean}
   */
  get hasIcon() {
    return !!this.button.icon;
  }

  get renderBadge() {
    if (!this.value.badge) return undefined;
    let value: any = {};
    if (isObject(this.value.badge)) value = { ...this.value.badge };
    else value.content = this.value.badge;
    value.overlap = true;
    value.class = "mr-2";
    return value;
  }

  /**
   * Returns a `to` prop for rendering
   * @returns {any}
   */
  get renderTo() {
    if (this.value && this.value.to) {
      // If there are no callback params, we have nothing to map
      // for the router
      if (!this.value.callbackParams) return this.value.to;
      let to: any = cloneDeep(this.value.to);
      let properties: any = to.props || [];
      // If the prop is a string, there should only be one param
      if (typeof properties === "string") properties = [properties];
      // Find the value for each property and pass it to the router params
      for (const prop of properties) {
        if (!to.params) to.params = {};
        to.params[prop] = this.value.callbackParams[prop];
      }
      delete to.props;
      return to;
    }
    return undefined;
  }

  get renderValue() {
    let binding: any = {};
    for (let property in this.button) {
      // Skip min-width if the button is an icon
      if (property === "min-width" && this.button.isIcon) continue;
      // Check against bound properties list
      if (!this.excludeFromBinding.includes(property)) {
        binding[property] = this.button[property];
      }
    }
    if (this.renderTo) binding.to = this.renderTo;
    binding.icon = this.button.isIcon;
    return binding;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.initialize(this.value);
  }

  // ---------------------------------------------------------------------------
  // WATCHED VALUES
  // ---------------------------------------------------------------------------
  @Watch("value")
  onValueChanged(newValue: any) {
    this.initialize(newValue);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Sets values in this button from a given button object.
   * @param {Object} button
   */
  initialize(button: any = {}) {
    // Resets button to default settings
    this.reset();

    // Exits if button object is empty
    if (isEmpty(button)) return;

    // Loop over and add properties from given button
    for (let property in button) {
      if (typeof button[property] === "function") {
        this.button[property] = button[property]();
        continue;
      }
      this.button[property] = button[property];
    }
  }

  /**
   * Sets all button properties to default values
   */
  reset() {
    this.button = { ...BUTTON_DEFAULTS };
  }

  /**
   * Callback function. Emits callback name and params.
   * @param {string} callback - Name of callback function for emitter
   * @param {any} callbackParams - Params to pass to callback function
   * @param {string} callbackType - Emits to global event bus or self emit to parent
   */
  callback(callback = "", callbackParams: any = {}, callbackType = "global") {
    // Disable the callback if `to` is set
    if (this.renderTo) return;
    if (callback.length === 0) {
      this.$emit("click");
      return;
    }
    // Emits a callback to the parent component
    if (callback && callbackType === "self")
      this.$emit(callback, callbackParams);
    // Emits a global callback
    else if (callback) EventBus.$emit(callback, callbackParams);
  }
}
