import { Store } from "vuex";
import VueRouter from "vue-router";
import { get } from "lodash";

export function createRouter(store: Store<any>): VueRouter {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
  });

  router.beforeEach((to, from, next) => {
    // Get redirect
    if (typeof localStorage !== "undefined") {
      if (!to.path.includes("callback") && to.path !== "/") {
        localStorage.setItem("80Acres-redirect", to.path);
      }
    }

    // If the user profile is loaded, proceed to load the route
    if (store.getters["user/getProfileLoaded"]() === true) {
      proceed(to, next);
    }
    // Otherwise, watch the store for when the user profile is loaded
    else {
      store.watch(store.getters["user/getProfileLoaded"], () => {
        if (store.getters["user/getProfileLoaded"]()) {
          proceed(to, next);
        }
      });
    }

    function proceed(to: any, next: any) {
      // Check app access status
      const adminRequired: boolean = get(to, "meta.admin", false);
      const isAdmin: boolean = store.getters["user/getIsAdmin"];

      if (adminRequired) {
        if (isAdmin) next();
        else next("/");
      } else next();
    }
  });

  return router;
}
