
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Form from "@/modules/core/mixins/Form.vue";
import { get } from "lodash";
import ItemAttribute from "@/modules/core/models/growops/inventory/ItemAttribute";
import { getIcon } from "@/util/icons";

@Component
export default class SpecSheetForm extends Mixins(Form) {
  // MAPPED VARIABLES ----------------------------------------------------------
  attributes!: any;

  //Props
  @Prop({ default: "destination" })
  destination!: string;

  @Prop({ default: "" })
  passedId!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  attribute = new ItemAttribute();
  loadingAttributes: boolean = true;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get categories() {
    return (
      Array.from(
        new Set(
          get(
            this.$store.state.inventory.attributeCategories[0],
            "rel_inventory_item_spec_version.rel_inventory_item_spec_attributes",
            []
          ).map((x: any) =>
            get(
              x,
              "rel_inventory_item_attribute.rel_inventory_item_attribute_categories.name",
              ""
            ).trim()
          )
        )
      ) || []
    );
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  mounted() {
    this.isLoading = false;
    this.retrieveCategories();
  }

  /**
   * Returns the mapped attribute specs and values tied to the parent category
   */
  buildMappedCategories(category: any) {
    return get(
      this.$store.state.inventory.categories[0],
      "rel_inventory_item_spec_version.rel_inventory_item_spec_attributes",
      []
    )
      .filter(
        (nextCategory: any) =>
          get(
            nextCategory.rel_inventory_item_attribute,
            "rel_inventory_item_attribute_categories.name",
            ""
          ).trim() === category
      )
      .map((nextCategory: any) => nextCategory);
  }

  getattributeUom(attribute: any) {
    return get(
      attribute,
      "rel_inventory_item_attribute.rel_inventory_uom.name",
      ""
    );
  }

  /**
   * Tells the store to retrieve the product spec categories/attributes
   */
  async retrieveCategories() {
    await this.$store.dispatch("inventory/retrieveCategories", {
      item_id: this.passedId,
    });
  }

  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
