import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const weighRoutes: RouteConfig = {
  path: "/weigh",
  component: () => import("@/modules/weigh/views/Index.vue"),
  children: [
    {
      path: "",
      name: "WeightLanding",
      component: () => import("@/modules/weigh/views/Landing.vue"),
    },
    {
      path: "/weigh/totes/:plotUuid/:orderUuid",
      name: "Totes",
      props: true,
      component: () => import("@/modules/weigh/views/Totes.vue"),
    },
    {
      path: "/weigh/summary/:taskUuid",
      name: "Summary",
      props: true,
      component: () => import("@/modules/weigh/views/Summary.vue"),
    },
    {
      path: "/weigh/complete/:taskUuid",
      name: "CompleteWeigh",
      props: true,
      component: () => import("@/modules/weigh/views/Complete.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("harvest-weigh");
    next();
  },
};
