
import { Component, Mixins, Watch } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";
import Dialog from "@/modules/core/mixins/Dialog.vue";
import Form from "@/modules/core/mixins/Form.vue";
import maxValue from "@/util/validators/maxValue";
import exclusiveMinValue from "@/util/validators/exclusiveMinValue";

@Component({
  validations: {
    updatedConsumed: {
      maxValue: maxValue("max"),
      minValue: exclusiveMinValue("min"),
    },
  },
})
export default class EditLotConsumed extends Mixins(Dialog, Form) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "edit-lot-consumed-actions";
  passedObject: any = {};
  passedTitle: string = "";
  currentQuantity: number = 0;
  afterQuantity: number = 0;
  lotOnHand: number = 0;
  updatedConsumed: number = 0;
  amountSuffix: string = "";
  packoutGoodUuid: string = "";

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  created() {
    this.setCloseEventName("close-edit-lot-consumed-dialog");
    this.events = [
      {
        name: `close-edit-lot-consumed`,
        function: this.updateAndCloseEditLotConsumed,
      },
    ];
  }

  // ---------------------------------------------------------------------------
  // WATCHERS
  // ---------------------------------------------------------------------------

  @Watch("passedObject")
  watchPassedObject(newVal: any) {
    if (!newVal || !newVal.inventoryItem) return;

    this.amountSuffix = newVal.inventoryItem.uom?.name;

    this.currentQuantity = newVal.packoutMaterialQuantity;

    this.lotOnHand = newVal.lotOnHand;

    this.afterQuantity = newVal.packoutMaterialAfterQuantity;

    this.packoutGoodUuid = newVal.packoutGoodUuid;
  }

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get formActions(): Array<any> {
    return [
      {
        callback: `close-edit-lot-consumed`,
        label: "Update",
        disabled: this.saveDisabled,
      },
    ];
  }

  get saveDisabled() {
    const fallback = true; // might need to be more elaborate
    const validationObject = this.getValidationObject("updatedConsumed");
    return validationObject ? validationObject.$invalid : fallback;
  }

  get calculatedOnHand(): number {
    return this.lotOnHand + this.currentQuantity;
  }

  get min(): number {
    return 0;
  }

  get max(): number {
    return this.calculatedOnHand;
  }

  get updatedConsumedParams(): any {
    return {
      min: this.min,
      minExclusive: true,
      max: this.max,
    };
  }

  async updateAndCloseEditLotConsumed(): Promise<void> {
    const packoutMaterial = this.passedObject.packoutMaterial;

    if (this.currentQuantity !== this.updatedConsumed) {
      const updatedAfterQuantity = this.calculatedOnHand - this.updatedConsumed;

      packoutMaterial.after_quantity = updatedAfterQuantity;
      packoutMaterial.before_quantity = this.calculatedOnHand;

      const packoutMaterialSaveVersion = packoutMaterial.getSaveVersion();

      await this.$store.dispatch("packout/saveGoodMaterial", {
        material: packoutMaterialSaveVersion,
      });

      await this.$store.dispatch("packout/retrieveCurrentPackoutGood", {
        uuid: this.packoutGoodUuid,
      });
    }

    EventBus.$emit(`close-edit-lot-consumed-dialog`);
  }

  initialize(values: any = {}) {
    this.passedObject = values.passedObject;
    this.passedTitle = values.passedTitle;
  }
}
