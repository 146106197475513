
import Dialog from "../../mixins/Dialog.vue";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class MaintenanceModeDialog extends Mixins(Dialog) {
  [key: string]: any;
  // LOCAL VARIABLES -----------------------------------------------------------
  settings: any = {};
  callback: any = () => {};
  scoped = true;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-maintenance-mode-dialog");
  }

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the app name
   * @returns {string}
   */
  get appName() {
    return this.$store.state.app.appName;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize(values: any = {}) {
    this.settings = values.settings || {};
    if (values.callback && typeof values.callback === "function") {
      this.callback = values.callback;
    }
  }
}
