import { set, setDeep, trackScannedUuid } from "@/util/vuex";
import axios from "axios";

const PALLET_STORE_DEFAULTS: { [key: string]: any } = {
  pallets: [],
  currentPallet: {},
  currentPalletLots: [],
  eligibleLots: [],
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = () => {
  let state: any = {};
  for (let property in PALLET_STORE_DEFAULTS) {
    state[property] = PALLET_STORE_DEFAULTS[property];
  }
  return state;
};

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setPallets: setDeep("pallets"),
  setCurrentPallet: set("currentPallet"),
  setEligibleLots: setDeep("eligibleLots"),
  setCurrentPalletLots: setDeep("currentPalletLots"),
  reset(state: any, property: string) {
    state[property] = PALLET_STORE_DEFAULTS[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  getPallets: (state: any) => {
    return state.pallets;
  },
  getCurrentPallet: (state: any) => {
    return state.currentPallet;
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  /**
   * Retrieves inventory pallets that are active for the current facility.
   * Active pallets are pallets that are created in the last 3 days
   * Commits items using `setPallets`.
   * @param context Store module context
   */
  retrieveActivePallets: function (context: any) {
    const facilityId = context.rootState.facilities.currentFacilityUuid;
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/inventory/pallets`, {
          params: {
            facilityId: facilityId,
          },
        })
        .then(
          (success: any) => {
            context.commit("setPallets", { list: success.data });
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves inventory pallet by uuid.
   * Commits item using `setCurrentPallet`.
   * @param uuid passed uuid of selected pallet
   * @param context Store module context
   */
  retrieveCurrentPallet: function (context: any, { uuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/inventory/pallet`, {
          params: {
            palletId: uuid,
          },
        })
        .then(
          (success: any) => {
            context.commit("setCurrentPallet", success.data);
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Returns a list of lots that are eligible to be scanned onto target pallet.
   * @param context Store module context
   * @param targetPalletUuid Target pallet for lots
   */
  retrievePalletLots: function (context: any, { palletUuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots`, {
          params: {
            palletId: palletUuid,
          },
        })
        .then(
          (success: any) => {
            context.commit("setCurrentPalletLots", { list: success.data });
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Returns a list of lots that are eligible to be scanned onto target pallet.
   * @param context Store module context
   * @param targetPalletUuid Target pallet for lots
   */
  retrieveEligibleLots: function (context: any, { targetPalletUuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots/eligible`, {
          params: {
            targetPalletId: targetPalletUuid,
            limit: 1_000,
          },
        })
        .then(
          (success: any) => {
            context.commit("setEligibleLots", { list: success.data });
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Creates a pallet.
   * @param pallet pallet to create
   * @param lotIds list of lot UUIDs to add to new pallet
   * @param context Store module context
   */
  createPallet: function (_context: any, { pallet, lotIds }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/inventory/pallet`, {
          pallet: pallet,
          lotIds: lotIds,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Updates given pallet by uuid.
   * @param pallet pallet to update
   * @param context Store module context
   */
  updatePallet: function (_context: any, { pallet }: any) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.VUE_APP_API_URL}/inventory/pallet`, {
          palletId: pallet.uuid,
          updates: pallet,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Palletizes a lot by uuid.
   * @param palletId uuid of pallet
   * @param lotId uuid of lot to add
   * @param context Store module context
   */
  palletizeLot: function (_context: any, { palletId, lotNumber, lotId }: any) {
    return new Promise((resolve, reject) => {
      try {
        trackScannedUuid(_context, lotId);
      } catch (error: any) {
        reject(error);
        return;
      }

      axios
        .put(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots`, {
          palletId: palletId,
          lotNumber: lotNumber,
          lotId: lotId,
        })
        .then((response: any) => {
          const { success, error, data } = response.data;
          if (success) {
            resolve(data);
          } else {
            trackScannedUuid(_context, lotId, true);
            reject(new Error(error));
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  /**
   * Palletizes lots by uuid.
   * @param palletId uuid of pallet
   * @param lotId uuid of lot to add
   * @param context Store module context
   */
  palletizeLots: function (
    _context: any,
    { palletId, lotIds, lotNumber }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots`, {
          palletId: palletId,
          lotIds: lotIds,
          lotNumber: lotNumber,
        })
        .then((response: any) => {
          const { success, error, data } = response.data;
          if (success) {
            resolve(data);
          } else {
            reject(new Error(error));
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  /**
   * Removes lot from pallet by uuid.
   * @param lotId uuid of lot to remove
   * @param context Store module context
   */
  depalletizeLot: function (_context: any, { lotId, lotIds }: any) {
    return new Promise((resolve, reject) => {
      try {
        trackScannedUuid(_context, lotId, true);
      } catch (error: any) {
        reject(error);
        return;
      }
      axios
        .delete(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots`, {
          data: {
            lotId: lotId,
            lotIds: lotIds,
          },
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            trackScannedUuid(_context, lotId, true);
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Relocates a pallet.
   * @param palletId pallet to relocate
   * @param locationId target location for pallet
   * @param context Store module context
   */
  relocatePallet: function (_context: any, { palletId, locationId }: any) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${process.env.VUE_APP_API_URL}/inventory/pallet/location`, {
          palletId: palletId,
          locationId: locationId,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Checks to see if a split operation will be allowed
   * @param context
   * @param sourcePalletUuid
   * @param targetPalletUuid
   * @param lotIds
   */
  prescreenSplit: function (
    context: any,
    { sourcePalletUuid, targetPalletUuid, lotIds }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/inventory/pallet/split/prescreen`,
          {
            sourcePalletId: sourcePalletUuid,
            targetPalletId: targetPalletUuid,
            lotIds: lotIds,
          }
        )
        .then(
          (success: any) => {
            resolve(success.data);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Splits lots from source pallet into target pallet
   * @param context
   * @param targetPalletUuid
   * @param lotIds
   */
  splitPallet: function (
    context: any,
    { sourcePalletUuid, targetPalletUuid, lotIds }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/inventory/pallet/split`, {
          sourcePalletId: sourcePalletUuid,
          targetPalletId: targetPalletUuid,
          lotIds: lotIds,
        })
        .then(
          (success: any) => {
            resolve(success.data);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Checks to see if a merge operation will be allowed
   * @param context
   * @param targetPalletUuid
   * @param lotIds
   */
  prescreenMerge: function (
    context: any,
    { sourcePalletUuid, targetPalletUuid }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/inventory/pallet/merge/prescreen`,
          {
            sourcePalletId: sourcePalletUuid,
            targetPalletId: targetPalletUuid,
          }
        )
        .then(
          (success: any) => {
            resolve(success.data);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Merges two given pallets.
   * @param sourcePalletId uuid of source pallet
   * @param targetPalletId uuid of target pallet
   * @param context Store module context
   */
  mergePallet: function (
    _context: any,
    { sourcePalletUuid, targetPalletUuid }: any
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/inventory/pallet/merge`, {
          sourcePalletId: sourcePalletUuid,
          targetPalletId: targetPalletUuid,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
