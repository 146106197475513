export class InvalidSocketServerUrl extends Error {
  constructor() {
    super("Invalid value for VUE_APP_SOCKET_SERVER_URL");
    this.name = "InvalidSocketServerUrl";
  }
}

export class SocketNotConnectedError extends Error {
  constructor() {
    super("SocketClient is not connected to the svc-socket service");
    this.name = "SocketNotConnectedError";
  }
}

/**
 * The socket is not initialized in the client
 * In the instance the pod would likely be down so we'd have to investigate the cluster.
 *
 * @export
 * @class SocketUninitializedError
 * @typedef {SocketUninitializedError}
 * @extends {Error}
 */
export class SocketUninitializedError extends Error {
  constructor() {
    super("SocketClient is not initialized");
    this.name = "SocketUninitializedError";
  }
}
