import axios from "axios";
import { set, setDeep, retrieveObject, retrieveSet } from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import {
  PackoutOrder,
  PackoutGood,
  PackoutOrderLine,
  PackoutBatch,
  PackoutMaterial,
  PackoutBatchLot,
} from "@eaua/model";
import { keyBy, find } from "lodash";
const PACKOUT_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    packouts: [],
    currentPackout: {},
    currentGood: {},
    currentGoods: [],
    goodMaterials: [],
    packoutRefreshInterval: 10000,
    packoutGoods: {},
    currentGoodUuid: "",
    currentGoodBatches: [],
    currentWorkingBatch: {},
    currentMaterialUUID: "",
    currentWeighGoodBatch: {},
    currentBatchLot: {},
    batchLotList: [],
    confirmedCaseCount: {},
    currentPalletLots: [],
    inventoryTransactions: [],
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = PACKOUT_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setPackouts: setDeep("packouts"),
  setCurrentPackout: set("currentPackout"),
  setCurrentGood: set("currentGood"),
  setCurrentGoods: setDeep("currentGoods"),
  setCurrentGoodMaterials: setDeep("goodMaterials"),
  setCurrentGoodUuid: set("currentGoodUuid"),
  setCurrentGoodBatches: setDeep("currentGoodBatches"),
  setCurrentWeighGoodBatch: setDeep("currentWeighGoodBatch"),
  setCurrentBatchLot: set("currentBatchLot"),
  setCurrentWorkingBatch: set("currentWorkingBatch"),
  setCurrentMaterialUUID: set("currentMaterialUUID"),
  setInventoryTransactions: set("inventoryTransactions"),
  setBatchLotList: setDeep("batchLotList"),
  setConfirmedCaseCount: set("confirmedCaseCount"),
  setCurrentPalletLots: setDeep("currentPalletLots"),
  setPackoutGoods(state: any, payload: any = {}) {
    state.packoutGoods = keyBy(payload?.list, "uuid");
  },
  reset(state: any, property: string) {
    state[property] = PACKOUT_STORE_DEFAULTS()[property];
  },
  resetStore(state: any) {
    state = PACKOUT_STORE_DEFAULTS();
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  /**
   * Returns filtered packouts with the route `packouts`
   * @returns {Array}
   */
  getFilteredPackouts: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["packouts"] || [];
    let search = rootState.filter.search.packouts || "";
    return filterItems(state.packouts, filters, search);
  },

  /**
   * Returns a single packout by uuid
   * @returns {Object}
   */
  getPackoutByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (uuid) return find(["uuid", uuid], state.packouts);
      return {};
    },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  /**
   * Retrieves packouts.
   * Commits packouts using `setPackouts`.
   * @param context Store module context
   * @param {string} where Where statement to select return
   * @param {object} pagination Pagination object
   * @param {string} sortBy sortBy statement to reorder the return
   * @param {boolean} removeQueryString boolean to use the view or not
   */
  retrievePackouts: function (
    context: any,
    {
      where = "",
      pagination = {},
      sortBy = "",
      removeQueryString = false,
    }: any = {}
  ) {
    let packoutTemplate: PackoutOrder = new PackoutOrder();

    let returnQuery: string = packoutTemplate.getReturnQuery(
      where,
      sortBy,
      removeQueryString
        ? packoutTemplate.queryReturnPagination
        : packoutTemplate.queryReturn,
      pagination,
      removeQueryString
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setPackouts",
      removeQueryString ? packoutTemplate.view : packoutTemplate.table
    );
  },

  retrievePackoutsWithDateRange: function (
    context: any,
    { facilityId, startDate, endDate }: any
  ) {
    let packoutTemplate: PackoutOrder = new PackoutOrder();
    let returnQuery: string = packoutTemplate.getReturnQuery(
      `{ facility_id: { _eq: "${context.rootState.facilities.currentFacilityUuid}"}, created: {_lt: "${endDate}", _gt: "${startDate}"} }`
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setPackouts",
      packoutTemplate.table
    );
  },

  /**
   * Retrieves a packout order given its uuid.
   * Commits packout using `setCurrentPackout`.
   * @param context Store module context
   * @param {string} uuid Packout UUID
   */
  retrieveCurrentPackout: function (context: any, { uuid }: any) {
    if (!uuid) return;
    let packoutTemplate: PackoutOrder = new PackoutOrder();
    let returnQuery: string = packoutTemplate.getReturnQuery(
      `{ uuid: { _eq: "${uuid}" } }`
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentPackout",
      packoutTemplate.table
    );
  },

  retrievePackoutTransactions: async function (
    context: any,
    { originator_id, limit }: any
  ) {
    if (!originator_id) return;
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            `/inventory/retrieveJournalTransactions`,
          {
            originator_id: originator_id,
            limit: limit || "",
          }
        )
        .then(
          (success: any) => {
            context.commit("setInventoryTransactions", success.data);
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves goods tied to a packout ID.
   * Commits packout using `setCurrentGoods`.
   * @param context Store module context
   * @param {string} uuid Packout UUID
   */

  retrieveCurrentPackoutGoods: function (context: any, { uuid }: any) {
    let goodTemplate = new PackoutGood();
    let returnQuery: string = goodTemplate.getReturnQuery(
      `{
          packout_id: { _eq: "${uuid}"}
        }`
    );

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setCurrentGoods",
      goodTemplate.table
    );
  },

  /**
   * Saves a packout good.
   * Refreshes the parent packout using `retrieveCurrentPackout`.
   * @param context Store module context
   * @param {Object} good Packout good save-version object
   */
  savePackoutGood: function (
    context: any,
    { good, removeMaterials = [] }: any
  ) {
    let goodTemplate = new PackoutGood();
    let upsertQuery: string = goodTemplate.getUpsertQuery(removeMaterials);
    if (!upsertQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: good,
          },
        })
        .then(
          (success: any) => {
            // Refreshes the current packout
            context.dispatch("retrieveCurrentPackout", {
              uuid: good.packout_id,
            });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a batch of packout goods.
   * Refreshes the parent packout using `retrieveCurrentPackout`.
   * @param context Store module context
   * @param {Object} good Packout good save-version object
   */
  savePackoutGoods: function (
    context: any,
    { goods, removeMaterials = [] }: any
  ) {
    let goodTemplate = new PackoutGood();
    let upsertQuery: string = goodTemplate.getUpsertQuery(removeMaterials);
    if (!upsertQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: goods,
          },
        })
        .then(
          (success: any) => {
            // Refreshes the current packout
            context.dispatch("retrieveCurrentPackout", {
              uuid: goods[0].packout_id,
            });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Deletes a packout line.
   * Refreshes the parent packout using `retrieveCurrentPackout`.
   * @param context Store module context
   * @param {string} uuid Packout line uuid
   * @param {string} good Packout good
   */
  deletePackoutLine: function (context: any, { uuid, good }: any) {
    if (!uuid) return;
    // If this is the last line for a packout good, remove the good too
    let inject: string = "";
    if (good.packout_lines.length === 1) {
      if (good.materials && good.materials.length > 0) {
        inject += `
          delete_growops_packout_good_materials (
            where: { packout_good_id: {_eq: "${good.uuid}"}}
          ) {
            affected_rows
          }
        `;
      }
      inject += `
        delete_growops_packout_goods (where: { uuid: {_eq: "${good.uuid}"}}) {
          affected_rows
        }
      `;
    }
    let lineTemplate = new PackoutOrderLine();
    let deleteQuery: string = lineTemplate.getDeleteQuery(
      `{ uuid: { _eq: "${uuid}" } }`,
      inject
    );
    if (!deleteQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: deleteQuery,
        })
        .then(
          (success: any) => {
            // Refreshes the current packout
            context.dispatch("retrieveCurrentPackout", {
              uuid: good.packout_id,
            });
            resolve();
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Deletes a packout line.
   * Refreshes the parent packout using `retrieveCurrentPackout`.
   * @param context Store module context
   * @param {string} uuid Packout line uuid
   * @param {string} good Packout good
   */
  deletePackoutGood: function (context: any, { uuid }: any) {
    if (!uuid) return;
    let goodTemplate = new PackoutGood();
    let deleteQuery: string = goodTemplate.getDeleteQuery(
      `{ uuid: { _eq: "${uuid}" } }`
    );
    if (!deleteQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: deleteQuery,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  retrievePackoutGoodsWithDateRange: function (
    context: any,
    { startDate, endDate, type = null }: any
  ) {
    const template = new PackoutGood();
    // Filter goods by their parent packout's created date
    let where: any = {
      rel_packout_order: {
        facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
        created: {
          _lt: endDate,
          _gt: startDate,
        },
        type: { _nin: ["mix", "item"] },
      },
      completed: { _is_null: true },
    };
    // If the type is mix, we want to change the packout type returned
    if (type === "mix") {
      where.rel_packout_order.type = { _eq: "mix" };
    }
    const returnQuery: string = template.getReturnQuery("$where");

    return retrieveSet(
      context,
      returnQuery,
      "setPackoutGoods",
      template.table,
      { where: where }
    );
  },

  /**
   * Retrieves packout goods from a packout.
   * Commits the list of packout goods using `setPackoutGoods`.
   * @param context Store module context
   * @param {string} packoutUuid Packout uuid
   */
  retrievePackoutGoodsFromPackout: function (
    context: any,
    { packoutUuid }: any
  ) {
    const template = new PackoutGood();
    // Filter goods by their parent packout's created date
    let where: any = {
      packout_id: { _eq: packoutUuid },
      completed: { _is_null: true },
    };
    const returnQuery: string = template.getReturnQuery("$where");

    return retrieveSet(
      context,
      returnQuery,
      "setPackoutGoods",
      template.table,
      { where: where }
    );
  },

  /**
   * Retrieves a packout order given its uuid.
   * Commits packout using `setCurrentGood`.
   * @param context Store module context
   * @param {string} uuid Packout UUID
   */
  retrieveCurrentPackoutGood: function (context: any, { uuid }: any) {
    let goodTemplate = new PackoutGood();
    let retrieveQuery: string = goodTemplate.getReturnQuery(
      `{
        uuid: { _eq: "${uuid}"}
      }`
    );

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: retrieveQuery,
        })
        .then(
          (success: any) => {
            context.commit(
              "setCurrentGood",
              success.data.data.growops_packout_goods[0]
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Returns a list of lots that are eligible to be scanned onto target pallet.
   * @param context Store module context
   * @param targetPalletUuid Target pallet for lots
   */
  retrievePalletLots: function (context: any, { palletUuid }: any) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/inventory/pallet/lots`, {
          params: {
            palletId: palletUuid,
          },
        })
        .then(
          (success: any) => {
            context.commit("setCurrentPalletLots", { list: success.data });
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves a packout order given its uuid.
   * Commits packout using `setConfirmedCaseCount`.
   * @param context Store module context
   * @param {string} uuid Packout UUID
   */
  retrieveConfirmedCaseCount: function (
    context: any,
    { originatorId, headerId }: any
  ) {
    if (!originatorId || !headerId) return;
    let returnQuery: string = `query retrieveConfirmedCaseCount {
      growops_inventory_journal_aggregate(
        where: {
          originator_id: {_eq: "${originatorId}"},
          type: {_in: ["PCK", "UPCK"]},
          amount: {_in:[1, -1]},
          inventory_lot: {
            lot_header_id: {_eq: "${headerId}"}
          }
        }
      ) {
        aggregate {
          sum {
            amount
          }
          min {

            created

          }
        }
      }
    }`;

    if (!returnQuery) return;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: returnQuery,
        })
        .then(
          (success: any) => {
            context.commit(
              "setConfirmedCaseCount",
              success.data.data["growops_inventory_journal_aggregate"][
                "aggregate"
              ]
            );
            resolve();
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  retrieveCurrentPackoutGoodBatches: function (context: any, { uuid }: any) {
    let batchesTemplate = new PackoutBatch();
    let returnQuery: string = batchesTemplate.getReturnQuery(
      `{
        packout_good_id: { _eq: "${uuid}"},

      },
      order_by: { created: desc }`
    );

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setCurrentGoodBatches",
      batchesTemplate.table
    );
  },

  retrieveCurrentPackoutGoodBatch: function (context: any, { uuid }: any) {
    let batchesTemplate = new PackoutBatch();
    let returnQuery: string = batchesTemplate.getReturnQuery(
      `{
        uuid: { _eq: "${uuid}"},

      },
      order_by: { created: desc }`
    );

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setCurrentWeighGoodBatch",
      batchesTemplate.table
    );
  },

  /**
   * Saves a packout good material.
   * Refreshes the parent packout using `retrieveCurrentPackout`.
   * @param context Store module context
   * @param {Object} good Packout good save-version object
   */
  saveGoodMaterial: function (context: any, { material }: any) {
    let goodMaterialTemplate = new PackoutMaterial();
    let upsertQuery: string = goodMaterialTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: material,
          },
        })
        .then(
          (success: any) => {
            // Refreshes the current packout
            context.commit(
              "setCurrentMaterialUUID",
              success.data.data.insert_growops_packout_good_materials
                .returning[0].uuid
            );
            resolve();
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a batchlot.
   * @param context Store module context
   */
  saveBatchLot: function (context: any, { batchLot }: any) {
    let batchLotTemplate = new PackoutBatchLot();
    let upsertQuery: string = batchLotTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: batchLot,
          },
        })
        .then(
          (success: any) => {
            context.commit(
              "setCurrentBatchLot",
              success.data.data.insert_growops_packout_good_batch_lots
                .returning[0].rel_inventory_lot
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieces a list of batch lots given uuid.
   * @param context Store module context
   */
  retrieveBatchLotList: function (context: any, { batchId }: any) {
    let batchLotTemplate = new PackoutBatchLot();
    let returnQuery: string = batchLotTemplate.getReturnQuery(
      `{
        batch_id: { _eq: "${batchId}"},

      },
      `
    );

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setBatchLotList",
      batchLotTemplate.table
    );
  },

  createGoodMaterialBatch: function (context: any, { batch }: any) {
    let goodMaterialBatchTemplate = new PackoutBatch();
    let upsertQuery: string = goodMaterialBatchTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: batch,
          },
        })
        .then(
          (success: any) => {
            // Set current batch id
            context.commit(
              "setCurrentWorkingBatch",
              success.data.data.insert_growops_packout_good_batches.returning[0]
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  updateGoodMaterialBatch: function (context: any, { batch }: any) {
    let goodMaterialBatchTemplate = new PackoutBatch();
    let upsertQuery: string = goodMaterialBatchTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: batch,
          },
        })
        .then(
          (success: any) => {
            // Refreshes the current packout
            // context.dispatch("retrieveCurrentPackoutGood");
            resolve();
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  deleteGoodMaterial: function (context: any, { goodMaterial }: any) {
    if (!goodMaterial.uuid) return;
    let goodMaterialTemplate = new PackoutMaterial();
    let deleteQuery: string = goodMaterialTemplate.getDeleteQuery(
      `{ uuid: { _eq: "${goodMaterial.uuid}" } }`
    );
    if (!deleteQuery) return;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: deleteQuery,
        })
        .then(
          (success: any) => {
            //Refreshes the current packout
            resolve();
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
