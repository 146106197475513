import { setDeep } from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import find from "lodash/fp/find";
import axios from "axios";
import { getIcon } from "@/util/icons";

const TRANSFER_STAGES: { [key: string]: any } = {
  fullReceive: {
    color: "green",
    value: getIcon("base", "complete"),
    label: "Received All",
  },
  partialReceive: {
    color: "purple",
    value: getIcon("base", "inProgress"),
    label: "Partial Received",
  },
  noneReceive: {
    color: "blue",
    value: getIcon("schedule", "duration"),
    label: "None Received",
  },
  canceled: {
    color: "red",
    value: getIcon("base", "cancel"),
    label: "Reversed",
  },
};
const TRANSFER_STORE_DEFAULTS: { [key: string]: any } = {
  inventoryTransfers: [],
  inventoryTransferHeaders: [],
  inventoryTransferHeader: {},
};
// initial state
const state = () => {
  let state: any = {};
  for (let property in TRANSFER_STORE_DEFAULTS) {
    state[property] = TRANSFER_STORE_DEFAULTS[property];
  }
  return state;
};
// getters
const getters = {
  /**
   * Returns a sales order given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getInventoryTransferByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.inventoryTransfers) || {};
    },
  getFilteredInventoryTransfers: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventorytransfer"] || [];
    let search = rootState.filter.search["inventorytransfer"] || "";
    return filterItems(state.inventoryTransfers, filters, search);
  },
  /**
   * Returns stages object
   */
  getStages: (state: any) => {
    return TRANSFER_STAGES;
  },
  /**
   * Returns stage options for a select menu
   */
  getStageOptions: (state: any) => {
    let options: any = [];
    for (let stage of Object.keys(TRANSFER_STAGES)) {
      let option: any = {
        text: TRANSFER_STAGES[stage].label,
        value: stage,
      };
      options.push(option);
    }
    return options;
  },
};
// mutations
const mutations = {
  setInventoryTransfers: setDeep("inventoryTransfers"),
  setInventoryTransferHeaders: setDeep("inventoryTransferHeaders"),
  setInventoryTransferHeader: setDeep("inventoryTransferHeader"),
  reset(state: any, property: string) {
    state[property] = TRANSFER_STORE_DEFAULTS[property];
  },
};
const actions = {
  archiveTransfer: async function (context: any, { transfer }: any) {
    const request = {
      transferUuid: transfer.uuid,
      archived: transfer.archived,
      archivedBy: transfer.archived_by,
    };
    await axios.post(
      `${process.env.VUE_APP_API_URL}/inventory/archive-transfer`,
      request
    );
    context.dispatch("retrieveInventoryTransferHeaders");
  },

  retrieveInventoryTransfers: async function (context: any) {
    const facilityUuid = context.rootState.facilities.currentFacilityUuid;
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/inventory/get-transfer-headers`,
      { facilityUuid }
    );
    context.commit("setInventoryTransfers", {
      list: response.data,
    });
  },

  retrieveInventoryTransferHeaders: async function (context: any) {
    const facilityUuid = context.rootState.facilities.currentFacilityUuid;
    const response: any = await axios.post(
      `${process.env.VUE_APP_API_URL}/inventory/get-transfer-headers`,
      { facilityUuid }
    );
    context.commit("setInventoryTransferHeaders", {
      list: response.data.growops_inventory_transfers,
    });
  },

  retrieveInventoryTransferHeaderByUuid: async function (
    context: any,
    { uuid }: any
  ) {
    if (!uuid) return;
    const response: any = await axios.post(
      `${process.env.VUE_APP_API_URL}/inventory/get-transfer-header`,
      { uuid }
    );
    context.commit("setInventoryTransferHeader", {
      list: response.data.growops_inventory_transfers,
    });
  },

  saveInventoryTransferHeader: async function (
    context: any,
    { inventoryTransfer }: any
  ) {
    if (inventoryTransfer.hasOwnProperty("uuid") && !inventoryTransfer.uuid) {
      delete inventoryTransfer.uuid;
    }
    const result: any = await axios.post(
      `${process.env.VUE_APP_API_URL}/inventory/save-transfer-header`,
      { inventoryTransfer }
    );
    context.dispatch("retrieveInventoryTransferHeaderByUuid", {
      uuid: result.data.insert_growops_inventory_transfers.returning[0].uuid,
    });
  },
  saveInventoryTransfer: async function (
    context: any,
    { inventoryTransfer }: any
  ) {
    if (inventoryTransfer.hasOwnProperty("uuid") && !inventoryTransfer.uuid) {
      delete inventoryTransfer.uuid;
    }
    await axios.post(`${process.env.VUE_APP_API_URL}/inventory/save-transfer`, {
      inventoryTransfer,
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
