
import Dialog from "../../mixins/Dialog.vue";
import { setListeners, unsetListeners } from "@/util/eventbus";
import { Component, Mixins } from "vue-property-decorator";
import { getIcon } from "@/util/icons";

@Component
export default class MaintenanceModeDialog extends Mixins(Dialog) {
  [key: string]: any;
  // LOCAL VARIABLES -----------------------------------------------------------
  settings: any = {};
  packageVersion = "";
  callback: any = () => {};

  events: any = [
    {
      name: "reload-page",
      function: this.reloadPage,
    },
  ];

  reloadPageButton: any = {
    callback: "reload-page",
    icon: getIcon("base", "reload"),
    label: "Reload page",
    class: "form-append-button mx-0",
  };

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-version-mismatch-dialog");
  }

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns the app name
   * @returns {string}
   */
  get appName() {
    return this.$store.state.app.appName;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize(values: any = {}) {
    this.settings = values.settings || {};
    this.packageVersion = values.packageVersion || "";
    if (values.callback && typeof values.callback === "function") {
      this.callback = values.callback;
    }
  }
  /**
   * Call the browser api to reload the page along with the cache
   */
  reloadPage() {
    window.location.reload();
  }
}
