import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const displayRoutes: RouteConfig = {
  path: "/display",
  component: () => import("@/modules/display/views/Index.vue"),
  children: [
    {
      path: "main",
      component: () => import("@/modules/display/views/Landing.vue"),
      name: "PackoutWIPDisplay",
    },
    {
      path: "recentWips",
      name: "PackroomConsumedLots",
      component: () => import("@/modules/display/views/RecentLots.vue"),
    },
    {
      path: "wipStatus",
      name: "CurrentPackoutWIPList",
      component: () => import("@/modules/display/views/WipSummary.vue"),
    },
    {
      path: "recentFGs",
      name: "PackroomConsumedFGs",
      component: () => import("@/modules/display/views/RecentFGs.vue"),
    },
    {
      path: "fgStatus",
      name: "FinishedGoodStatus",
      component: () => import("@/modules/display/views/FGStatus.vue"),
    },
    {
      path: "fgDetail/:uuid",
      name: "FinishedGoodDetail",
      props: true,
      component: () => import("@/modules/display/views/FGDetail.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("display");
    next();
  },
};
