import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const packoutRoutes: RouteConfig = {
  path: "/packout",
  component: () => import("@/modules/storage/views/Index.vue"),
  children: [
    {
      path: "main",
      component: () => import("@/modules/packout/views/Landing.vue"),
      name: "PackoutMain",
    },
    {
      path: "details/:uuid",
      name: "PackoutDetails",
      props: true,
      component: () => import("@/modules/packout/views/Details.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("packout");
    next();
  },
};
