
import { Component, Mixins } from "vue-property-decorator";
import { required } from "vuelidate/lib/validators";
import { getIcon } from "@/util/icons";
import { emitNotification } from "@/util/notifications";
import { Profile } from "@eaua/model";
import Dialog from "@/modules/core/mixins/Dialog.vue";
import Form from "@/modules/core/mixins/Form.vue";

@Component({
  validations: {
    facilityId: { required },
  },
})
export default class AppFacilitySelector extends Mixins(Dialog, Form) {
  // LOCAL VARIABLES -----------------------------------------------------------
  facilityId: string = "";
  facilityIcon: string = getIcon("facility", "campus");

  // Global events this component watches for and the function triggered
  events: any = [
    {
      name: "update-facility-preferences",
      function: this.updateProfile,
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns an array of facilities for a dropdown menu
   * @returns {Array}
   */
  get facilityOptions() {
    return this.$store.getters["facilities/getFacilityUuidOptions"];
  }

  /**
   * Returns a facility object for the current facility id
   * @returns {Object}
   */
  get selectedFacility() {
    return this.$store.getters["facilities/getFacilityByUuid"](this.facilityId);
  }

  /**
   * Returns the currently loaded profile object
   * @returns {Profile}
   */
  get profile() {
    return new Profile(this.$store.state.user.profile || {});
  }

  /**
   * Returns the current facility uuid
   * @returns {string}
   */
  get currentFacilityUuid() {
    return this.$store.state.facilities.currentFacilityUuid || "";
  }

  /**
   * Returns true if the user has a facility, but not a facility_id
   * @returns {boolean}
   */
  get confirmation() {
    if (this.profile.uuid) {
      return !!this.profile.facility_code && !this.profile.facility_id;
    }
    return false;
  }

  /**
   * Returns true if save should be disabled. If the form hasn't been touched, or
   * if the input facility isn't valid.
   * @returns {boolean}
   */
  get saveDisabled() {
    // Disable save if nothing's been touched
    if (!this.$v.$anyDirty && !this.confirmation) return true;
    // Disable save if validation's pending
    if (this.$v.$pending) return true;
    if (this.isLoading) return true;
    if (this.$v.$anyError) return true;
    return false;
  }

  /**
   * Returns action buttons for the selector dialog. Disables `cancel` if the
   * current user has no set facility.
   * @returns {Array}
   */
  get computedActions() {
    let actions: Array<Object> = [];
    if (this.currentFacilityUuid && !this.confirmation) {
      actions.push({
        callback: "close-facility-selector-dialog",
        label: "Cancel",
      });
    }
    actions.push({
      callback: "update-facility-preferences",
      label: this.confirmation ? "Confirm Facility" : "Update Facility",
      isLoading: this.isLoading,
      disabled: this.saveDisabled,
    });
    return actions;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-facility-selector-dialog");
    this.initialize();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize() {
    this.facilityId = "";
    this.setFacilityId();
  }

  /**
   * Adds facility code and id to the profile details
   * @returns {Object}
   */
  buildProfile() {
    if (!this.profile.details) this.profile.details = {};
    this.profile.details.facility = this.selectedFacility.code;
    this.profile.details.facility_id = this.facilityId;
    return this.profile.getSaveVersion();
  }

  /**
   * Sets the facilityId based on the current profile
   */
  setFacilityId() {
    if (this.profile.uuid && !this.facilityId) {
      // If the profile has a facility id set, just copy it over
      if (this.profile.facility_id) this.facilityId = this.profile.facility_id;
      // If the profile just has a facility code, get the id from
      // the facility with that code
      else if (this.profile.facility_code) {
        let facility: any = this.$store.getters["facilities/getFacilityByCode"](
          this.profile.facility_code
        );
        this.facilityId = facility.uuid;
      }
      this.validateInput("facilityId");
    }
  }

  /**
   * Updates the user profile with new facility. `App.vue` watches for profile
   * changes and will update `currentFacility` in the store.
   */
  async updateProfile() {
    this.$v.$touch();
    if (!this.$v.$anyError && this.facilityId) {
      await this.$store
        .dispatch("user/saveProfile", {
          profile: this.buildProfile(),
        })
        .then((success) => {
          this.close();
          emitNotification({
            priority: "low",
            message: "Updated facility preferences.",
            title: "Success",
            type: "success",
          });
          this.$router.go(0);
        })
        .catch((error) => {
          emitNotification({
            inDialog: true,
            message: `Failed to save facility change. ${error.message}`,
            title: "Error",
            type: "error",
          });
        });
      this.isLoading = false;
    }
  }
}
