import { AppSettings } from "@eaua/model";
import { retrieveObject, set, toggle } from "@/util/vuex";

const DEFAULT_APPNAME = "Kiosk";
export const APP_OPTIONS = [
  {
    text: "GrowOps",
    value: "growops",
  },
  {
    text: "RootOps",
    value: "rootops",
  },
];

const APP_THEMES: any = {
  kiosk: "#00897B",
};

export default {
  namespaced: true,

  state: {
    authenticated: false,
    navDrawer: null,
    notification: {},
    showNotification: false,
    pageLoading: false,
    pageTitle: "",
    appName: DEFAULT_APPNAME,
    appLoading: false,
    selectedApp: "kiosk",
    appSettings: {},
  },

  getters: {
    getTheme: (state: any) => {
      return APP_THEMES[state.selectedApp];
    },
  },

  mutations: {
    setAuthenticated: set("authenticated"),
    setNavDrawer: set("navDrawer"),
    toggleNavDrawer: toggle("navDrawer"),
    setNotification: set("notification"),
    setShowNotification: set("showNotification"),
    setPageLoading: set("pageLoading"),
    setPageTitle: set("pageTitle"),
    setAppName: set("appName"),
    setAppLoading: set("appLoading"),
    setAppSettings: set("appSettings"),
    setSelectedApp: set("selectedApp"),
  },

  actions: {
    /**
     * Retrieves the app settings that determine the status of the application
     * ie. {
     *  name: Kiosk
     *  maintenance_mode: false,
     *  current_version: 2.6.6,
     *  maintenance_message: "Kiosk is under maintenance up to 8pm"
     * }
     */
    retrieveAppSettings: function (context: any) {
      const appSettingsTemplate = new AppSettings();
      const returnQuery = appSettingsTemplate.getReturnQuery(
        `{ name: { _ilike: "%${context.state.appName}%"}}`
      );
      if (!returnQuery) return;

      return retrieveObject(
        context,
        returnQuery,
        "setAppSettings",
        appSettingsTemplate.table
      );
    },
  },
};
