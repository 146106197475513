
import { Component, Mixins } from "vue-property-decorator";
import Notification from "../../mixins/Notification.vue";

@Component
export default class AppNotificationBanner extends Mixins(Notification) {
  created() {
    this.priority = "medium";
  }
}
