
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class KioskHeader extends Vue {
  // ---------------------------------------------------------------------------
  // PROPS
  // ---------------------------------------------------------------------------
  @Prop({ type: String, default: "title" }) title!: string;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get environment() {
    return process.env.VUE_APP_ENV!.toUpperCase();
  }

  get isProd() {
    return this.environment === "PROD";
  }

  goHome() {
    this.$router.push("/");
  }
}
