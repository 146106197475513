
import { Vue, Component, Prop } from "vue-property-decorator";
import { isObject, generateUniqueKey } from "@/util/helpers";
import { iteratorPrintTable } from "@/util/print";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import Printd from "printd";
import VueBarcode from "vue-barcode";

@Component({
  components: {
    barcode: VueBarcode,
  },
})
export default class BaseDataIteratorPrintTable extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  subtitle!: string;

  @Prop({ default: "" })
  description!: string;

  @Prop({ default: () => [] })
  headers!: any;

  @Prop({ default: () => [] })
  items!: any;

  @Prop({ default: () => {} })
  status!: any;

  @Prop({ default: "" })
  itemKey!: string;

  @Prop({ default: "" })
  sortBy!: any;

  @Prop({ default: "" })
  groupBy!: any;

  @Prop({ default: () => {} })
  footer!: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  pagination: any = {
    rowsPerPage: -1,
  };

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns headers array, excluding actions
   * @returns {Array}
   */
  get filteredHeaders() {
    return filter(this.headers, function (h) {
      return !["actions"].includes(h.value);
    });
  }

  /**
   * Returns true if the headers array includes progress
   * @returns {boolean}
   */
  get hasProgress() {
    for (let header of this.headers) {
      if (header.value === "progress") return true;
    }
    return false;
  }

  /**
   * Returns headers array, excluding actions, status, and progress
   * @returns {Array}
   */
  get bodyfilteredHeaders() {
    return filter(this.headers, function (h) {
      return !["actions", "status", "progress"].includes(h.value);
    });
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.initialSort();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Sorts the table by `sortBy` value or title
   */
  initialSort() {
    if (isEmpty(this.headers)) return;
    for (let header of this.headers) {
      // Parse if sortBy is an array
      if (
        Array.isArray(this.sortBy) &&
        this.sortBy.length > 0 &&
        header.value === this.sortBy[0]
      ) {
        this.pagination.sortBy = [header.value];
        if (this.sortBy[1] && this.sortBy[1] === "desc") {
          this.pagination.descending = [true];
        }
        break;
      }
      // Parse if sortBy is a string
      else if (this.sortBy && header.value === this.sortBy) {
        this.pagination.sortBy = [header.value];
        break;
      }
      // Default sort column to the title
      if (header.title) {
        this.pagination.sortBy = [header.value];
      }
    }
  }

  /**
   * Returns true if the status object is not empty, and the given item has a status
   * @returns {boolean}
   */
  hasStatus(item: any = {}) {
    return !isEmpty(this.status) && item.status;
  }

  /**
   * Returns the status object for this item. This object should include:
   * label, color, icon, and value
   * @param {string} value - Status value
   * @returns {Object} Status object
   */
  getStatus(value: any = "") {
    return this.status[value] || null;
  }

  /**
   * Triggers the brower's print dialog
   */
  print() {
    let printd: Printd = new Printd();
    printd.print(this.$refs["print-table"] as HTMLElement, [
      iteratorPrintTable,
    ]);
  }

  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }

  /**
   * Encapsulate isObject helper function for use in template
   */
  isObject(item: any) {
    return isObject(item);
  }
}
