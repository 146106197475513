import { RouteConfig } from "vue-router/types/router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const PalletRoutes: RouteConfig = {
  path: "/palletizer",
  component: () => import("@/modules/inventory/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/palletizer/views/Landing.vue"),
      name: "PalletizerMain",
    },
    {
      path: "details/:uuid",
      component: () => import("@/modules/palletizer/views/Details.vue"),
      name: "PalletizerDetails",
      props: true,
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("palletizer");
    next();
  },
};
