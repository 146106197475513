import { set, setDeep, retrieveSet, retrieveObject } from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import find from "lodash/fp/find";
import uniq from "lodash/uniq";
import axios from "axios";
import { Bom } from "@eaua/model";

const BOM_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    boms: [],
    bomsByItemId: {},
    currentBom: {},
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = BOM_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setBoms: setDeep("boms"),
  setCurrentBom: set("currentBom"),
  setBomsByItemIds(state: any, payload: any) {
    if (!payload) return;
    for (const bom of payload.list || []) {
      if (!bom.item_id) continue;
      state.bomsByItemId[bom.item_id] = bom;
    }
  },
  reset(state: any, property: string) {
    state[property] = BOM_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  getBomByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.boms) || {};
    },

  getBomByItemId:
    (state: any) =>
    (itemId: string = "") => {
      if (!itemId) return {};
      return find({ item_id: itemId }, state.boms) || {};
    },

  getBomByGoodId:
    (state: any) =>
    (goodId: string = "") => {
      if (!goodId) return {};
      return find({ good_id: goodId }, state.boms) || {};
    },

  getBomOptions: (state: any) => {
    return state.boms.map((b: any) => {
      const bom = new Bom(b);
      return {
        text: bom.name,
        value: b.uuid,
      };
    });
  },

  getFilteredBoms: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["boms"] || [];
    let search = rootState.filter.search["boms"] || "";
    return filterItems(state.boms, filters, search);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  archiveRecipe: function (context: any, { recipe }: any) {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation archiveRecipe($set: growops_inventory_recipes_set_input) {
            update_growops_inventory_recipes(where: {uuid: {_eq: "${recipe.uuid}"}}, _set: $set) {
              returning {
                uuid
              }
            }
          }`,
          variables: {
            set: {
              archived: recipe.archived,
              archived_by: recipe.archived_by,
            },
          },
        })
        .then(
          (success: any) => {
            // Refresh deliveries
            context.dispatch("retrieveBoms");
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a bom.
   * Commits saved bom using `setCurrentBom`.
   * @param context Store module context
   * @param {Object} bom Recipe save-version object
   */
  saveBom: function (context: any, { bom }: any) {
    let bomTemplate = new Bom();
    let upsertQuery: string = bomTemplate.getUpsertQuery(bom.uuid || "");
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: bom,
          },
        })
        .then(
          (success: any) => {
            resolve(success.data.data);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves boms.
   * Commits boms using `setBoms`.
   * @param context Store module context
   * @param {string} facilityId Facility UUID
   */
  retrieveBoms: function (context: any) {
    let where: string = `
    { 
      archived: {_is_null: true},
      good_id: {_is_null: true},
      _or: [
        {inventory_item: {archived: {_is_null: true}}},
        {cropByCropId: {archived: {_is_null: true}}}
      ]
    }
    `;
    let bomTemplate = new Bom();
    let returnQuery: string = bomTemplate.getReturnQuery(
      where,
      `{inventory_item: {name: asc}}`
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setBoms", bomTemplate.table);
  },

  /**
   * Retrieves a bom
   * @param {string} where Where clause
   * @param {boolean} includeLots Determines if materials should pull back lots
   * @param {boolean} onHandOnly Determines if we only care about lots on hand
   */
  retrieveBom: function (
    context: any,
    { where = "", includeLots = false, onHandOnly = false }: any = {}
  ) {
    let bomTemplate = new Bom();
    let returnQuery: string = bomTemplate.getReturnQuery(
      where,
      "",
      includeLots
        ? bomTemplate.getQueryReturnWithFacilityLots("", onHandOnly)
        : ""
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentBom",
      bomTemplate.table
    );
  },

  /**
   * Retrieves a bom by item id.
   * Commits bom using a passed mutation.
   * @param context Store module context
   * @param {Array} itemIds Array of item ids
   */
  retrieveBomsByItemIds: function (
    context: any,
    {
      itemIds,
      mutation = "setBoms",
      includeLots = false,
      onHandOnly = false,
    }: any
  ) {
    if (typeof itemIds === "string") itemIds = [itemIds];
    itemIds = uniq(itemIds);
    let bomTemplate = new Bom();
    let returnQuery: string = bomTemplate.getReturnQuery(
      `{
        archived: {_is_null: true},
        item_id: {_in: ["${itemIds.join('", "')}"]}
      }`,
      "",
      includeLots
        ? bomTemplate.getQueryReturnWithFacilityLots("", onHandOnly)
        : ""
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, mutation, bomTemplate.table);
  },

  /**
   * Retrieves boms by an array of item ids and good ids.
   * Useful for getting boms specific to a given good.
   * Commits boms using `setBoms`.
   * @param context Store module context
   * @param {Array} itemIds Array of item UUIDs
   */
  retrieveBomsForPackoutGoods: function (context: any, { goodIds }: any) {
    let facilityId: string = context.rootState.facilities.currentFacilityUuid;
    if (!facilityId) return;

    let bomTemplate = new Bom();
    let returnQuery: string = bomTemplate.getReturnQuery(
      `{
        archived: {_is_null: true},
        _or: [
          {good_id: {_in: ["${goodIds.join('", "')}"]}}
        ]
      }`,
      "",
      bomTemplate.getQueryReturnWithFacilityLots(facilityId, true)
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setBoms", bomTemplate.table);
  },

  /**
   * Retrieves boms by an array of item ids and good ids.
   * Useful for getting boms specific to a given good.
   * Commits boms using `setBoms`.
   * @param context Store module context
   * @param {Array} itemIds Array of item UUIDs
   */
  retrieveBomsByGoodId: function (context: any, { goodId }: any) {
    let facilityId: string = context.rootState.facilities.currentFacilityUuid;
    if (!facilityId) return;

    let bomTemplate = new Bom();
    let returnQuery: string = bomTemplate.getReturnQuery(
      `{
        good_id: {_eq: "${goodId}"}
    }`,
      "",
      bomTemplate.getQueryReturnWithFacilityLots(facilityId, true)
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentBom",
      bomTemplate.table
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
