
import { Component, Mixins, Prop } from "vue-property-decorator";
import { IEventBusEvent, setListeners, unsetListeners } from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import { DateTime } from "luxon";
import { clone, get } from "lodash";
import { BomMaterial, Bom } from "@eaua/model";
import _ from "lodash";

@Component
export default class BatchListForm extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "batch-list-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  @Prop({ default: "" })
  passedGoodId!: "";

  isLoading: boolean = false;

  // LOCAL VARIABLES -----------------------------------------------------------
  headers: Array<any> = [
    {
      text: "Batch",
      value: "batch_number",
    },
    {
      text: "Completed",
      value: "completed_date",
    },
    {
      text: "Consumed oz(s)",
      value: "consumed_weight",
    },
    {
      text: "Actual oz(s)",
      value: "actual_weight",
    },
    {
      text: "Retained %",
      value: "retained",
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get materials(): Array<any> {
    return get(this.$store, "state.packout.currentGood.materials", []);
  }

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-batch-list-dialog-${this.dialogId}`,
        label: "Close",
      },
    ];
  }

  get currentBatches() {
    return this.$store.state.packout.currentGoodBatches || [];
  }

  // Returns a formatted object of all batches, and calculates retained weight
  // by using the consumed lot amount and the amount weighed in at the weigh kiosk.
  get batchList(): Array<any> {
    let currentBatch = clone(this.currentBatches);
    let batchList: any = [];
    if (get(this.bom, "materials", false)) {
      for (let item of this.materials) {
        let bomMaterial: any = {};
        for (let material of get(this.bom, "materials", {})) {
          if (item.lot.item_id == material.item_id) {
            bomMaterial = material;
          }
        }
        let materialItem: any = {};

        let itemIndex = this.findItemIndex(currentBatch, item);

        let batchIndex = batchList.findIndex(
          (x: any) => x.batch_number === currentBatch[itemIndex].number
        );

        if (batchIndex >= 0) {
          batchList[batchIndex].consumed_weight +=
            item.before_quantity - item.after_quantity;
          batchList[batchIndex].retained =
            Math.round(
              (batchList[batchIndex].actual_weight /
                batchList[batchIndex].consumed_weight) *
                100
            ) + "%";
        } else {
          materialItem.batch_number = currentBatch[itemIndex].number;
          materialItem.consumed_weight =
            item.before_quantity - item.after_quantity;
          materialItem.uuid = item.uuid;
          materialItem.item_name = get(item, "lot.inventory_item.name", "");
          materialItem.batch_id = item.batch_id;
          materialItem.uom_name = item.lot.inventory_item.uom.name;
          materialItem.actual_weight = currentBatch[itemIndex].actual;
          materialItem.retained =
            Math.round(
              (materialItem.actual_weight / materialItem.consumed_weight) * 100
            ) + "%";
          materialItem.completed_date = currentBatch[itemIndex].completed
            ? DateTime.fromISO(currentBatch[itemIndex].completed).toFormat(
                "MM/dd"
              )
            : "N/A";
          batchList.push(materialItem);
        }
      }
    }
    return _.sortBy(batchList, ["batch_number", "created"]).reverse();
  }

  findItemIndex(currentBatch: any, item: any) {
    let itemIndex = currentBatch.findIndex(
      (x: any) => x.uuid === item.batch_id
    );
    return itemIndex !== -1 ? itemIndex : 0;
  }

  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: Array<IEventBusEvent> = [];

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    this.retrieveCurrentBatch();
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  async retrieveCurrentBatch(): Promise<void> {
    this.showPreloader = true;
    await this.$store.dispatch("packout/retrieveCurrentPackoutGoodBatches", {
      uuid: this.passedGoodId,
    });
    this.showPreloader = false;
  }

  async retrieveCurrentBatchLotList(): Promise<void> {
    this.showPreloader = true;
    await this.$store.dispatch("packout/retrieveBatchLotList", {
      batchId: this.passedGoodId,
    });
    this.showPreloader = false;
  }
}
