import BaseModel from "../../Base";

export default class FulfillmentLot extends BaseModel {
  table = "growops_fulfillment_lots_vw";
  view = "growops_fulfillment_lots_vw";

  properties = {
    box_id: "",
    delivery_id: "",
    lot_id: "",
    lot_header_id: null,
    item_name: "",
    item_number: "",
    lot_number: 0,
    lot_header_number: 0,
    lot_created: "",
    on_hand: 0,
    box_created: "",
    packed: null,
    loaded: null,
    qty_in_this_delivery: 0,
    sales_order_line_id: "",
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    super.initialize(values);
  }
}
