
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import { emitNotification } from "@/util/notifications";
import Form from "@/modules/core/mixins/Form.vue";
import { findTimeDiffInHours, getUTCNow } from "@/util/helpers";
import { Bom, PackoutGood } from "@eaua/model";
import ButtonOptions from "@/types/app/shared/ButtonOptions";
import BomMaterialCheck from "@/modules/core/mixins/LotMaterialCheck.vue";
import {
  flatMap,
  map,
  sumBy,
  find,
  round,
  some,
  filter,
  difference,
  uniqBy,
  get,
  cloneDeep,
  clone,
} from "lodash";
import { ICON_DEFINITIONS } from "@/util/icons";
import DataRefresh from "@/modules/core/mixins/DataRefresh.vue";
import HaltScanning from "@/modules/core/mixins/HaltScanning.vue";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";

@Component
export default class WipSummaryForm extends Mixins(
  Form,
  DataRefresh,
  HaltScanning,
  BomMaterialCheck
) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({})
  selectedPackout: any;

  @Prop({ default: "Current Packout" })
  title!: string;

  packoutStorageKey = "selected-packout-id";
  packoutGood: any;
  isLoading: boolean = false;
  materiaLotCount: number = 0;
  underscanned = false;
  homeRoute = "home";

  // Context actions

  actions: Array<ButtonOptions> = [
    {
      label: "Add Lot",
      event: "open-add-lot-dialog",
      icon: ICON_DEFINITIONS.base.add,
    },
  ];

  events: Array<IEventBusEvent> = [
    {
      name: "halt-lot-scanning",
      function: this.setHaltScanning,
    },
    {
      name: "show-saving-dialog",
      function: this.showSavingDialog,
    },
    {
      name: "navigate-lot",
      function: this.addScannedLot,
    },
    {
      name: "navigate-lot_deprecated",
      function: this.addScannedLot,
    },
    {
      name: "navigate-pallet",
      function: this.getScannedPallet,
    },
    {
      name: "navigate-pallet_deprecated",
      function: this.getScannedPallet,
    },
    {
      name: "open-add-lot-dialog",
      function: this.showAddLotDialog,
    },
    {
      name: "add-bom-lot",
      function: this.addManualLot,
    },
  ];

  // ---------------------------------------------------------------------------
  // REFS
  // ---------------------------------------------------------------------------

  @Ref("add-lot-dialog")
  readonly addLotDialog!: any;

  @Ref("casing-completed-dialog")
  readonly casingCompleteDialog!: any;

  @Ref("saving-dialog")
  readonly savingDialog!: any;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get headers(): Array<any> {
    let headers: Array<any> = [
      {
        text: "Number",
        value: "number",
      },
      {
        text: "Item",
        value: "item_name",
      },
      {
        text: "Consumed",
        value: "consumed",
      },
      {
        text: "Target",
        value: "target",
      },
      {
        text: "Remaining",
        value: "remaining",
      },
      {
        text: "Lot Count",
        value: "lotCount",
      },
    ];
    return headers;
  }

  get boms(): Bom[] {
    return (this.$store.state.boms.boms || []).map((m: any) => new Bom(m));
  }

  get inventoryTransactions(): any {
    return this.$store.state.transaction.inventoryTransactions || [];
  }

  get packoutGoods(): any {
    return this.$store.state.packout.currentGoods;
  }

  get palletLots(): any {
    return this.$store.state.packout.currentPalletLots || [];
  }

  get consumedAggregate(): number {
    return sumBy(this.materialsList, "consumed");
  }

  get materialsList(): Array<any> {
    let list: any = {};
    let materials = flatMap(this.boms, "materials");
    for (let bomMat of materials) {
      if (
        bomMat.inventory_item.categoryByTagId.name === "wip" &&
        !this.checkItemTagsForMix(bomMat)
      ) {
        let materialTransactions = this.getMaterialTransactions(bomMat);
        let consumedQty = round(
          sumBy(materialTransactions, "amount") * -1 || 0
        );
        let targetQty = round(this.getTargetQty(bomMat));
        // If this item hasn't been added, build it
        if (!list[bomMat.item_id]) {
          list[bomMat.item_id] = {
            number: bomMat.inventory_item.number,
            item_name: bomMat.inventory_item.name,
            consumed: consumedQty,
            target: targetQty || 0,
            remaining: round(targetQty - consumedQty),
            transactionRecords: [...(materialTransactions.uuid || [])],
            lotCount: this.retrieveLotTransactionCount(bomMat.item_id),
          };
        } else {
          let newTransactions = difference(
            materialTransactions.uuid,
            list[bomMat.item_id].uuid
          );

          if (newTransactions.length > 0) {
            list[bomMat.item_id].consumed += consumedQty;
          }
          list[bomMat.item_id].target += targetQty;
          list[bomMat.item_id].remaining =
            round(
              list[bomMat.item_id].target - list[bomMat.item_id].consumed
            ) || 0;
        }
      }
    }

    return Object.values(list);
  }

  get currentPackout() {
    if (this.selectedPackout) {
      return this.$store.state.packout.packouts.find(
        (packout: any) => packout.uuid === this.selectedPackout
      );
    }
    return {};
  }

  // Returns true if all goods are completed
  get isPackoutComplete() {
    return this.currentPackout?.goods?.every((good: any) => good.completed);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  @Watch("selectedPackout")
  async onPackoutChanged() {
    await this.retrieveAllPageData();
  }

  @Watch("packoutGoods")
  async onPackoutGoodsChanged() {
    this.overrideItems = map(this.packoutGoods, "item_id");
  }

  created() {
    this.setRefreshInterval(120000);
    this.scheduleAsyncRefresh(this.retrieveAllPageData);
  }

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    this.$store.commit("packout/reset", "currentGoods");
    this.$store.commit("boms/reset", "boms");
    this.$store.commit("lots/reset", "currentLot");
    this.$store.commit("lots/reset", "currentLotCount");
    this.$store.commit("packout/reset", "inventoryTransactions");
    this.$store.commit("packout/reset", "currentPalletLots");
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async retrieveAllPageData() {
    this.showPreloader = true;
    this.haltScanning = true;
    try {
      await this.retrievePackoutTransactions();
      await this.retrievePackoutGoods();
      await this.retrieveBoms();
    } catch {
      emitNotification({
        inDialog: true,
        message: `Error retrieving page data.`,
        title: "Error",
        type: "error",
      });
    }
    this.haltScanning = false;
    this.showPreloader = false;
  }

  /**
   * checks each tag on an inventory item to see if it contains a mix type
   */
  checkItemTagsForMix(bomMat: any): boolean {
    return some(
      bomMat.inventory_item?.tagByItemId,
      (item) => item.tagByTagId.name === "mix"
    );
  }

  /**
   * this function looks at each item to determine if it's addable as a mix wip
   * to the selected packout and then updates the needed WIP quantities accordingly
   * @param item_id
   */
  get consumedMixes() {
    return this.inventoryTransactions.reduce((acc: any, transaction: any) => {
      let item_id = transaction.inventory_lot.item_id;
      if (!acc[item_id]) acc[item_id] = transaction.amount;
      else acc[item_id] += transaction.amount;
      return acc;
    }, {});
  }

  /**
   * Returns the proportional quantity for a given material
   */
  getTargetQty(bomMat: any): number {
    let bom = find(this.boms, (bom) => bom.uuid === bomMat.recipe_id);
    let goods = this.packoutGoods;
    let good = find(goods, (good) => good.uuid === bom.good_id);
    if (!good) return 0;
    let goodQty = good.quantity;
    if (this.consumedMixes[good.item_id]) {
      goodQty += this.consumedMixes[good.item_id];
    }
    return round(
      this.recipeMultiplier(goodQty, bom.quantity) * bomMat.quantity,
      4
    );
  }

  /**
   * returns the total amount of a material scanned to the packout
   * @param bomMat
   */
  getMaterialTransactions(bomMat: any): any {
    let transactionRecords = filter(
      this.inventoryTransactions,
      (record: any) => record.details.item_id === bomMat.inventory_item.uuid
    );
    if (transactionRecords) {
      return transactionRecords;
    }
    return [];
  }

  /**
   * Divides the good quantity by the bom quantity to get the overall multiplier.
   * Recipe qty will almost always be 1, but could be different.
   */
  recipeMultiplier(goodQuantity: number, bomQuantity: number): number {
    if (!bomQuantity) return 0;
    return goodQuantity / bomQuantity;
  }

  async postTransaction(amount: number = 0): Promise<any> {
    // If the lot is valid, consume the on hand amount
    let selectedLot = this.$store.state.lots.currentLot;
    if (this.isValidLot) {
      await this.submitTransactions(
        this.updateWipLotTransaction(
          false,
          selectedLot,
          this.selectedPackout,
          amount
        )
      );
    }
    // If it's not valid, show an error
    else {
      EventBus.$emit("set-saving-dialog-error", this.lotValidationMessage);
      this.haltScanning = false;
    }
  }

  /**
   * Builds a transaction object for updating the inventory journal
   * @param {boolean} restore boolean to calculate restore or consume quantity
   */
  updateWipLotTransaction(
    restore: boolean,
    lot: any,
    packout: any,
    amount: number = 0
  ): any {
    return {
      amount: restore
        ? this.calculateAmount(lot.on_hand)
        : this.calculateAmount(lot.on_hand, amount) * -1,
      created: getUTCNow(),
      created_by: this.$store.state.user.profile.uuid,
      modified: getUTCNow(),
      modified_by: this.$store.state.user.profile.uuid,
      details: {
        source: "packout WIP Entry",
        item_id: lot.item_id,
      },
      lot_id: lot.uuid,
      originator_id: packout,
      lot_location_id: lot.location_id,
      type: "SPCK",
    };
  }

  retrieveLotTransactionCount(itemId: any): Number {
    let lotTransactions =
      this.$store.getters["transaction/getLotTransactions"](itemId);
    return uniqBy(lotTransactions, "lot_id").length || 0;
  }

  /**
   * this function checks if the add manual lot amount has been supplied before
   * preparing the lot transaction
   * @param lotAmount
   * @param manualAmount
   */
  calculateAmount(lotAmount: number, manualAmount: number = 0) {
    if (manualAmount > 0) {
      return manualAmount;
    } else {
      return lotAmount;
    }
  }

  async addManualLot(lot: any = {}): Promise<void> {
    if (this.haltScanning || this.isComplete) return;
    this.haltScanning = true;
    // Give computed properties a tick to catch up
    this.$nextTick(async () => {
      // If the lot is valid, create a packout material for the lot
      await this.postTransaction(lot.amount);
      this.haltScanning = false;
    });
  }

  /**
  /* function to retrieve lots tied to a scanned pallet
   * @param scannedLotId
   */
  async getScannedPallet(scannedPalletId: string): Promise<void> {
    if (this.isPackoutComplete) {
      emitNotification({
        message: `Packout is already completed. Please select a new packout to continue.`,
        title: "Error",
        type: "error",
      });
      return;
    }
    if (!this.manualLotNumber && !this.selectedLotNumber && !scannedPalletId) {
      emitNotification({
        inDialog: true,
        message: `Please provide, select, or scan a pallet QR code.`,
        title: "Error",
        type: "error",
      });
      return;
    }
    EventBus.$emit("show-saving-dialog", "Adding Scanned Pallet Lots");
    await this.$store
      .dispatch("packout/retrievePalletLots", {
        palletUuid: scannedPalletId,
      })
      .then(
        async (success: any) => {
          let palletLots = this.palletLots || [];
          await this.addScannedPalletLots(palletLots);
        },
        (fail: any) => {
          emitNotification({
            inDialog: true,
            message: `Lots not found or not eligible for WIP entry.`,
            title: "Error",
            type: "error",
          });
        }
      );
    EventBus.$emit("close-saving-dialog");
  }

  /**
   * Queries for a scanned pallet id, and adds all associated lots to packout
   */
  async addScannedPalletLots(palletLots: any): Promise<void> {
    if (this.haltScanning || this.isComplete) return;
    this.haltScanning = true;
    if (!palletLots) {
      emitNotification({
        inDialog: false,
        message: `No Lots Found Connected To Pallet`,
        title: "Error",
        type: "error",
      });
      return;
    }
    let palletLotTransactions: any = [];
    let allPalletLotsValid: any = true;
    this.allowEmpty = true;
    for (let pLot of palletLots) {
      let isValid: boolean = await this.verifyValidLot(pLot);
      if (!isValid) {
        allPalletLotsValid = false;
        break;
      }
    }
    if (allPalletLotsValid) {
      this.allowEmpty = false;
      for (let lot of palletLots) {
        palletLotTransactions.push(
          this.updateWipLotTransaction(false, lot, this.selectedPackout)
        );
      }
    } else {
      this.allowEmpty = false;
      emitNotification({
        inDialog: false,
        message: `Pallet cannot be consumed as it contains invalid lots. Please review Pallet to remove invalid lots and try again.`,
        title: "Error",
        type: "error",
      });
    }

    await this.$store
      .dispatch("inventory/submitTransactions", {
        lotTransactions: palletLotTransactions,
      })
      .then(async (success) => {
        await this.retrieveAllPageData();
      })
      .catch((error) => {
        EventBus.$emit(
          "set-saving-dialog-error",
          "There was an issue scanning the pallet. Please try again."
        );
      })
      .finally(() => {
        this.haltScanning = false;
      });
  }

  /**
   * Queries for a scanned lot id, and adds it to packout good materials
   * if it's a valid lot for this bom
   */
  async addScannedLot(lotId: string): Promise<void> {
    if (this.isPackoutComplete) {
      emitNotification({
        message: `Packout is already completed. Please select a new packout to continue.`,
        title: "Error",
        type: "error",
      });
      return;
    }
    if (this.haltScanning || this.isComplete) return;
    this.haltScanning = true;
    EventBus.$emit("show-saving-dialog", "Adding Scanned Lot");
    await this.$store
      .dispatch("lots/retrieveCurrentLot", {
        uuid: lotId,
        useView: true,
      })
      .then((success) => {
        // Give computed properties a tick to catch up
        this.$nextTick(async () => {
          // If the lot is valid, consume the on hand amount
          let scannedLot = this.$store.state.lots.currentLot;
          if (this.isValidLot) {
            await this.submitTransactions(
              this.updateWipLotTransaction(
                false,
                scannedLot,
                this.selectedPackout
              )
            );
            EventBus.$emit("close-saving-dialog");
          }
          // If it's not valid, show an error
          else {
            EventBus.$emit(
              "set-saving-dialog-error",
              this.lotValidationMessage
            );
            this.haltScanning = false;
          }
        });
      })
      .catch((error) => {
        EventBus.$emit(
          "set-saving-dialog-error",
          "There was an issue scanning the lot. Please try again."
        );
        this.haltScanning = false;
      });
  }

  async verifyValidLot(lot: any): Promise<boolean> {
    this.$store.state.lots.currentLot = lot;
    await this.$nextTick();
    return this.isValidLot;
  }

  /**
   * Updates the Inventory Journal with new lot transactions
   */
  async submitTransactions(transactions: any): Promise<void> {
    await this.$store
      .dispatch("inventory/submitTransactions", {
        lotTransactions: transactions,
      })
      .then(async (success) => {
        await this.updatePackoutGood();
      })
      .catch((error) => {
        emitNotification({
          priority: "medium",
          message: `Packout Update Failed`,
          title: "Error",
          type: "error",
        });
      });
  }

  async updatePackoutGood() {
    await this.$store
      .dispatch("packout/savePackoutGoods", {
        goods: this.buildPackoutGood(),
      })
      .then(async (success) => {
        emitNotification({
          priority: "low",
          message: `Packout Updated Successfully`,
          title: "Success",
          type: "success",
        });
        //refresh all page data to reflect new transaction(s)
        await this.retrieveAllPageData();
      })
      .catch((error) => {
        emitNotification({
          priority: "medium",
          message: `Packout Update Failed`,
          title: "Error",
          type: "error",
        });
      });
  }

  buildPackoutGood() {
    const saveGoodList: PackoutGood[] = [];
    let dateToUse = this.currentLot.use_by || this.currentLot.pack_by_date;
    if (!this.boms) {
      return []; // Nothing to update
    }

    for (const bom of this.boms) {
      const good = this.packoutGoods.find(
        (g: PackoutGood) => g.uuid === bom.good_id
      );
      if (good) {
        const material = bom.materials.find(
          (bm: any) =>
            bm.inventory_item.uuid === this.$store.state.lots.currentLot.item_id
        );
        if (material) {
          if (!good.use_by || findTimeDiffInHours(dateToUse, good.use_by) > 0) {
            good.use_by =
              this.$store.state.lots.currentLot.use_by ||
              this.$store.state.lots.currentLot.pack_by_date;
          }
          let newGood = new PackoutGood(good);
          saveGoodList.push(newGood.getSaveVersion());
        }
      }
    }
    return saveGoodList;
  }

  /**
   * Tells the store to update recipes from the database
   */
  async retrieveBoms() {
    const packoutGoods = this.packoutGoods;
    if (packoutGoods && packoutGoods.length > 0) {
      let goodIds: any = packoutGoods.map((good: any) => good.uuid);
      this.$store.dispatch("boms/retrieveBomsForPackoutGoods", {
        goodIds: goodIds,
      });
    }
  }

  /**
   * Retrieves packout good information based on the selected packout
   */
  async retrievePackoutGoods(): Promise<void> {
    await this.$store.dispatch("packout/retrieveCurrentPackoutGoods", {
      uuid: this.selectedPackout,
    });
  }

  /**
   * Retrieves an updated list of transactions against the current packout
   */
  async retrievePackoutTransactions() {
    this.caseCountLoading = true;
    await this.$store.dispatch("transaction/retrieveJournalTransactions", {
      originator_id: this.selectedPackout,
    });
  }

  showSavingDialog(label: string = ""): void {
    this.savingDialog.open({ label: label });
  }

  showAddLotDialog(): void {
    // If scanning is halted, prevent manually adding lots
    if (!this.haltScanning) {
      this.addLotDialog.open({
        usedLots: [],
        extendExpiry: this.extendedExpiry,
        overrideItems: this.overrideItems,
      });
    }
  }
}
