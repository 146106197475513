
import { Component, Mixins, Prop } from "vue-property-decorator";
import Dialog from "@/modules/core/mixins/Dialog.vue";

@Component
export default class AddBomLotDialog extends Mixins(Dialog) {
  [key: string]: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  portalDestination = "add-bom-lot-actions";
  scoped = true;
  extendExpiry = false;
  usedLots: Array<string> = [];
  overrideItems: Array<string> = [];

  @Prop({ default: false })
  includeAmount!: boolean;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-add-bom-lot-dialog");
  }

  mounted() {
    this.$store.commit("lots/reset", "currentLot");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize(values: any = {}) {
    this.usedLots = values.usedLots || [];
    this.extendExpiry = values.extendExpiry || false;
    this.overrideItems = values.overrideItems || [];
  }
}
