
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { orderBy, get, uniqBy } from "lodash";
import { getIcon } from "@/util/icons";
import { getUTCNow } from "@/util/helpers";
import { setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";

@Component
export default class LotList extends Vue {
  // ---------------------------------------------------------------------------
  // LOCAL VARIABLES
  // ---------------------------------------------------------------------------
  packoutStorageKey = "selected-packout-id";
  currentPackoutId: string = "";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get headers(): Array<any> {
    let headers: Array<any> = [
      {
        text: "Lot #",
        value: "number",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Consumed",
        value: "amount",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ];
    return headers;
  }

  /**
   * Returns array of actions depending on current state
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: "open-remove-confirmation-dialog",
        icon: getIcon("base", "delete"),
        isIcon: true,
        label: "Delete",
        disabled: this.deleteDisabled,
      },
    ];
    return actions;
  }

  get inventoryTransactions() {
    return get(this.$store, "state.packout.inventoryTransactions", []);
  }

  get currentPackout() {
    if (this.transactions.length > 0) {
      const currentPackoutId = this.transactions[0].originator_id;
      return this.$store.state.packout.packouts.find(
        (packout: any) => packout.uuid === currentPackoutId
      );
    }
    return {};
  }

  /**
   * Returns an array of consumed lots associated with the selected packout
   * @returns {Array}
   */
  get consumedLots(): Array<any> {
    let consumedLots: any = [];
    for (let transaction of this.transactions || []) {
      //Only display lot consumptions (negative transaction amounts)
      if (transaction.amount < 0) {
        let transactionListItem: any = [];
        transactionListItem.uuid = transaction.uuid;
        transactionListItem.amount = transaction.amount;
        transactionListItem.number = transaction.inventory_lot.number;
        transactionListItem.lot_id = transaction.lot_id;
        transactionListItem.item_id = transaction.details.item_id;
        transactionListItem.name =
          transaction.inventory_lot.inventory_item.name;
        transactionListItem.location_id = transaction.location_id;
        transactionListItem.originator_id = transaction.originator_id;
        consumedLots.push(transactionListItem);
      }
    }
    return orderBy(consumedLots, ["number"], "desc");
  }

  /**
   * Returns an array of inventory journal records associated with currently selected packout
   * @returns {Array}
   */
  get transactions(): Array<any> {
    return this.$store.getters["transaction/getLotTransactions"](null);
  }

  get deleteDisabled() {
    return this.currentPackout?.goods?.every((good: any) => good.completed);
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: any = [
    {
      name: "confirm-remove-wip-lot",
      function: this.removeLot,
    },
    {
      name: "open-remove-confirmation-dialog",
      function: this.showRemoveDialog,
    },
  ];

  // ---------------------------------------------------------------------------
  // REFS
  // ---------------------------------------------------------------------------

  @Ref("remove-confirmation-dialog")
  readonly removeConfirmationDialog!: any;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  async created() {
    this.retrieveFormData();
  }

  beforeMount() {
    this.currentPackoutId = localStorage[this.packoutStorageKey];
  }

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  @Watch("consumedLots", { immediate: true })
  consumedLotsChanged() {
    this.updateLotCount(uniqBy(this.consumedLots, "lot_id").length || 0);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Function to submit the new positive journal transaction after a lot
   * has been restored from the consumed list
   */
  async removeLot(transaction: any) {
    await this.submitTransactions(
      this.updateWipLotTransaction(transaction),
      true
    );
  }

  /**
   * Form function to retreive initial packout journal transaction list
   */
  async retrieveFormData() {
    await this.retrievePackoutTransactions();
  }

  /**
   * Retrieves an updated list of transactions against the current packout
   */
  async retrievePackoutTransactions() {
    await this.$store.dispatch("transaction/retrieveJournalTransactions", {
      originator_id: this.currentPackoutId,
    });
  }

  /**
   * Restores Lot Quantity with a Positive Inventory Journal Transaction
   * @param {transactions}
   */
  async submitTransactions(
    transactions: any,
    removal: boolean = false
  ): Promise<void> {
    await this.$store
      .dispatch("inventory/submitTransactions", {
        lotTransactions: transactions,
        removal: removal,
      })
      .then(async (success) => {
        emitNotification({
          priority: "low",
          message: `Restored lot from Packout`,
          title: "Success",
          type: "success",
        });
        await this.retrievePackoutTransactions();
      })
      .catch((error) => {
        emitNotification({
          priority: "medium",
          message: `Failed To Restore Lot`,
          title: "Error",
          type: "error",
        });
      });
  }

  /**
   * Builds a transaction object for updating the inventory journal
   * @param {boolean} restore boolean to calculate restore or consume quantity
   */
  updateWipLotTransaction(transaction: any): any {
    return {
      amount: transaction.amount * -1,
      created: getUTCNow(),
      created_by: this.$store.state.user.profile.uuid,
      modified: getUTCNow(),
      modified_by: this.$store.state.user.profile.uuid,
      details: {
        source: "Restore Packout WIP Entry",
        item_id: transaction.item_id,
      },
      lot_id: transaction.lot_id,
      originator_id: transaction.originator_id,
      lot_location_id: transaction.location_id,
      type: "SPCK",
    };
  }

  // Updates the store with the total lot count
  updateLotCount(count: number) {
    this.$store.commit("lots/updateCurrentLotCount", count);
  }

  // Restore Confirmation Dialog.
  showRemoveDialog(transaction: any = {}): void {
    this.removeConfirmationDialog.open({
      passedObject: transaction,
      passedTitle: `Restore Lot ${transaction.number}`,
      passedMessage: `Are you sure you want to restore this ${
        transaction.amount * -1
      }oz lot?`,
    });
  }
}
