import Vue from "vue";
import Vuex from "vuex";
import LogRocket from "logrocket";
import createPlugin from "logrocket-vuex";

const logrocketPlugin = createPlugin(LogRocket);

import modules from "./modules";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules,
  plugins: [logrocketPlugin],
});

export function createStore() {
  return new Vuex.Store({
    modules,
    plugins: [logrocketPlugin],
  });
}
