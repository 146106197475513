
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { generateUniqueKey } from "@/util/helpers";

@Component
export default class BaseIterator extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  headers!: any; // Table/card headers

  @Prop({ default: () => [] })
  items!: any;

  @Prop({ default: () => [] })
  value!: any;

  @Prop({ default: undefined })
  itemCount!: number;

  @Prop({ default: "" })
  itemKey!: string; // Used in table for selecting items, should be unique

  @Prop({ default: () => [] })
  actions!: any; // Actions available to each item

  @Prop({ default: () => {} })
  status!: any; // Define possible statuses for items

  /**
   * Defines which header to sort by. Can be an array, ie ["name", "desc"].
   * Default order is ascending. Default value is itemKey if nothing is defined.
   */
  @Prop({ default: "" })
  sortBy!: any;

  @Prop({ default: false })
  selectable!: boolean; // Allows items to be selected

  @Prop({ default: "card" })
  displayType!: string;

  @Prop({ default: true })
  showToolbar!: boolean;

  @Prop({ default: "" })
  searchComponent!: string;

  @Prop({ default: true })
  showDisplayOptions!: boolean; // Allows the display type to be changed

  @Prop({ default: false })
  showGantt!: boolean;

  @Prop({ default: false })
  twoLineList!: boolean; // Displays card header above the value

  @Prop({ default: false })
  hideCardHeaders!: boolean; // Only shows values and icons on cards if true

  // @Prop({ default: "xs12 sm6 md4 xl3" })
  // cardBreakpoints!: string;

  @Prop({ default: false })
  searchOnly!: boolean; // Hides filter drawer button if true

  @Prop({ default: false })
  flatTable!: boolean; // Removes elevation on table if true

  @Prop({ default: null })
  addItemButton!: any; // Button at bottom of table to add items

  @Prop({ default: false })
  hidePagination!: boolean; // Hides actions at bottom of table

  // LOCAL VARIABLES -----------------------------------------------------------
  display: string = this.displayType;
  selectedIndexes: any = [];
  page: number = 1;
  itemsPerPage: number = 30;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get itemValues() {
    if (this.value.length > 0) return this.value;
    else return this.items;
  }

  set itemValues(value: any) {
    this.$emit("input", value);
  }

  /**
   * Returns true if the items array is not empty
   * @returns {boolean}
   */
  get hasItems() {
    return this.itemValues.length > 0;
  }

  /**
   * Returns the nubmer of pages in card view
   * @returns {number}
   */
  get numberPages() {
    return Math.ceil(this.itemValues.length / this.itemsPerPage);
  }

  /**
   * Returns true if pagination controls should show
   * @returns {boolean}
   */
  get showPagination() {
    return (
      !this.hidePagination &&
      this.itemValues.length > this.itemsPerPage &&
      this.display === "card"
    );
  }

  /**
   * Returns array of items to show on the current page
   * @returns {Array}
   */
  get pagedItems() {
    if (this.hidePagination) {
      return this.itemValues;
    }

    let start: number = (this.page - 1) * this.itemsPerPage;
    return this.itemValues.slice(start, this.itemsPerPage + start);
  }

  /**
   * Returns current page information for card view.
   * ie `Showing 1-30 of 247`
   * @returns {string}
   */
  get pagingInformation() {
    let start: number = (this.page - 1) * this.itemsPerPage;
    start += 1;
    let end: number = start + this.itemsPerPage - 1;
    if (end > this.itemValues.length) end = this.itemValues.length;
    return `Showing ${start}-${end} of ${this.itemValues.length}`;
  }

  /**
   * Returns an array of iterator items that have been selected
   * @returns {Array}
   */
  get selectedItems() {
    let items: any = [];
    for (let index of this.selectedIndexes) {
      if (this.itemValues[index]) {
        items.push(this.itemValues[index]);
      }
    }
    this.$emit("updated-selected-items", items);
    return items;
  }

  /**
   * Returns true if a table view should be displayed. Defaults to table view
   * if there are no items.
   * @returns {boolean}
   */
  get showTable() {
    return this.display === "table" || !this.hasItems;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("itemValues")
  onItemsChanged() {
    this.page = 1;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Changes the display type, ie `card` or `table`
   * @param {string} value
   */
  changeIteratorDisplay(value: string) {
    this.display = value;
  }

  /**
   * Sets the indices of currently selected items
   * @param {Array} value
   */
  updateSelectedIndexes(value: any) {
    this.selectedIndexes = value;
  }

  updatePagination(value: any) {
    this.$emit("update-pagination", value);
  }

  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }

  /**
   * Allows programmatic selection of items from external entities.
   * Contingent on specified "itemKey" in component props.
   * @param {string} keyToSelect - Key of item that should be selected, by "itemKey"
   */
  selectItem(keyToSelect: string) {
    if (this.display === "table")
      this.$refs[`${this._uid}-data-table`].selectItem(keyToSelect);
  }
}
