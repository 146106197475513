import {
  camelCase,
  clone,
  cloneDeep,
  get,
  isNil,
  merge,
  snakeCase,
  union,
} from "lodash";
import {
  formatTimestamp,
  getUTCNow,
  isHtml,
  sanitizeHtmlString,
} from "@/util/helpers";
import { store } from "../store";

export default class BaseModel {
  // Index signature. Needed to access properties as strings.
  [key: string]: any;

  // Table name to query.
  table = "";
  view = "";
  primaryKey = "";
  insertType = ""; // GraphQL insert type
  updateType = ""; // GraphQL update type
  whereType = ""; // GraphQL where type
  mutationWhereType = ""; // GraphQL where type
  orderByType = ""; // GraphQL order by type

  // Class properties. Must match database columns.
  properties: { [key: string]: any } = {};
  // Additional properties from a view.
  derivedProperties: { [key: string]: any } = {};
  // Additional properties from a view.
  viewProperties: { [key: string]: any } = {};
  // List of minimum properties need for this model
  minifiedProperties: string[] = ["uuid", "name"];
  // List of date properties for this model for formatting
  dateProperties: string[] = [
    "added",
    "canceled",
    "completed",
    "created",
    "modified",
    "removed",
  ];
  // Class relationships. Matches hasura relationship names.
  relationships: { [key: string]: any } = {};
  // Validations object for use with vuelidate library.
  validations: { [key: string]: any } = {};

  // Values passed to the initialize method
  initialValues: any = {};

  // Query return string. Defines properties to return in hasura query.
  queryReturn = "";
  // Minified query return string. Defines properties to return in hasura query.
  // Is built from `this.minifiedProperties`
  minifiedQueryReturn = "";
  // Query update columns. Columns to update on upsert statements.
  queryUpdateColumns: string[] = [];

  // The uuid for this object.
  uuid = "";

  // Reference to vuex store.
  store: any = {};
  // The currently logged in user's id.
  currentUserId = "";
  // The current facility
  currentFacilityId = "";

  dateFormat = "date";

  // Always true. Easy way to check if your object is anonymous
  // or if it extends BaseModel
  isModel = true;

  // If true, properties will be transformed from snake case to camelcase
  useCamelCase = false;

  /**
   * Parent constructor. Need to call `super(store)` from child constructor
   * to access parent methods using `this`.
   * @param store Reference to vuex store
   */
  constructor(store: any = {}) {
    this.setStore(store);
  }

  /**
   * Initializes class properties. Checks for passed values first,
   * and fills in gaps from `this.properties` object
   * @param {Object} values - Values to add to properties
   */
  initialize(v: any = {}) {
    // Set GraphQL types
    this.setGraphQlTypes();

    // Insures we don't alter the referenced object
    let values: any = cloneDeep(v);

    // If an instantiated model or display version is passed in,
    // this will pull the initialValues from the passed object
    if (values.isModel || values.isDisplayVersion) {
      values = values.initialValues;
    }

    // Save the initial values for diff comparison
    this.initialValues = cloneDeep(values);

    // Create and initialize class properties from `this.properties`,
    // `this.viewProperties`, and `this.derivedProperties`
    this.initializeClassProperties(this.properties, values);
    this.initializeClassProperties(this.viewProperties, values);
    this.initializeClassProperties(this.derivedProperties, values);

    // Create class properties from `this.relationships` if the passed
    // value has a property for a given relationship
    for (const r of Object.keys(this.relationships)) {
      const relationship: string = this.getPropertyName(r);
      if (Object.prototype.hasOwnProperty.call(values, r))
        this[relationship] = cloneDeep(values[r]);
    }

    // Creates a validations template if there are no validations set
    if (Object.keys(this.validations).length === 0) {
      this.validations = this.getValidationsTemplate();
    }

    // Creates a query return object template for GraphQL queries
    if (!this.queryReturn) {
      this.queryReturn = this.getQueryReturnTemplate();
    }

    // Creates a minified query return object template for GraphQL queries
    if (!this.minifiedQueryReturn) {
      this.minifiedQueryReturn = this.getMinifiedQueryReturnTemplate();
    }

    // Creates an array of columns to update for GraphQL queries
    if (this.queryUpdateColumns.length === 0) {
      this.queryUpdateColumns = this.getQueryUpdateColumns();
    }

    // Set the current user id if the store has been referenced
    this.setCurrentUserId();
    // Set the current facility id if the store has been referenced
    this.setCurrentFacilityId();
  }

  /**
   * Initializes class properties, given a properties object and values
   * @param {any} properties Properties object
   * @param {any} values Values object
   */
  initializeClassProperties(properties: any = {}, values: any = {}) {
    for (const p of Object.keys(properties)) {
      const property: string = this.getPropertyName(p);
      if (Object.prototype.hasOwnProperty.call(values, p)) {
        this[property] = cloneDeep(values[p]);
      } else if (Object.prototype.hasOwnProperty.call(values, property)) {
        this[property] = cloneDeep(values[property]);
      } else {
        this[property] = cloneDeep(properties[p]);
      }
    }
  }

  /**
   * Resets a given property to its default value
   * @param {string} property Property name
   */
  resetProperty(property = "") {
    if (!property) return;
    const p = snakeCase(property);
    if (
      Object.prototype.hasOwnProperty.call(this.properties, property) &&
      Object.prototype.hasOwnProperty.call(this, property)
    ) {
      this[property] = cloneDeep(this.properties[property]);
    } else if (
      Object.prototype.hasOwnProperty.call(this.properties, p) &&
      Object.prototype.hasOwnProperty.call(this, property)
    ) {
      this[property] = cloneDeep(this.properties[p]);
    }
  }

  /**
   * Resets a given array of properties to their default value
   * @param {Array} properties Property names
   */
  resetProperties(properties: string[] = []) {
    for (const property of properties) {
      this.resetProperty(property);
    }
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets graphql data types
   */
  setGraphQlTypes() {
    // Set GraphQL types
    this.insertType = `[${this.table}_insert_input!]!`;
    this.updateType = `${this.table}_set_input`;
    this.whereType = `${this.table}_bool_exp`;
    this.mutationWhereType = this.whereType + "!";
    this.orderByType = `[${this.table}_order_by!]`;
  }

  /**
   * Mutates `this.table`
   * @param table
   */
  setTable(table = "") {
    this.table = table;
  }

  /**
   * Mutates `this.properties`
   * @param properties
   */
  setProperties(properties: any = {}) {
    this.properties = properties;
  }

  /**
   * Mutates `this.relationships`
   * @param relationships
   */
  setRelationships(relationships: any = {}) {
    this.relationships = relationships;
  }

  /**
   * Mutates the vuex store reference
   * @param store
   */
  setStore(store: any = {}) {
    this.store = store;
  }

  /**
   * Mutates `this.validations`
   * @param validations
   */
  setValidations(validations: any = {}) {
    this.validations = validations;
  }

  /**
   * Mutates `this.queryReturn`
   * @param queryReturn
   */
  setQueryReturn(queryReturn = "") {
    this.queryReturn = queryReturn;
  }

  /**
   * Mutates `this.queryUpdateColumns`
   * @param queryUpdateColumns
   */
  setQueryUpdate(queryUpdateColumns: string[] = []) {
    this.queryUpdateColumns = queryUpdateColumns;
  }

  /**
   * Sets the current user id if the store has been referenced
   */
  setCurrentUserId() {
    this.currentUserId = get(store, "state.user.profile.uuid", "") || "";
  }

  /**
   * Sets the current facility id if the store has been referenced
   */
  setCurrentFacilityId() {
    this.currentFacilityId =
      get(store, "state.facilities.currentFacilityUuid", "") || "";
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns `true` if this class has had any properties modified
   * @returns {boolean}
   */
  isModified(): boolean {
    for (const p of Object.keys(this.properties)) {
      if (this.initialValues[p] !== this[this.getPropertyName(p)]) return true;
    }
    return false;
  }

  /**
   * Returns `true` if this class has no uuid set
   * @returns {boolean}
   */
  isNew(): boolean {
    return !this.uuid;
  }

  /**
   * Returns true if this model's properties match the default property values
   * @returns {boolean | undefined}
   */
  isNewObject(): boolean | undefined {
    for (const p of Object.keys(this.properties)) {
      const property: string = this.getPropertyName(p);
      if (!Object.prototype.hasOwnProperty.call(this, property))
        return undefined;
      if (this[property] !== this.properties[p]) return false;
    }
    return true;
  }

  /**
   * Returns true if the value matches the variable name pattern for GraphQL
   * @param value Value to be tested
   * @returns {boolean}
   */
  isQueryVariable(value: string): boolean {
    return /^\$\w+$/.test(value);
  }

  /**
   * Returns the current class name, ie will return child object's name
   * if called from child
   */
  getClassName() {
    return this.constructor.name;
  }

  /**
   * Returns a property name. If `this.useCamelCase` is true, this
   * returns a transformed name
   * @param {string} property Property name
   * @returns {string}
   */
  getPropertyName(property: string): string {
    return this.useCamelCase ? camelCase(property) : property;
  }

  /**
   * Builds a basic delete query if a table name is set and a where clause
   * is provided.
   * @param {string} where Where object string
   * @param {string} injectString Query string to inject in the mutation
   * @param {string} injectPosition Inject before or after the query body, defaults to `after`
   * @param {string} overrideBody Overrides the default delete query body
   * @returns {string}
   */
  getDeleteQuery(
    where = "",
    injectString = "",
    injectPosition = "after",
    overrideBody = ""
  ): string {
    // Build the parameters to pass to the mutation
    let params = "";
    if (!where || where === "variable" || this.isQueryVariable(where)) {
      where = this.isQueryVariable(where) ? where : "$where";
      params = `(${where}: ${this.mutationWhereType})`;
    }
    if (!(where || overrideBody || params) || !this.table) return "";

    return `
      mutation delete_from_${this.table}${params} {
        ${injectPosition === "before" ? injectString : ""}
        ${overrideBody || this.getDeleteQueryBody(where)}
        ${injectPosition === "after" ? injectString : ""}
      }
    `;
  }

  /**
   * Returns the body for a delete query without the mutation wrapper
   * @param {string} where Where object string
   * @returns {string}
   */
  getDeleteQueryBody(where = ""): string {
    if (!where || !this.table) return "";
    return `
      delete_${this.table} (where: ${where}) {
        affected_rows
      }
    `;
  }

  /**
   * Builds a basic return query if a table name and a query return
   * string are set.
   * @param {string} where Where object string
   * @param {string} orderBy Order by object string
   * @param {string} returnString Allows you to overwrite the return object
   * @param {Object} pagination Object containing pagination info
   * @param {boolean} useView Specifies if a view or table should be used
   * @returns {string}
   */
  getReturnQuery(
    where = "",
    orderBy = "",
    returnString = "",
    pagination: any = {},
    useView = false
  ): string {
    // If the table name is not set, or there is no query return
    // string, return an empty string.
    if (useView && !this.view) return "";
    else if (!useView && !this.table) return "";
    if (!this.queryReturn && !returnString) return "";

    let whereIsObject = false;

    // Build the parameters to pass to the mutation
    const params: Array<string> = [];
    if (where === "variable" || this.isQueryVariable(where)) {
      where = this.isQueryVariable(where) ? where : "$where";
      whereIsObject = true;
      params.push(`${where}: ${this.whereType}`);
    }

    if (orderBy === "variable" || this.isQueryVariable(orderBy)) {
      orderBy = this.isQueryVariable(orderBy) ? orderBy : "$order_by";
      params.push(`${orderBy}: ${this.orderByType}`);
    }

    let tableName: string = useView ? this.view : this.table;
    let table: string = tableName;
    const filters: any = [];
    // Set limit and offset
    const offset: number | null = get(pagination, "offset", null);
    let limit: number | null = get(pagination, "limit", null);
    if (limit === -1) limit = null;
    // Set search terms
    const search: string[] = get(pagination, "search", []);
    const terms: string[] = [];
    // Build search terms array
    for (const term of search) {
      terms.push(`{ search_text: { _like: "%${term}%" }}`);
    }
    // Build query filters
    // TODO: Convert search filters over to object-based where
    if (!whereIsObject) {
      if (where && terms.length) {
        // Strip the bracket off the end of the string
        where = where.substring(0, where.length - 1);
        // Remember to re-add the closing brace
        where += `, _and: [${terms.join(", ")}]}`;
      } else if (terms.length) {
        where = `{ _and: [${terms.join(", ")}] }`;
      }
    }
    if (where) filters.push(`where: ${where}`);
    if (orderBy) filters.push(`order_by: ${orderBy}`);
    if (offset) filters.push(`offset: ${offset}`);
    if (limit) filters.push(`limit: ${limit}`);

    // Only run the count query if pagination is set
    let countQuery = "";
    if (
      Object.prototype.hasOwnProperty.call(pagination, "offset") ||
      Object.prototype.hasOwnProperty.call(pagination, "limit")
    ) {
      countQuery = `${table}_aggregate`;
      if (where) countQuery += `(where: ${where})`;
      countQuery += `{
        aggregate {
          count(distinct: true)
        }
      }`;
    }
    if (filters.length) table += `(${filters.join(", ")})`;
    if (params.length) tableName += `(${params.join(", ")})`;
    return `
      query retrieve_from_${tableName} {
        ${countQuery}
        ${table} {
          ${returnString ? returnString : this.queryReturn}
        }
      }
    `;
  }

  /**
   * Builds a basic upsert query if a table name and a query return
   * string are set. Expects a variable called `set` to be passed
   * in the GraphQL call.
   * @param {string} where Limits scope of update query
   * @param {string} returnString Allows you to overwrite the return object
   * @returns {string}
   */
  getUpdateQuery(where = "", returnString = ""): string {
    // If the table name is not set, there is no query return string,
    // return an empty string
    if (!this.table || (!this.queryReturn && !returnString)) return "";

    // Build the parameters to pass to the mutation
    const params: string[] = [`$set: ${this.updateType}`];
    if (!where || where === "variable" || this.isQueryVariable(where)) {
      where = this.isQueryVariable(where) ? where : "$where";
      params.push(`${where}: ${this.mutationWhereType}`);
    }

    return `
      mutation update_${this.table}(${params.join(", ")}) {
        ${this.getUpdateQueryBody(where, returnString, "set")}
      }
    `;
  }

  /**
   * Builds a basic update query body
   * @param {string} where Required. Limits scope of update query
   * @param {string} returnString Allows you to overwrite the return object
   * @param {string} variable Allows you to customize the graphql variable name
   * @returns {string}
   */
  getUpdateQueryBody(where = "", returnString = "", variable = "set"): string {
    if (!where) return "";
    return `
      update_${this.table} (
        _set: $${variable},
        where: ${where}
      ) {
        returning {
          ${returnString ? returnString : this.queryReturn}
        }
      }
    `;
  }

  /**
   * Builds a basic upsert query if a table name and a query return
   * string are set. Expects a variable called `input` to be passed
   * in the GraphQL call.
   * @param {string} returnString Allows you to overwrite the return object
   * @param {string} injectString Allows you to inject queries before the upsert
   * @param {any} variables Allows you define variable names and their types
   * @returns {string}
   */
  getUpsertQuery(
    returnString = "",
    injectString = "",
    variables: any = {}
  ): string {
    // If the table name is not set, there is no query return string,
    // there is no conflict string, or no update columns are set,
    // return an empty string.
    const onConflict: string = this.getOnConflictString();
    if (!this.table || (!this.queryReturn && !returnString) || !onConflict) {
      return "";
    }

    // Build the parameters to pass to the mutation
    const params: string[] = [];
    if (Object.keys(variables).length === 0) {
      params.push(`$input: ${this.insertType}`);
    } else {
      for (const v of Object.keys(variables)) {
        params.push(`${v}: ${variables[v]}`);
      }
    }

    return `
      mutation upsert_into_${this.table}(${params.join(", ")}) {
        ${injectString}
        ${this.getUpsertQueryBody(returnString, "input")}
      }
    `;
  }

  /**
   * Builds a basic upsert query body
   * @param {string} returnString Allows you to overwrite the return object
   * @param {string} variable Allows you to customize the graphql variable name
   * @returns {string}
   */
  getUpsertQueryBody(returnString = "", variable = "input"): string {
    return `
      insert_${this.table} (
        objects: $${variable},
        on_conflict: ${this.getOnConflictString()}
      ) {
        returning {
          ${returnString ? returnString : this.queryReturn}
        }
      }
    `;
  }

  /**
   * Returns the display version of this class. Expected to be
   * overloaded by child.
   * @param {Array} dates String array of date properties to format.
   * @returns {Object} Mapped properties
   */
  getDisplayVersion(dates: string[] = []) {
    let values: any = this.getObjectValues();
    // Add view properties
    if (this.viewProperties) {
      values = merge(values, this.getObjectValues([], this.viewProperties));
    }
    // Add derived properties
    if (this.derivedProperties) {
      values = merge(values, this.getObjectValues([], this.derivedProperties));
    }

    // Add display values for dates
    if (dates.length === 0) dates = this.dateProperties;
    for (const date of dates) {
      if (Object.prototype.hasOwnProperty.call(values, date)) {
        let displayProperty = `display_${date}`;
        if (this.useCamelCase) displayProperty = camelCase(displayProperty);
        values[displayProperty] = formatTimestamp(
          values[date],
          this.dateFormat
        );
      }
    }
    // Add a display version flag to distinguish between
    // a model and display object
    values.isDisplayVersion = true;
    // Add initial values for easier re-initializing
    values.initialValues = cloneDeep(this.initialValues);
    return values;
  }

  /**
   * Returns the save version of this class.
   * @returns {Object} Mapped properties
   */
  getSaveVersion(): Record<string, unknown> {
    const values: any = this.getObjectValues(
      ["created", "modified"],
      this.properties,
      true
    );
    // Remove the uuid if it's empty or null
    if (Object.prototype.hasOwnProperty.call(values, "uuid") && !values.uuid) {
      delete values.uuid;
    }
    // Remove the number if it's empty or null
    if (
      Object.prototype.hasOwnProperty.call(values, "number") &&
      !values.number
    ) {
      delete values.number;
    }
    // Add a created by user if property exists and
    // if a current user is set
    if (
      Object.prototype.hasOwnProperty.call(values, "created_by") &&
      !values.created_by &&
      this.currentUserId
    ) {
      values.created_by = this.currentUserId;
    }
    // Add a modified by user if property exists and
    // if a current user is set
    if (
      Object.prototype.hasOwnProperty.call(values, "modified_by") &&
      this.currentUserId
    ) {
      values.modified_by = this.currentUserId;
    }
    if (Object.prototype.hasOwnProperty.call(values, "modified")) {
      values.modified = getUTCNow();
    }
    return values;
  }

  /**
   * Returns object property values. Only properties in `this.properties`
   * will be returned. Will filter out relationships.
   * @param {Array} filter String array of properties to ignore.
   * @param {Object} properties Allows passing a different properties object
   * @param {boolean} forceOriginalProperties Does not convert to camel case regardless
   * @returns {Object}
   */
  getObjectValues(
    filter: string[] = [],
    properties: any = this.properties,
    forceOriginalProperties = false
  ): Record<string, unknown> {
    const values: any = {};
    // Adds set properties to values, or sets to default value
    // from `this.properties`.
    for (const p of Object.keys(properties)) {
      const alteredProperty: string = this.getPropertyName(p);
      const property: string = forceOriginalProperties ? p : alteredProperty;
      // Skip a property if it's included in the filter array
      if (filter.includes(property) || filter.includes(p)) continue;
      // Sets property to this model's property, if it the
      // value isn't null or undefined
      values[property] = !isNil(this[alteredProperty])
        ? clone(this[alteredProperty])
        : clone(properties[p]);
      // Check for and sanitize html values
      if (isHtml(values[property])) {
        values[property] = sanitizeHtmlString(values[property]);
      }
    }
    return values;
  }

  /**
   * Returns a validation object from class properties. Setting
   * properties to an empty object insures that `$anyDirty` is triggered
   * on changes
   * @returns {Object} validation template object
   */
  getValidationsTemplate(): Record<string, unknown> {
    const validations: any = {};
    for (const p of Object.keys(this.properties)) {
      const property: string = this.getPropertyName(p);
      validations[property] = {};
    }
    return validations;
  }

  /**
   * Returns a template string for a GraphQL query return object.
   * Can be appended for more complex operations.
   * @returns {string} query string template
   */
  getQueryReturnTemplate(useView = false): string {
    let query: string = Object.keys(this.properties).join(", ") + " ";
    if (useView) query += Object.keys(this.viewProperties).join(", ") + " ";
    return query;
  }

  /**
   * Returns a template string for a GraphQL query return object.
   * Uses only properties listed in `this.minifiedProperties`
   * Can be appended for more complex operations.
   * @returns {string} query string template
   */
  getMinifiedQueryReturnTemplate(useView = false): string {
    // We pull the property names from `this.properties`
    // (and `this.viewProperties` if useView is true) to
    // insure the minified properties have something to map to
    // and we have a valid query
    let properties: string[] = Object.keys(this.properties);
    if (useView) {
      properties = union(properties, Object.keys(this.viewProperties));
    }
    // Filter minified properties to only properties from above
    const minifiedProperties: string[] = this.minifiedProperties.filter(
      (p: any) => properties.includes(p)
    );
    const query: string = minifiedProperties.join(", ") + " ";
    return query;
  }

  /**
   * Returns an array of columns to update on upsert.
   * @param {Array} filter Columns to not update
   * @returns {string} query update template
   */
  getQueryUpdateColumns(
    filter: string[] = ["uuid", "created", "created_by"]
  ): string[] {
    return Object.keys(this.properties).filter(
      (p: string) => !filter.includes(p)
    );
  }

  /**
   * Returns an on_conflict GraphQL object for nested upsert queries.
   * @returns {Object}
   */
  getOnConflictObject(): Record<string, unknown> {
    if (!this.primaryKey || this.queryUpdateColumns.length === 0) return {};
    return {
      constraint: this.primaryKey,
      update_columns: this.queryUpdateColumns,
    };
  }

  /**
   * Returns an on_conflict GraphQL string for upsert queries.
   * @returns {string}
   */
  getOnConflictString(): string {
    if (!this.primaryKey) return "";
    return `{
      constraint: ${this.primaryKey},
      update_columns: [${this.queryUpdateColumns.join(", ")}]
    }`;
  }

  /**
   * Returns true if this model's properties match a given model
   * @param model
   * @returns {boolean | undefined}
   */
  sameAs(model: any = {}): boolean | undefined {
    for (const p of Object.keys(this.properties)) {
      const property: string = this.getPropertyName(p);
      if (!Object.prototype.hasOwnProperty.call(this, property))
        return undefined;
      if (!Object.prototype.hasOwnProperty.call(model, property)) return false;
      if (this[property] !== model[property]) return false;
    }
    return true;
  }
}
