
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import isEmpty from "lodash/isEmpty";
import { EventBus } from "@/util/eventbus";
import { getIcon } from "@/util/icons";
import { generateUniqueKey } from "@/util/helpers";

@Component
export default class BaseDialog extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: true })
  persistent!: boolean;

  @Prop({ default: true })
  dismissable!: boolean;

  @Prop({ default: true })
  scrollable!: boolean;

  @Prop({ default: false })
  fullscreen!: boolean;

  @Prop({ default: "800" })
  maxWidth!: string | number;

  @Prop({ default: () => [] })
  actions!: any;

  @Prop({ default: "both" })
  borders!: any;

  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: true })
  resetOnClose!: boolean;

  // COMPUTED -----------------------------------------------------
  get hasActions() {
    return !isEmpty(this.actions);
  }

  get hasActionsSlot() {
    return !!this.$slots.actions;
  }

  get hasTitle() {
    return !isEmpty(this.title);
  }

  get visible() {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  // METHODS ------------------------------------------------------
  getIcon(icon: string) {
    return getIcon("base", icon);
  }

  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
