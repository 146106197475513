import boms from "./boms";
import inventory from "../inventory";
import lots from "./lots";
import transfers from "./transfers";
import locations from "./locations";
import pallets from "./pallets";
import transaction from "./transaction";
export default {
  boms,
  inventory,
  locations,
  lots,
  transfers,
  pallets,
  transaction,
};
