
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import { isObject, generateUniqueKey, formatTimestamp } from "@/util/helpers";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

@Component
export default class BaseDataIteratorTable extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  headers!: any;

  @Prop({ default: () => [] })
  items!: any;

  @Prop({ default: undefined })
  itemCount!: number;

  @Prop({ default: "" })
  itemKey!: string; // Used in table for selecting items, should be unique

  @Prop({ default: () => [] })
  actions!: any;

  @Prop({ default: () => {} })
  status!: any;

  @Prop({ default: () => {} })
  footer!: any;

  /**
   * Defines which header to sort by. Can be an array, ie ["name", "desc"].
   * Default order is ascending. Default value is itemKey if nothing is defined.
   */
  @Prop({ default: "" })
  sortBy!: any;

  @Prop({ default: false })
  selectable!: boolean;

  @Prop({ default: () => [] })
  selectedItems!: any;

  @Prop({ default: false })
  hidePagination!: boolean; // Hides actions at bottom of table

  @Prop({ default: false })
  flat!: boolean; // Removes elevation on table if true

  @Prop({ default: false })
  compact!: boolean; // Reduces padding on table elements

  @Prop({ default: false })
  dense!: boolean; // Reduces padding on table elements

  @Prop({ default: null })
  addItemButton!: any; // Button at bottom of table to add items

  // LOCAL VARIABLES -----------------------------------------------------------
  selected: any = this.selectedItems;
  rowsPerPage: any = [10, 25, 50, 100];
  pagination: any = {};
  updatedSortBy: string = "";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns true if the actions array is not empty
   * @returns {boolean}
   */
  get hasActions() {
    return !isEmpty(this.actions);
  }

  /**
   * Returns true if the headers array includes progress
   * @returns {boolean}
   */
  get hasProgress() {
    for (let header of this.headers) {
      if (header.value === "progress") return true;
    }
    return false;
  }

  /**
   * Returns headers array, excluding actions, status, and progress
   * @returns {Array}
   */
  get filteredHeaders() {
    return filter(this.headers, function (h) {
      return !["actions", "status", "progress"].includes(h.value);
    });
  }

  get finalSortBy() {
    return this.updatedSortBy;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("selected")
  onSelectedChanged() {
    if (this.selected !== this.selectedItems) {
      let selectedIndexes = [];
      for (let item of this.selected) {
        let index = findIndex(this.items, item);
        if (index > -1) {
          selectedIndexes.push(index);
        }
      }
      this.$emit("update-selected-indexes", selectedIndexes);
    }
  }

  @Watch("pagination")
  onPaginationChanged() {
    this.$emit("update-pagination", this.pagination);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.initialSort();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Adds item as callback param to actions
   */
  getActions(item: any) {
    let actions = [];
    for (let a of this.actions) {
      let action = cloneDeep(a);
      action.callbackParams = item;
      if (
        action.hasOwnProperty("disabled") &&
        typeof action.disabled === "function"
      ) {
        action.disabled = action.disabled(item);
      }
      actions.push(action);
    }
    return actions;
  }

  /**
   * Sorts the table by `sortBy` value or title
   */
  initialSort() {
    // Show all rows if the pagination is hidden
    if (this.hidePagination) {
      this.rowsPerPage = [-1];
    }
    if (isEmpty(this.headers)) return;
    for (let header of this.headers) {
      // Parse if sortBy is an array
      if (
        Array.isArray(this.sortBy) &&
        this.sortBy.length > 0 &&
        header.value === this.sortBy[0]
      ) {
        this.pagination.sortBy = [header.value];
        if (this.sortBy[1] && this.sortBy[1] === "desc") {
          this.pagination.sortDesc = [true];
        }
        break;
      }
      // Parse if sortBy is a string
      else if (this.sortBy && header.value === this.sortBy) {
        this.pagination.sortBy = [header.value];
        break;
      }
      // Default sort column to the title
      if (header.title) {
        this.pagination.sortBy = [header.value];
      }
    }
  }

  /**
   * Returns color for progress bar
   */
  progressColor(value: number) {
    if (value < 50) return "red";
    if (value >= 50 && value < 75) return "yellow";
    if (value >= 75 && value < 100) return "blue";
    if (value > 100) return "red";
    return "primary";
  }

  // localeSort(items: any[], index: number, isDescending: boolean) {
  // 	if (this.finalSortBy === null) {
  // 		return items;
  // 	}
  // 	return items.sort((a: any, b: any) => {
  // 		if (typeof a[this.finalSortBy] === "string") {
  // 			return !isDescending
  // 				? a[this.finalSortBy].localeCompare(b[this.finalSortBy], undefined, {
  // 						numeric: true,
  // 						sensitivity: "base"
  // 				  })
  // 				: b[this.finalSortBy].localeCompare(a[this.finalSortBy], undefined, {
  // 						numeric: true,
  // 						sensitivity: "base"
  // 				  });
  // 		} else {
  // 			return !isDescending
  // 				? a[this.finalSortBy] - b[this.finalSortBy]
  // 				: b[this.finalSortBy] - a[this.finalSortBy];
  // 		}
  // 	});
  // }

  updateSortBy(paginationData: any) {
    this.updatedSortBy = paginationData.sortBy;
  }

  /**
   * Returns true if the status object is not empty, and the given item has a status
   * @returns {boolean}
   */
  hasStatus(item: any) {
    return !isEmpty(this.status) && item.status;
  }

  /**
   * Returns the status object for this item. This object should include:
   * label, color, icon, and value
   * @param {string} value - Status value
   * @returns {Object} Status object
   */
  getStatus(value: any = "") {
    return this.status[value] || null;
  }

  /**
   * Encapsulate generateUniqueKey for use in template
   * @param {Object} item - Iterator item
   * @returns {string} Hashed key
   */
  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }

  /**
   * Encapsulate isObject helper function for use in template
   */
  isObject(item: any) {
    return isObject(item);
  }

  /**
   * Allows programmatic selection of items from external entities.
   * Contingent on specified "itemKey" in component props.
   * @param {string} keyToSelect - Key of item that should be selected, by "itemKey"
   */
  selectItem(keyToSelect: string) {
    if (this.itemKey)
      this.$refs[`${this._uid}-checkbox-${keyToSelect}`].click();
  }
}
