import { retrieveObject, set } from "@/util/vuex";
import axios from "axios";
import CropOrderLine from "../../models/growops/crop/OrderLine";
import HarvestTask, { IHarvestTask } from "../../models/growops/tasks/Harvest";

interface HarvestTaskState {
  [key: string]: any;
  currentHarvestTask: IHarvestTask | null;
  sessionHarvestTasks: Array<IHarvestTask>;
  currentCropOrderLine: { [key: string]: any } | null;
  retrieveTaskInterval: number;
}

const HARVEST_TASK_DEFAULT: HarvestTaskState = {
  currentHarvestTask: null,
  sessionHarvestTasks: [],
  currentCropOrderLine: null,
  retrieveTaskInterval: 5000,
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = () => {
  const state: any = {};
  for (const property in HARVEST_TASK_DEFAULT) {
    state[property] = HARVEST_TASK_DEFAULT[property];
  }
  return state;
};

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------
const mutations = {
  setCurrentHarvestTask: set("currentHarvestTask"),
  addHarvestTask(state: HarvestTaskState, payload: any) {
    state.sessionHarvestTasks.push(payload.harvestTask);
  },
  setCurrentCropOrderLine: set("currentCropOrderLine"),
  resetStore(state: any) {
    Object.assign(state, HARVEST_TASK_DEFAULT);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------
const actions = {
  resetStore(context: any) {
    context.commit("resetStore");
  },
  /**
   * Sends a basic upsert query for the harvest task table
   * @param context Store module context
   * @param {string} uuid Facility UUID
   */
  saveHarvestTask: function (context: any, { harvestTask }: any) {
    const harvestTaskTemplate = new HarvestTask();
    const upsertQuery: string = harvestTaskTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: harvestTask,
          },
        })
        .then(
          (success: any) => {
            // Set the current crop detail
            const queryName = `insert_${harvestTaskTemplate.table}`;
            context.commit(
              "setCurrentHarvestTask",
              success.data.data[queryName].returning[0]
            );
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves a Harvest Task with the associated order and line id
   * @param context Store module context
   * @param {string} uuid Harvest task uuid
   */

  retrieveHarvestTaskByOrderAndLineId(
    context: any,
    { order_id, line_id }: any
  ) {
    const taskTemplate = new HarvestTask();
    const returnQuery = taskTemplate.getReturnQuery(
      `{_and:[
          {order_id:{_eq:"${order_id}"}},
          {line_id:{_eq:"${line_id}"}}
        ]}`
    );

    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentHarvestTask",
      taskTemplate.table
    );
  },

  /**
   * Retrieves a Harvest Task with the associated uuid
   * @param context Store module context
   * @param {string} uuid Harvest task uuid
   */

  retrieveHarvestTask(context: any, { uuid }: any) {
    const taskTemplate = new HarvestTask();
    const returnQuery = taskTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}" }}`
    );

    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentHarvestTask",
      taskTemplate.table
    );
  },
  /**
   * Retrieves a Crop order line with the associated line
   * and order id pair
   * @param context Store module context
   * @param {string} order_id crop order uuid
   * @param {string} line_id destination line uuid
   */

  retrieveCropOrderLine(context: any, { order_id, line_id }: any) {
    if (!line_id) return;

    const orderLineTemplate = new CropOrderLine();
    const returnQuery = orderLineTemplate.getReturnQuery(
      `{_and:[
        {order_id:{_eq:"${order_id}"}},
        {destination_line_id:{_eq:"${line_id}"}}
      ]}`
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentCropOrderLine",
      orderLineTemplate.table
    );
  },

  /**
   * saves a Crop order line
   * @param context Store module context
   * @param {object} cropOrderLine crop order uuid
   */

  saveCropOrderLine(context: any, { cropOrderLine }: any) {
    const orderLineTemplate = new CropOrderLine();
    const upsertQuery: string = orderLineTemplate.getUpsertQuery();
    if (!upsertQuery) return;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: cropOrderLine,
          },
        })
        .then(
          (success: any) => {
            const queryName = `insert_${orderLineTemplate.table}`;
            context.commit(
              "setCurrentCropOrderLine",
              success.data.data[queryName].returning[0]
            );
            resolve(true);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
