import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import { store } from "./store";
import App from "./App.vue";
import GlobalMixin from "./mixins/Global.vue";

import PortalVue from "portal-vue";
import VueMeta from "vue-meta";
import { createRouter } from "./router/createRouter";
import { registerComponents } from "./config/registerComponents";
import VueRouter from "vue-router";
import { Store } from "vuex";
import Vuetify from "vuetify";
import { VUETIFY_OPTIONS } from "./vuetify/vuetify";

export class CoreModule implements VueModule {
  readonly name = "core";
  router?: VueRouter;
  store?: Store<any>;
  constructor() {
    this.router = createRouter(store);
    this.store = store;
  }
  install(Vue: typeof _Vue) {
    registerComponents(Vue);
    Vue.use(Vuetify);
    Vue.use(PortalVue);
    Vue.use(VueMeta);
    Vue.use(VueRouter);
    let vuetify = new Vuetify(VUETIFY_OPTIONS);
    new Vue({
      mixins: [GlobalMixin],
      router: this.router,
      store: store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
}
