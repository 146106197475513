import { render, staticRenderFns } from "./KioskHeader.vue?vue&type=template&id=661e8975&scoped=true&"
import script from "./KioskHeader.vue?vue&type=script&lang=ts&"
export * from "./KioskHeader.vue?vue&type=script&lang=ts&"
import style0 from "./KioskHeader.vue?vue&type=style&index=0&id=661e8975&prod&lang=scss&scoped=true&"
import style1 from "./KioskHeader.vue?vue&type=style&index=1&id=661e8975&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e8975",
  null
  
)

export default component.exports