
import { Vue, Component, Prop } from "vue-property-decorator";
import ButtonOptions from "@/types/app/shared/ButtonOptions";
import { EventBus } from "@/util/eventbus";
@Component
export default class DockButton extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ type: Object })
  options!: ButtonOptions;

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  emitEvent() {
    EventBus.$emit(this.options.event);
  }
}
