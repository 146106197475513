
import { Component, Prop, Mixins } from "vue-property-decorator";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getIcon } from "@/util/icons";
import { formatTimestamp, getUTCFromDate } from "@/util/helpers";
import Form from "../../mixins/Form.vue";
import { DateTime } from "luxon";

@Component({
  validations: {
    date: { required: requiredIf("required") },
  },
})
export default class BaseDatePicker extends Mixins(Form) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  min!: string;

  @Prop({ default: "" })
  max!: string;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: "" })
  value!: string;

  @Prop({ default: getIcon("base", "date") })
  icon!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  clearable!: boolean;

  // LOCAL VARIABLES -----------------------------------------------------------
  showMenu: boolean = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns formatted date from `this.value`.
   * ie, `yyyy-MM-dd`
   * @returns {string}
   */
  get date() {
    return this.getPickerDate(this.value) || "";
  }

  /**
   * Emits input to change `this.value`.
   * ie, `yyyy-MM-dd +Timezone`
   */
  set date(value: string) {
    this.$emit("input", getUTCFromDate(value));
  }

  /**
   * Returns formatted date from `this.value`.
   * ie, `yyyy-MM-dd`
   * @returns {string}
   */
  get minDate() {
    if (this.min) {
      return DateTime.fromJSDate(new Date(this.min))
        .toUTC()
        .toFormat("yyyy-MM-dd");
    }
    return "";
  }

  /**
   * Returns true if any validation errors exist
   * @returns {boolean}
   */
  get hasErrors() {
    return this.$v.$anyError;
  }

  /**
   * Returns a prettified display date.
   * ie, `ddd, MMMM Do YYYY`
   * @returns {string}
   */
  get formattedDate() {
    return formatTimestamp(this.date, "date");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns an ISO date string.
   * ie, `yyyy-MM-dd`
   * @returns {string}
   */
  getPickerDate(value: string) {
    return formatTimestamp(value, "datepicker");
  }
}
