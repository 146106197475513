
import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonOptions from "@/types/app/shared/ButtonOptions";
import { EventBus } from "@/util/eventbus";
import { ICON_DEFINITIONS } from "@/util/icons";

@Component
export default class Dock extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  actions!: ButtonOptions[];

  @Prop({ type: Object, default: null })
  back!: ButtonOptions;

  @Prop({ type: Object, default: null })
  next!: ButtonOptions;

  menuButton: ButtonOptions = {
    label: "Actions",
    event: "open-menu",
    icon: "mdi-menu",
  };
  fab = false;

  closeOnClick = true;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Computed property to give back button an icon if it's missing
   */
  get backButton(): ButtonOptions {
    if (this.back && this.back.icon) return this.back;
    return {
      ...this.back,
      icon: ICON_DEFINITIONS.base.back,
    };
  }
  /**
   * Computed property to give next button an icon if it's missing
   */
  get nextButton(): ButtonOptions {
    if (this.next && this.next.icon) return this.next;
    return {
      ...this.next,
      icon: ICON_DEFINITIONS.base.next,
    };
  }
  /**
   * Address when the appbar is visible
   * returns true when at least one of the
   * buttons is passed as prop else return false
   */
  get isVisible(): boolean {
    return !!this.back || !!this.next || this.actions.length > 0;
  }

  emitEvent(event: string) {
    EventBus.$emit(event);
  }
}
