
import Dialog from "@/modules/core/mixins/Dialog.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import { EventBus } from "@/util/eventbus";

@Component
export default class SavingDialog extends Mixins(Dialog) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  homeRoute!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  label: string = "Saving";
  scoped = false;
  error: boolean = false;
  warning: boolean = false;
  finished: boolean = false;
  saveItem: object = {};

  completeIcon: string = getIcon("base", "complete");
  errorIcon: string = getIcon("base", "error");

  portalDestination = "saving-dialog-actions";

  events = [
    {
      name: "update-saving-dialog-label",
      function: this.updateLabel,
    },
    {
      name: "set-saving-dialog-error",
      function: this.setError,
    },
    {
      name: "set-saving-dialog-finished",
      function: this.setFinished,
    },
    {
      name: "saving-dialog-route-home",
      function: this.routeToHome,
    },
  ];

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.setCloseEventName("close-saving-dialog");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  initialize(values: any = {}) {
    this.label = values.label || "Saving";
    this.error = false;
    this.warning = false;
    this.finished = false;
    this.setActions();
  }

  updateLabel(label: string = "", disableButtons: boolean = false) {
    this.label = label;
    if (disableButtons) {
      this.actions = [];
      this.warning = false;
    }
  }

  setActions(passedActions: any = null) {
    let actions: Array<any> = [];
    if (this.finished || this.error || this.warning) {
      actions.push({
        callback: "close-saving-dialog",
        label: this.warning ? "Cancel" : "Dismiss",
      });
    }
    if (this.finished && this.homeRoute) {
      actions.push({
        callback: `saving-dialog-route-home`,
        label: "Go Home",
      });
    }

    if (passedActions) {
      actions.push(passedActions);
    }
    this.actions = actions;
  }

  setError(label: string, warning: boolean = false) {
    if (label) this.label = label;
    if (warning) {
      this.warning = true;
      this.setActions({
        callback: "saving-dialog-confirmation",
        label: "Add Lot",
      });
    } else {
      this.error = !!label;
      this.setActions();
    }
  }

  setFinished(label: string) {
    this.finished = !!label;
    if (label) this.label = label;
    this.setActions();
  }

  routeToHome() {
    this.$router.push({ name: this.homeRoute });
  }
}
