import { RouteConfig } from "vue-router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const harvestRoutes: RouteConfig = {
  path: "/harvest",
  component: () => import("@/modules/storage/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/harvest/views/Landing.vue"),
      name: "HarvestMain",
    },
    {
      path: "tasks",
      component: () => import("@/modules/harvest/views/Tasks.vue"),
      name: "HarvestTasks",
    },
    {
      path: "plots/:uuid",
      name: "HarvestPlots",
      component: () => import("@/modules/harvest/views/Plots.vue"),
    },
    {
      path: "data/:taskUuid",
      name: "HarvestData",
      props: true,
      component: () => import("@/modules/harvest/views/Data.vue"),
    },
    {
      path: "waiting-weight",
      name: "WaitingWeight",
      component: () => import("@/modules/harvest/views/Wait.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("harvest");
    next();
  },
};
