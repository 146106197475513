
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { orderBy, every } from "lodash";
import BaseSelection from "@/modules/core/mixins/BaseSelection.vue";

@Component
export default class PackoutSelection extends Mixins(BaseSelection) {
  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Packout";
  optionsName = "packoutOptions";

  // ---------------------------------------------------------------------------
  // PROPS
  // ---------------------------------------------------------------------------
  @Prop({ default: false })
  dateRangeQuery!: boolean;

  @Prop({ default: "" })
  startDate!: string;

  @Prop({ default: "" })
  endDate!: string;

  @Prop({ default: "" })
  type!: string;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of packout options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets packouts from the store.
   * @returns {Array}
   */
  get packoutOptions() {
    if (this.type === "shift") {
      return orderBy(
        (Object.values(this.$store.state.packout.packouts) || []).reduce(
          (acc: any[], p: any) => {
            if (p.type === "shift") {
              acc.push({
                text: `PK/${p.number}`,
                value: p.uuid,
                number: p.number,
                notes: p.notes,
                created: p.created,
                completed: this.checkCompletedGoods(p.goods),
              });
            }
            return acc;
          },
          []
        ),
        "number",
        "desc"
      );
    } else {
      return [];
    }
  }
  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("packoutOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (this.packoutOptions.length === 1) {
      this.selected = this.packoutOptions[0].value;
    }
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update packouts from the database
   */
  async retrieveData(): Promise<any> {
    this.isLoading = true;
    if (this.dateRangeQuery) {
      await this.$store.dispatch("packout/retrievePackoutsWithDateRange", {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type,
      });
    }
    this.isLoading = false;
  }

  /**
   * Function to check for completed good statuses when using the
   * base PackoutOrder Model
   */
  checkCompletedGoods(packoutGoods: any) {
    return every(
      packoutGoods,
      (good: any) => good.completed !== null && good.completed !== undefined
    );
  }

  /**
   * Function to set the drop down color based on completed status of the packout
   * Red represents completed and black represents In Progress
   */
  getColor(item: any) {
    if (!item.completed) {
      return "black";
    } else {
      return "red";
    }
  }

  /**
   * Resets items
   */
  resetData() {
    this.$store.commit("packout/reset", "packouts");
  }
}
