
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getIcon } from "@/util/icons";
import Form from "../../mixins/Form.vue";
import { CropOrderLocation } from "@eaua/model";

@Component({
  validations: {
    location: {
      zone_id: { required },
      plot_ids: { required: requiredIf("requirePlots") },
    },
  },
})
export default class ZoneLocationSelection extends Mixins(Form) {
  /// PROPS --------------------------------------------------------------------
  @Prop({ default: () => new CropOrderLocation() })
  location!: CropOrderLocation;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: "" })
  facility!: string;

  @Prop({ default: 0 })
  index!: number;

  @Prop({ default: true })
  requirePlots!: boolean;

  @Prop({ default: "" })
  zoneType!: string;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns true if the ref is invalid
   * @returns {boolean}
   */
  get invalid() {
    if (!this.location.zone_id) return true;
    if (this.requirePlots && this.location.plot_ids.length === 0) return true;
    // Disable save if validation's pending
    if (this.$v.$pending) return true;
    if (this.isLoading) return true;
    if (this.$v.$anyError) return true;
    return false;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("invalid")
  onValidityChanged() {
    this.location.valid = !this.invalid;
  }

  @Watch("location.zone_id")
  onZoneChanged() {
    this.validateInput("location.zone_id");
    this.location.plot_ids = [];
  }

  @Watch("location.plot_ids", { deep: true })
  onPlotsChanged() {
    this.validateInput("location.plot_ids");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
