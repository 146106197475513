import BaseModel from "../Base";
import { formatPhoneNumber } from "@/util/helpers";
import get from "lodash/get";

export default class Contact extends BaseModel {
  table = "shared_contacts";
  primaryKey = "contacts_pkey";

  properties = {
    uuid: "",
    name: "",
    description: null,
    address: null,
    phone: null,
    email: null,
    website: null,
    created: null,
    created_by: null,
    title: null,
    type: "default",
  };

  // Derived properties
  display_address = "";

  // Config: will eventually move to database
  config: any = {
    types: {
      default: {
        text: "Default",
        value: "default",
      },
      accounting: {
        text: "Accounting",
        value: "accounting",
      },
      distribution: {
        text: "Distribution",
        value: "distribution",
      },
      purchasing: {
        text: "Buyer",
        value: "buyer",
      },
      shipping: {
        text: "Shipping",
        value: "shipping",
      },
      other: {
        text: "Other",
        value: "other",
      },
    },
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Set derived values
    this.setDisplayAddress();
  }

  // ---------------------------------------------------------------------------
  // SETTERS
  // ---------------------------------------------------------------------------

  /**
   * Sets the display address for this contact
   */
  setDisplayAddress() {
    this.display_address = get(this.address, "formatted", "");
  }

  // ---------------------------------------------------------------------------
  // GETTERS
  // ---------------------------------------------------------------------------

  /**
   * Returns a display Contact
   * @returns {Object}
   */
  getDisplayVersion(): object {
    const values: any = super.getDisplayVersion();
    values.address = this.display_address;
    values.phone = formatPhoneNumber(this.phone || "");
    if (values.type) {
      values.type = get(this.config, `types.${values.type}.text`, "");
    }
    return values;
  }
}
