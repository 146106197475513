
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

@Component
export default class BaseDataIteratorCard extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  headers!: any;

  @Prop({ default: () => {} })
  item!: any;

  @Prop({ default: () => [] })
  actions!: any;

  @Prop({ default: () => {} })
  status!: any;

  @Prop({ default: false })
  selectable!: boolean; // Allows item to be selected

  @Prop({ default: false })
  twoLineList!: boolean; // Displays card header above the value

  @Prop({ default: false })
  hideHeaders!: boolean; // Only shows values and icons on cards if true

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the item property that is defined as the title in headers
   * @returns {string}
   */
  get title() {
    if (!this.hasHeaders) return "";
    for (let header of this.headers) {
      if (!header.title) continue;
      // Return the display value if one is set, otherwise, return the item
      // property that matches this header's value
      if (header.displayValue) {
        return this.item[header.displayValue];
      } else return this.item[header.value];
    }
    return "";
  }

  /**
   * Returns the item property that is defined as the subtitle in headers
   * @returns {string}
   */
  get subtitle() {
    if (!this.hasHeaders) return "";
    for (let header of this.headers) {
      if (!header.subtitle) continue;
      // Return the display value if one is set, otherwise, return the item
      // property that matches this header's value
      if (header.displayValue) {
        return this.item[header.displayValue];
      } else return this.item[header.value];
    }
    return "";
  }

  /**
   * Returns true if the actions array is not empty
   * @returns {boolean}
   */
  get hasActions() {
    return !isEmpty(this.actions);
  }

  /**
   * Returns true if the headers array is not empty
   * @returns {boolean}
   */
  get hasHeaders() {
    return !isEmpty(this.headers);
  }

  /**
   * Returns true if the headers array is not empty
   * @returns {boolean}
   */
  get hasHeadersToList() {
    let count: number = 0;
    for (let header of this.headers) {
      if (header.title || header.subtitle) continue;
      if (["status", "actions", "progress"].includes(header.value)) continue;
      count++;
    }
    return count > 0;
  }

  /**
   * Returns true if the status object is not empty, and this item has a status
   * @returns {boolean}
   */
  get hasStatus() {
    return !isEmpty(this.status) && this.getStatus(this.item.status);
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  /**
   * Emits `select-item` with value of `this.item`
   */
  @Emit()
  selectItem() {
    return this.item;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns the actions array with this item added to callback params. This
   * allows for items to be passed to a dialog, for example
   * @param {Oject} item
   * @returns {Array} Actions array
   */
  getActions(item: any) {
    let actions: any = cloneDeep(this.actions);
    for (let action of actions) {
      if (
        action.hasOwnProperty("disabled") &&
        typeof action.disabled === "function"
      ) {
        action.disabled = action.disabled(item);
      }
      action.callbackParams = item;
    }
    return actions;
  }

  /**
   * Returns the status object for this item. This object should include:
   * label, color, icon, and value
   * @param {string} value - Status value
   * @returns {Object|null} Status object
   */
  getStatus(value: string = "") {
    return this.status[value] || null;
  }

  /**
   * Encapsulate getIcon for use in template
   * @param {string=} category - Icon category
   * @param {string=} icon - Icon name
   * @returns {string} Icon value
   */
  getIcon(category: string = "", icon: string = "") {
    return getIcon(category, icon);
  }
}
