
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import maxValue from "@/util/validators/maxValue";
import minValue from "@/util/validators/minValue";
import { required, requiredIf } from "vuelidate/lib/validators";

@Component({
  validations: {
    number: {
      maxValue: maxValue("max"),
      minValue: minValue("min"),
      required: requiredIf("required"),
    },
  },
})
export default class BaseNumberField extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: null })
  value!: any;

  @Prop({ default: () => {} })
  config!: any;

  @Prop({ default: () => {} })
  row!: any;

  // LOCAL VARIABLES -----------------------------------------------------------
  number: any = this.value || null;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns the label set in config. This is used for the field label
   * and error messages.
   * @returns {string}
   */
  get label() {
    if (this.config.label) return this.config.label;
    return "";
  }

  /**
   * Returns the max value set in config.
   * @returns {number|null}
   */
  get max() {
    if (
      this.config.hasOwnProperty("max") &&
      typeof this.config.max === "function"
    )
      return this.config.max(this.row);
    if (this.config.hasOwnProperty("max")) return this.config.max;
    return null;
  }

  /**
   * Returns the min value set in config.
   * @returns {number|null}
   */
  get min() {
    if (this.config.hasOwnProperty("min")) return this.config.min;
    return null;
  }

  /**
   * Returns params for error messages
   * @returns {Object}
   */
  get params() {
    let params: any = {};
    if (this.max) params.max = this.max;
    if (this.min || this.min === 0) params.min = this.min;
    return params;
  }

  /**
   * Returns true if required is set in config.
   * @returns {boolean}
   */
  get required() {
    if (this.config.required) return true;
    return false;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  /**
   * V-model was not updating correctly, so this is a workaround
   */
  @Watch("number")
  onNumberChanged() {
    this.$emit("input", this.number);
    EventBus.$emit("iterator-number-field-changed");
  }
}
