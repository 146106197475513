
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { getIcon } from "@/util/icons";
import get from "lodash/get";
import union from "lodash/union";
import BaseSelection from "@/modules/core/mixins/BaseSelection.vue";

@Component
export default class PlotSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: false })
  showAvailable!: boolean;

  @Prop({ default: false })
  showIcon!: boolean;

  @Prop({ default: false })
  showLine!: boolean;

  @Prop({ default: false })
  showOccupied!: boolean;

  @Prop({ default: true })
  showOrder!: boolean;

  @Prop({ default: true })
  smallChips!: boolean;

  @Prop({ default: "" })
  zoneId!: string;

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Plot";
  optionsName = "plotOptions";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of plot options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets plots from the store.
   * @returns {Array}
   */
  get plotOptions(): Array<any> {
    let options: any[] = [];
    if (this.isLoading) return options;
    if (!this.required && !this.allowMultiple) {
      options.push({ value: null, text: "None" });
    }
    if (this.options)
      return this.filterUsedOptions(union(options, this.options));
    if (!this.zoneId) return [];
    return this.filterUsedOptions(
      union(
        options,
        this.$store.getters["facilities/getPlotOptionsForStandardSelection"](
          this.zoneId,
          this.showAvailable,
          this.showAvailable,
          this.showOccupied,
          this.showLine,
          this.showOrder
        )
      )
    );
  }

  /**
   * Returns an icon if `this.showIcon` is true
   * @returns {string | null}
   */
  get icon() {
    if (this.showIcon) return getIcon("facility", "plot");
    return null;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("plotOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (this.plotOptions.length === 1)
      this.selected = this.plotOptions[0].value;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update lots from the database
   */
  async retrieveData(): Promise<any> {
    if (
      this.zoneId &&
      get(
        this.$store,
        `state.facilities.plotOptions[${this.zoneId}].length`,
        0
      ) === 0
    ) {
      this.isLoading = true;

      // Add filters to where clause
      let where: string = `
        {
          zone_id: {_eq: "${this.zoneId}"},
          active: {_eq: true}
        }
      `;

      // Override where if an override statement is passed via prop
      if (this.overrideWhere) where = this.overrideWhere;

      // Dispatch query
      await this.$store.dispatch("facilities/retrievePlotOptions", {
        where: where,
      });
      this.isLoading = false;
    }
  }

  /**
   * Resets items
   */
  resetData() {
    this.$store.commit("facilities/reset", "plotOptions");
  }
}
