
import { Vue, Component } from "vue-property-decorator";
import { ICellRenderer, ICellRendererParams } from "ag-grid-community";
import { IEventBusEvent, setListeners, unsetListeners } from "@/util/eventbus";
import get from "lodash/get";

const AUTOSAVE_DEFAULTS = () => {
  return {
    class: "",
    color: "",
    icon: "",
    label: "",
    loading: false,
    timestamp: "",
  };
};

@Component
export default class AutosaveCell extends Vue implements ICellRenderer {
  params!: ICellRendererParams;

  autosave: any = AUTOSAVE_DEFAULTS();
  events: Array<IEventBusEvent> = [];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get iconTooltip(): any {
    let label: string = this.autosave.label;
    if (label && this.autosave.timestamp && !this.autosave.loading) {
      label += ` at ${this.autosave.timestamp}`;
    }
    return {
      class: this.autosave.class,
      color: this.autosave.color || "primary",
      value: this.autosave.icon,
      label: label,
    };
  }

  get renderText(): string | number {
    return this.params.value;
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.events.push({
      name: `autosave-update-${get(this.params, "colDef.field", "none")}-${
        this.params.rowIndex
      }`,
      function: (value: any) => {
        this.autosave = value || AUTOSAVE_DEFAULTS();
      },
    });
  }

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
