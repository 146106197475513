
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import Form from "@/modules/core/mixins/Form.vue";
import { mapState } from "vuex";
import Profile from "@/modules/core/models/shared/Profile";

@Component({
  computed: {
    ...mapState("user", ["profile"]),
  },
})
export default class ConfirmResetForm extends Mixins(Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------

  //Props
  @Prop({ default: "destination" })
  destination!: string;

  // LOCAL VARIABLES -----------------------------------------------------------

  events: IEventBusEvent[] = [
    {
      name: "confirm-reset-task",
      function: this.confirmReset,
    },
  ];

  /**
   * Returns action buttons array that's passed to the parent dialog
   * @returns {Array}
   */
  get actions(): Array<any> {
    return [
      {
        callback: "confirm-reset-task",
        label: "Yes",
        isLoading: this.isLoading,
      },
    ];
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  buildProfile() {
    let profile = new Profile(this.profile || {});
    if (!profile.details) profile.details = {};

    profile.details.current_work_item.completed = false;

    return profile.getSaveVersion();
  }

  /** Saves the current profile with the current working
   * harvest task in the details object
   */
  async confirmReset() {
    this.isLoading = true;
    EventBus.$emit("toggle-cancel");
    await this.$store
      .dispatch("user/saveProfile", {
        profile: this.buildProfile(),
      })
      .then(() => {
        emitNotification({
          priority: "low",
          message: "Returning to main screen.",
          title: "Success",
          type: "success",
        });

        this.$router.push({ name: "MixingWeightLanding" });
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to reset task. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });

    EventBus.$emit("toggle-cancel");
    this.isLoading = false;
  }
}
