
import { Vue, Component } from "vue-property-decorator";

@Component
export default class EnvironmentHeader extends Vue {
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get isProd() {
    return process.env.VUE_APP_ENV === "prod";
  }
  get environmentString() {
    return process.env.VUE_APP_ENV?.toUpperCase();
  }
}
