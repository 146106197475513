import BaseModel from "../../Base";
import { required } from "vuelidate/lib/validators";

export default class BoxType extends BaseModel {
  table = "growops_delivery_box_types";
  primaryKey = "delivery_box_types_pkey";

  properties = {
    uuid: "",
    name: "",
    details: null,
  };

  constructor(values: any = {}, store: any = {}) {
    super(store);
    this.initialize(values);
  }

  // ---------------------------------------------------------------------------
  // INITIALIZE
  // ---------------------------------------------------------------------------

  initialize(values: any = {}) {
    // Call parent initialize function
    super.initialize(values);

    // Add validation rules
    this.validations.name = { required };
  }
}
