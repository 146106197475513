import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { Store } from "vuex";
import { fulfillmentRoutes } from "./router/fulfillment-routes";

export class FulfillmentModule implements VueModule {
  readonly name = "fulfillment";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(fulfillmentRoutes);
  }
}
