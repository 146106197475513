import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { PropagationRoutes } from "./router/propagation-routes";

export class PropagationModule implements VueModule {
  readonly name = "propagation";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(PropagationRoutes);
  }
}
