import { set, setDeep, retrieveSet, retrieveObject } from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import axios from "axios";
import find from "lodash/fp/find";
import Location from "../../../models/growops/inventory/Location";
import Room from "../../../models/growops/inventory/LocationRoom";

const LOCATION_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    locations: [],
    currentLocation: {},
    rooms: [],
    currentRoom: {},
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = LOCATION_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setLocations: setDeep("locations"),
  setCurrentLocation: set("currentLocation"),
  setRooms: setDeep("rooms"),
  setCurrentRoom: set("currentRoom"),
  reset(state: any, property: string) {
    state[property] = LOCATION_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  // LOCATIONS ---------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Returns location options for a dropdown.
   * @returns {Array}
   */
  getLocationOptions: (state: any) => {
    return (state.locations || []).map((loc: any) => {
      const location = new Location(loc);
      return {
        text: location.getDisplayName(),
        value: loc.uuid,
      };
    });
  },

  /**
   * Returns an array of sorted locations for a given room
   * @param {string} room_id A room uuid
   * @returns {Array}
   */
  getLocationsFromRoom:
    (state: any, getters: any) =>
    (room_id: string = "") => {
      let room: any = getters["getRoomByUuid"](room_id);
      let locations: any = room.rel_locations || [];
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted location rooms
      return locations.sort((a: any, b: any) =>
        collator.compare(a.name, b.name)
      );
    },

  /**
   * Returns location options for a dropdown.
   * @returns {Array}
   */
  getLocationOptionsFromRoom:
    (state: any, getters: any) => (roomId: string) => {
      return getters["getLocationsFromRoom"](roomId).map((loc: any) => {
        const location = new Location(loc);
        return {
          text: location.getDisplayName(),
          value: loc.uuid,
        };
      });
    },

  /**
   * Returns an location given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getLocationByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.locations) || {};
    },

  getFilteredLocations: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventory-locations"] || [];
    let search = rootState.filter.search["inventory-locations"] || "";
    return filterItems(state.locations, filters, search);
  },

  // ROOMS -------------------------------------------------------------------
  // -------------------------------------------------------------------------

  /**
   * Returns rooms options for a dropdown.
   * @returns {Array}
   */
  getRoomOptions: (state: any) => {
    return (state.rooms || []).map((r: any) => {
      return {
        text: r.name,
        value: r.uuid,
      };
    });
  },

  /**
   * Returns a room by a nested location id
   * @returns {object}
   */
  getRoomByLocationId:
    (state: any) =>
    (locationId: string = "") => {
      if (!locationId) return {};
      for (const room of state.rooms) {
        for (const loc of room.rel_locations || []) {
          if (loc.uuid === locationId) return room;
        }
      }
      return {};
    },

  /**
   * Returns an rooms given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getRoomByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.rooms) || {};
    },

  getFilteredRooms: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters =
      rootGetters["filter/getRoutedFilters"]["inventory-rooms"] || [];
    let search = rootState.filter.search["inventory-rooms"] || "";
    return filterItems(state.rooms, filters, search);
  },

  getRoomRegistry: (state: any) => {
    let collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });
    return state.rooms.reduce((registry: any, room: any) => {
      room.locations = room.rel_locations.sort((a: any, b: any) =>
        collator.compare(a.name, b.name)
      );
      room.locationOptions = room.locations.map((loc: any) => {
        const location = new Location(loc);
        return {
          text: location.getDisplayName(),
          value: loc.uuid,
        };
      });
      registry[room.uuid] = room;
      return registry;
    }, {});
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // LOCATIONS ---------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves a location by uuid.
   * Commits location using `setCurrentLocation`.
   * @param context Store module context
   * @param {string} uuid Location UUID
   */
  retrieveLocation: function (context: any, { uuid }: any) {
    let locTemplate = new Location();
    let returnQuery: string = locTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentLocation",
      locTemplate.table
    );
  },

  /**
   * Resets currentLocation.
   */
  resetLocation: function (context: any) {
    mutations.reset(state, "currentLocation");
  },

  /**
   * Retrieves inventory locations by facility id (if passed)
   * Commits locations using `setLocations`.
   * @param context Store module context
   */
  retrieveLocations: function (context: any, { facilityId = "" }: any = {}) {
    let where: string = "";
    if (facilityId) where = `{rel_room: {facility_id: {_eq: "${facilityId}"}}}`;

    let locTemplate = new Location();
    let returnQuery: string = locTemplate.getReturnQuery(where, `{name: asc}`);
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setLocations", locTemplate.table);
  },

  /**
   * Saves an inventory location.
   * Refreshes locations using `retrieveLocations`.
   * @param context Store module context
   * @param {Object} location Location save-version object
   */
  saveLocation: function (context: any, { location }: any) {
    let locTemplate = new Location();
    let upsertQuery: string = locTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: location,
          },
        })
        .then(
          (success: any) => {
            resolve(success.data.data);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // ROOMS -------------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves a room by uuid.
   * Commits room using `setCurrentRoom`.
   * @param context Store module context
   * @param {string} uuid Room UUID
   */
  retrieveRoom: function (context: any, { uuid }: any) {
    let locTemplate = new Room();
    let returnQuery: string = locTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentRoom",
      locTemplate.table
    );
  },

  /**
   * Retrieves inventory rooms by facility id (if passed)
   * Commits rooms using `setRooms`.
   * @param context Store module context
   */
  retrieveRooms: function (context: any, { facilityId = "" }: any = {}) {
    let where: string = "";
    if (facilityId) where = `{ facility_id: {_eq: "${facilityId}"} }`;

    let locTemplate = new Room();
    let returnQuery: string = locTemplate.getReturnQuery(where, `{name: asc}`);
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setRooms", locTemplate.table);
  },

  /**
   * Saves an inventory room.
   * Refreshes rooms using `retrieveRooms`.
   * @param context Store module context
   * @param {Object} room Room save-version object
   */
  saveRoom: function (context: any, { room }: any) {
    let locTemplate = new Room();
    let upsertQuery: string = locTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: room,
          },
        })
        .then(
          (success: any) => {
            resolve(success.data.data);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
