import { set, toggle } from "@/util/vuex";
import groupBy from "lodash/groupBy";

export default {
  namespaced: true,

  state: {
    search: {},
    filters: [],
    filterDrawer: false,
    openOnRoutes: {},
  },

  getters: {
    getRoutedFilters: (state: any) => {
      return groupBy(state.filters, (f: any) => {
        return f.route;
      });
    },
  },

  mutations: {
    setSearch: set("search"),
    setFilters: set("filters"),
    setFilterDrawer: set("filterDrawer"),
    toggleFilterDrawer: toggle("filterDrawer"),
    setOpenOnRoutes: set("openOnRoutes"),
  },

  actions: {},
};
