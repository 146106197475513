
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { Bom, BomMaterial, PackoutGood } from "@eaua/model";
import { cloneDeep, find, get } from "lodash";
import { DateTime } from "luxon";
import _ from "lodash";
import { getIcon } from "@/util/icons";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";

@Component
export default class MaterialsList extends Vue {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  materials!: Array<BomMaterial>;

  @Prop({ default: false })
  showAmount!: boolean;

  //LOCAL VARIABLES --------------------------------------------------------------
  headers: Array<any> = [
    {
      text: "Item Number",
      value: "item_number",
    },
    {
      text: "Item",
      value: "item_name",
    },
    {
      text: "Lot",
      value: "lot_number",
    },
    {
      text: "Batch",
      value: "batch_number",
    },
    {
      text: "Harvested",
      value: "harvest_date",
    },
    {
      text: "Consumed",
      value: "amount",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
    },
  ];

  actions: Array<any> = [
    {
      callback: "open-remove-confirmation-dialog",
      icon: getIcon("base", "delete"),
      isIcon: true,
      label: "Delete",
      disabled: (item: any) => {
        return !(item.completed === null);
      },
    },
  ];

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  events: any = [
    {
      name: "confirm-remove-batch-item",
      function: this.removeLot,
    },
    {
      name: "open-remove-confirmation-dialog",
      function: this.showRemoveDialog,
    },
  ];

  // ---------------------------------------------------------------------------
  // REFS
  // ---------------------------------------------------------------------------

  @Ref("remove-confirmation-dialog")
  readonly removeConfirmationDialog!: any;

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  get bom(): Bom {
    return new Bom(this.$store.state.boms.currentBom);
  }

  get currentBatches(): Array<any> {
    return this.$store.state.packout.currentGoodBatches;
  }

  // This is a list of materials, not grouped by batch.
  get materialList(): Array<any> {
    let currentBatch = this.currentBatches;
    let materialList: any = [];
    if (get(this.bom, "materials", false)) {
      for (let bm of this.materials) {
        let bomMaterial: any = find(this.bom.materials, {
          item_id: bm.lot.item_id,
        });
        let materialItem: any = {};
        let itemIndex = this.findItemIndex(currentBatch, bm);
        materialItem.batch_number = currentBatch[itemIndex].number;
        materialItem.consumed = bm.before_quantity - bm.after_quantity;
        materialItem.uuid = bm.uuid;
        materialItem.item_name = get(bm, "lot.inventory_item.name", "");
        materialItem.batch_id = bm.batch_id;
        materialItem.uom_name = get(bm, "lot.inventory_item.uom.name", "");
        materialItem.item_number = get(bm, "lot.inventory_item.number", "");
        materialItem.lot_number = bm.lot.number;
        materialItem.created = bm.created;
        materialItem.lot_id = bm.lot_id;
        materialItem.completed = currentBatch[itemIndex].completed;
        materialItem.amount =
          materialItem.consumed + " " + materialItem.uom_name;
        materialItem.harvest_date = DateTime.fromISO(bm.lot.created).toFormat(
          "MM/dd"
        );
        materialItem.use_by = bm.use_by;

        materialList.push(materialItem);
      }
    }

    return _.sortBy(materialList, ["batch_number", "created"]).reverse();
  }

  findItemIndex(currentBatch: any, bm: any) {
    let itemIndex = currentBatch.findIndex((x: any) => x.uuid === bm.batch_id);
    return itemIndex !== -1 ? itemIndex : 0;
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async removeLot(item: any) {
    await this.$store
      .dispatch("packout/deleteGoodMaterial", {
        goodMaterial: item,
      })
      .then((success) => {
        EventBus.$emit("retrieve-packout-good-and-calculate-use-by", item);
        emitNotification({
          priority: "low",
          message: "Deleted lot from batch",
          title: "Success",
          type: "success",
        });
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to delete lot. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });
  }

  // Universal confirmation dialog.
  showRemoveDialog(item: any = {}): void {
    this.removeConfirmationDialog.open({
      passedObject: item,
      passedTitle: `Remove ${item.lot_number}`,
      passedMessage: `Are you sure you want to remove ${item.amount} of ${item.lot_number} ${item.item_name}?`,
    });
  }

  isRemoveDisabled(batchItem: any = {}) {
    if (batchItem.completed == null) return false;
    return true;
  }
}
