
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import isEmpty from "lodash/isEmpty";
import { getIcon } from "@/util/icons";
import { generateUniqueKey } from "@/util/helpers";
import Dialog from "@/modules/core/mixins/Dialog.vue";

@Component
export default class BaseDialog extends Mixins(Dialog) {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props
  @Prop({ default: "destination" })
  destination!: string;

  @Prop({ default: true })
  persistent!: boolean;

  @Prop({ default: false })
  eager!: boolean;

  @Prop({ default: true })
  dismissable!: boolean;

  @Prop({ default: true })
  scrollable!: boolean;

  @Prop({ default: false })
  fullscreen!: boolean;

  @Prop({ default: "800" })
  maxWidth!: string | number;

  @Prop({ default: "both" })
  borders!: any;

  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: true })
  resetOnClose!: boolean;

  get hasActionsSlot() {
    return !!this.$slots.actions;
  }

  get hasTitle() {
    return !isEmpty(this.title);
  }

  // METHODS ------------------------------------------------------
  getIcon(icon: string) {
    return getIcon("base", icon);
  }

  generateUniqueKey(item: any) {
    return generateUniqueKey(item);
  }
}
