
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import { required, requiredIf } from "vuelidate/lib/validators";
import maxValue from "@/util/validators/maxValue";
import exclusiveMinValue from "@/util/validators/exclusiveMinValue";
import { getIcon } from "@/util/icons";
import Form from "@/modules/core/mixins/Form.vue";
import MaterialCheck from "@/modules/core/mixins/LotMaterialCheck.vue";
import clone from "lodash/clone";

@Component({
  validations: {
    lotNumber: { required },
    amount: {
      maxValue: maxValue("max"),
      minValue: exclusiveMinValue("min"),
      required: requiredIf("includeAmount"),
    },
  },
})
export default class AddBomLotForm extends Mixins(MaterialCheck, Form) {
  [key: string]: any;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "add-bom-lot-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  @Prop({ default: false })
  extendExpiry!: boolean;

  @Prop({ default: false })
  includeAmount!: boolean;

  @Prop({ default: () => [] })
  overrides!: Array<string>;

  @Prop({ default: () => [] })
  used!: Array<string>;

  // LOCAL VARIABLES -----------------------------------------------------------
  amount: number | null = null;
  events: Array<IEventBusEvent> = [];
  lotNumber: string | number = "";
  min = 0;
  timeout: any = null;
  loadingLot = false;
  attemptedSearch = false;

  retryButton: any = {
    callback: "retry",
    callbackType: "self",
    icon: getIcon("base", "reload"),
    isIcon: true,
    label: "Retry",
  };

  headers: any[] = [
    {
      text: "Item",
      value: "item_name",
    },
    {
      text: "Location",
      value: "location_name",
    },
    {
      text: "Created",
      value: "display_created",
    },
    {
      text: "On hand",
      value: "on_hand",
      displayValue: "displayOnHand",
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-add-bom-lot-dialog-${this.dialogId}`,
        label: "Cancel",
      },
      {
        callback: `add-bom-lot-${this.dialogId}`,
        text: this.isLoading,
        label: "Add Lot",
        loading: this.isLoading,
        disabled: this.saveDisabled,
      },
    ];
  }

  get amountParams(): any {
    return {
      min: this.min,
      minExclusive: true,
      max: this.max,
    };
  }

  get getAlertType() {
    if (!this.isValidLot) {
      return "error";
    } else if (this.packByWarningMessage != "") {
      return "warning";
    }
    return "success";
  }

  get amountSuffix(): string {
    return this.currentLot.uom_name || "";
  }

  get displayLot(): any {
    return this.currentLot.getDisplayVersion();
  }

  get iconTooltip(): any {
    if (this.hasLot) {
      return {
        value: getIcon("base", "complete"),
        label: "Lot Found",
        color: "primary",
      };
    }
    return {
      value: getIcon("base", "error"),
      label: "Not Found",
      color: "error",
    };
  }

  get max(): number {
    return this.currentLot.on_hand;
  }

  get saveDisabled() {
    if (!this.isValidLot || this.loadingLot) return true;
    if (
      this.includeAmount &&
      (this.getValidationObject("amount") || {}).$invalid
    ) {
      return true;
    }
    return false;
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("lotNumber", { immediate: true })
  onLotChanged() {
    if (+this.lotNumber !== this.currentLot.number && +this.lotNumber > 100) {
      this.$v.$reset();
      this.amount = null;
      this.scheduleLotUpdate();
    }
  }

  @Watch("used", { immediate: true })
  onUsedChanged() {
    this.usedLots = clone(this.used);
  }

  @Watch("overrides", { immediate: true })
  onOverrideItemsChanged() {
    this.overrideItems = clone(this.overrides);
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  created() {
    this.events = [
      {
        name: `add-bom-lot-${this.dialogId}`,
        function: this.addLot,
      },
    ];
    // Prevent substitutions from being cleared every time the
    // lot dialog is closed
    this.resetSubstitutionsOnDestroy = false;
  }

  mounted() {
    // Check if the expiration date should be extended
    this.extendedExpiry = this.extendExpiry;
    this.$store.commit("lots/reset", "currentLot");
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  async addLot() {
    EventBus.$emit("add-bom-lot", {
      lotId: this.currentLot.uuid,
      amount: this.amount,
    });
    EventBus.$emit(`close-add-bom-lot-dialog-${this.dialogId}`);
  }

  async retrieveLot() {
    this.loadingLot = true;
    await this.$store.dispatch("lots/retrieveCurrentLot", {
      number: this.lotNumber,
      useView: true,
    });
    this.loadingLot = false;
    this.attemptedSearch = true;
  }

  /**
   * Adds a delay before looking up a lot. This gives the user
   * 800 ms between each keystroke, and prevents over-querying
   */
  scheduleLotUpdate() {
    this.$store.commit("lots/reset", "currentLot");
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(this.retrieveLot, 800);
  }

  /**
   * Used by the form to allow submit on enter key
   */
  submitForm() {
    if (this.saveDisabled) return null;
    return this.addLot();
  }
}
