
import { Vue, Component, Prop } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import { ICellRenderer, ICellRendererParams } from "ag-grid-community";

@Component
export default class ActionsCell extends Vue implements ICellRenderer {
  params!: ICellRendererParams;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  get actions(): Array<any> {
    // @ts-ignore
    return (this.params.value || []).map((v: any) => {
      v.callbackParams = cloneDeep(this.params.data);
      return v;
    });
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
