
import { Component, Mixins } from "vue-property-decorator";
import Notification from "../../mixins/Notification.vue";

@Component
export default class AppNotificationBannerInDialog extends Mixins(
  Notification
) {
  created() {
    this.inDialog = true;
  }
}
