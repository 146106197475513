
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import union from "lodash/union";
import BaseSelection from "@/modules/core/mixins/BaseSelection.vue";

@Component
export default class LineSelection extends Mixins(BaseSelection) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: () => [] })
  moduleIds!: string | string[];

  @Prop({ default: false, type: Boolean })
  includeModuleName!: Boolean;

  @Prop({ default: false, type: Boolean })
  includeTargetedOrders!: Boolean;

  // LOCAL VARIABLES -----------------------------------------------------------
  defaultLabel: string = "Line";

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns an array of line options for a drop down.
   * If not required, adds `None` option to beginning of list.
   * Uses `options` prop if passed in, or gets lines from the store.
   * @returns {Array}
   */
  get lineOptions(): Array<any> {
    let options: any[] = [];
    if (!this.required && !this.allowMultiple) {
      options.push({ value: null, text: "None" });
    }
    if (this.options)
      return this.filterUsedOptions(union(options, this.options));
    return this.filterUsedOptions(
      union(
        options,
        this.$store.getters["facilities/getModuleLineOptions"](
          this.includeModuleName
        )
      )
    );
  }

  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------

  @Watch("lineOptions.length", { immediate: true })
  onOptionsChanged() {
    // If there is only one option, select it
    if (this.lineOptions.length === 1)
      this.selected = this.lineOptions[0].value;
  }

  @Watch("moduleIds")
  onModuleChanged() {
    this.resetData();
    this.retrieveData();
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Tells the store to update lines from the database
   */
  async retrieveData(): Promise<any> {
    if (
      this.moduleIds &&
      this.$store.state.facilities.lineOptions.length === 0
    ) {
      this.isLoading = true;
      await this.$store.dispatch("facilities/retrieveLines", {
        moduleIds: this.moduleIds,
        includeTargetedOrders: this.includeTargetedOrders,
        forSelection: true,
      });
      this.isLoading = false;
    }
  }

  /**
   * Resets lines
   */
  resetData() {
    this.$store.commit("facilities/reset", "lineOptions");
  }
}
