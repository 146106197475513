import { RouteConfig } from "vue-router/types/router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const storageRoutes: RouteConfig = {
  path: "/storage",
  component: () => import("@/modules/storage/views/Index.vue"),
  children: [
    {
      path: "",
      component: () => import("@/modules/storage/views/Entry.vue"),
      name: "StorageEntry",
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("storage");
    next();
  },
};
