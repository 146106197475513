
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  IEventBusEvent,
  setListeners,
  unsetListeners,
} from "@/util/eventbus";
import Form from "@/modules/core/mixins/Form.vue";
import Profile, { IProfile } from "@/modules/core/models/shared/Profile";
import { emitNotification } from "@/util/notifications";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("user", ["profile"]),
  },
})
export default class TransferControlForm extends Mixins(Form) {
  [key: string]: any;
  profile!: IProfile;
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "transfer-control-actions" })
  destination!: string;

  @Prop({ default: 0 })
  dialogId!: number | null;

  @Prop({ default: "" })
  batchId!: string;

  @Prop({ default: "" })
  goodId!: string;

  isLoading: boolean = false;

  events: Array<IEventBusEvent> = [
    {
      name: "transfer-control",
      function: this.transferControl,
    },
  ];

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------

  /**
   * Returns array of actions depending on current state
   */
  get actions(): Array<any> {
    return [
      {
        callback: `close-transfer-control-dialog-${this.dialogId}`,
        label: "Cancel",
      },
      {
        callback: "transfer-control",
        text: this.isLoading,
        label: "Transfer Control",
        loading: this.isLoading,
        disabled: this.isLoading,
      },
    ];
  }

  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------

  mounted() {
    setListeners(this.events);
  }

  beforeUpdate() {
    setListeners(this.events);
  }

  beforeDestroy() {
    unsetListeners(this.events);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  // Build profile object for sending to weigh.
  // We need the good Id and batch Id. we flag completed true
  // to let weigh know the batch is complete and ready.
  buildProfile() {
    let profile = new Profile(this.profile || {});
    if (!profile.details) profile.details = {};
    profile.details.current_work_item = {
      uuid: this.goodId,
      batch_id: this.batchId,
      completed: true,
    };
    return profile.getSaveVersion();
  }

  async transferControl(): Promise<void> {
    await this.$store
      .dispatch("user/saveProfile", {
        profile: this.buildProfile(),
      })
      .then(() => {
        emitNotification({
          priority: "low",
          message: "Updated current mixing item.",
          title: "Success",
          type: "success",
        });
        this.routeToWait();
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to save current mixing item. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });
  }

  routeToWait(): void {
    this.$router.push({ name: "MixingWaitingWeight" });
  }
}
