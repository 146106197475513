import { set, setDeep, retrieveSet } from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import find from "lodash/fp/find";
import axios from "axios";
import { CropOrderDetailType, CropStage } from "@eaua/model";

const STAGE_STORE_DEFAULTS = (): Record<string, any> => {
  return {
    stages: [],
    currentStage: {},
    recipeStages: [],
    currentRecipeStage: {},
    detailTypes: [],
    refreshInterval: 60000,
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = STAGE_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setStages: setDeep("stages"),
  setCurrentStage: set("currentStage"),
  setDetailTypes: setDeep("detailTypes"),
  reset(state: any, property: string) {
    state[property] = STAGE_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  /**
   * Returns a detail type from the store, given its id
   */
  getOrderDetailTypeById: (state: any) => (id: string) => {
    return state.detailTypes.find((t: any) => t.uuid === id) || {};
  },

  /**
   * Returns a detail type from the store, given its value
   */
  getOrderDetailTypeByValue: (state: any) => (value: string) => {
    return state.detailTypes.find((t: any) => t.value === value) || {};
  },

  getOrderDetailTypesForStatusIcons: (state: any) => {
    let stages: any = {};
    for (const dt of state.detailTypes || []) {
      stages[dt.value] = new CropOrderDetailType(dt).getIconVersion();
    }
    return stages;
  },

  /**
   * Returns a stage given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getStageByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.stages) || {};
    },

  /**
   * Returns a stage given a value
   * @param {string} uuid
   * @returns {Object}
   */
  getStageByValue:
    (state: any) =>
    (value: string = "") => {
      if (!value) return {};
      return (
        find(
          { value: value },
          (state.stages || []).map((s: any) => new CropStage(s))
        ) || {}
      );
    },

  /**
   * Returns an array of stage options for a dropdown
   * @returns {Array}
   */
  getStageOptions:
    (state: any) =>
    (includeHidden: boolean = false) => {
      return state.stages.reduce((acc: any, s: any) => {
        if (!s.hidden || includeHidden) {
          const stage = new CropStage(s).getDisplayVersion();
          acc.push({
            value: s.uuid,
            text: stage.name,
            icon: {
              ...stage.icon,
              size: "16",
              class: "mr-2",
            },
          });
        }
        return acc;
      }, []);
    },

  /**
   * Returns an array of filtered stages for the route `research-stages`
   * @returns {Array}
   */
  getFilteredStages: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["crop-stages"] || [];
    let search = rootState.filter.search["crop-stages"] || "";
    return filterItems(state.stages, filters, search);
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // STAGES -----------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Saves a stage.
   * Retrieves all stages using `retrieveStages`.
   * @param context Store module context
   * @param {Object} stage Stage save-version object
   */
  saveStage: function (context: any, { stage }: any): Promise<void> | void {
    let stageTemplate = new CropStage();
    let upsertQuery: string = stageTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: stage,
          },
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveStages");
            resolve();
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves all stages, sorted by name.
   * Commits stages using `setStages`.
   * @param context Store module context
   */
  retrieveStages: function (context: any) {
    let stageTemplate = new CropStage();
    let where: string = "";
    let returnQuery: string = stageTemplate.getReturnQuery(
      where,
      "{rel_order_detail_type: {name: asc}}"
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setStages", stageTemplate.table);
  },

  // ORDER DETAIL TYPES ------------------------------------------------------
  // -------------------------------------------------------------------------

  /**
   * Retrieves all order detail types, sorted by name.
   * Commits types using `setDetailTypes`.
   * @param context Store module context
   */
  retrieveDetailTypes: function (context: any, { where = null }: any = {}) {
    const template = new CropOrderDetailType();
    let returnQuery: string = template.getReturnQuery(
      where ? "$where" : "",
      "$order_by",
      template.queryReturnWithStages
    );

    return retrieveSet(context, returnQuery, "setDetailTypes", template.table, {
      where: where || undefined,
      order_by: { name: "asc" },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
