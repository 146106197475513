
import { mapState } from "vuex";
import { Vue, Component, Watch } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";

@Component({
  computed: {
    ...mapState("filter", ["filterDrawer", "openOnRoutes"]),
  },
})
export default class FilterDrawer extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Local Variables - Replaces data()
  filterDrawer!: boolean; // Define the type for filterDrawer
  openOnRoutes!: any;
  drawerProps: any = {
    app: true,
    clipped: true,
    right: true,
    "disable-resize-watcher": true,
  };
  dialogProps: any = {
    fullscreen: true,
    hideOverlay: true,
    transition: "slide-x-reverse-transition",
  };

  // COMPUTED -----------------------------------------------------
  get showDrawer() {
    return this.filterDrawer;
  }
  set showDrawer(val) {
    this.$store.commit("filter/setFilterDrawer", val);
  }

  get drawerStyle() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return { width: "100%" };
    } else return null;
  }

  get wrapper() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return "v-dialog";
    }
    return "v-navigation-drawer";
  }

  get componentProps() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return this.dialogProps;
    }
    return this.drawerProps;
  }

  get routePath() {
    return this.$route.path || "";
  }

  // Gives the filter drawer temporary memory
  // Helps prevent it staying open on pages with no filters
  @Watch("showDrawer")
  onDrawerChange(newValue: boolean, oldValue: boolean) {
    let temp: any = cloneDeep(this.openOnRoutes);
    if (this.routePath) temp[this.routePath] = this.showDrawer;
    this.$store.commit("filter/setOpenOnRoutes", temp);
  }

  @Watch("routePath")
  onRouteChange(newValue: string, oldValue: string) {
    if (this.openOnRoutes[this.routePath]) {
      this.showDrawer = this.openOnRoutes[this.routePath];
    } else this.showDrawer = false;
  }
}
