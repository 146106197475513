import { Vue as _Vue } from "vue/types/vue";
import { VueModule } from "vue-modules";
import VueRouter from "vue-router";
import { storageRoutes } from "./router/storage-routes";

export class StorageModule implements VueModule {
  readonly name = "storage";
  constructor(private router: VueRouter) {}

  install(Vue: typeof _Vue) {
    this.router.addRoute(storageRoutes);
  }
}
