
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { EventBus, setListeners, unsetListeners } from "@/util/eventbus";
import { emitNotification } from "@/util/notifications";
import {
  Bom,
  PackoutOrderLine,
  PackoutMaterial,
  PackoutGood,
} from "@eaua/model";
import Form from "@/modules/core/mixins/Form.vue";
import DataRefresh from "@/modules/core/mixins/DataRefresh.vue";
import SocketClientMixin from "@/modules/core/mixins/SocketClientMixin.vue";
import { get } from "lodash";

@Component
export default class PackedAllForm extends Mixins(
  Form,
  DataRefresh,
  SocketClientMixin
) {
  [key: string]: any;

  // PROPS ---------------------------------------------------------------------
  @Prop({ default: 0 })
  createdCaseCount!: number;

  // LOCAL VARIABLES -----------------------------------------------------------
  lotsLoading = false;
  caseCountLoading = false;
  qtysValid: boolean = true;
  totalPacked: number = 0;
  qtyToFulfill: number = 0;
  goodMaterialsToSave: any[] = [];
  completedGoodMessage = "This Good Has Already Been Completed";
  headers: any[] = [
    { text: "Customer", value: "customer" },
    { text: "Order", value: "salesOrderNumber" },
    { text: "Ordered", value: "requestedQuantity" },
    { text: "Allocated", value: "allocated" },
    {
      text: "From Inv.",
      value: "from_inventory",
    },
    {
      text: "Actual",
      value: "actual_quantity",
      type: "number-field",
      config: {
        label: "Qty",
        min: 0,
        required: true,
      },
    },
  ];
  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns current Packout Good from store
   * @returns {Object}
   */
  get currentGood() {
    return new PackoutGood(this.$store.state.packout.currentGood, this.$store);
  }

  /**
   * Returns the recipe for this good.
   * @returns {Object}
   */
  get currentRecipe(): Bom {
    return new Bom(this.$store?.state?.boms?.currentBom || {});
  }

  /**
   * Returns lots from store grouped by item id
   */
  get packagingMaterialLots(): Record<string, any> {
    return this.$store.state.lots?.itemLots || {};
  }

  get packagingMaterials() {
    return (this.currentRecipe?.materials || []).filter((rm: any) => {
      const categoryName = rm?.inventory_item?.categoryByTagId?.name;
      return categoryName === "packaging" && !!rm.item_id;
    });
  }

  get packoutMaterials() {
    return this.packagingMaterials.reduce((acc: any, rm: any) => {
      let amount = rm.quantity * this.confirmedCaseCount;
      const categoryName = rm?.inventory_item?.categoryByTagId?.name;

      for (const lot of this.packagingMaterialLots[rm.item_id] || []) {
        const afterQuantity = lot.on_hand - amount;
        acc.push(
          new PackoutMaterial(
            {
              lot_id: lot.uuid,
              lot: lot,
              before_quantity: lot.on_hand,
              packout_good_id: this.currentGood.uuid,
              after_quantity: afterQuantity > 0 ? afterQuantity : 0,
              type: categoryName,
              recipe_material_id: rm.uuid,
              status: "complete",
            },
            this.$store
          )
        );
        if (afterQuantity < 0) {
          amount = Math.abs(afterQuantity);
        } else {
          break;
        }
      }
      return acc;
    }, []);
  }

  /**
   * Returns text color based upon matching rel_lots to scannedCaseLots
   * @returns {string}
   */
  get scannedCaseAmountMsgColor() {
    return this.confirmedCaseCount !== this.createdCaseCount
      ? "color: #FF0000"
      : "color: #000000";
  }
  /**
   * Returns number of current packout good's inventory transactions
   * Filter out lot created on lot header creation
   * @returns {number}
   */
  get confirmedCaseCount() {
    return (
      get(this.$store, "state.packout.confirmedCaseCount.sum.amount", 0) || 0
    );
  }

  get isGoodCompleted(): boolean {
    return !!this.currentGood.completed;
  }

  /**
   * Returns msg of how many label created vs how many labels scanned
   * @returns {string}
   */
  get scannedCaseAmountMsg() {
    return `${this.confirmedCaseCount} OF ${this.createdCaseCount} CASES SCANNED`;
  }
  /**
   * Returns a scannedCaseLots amount and packout item name
   * @returns {string}
   */
  get itemName() {
    return `${this.confirmedCaseCount}x ${this.currentGood.inventory_item.name}`;
  }
  /**
   * Returns a display version array of packout lines
   * @returns {Array}
   */
  get mappedLines() {
    let lines: any = [];
    if (this.currentGood.packout_lines) {
      for (let pl of this.currentGood.packout_lines) {
        // Default actual to allocated, if it's not set
        if (!pl.actual_quantity) {
          pl.actual_quantity = pl.allocated;
        }
        lines.push(new PackoutOrderLine(pl).getDisplayVersion());
      }
    }
    return lines;
  }
  /**
   * Returns array of actions depending on current state
   * @returns {Array}
   */
  get actions() {
    let actions = [
      {
        callback: "close-packed-all-dialog",
        label: "Cancel",
      },
      {
        callback: "save-good",
        disabled:
          this.lotsLoading || this.caseCountLoading || this.isGoodCompleted,
        text: this.isLoading,
        label: "Confirm Complete",
        loading: this.isLoading,
      },
    ];
    // Hide close button if loading
    if (this.isLoading) actions = actions.slice(1);
    return actions;
  }
  // ---------------------------------------------------------------------------
  // EVENTS
  // ---------------------------------------------------------------------------
  @Watch("actions", { immediate: true })
  emitActions() {
    this.$emit("update-packed-all-actions", this.actions);
  }

  @Watch("packagingMaterials", { deep: true, immediate: true })
  onMaterialsChanged() {
    if (this.packagingMaterials.length > 0) {
      this.retrievePackagingLots();
    }
  }
  // ---------------------------------------------------------------------------
  // LIFECYCLE EVENTS
  // ---------------------------------------------------------------------------
  mounted() {
    setListeners(this.events);
    this.retrieveConfirmedCaseCount();
  }
  beforeUpdate() {
    setListeners(this.events);
  }
  beforeDestroy() {
    unsetListeners(this.events);
  }

  events: any = [
    {
      name: "save-good",
      function: this.saveGoodMaterials,
    },
  ];
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  markAsCompleted() {
    this.$emit("markAsCompleted", true);
  }

  async retrieveConfirmedCaseCount() {
    this.caseCountLoading = true;
    await this.$store.dispatch("packout/retrieveConfirmedCaseCount", {
      originatorId: this.currentGood?.uuid,
      headerId: this.currentGood?.lot_header_id,
    });
    this.caseCountLoading = false;
  }

  async retrievePackagingLots() {
    this.lotsLoading = true;
    await this.$store.dispatch("lots/retrieveLotsByItemIds", {
      itemIds: this.packagingMaterials.map((m: any) => m.item_id),
      facilityId: this.$store.state.facilities.currentFacilityUuid,
    });
    this.lotsLoading = false;
  }

  async saveGoodMaterials() {
    this.isLoading = true;
    if (this.packoutMaterials?.length > 0) {
      await this.$store.dispatch("packout/saveGoodMaterial", {
        material: this.packoutMaterials.map((m: any) => m.getSaveVersion()),
      });
    }
    await this.$store.dispatch("packout/retrieveCurrentPackoutGood", {
      uuid: this.currentGood.uuid,
    });
    this.save();
  }
  /**
   * Dispatches `savePackoutGood` in the store. Emits message upon
   * success or failure.
   */
  async save() {
    this.isLoading = true;
    let name: string = this.currentGood.name;
    this.currentGood.quantity = this.confirmedCaseCount;
    await this.$store
      .dispatch("packout/savePackoutGood", {
        good: this.currentGood.getCompleteVersion(this.mappedLines),
      })
      .then((success) => {
        this.markAsCompleted();
        EventBus.$emit("close-packed-all-dialog");
        emitNotification({
          priority: "low",
          message: `Saved ${name}`,
          title: "Success",
          type: "success",
        });
        this.upsertPackoutGoods(this.currentGood.uuid);
      })
      .catch((error) => {
        emitNotification({
          inDialog: true,
          message: `Failed to save ${name}. ${error.message}`,
          title: "Error",
          type: "error",
        });
      });
    this.isLoading = false;
  }
}
